import * as consts from '../../consts/consts';

import { Card, CardBody, Col, Input, Row } from 'reactstrap';
import { Features, Organization, Person, ExtendedPerson } from '../../types';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  SKILL_TYPE_BEHAVIOR,
  SKILL_TYPE_EXPERIENCE,
} from '../../utils/models/Skill';
import {
  getContributionPerson,
  getContributionsForPerson,
  getUniqueSkillWordCloudsFromContribution,
} from '../../utils/models/Activity';
import { isEnabled, prepTagsForSubmit } from '../../utils/util/util';

import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import Loading from '../Widgets/Loading';
import ModalContributionEditor from '../Activities/ModalContributionEditor';
import PersonProfileActivities from './PersonProfileActivities';
import PersonProfileAwardsSummary from './PersonProfileAwardsSummary';
import PersonProfileCompleteness from './PersonProfileCompleteness';
import PersonProfileCredentialsSummary from './PersonProfileCredentialsSummary';
import PersonProfileIntroSummary from './PersonProfileIntroSummary';
import PersonProfileSkillsSummary from './PersonProfileSkillsSummary';
import PersonTimelineActivities from './PersonTimelineActivities';
import PrependedInput from '../Widgets/Inputs/PrependedInput';
import { connect } from 'react-redux';
import { peopleObjectsAreEqual } from '../../utils/models/Person';
import { useAuth0 } from '@auth0/auth0-react';

type Props = {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
  features: Features;
  person: ExtendedPerson;
  isMe: boolean;
  setCreateActivityIsOpen?: (
    value: boolean | ((prevState: boolean) => boolean)
  ) => void;
  filterName?: string;
  setFilterName?: (value: string | ((prevState: string) => string)) => void;
  openCreateActivityDialog?: (type: () => void) => void;
  feedbackList?: object[];
  setPerson: (value: Person | ((prevState: Person) => Person)) => void;
  showPerformance: boolean;
};

const PersonDashboard: FC<Props> = (props: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const { formatMessage } = useIntl();

  const propsSetFilterName = props.setFilterName;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [activities, setActivities] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editIntroIsOpen, setEditIntroIsOpen] = useState(false);
  const [editSkillsIsOpen, setEditSkillsIsOpen] = useState(false);
  const [editBehaviorsIsOpen, setEditBehaviorsIsOpen] = useState(false);
  const [editContributionModal, setEditContributionModal] = useState(false);
  const toggleEditContributionModal = () =>
    setEditContributionModal(!editContributionModal);
  const [currentActivity, setCurrentActivity] = useState(null);

  const { user } = useAuth0();

  const userSub = user?.sub;
  const currentOrgId = props.currentOrganization?.id;
  const isMe = props.isMe;
  const personId = props.person.id;

  const onContributionModalClosed = useCallback(() => {
    setEditContributionModal(false);
  }, []);

  // fetch activities associated with this person
  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (userSub && currentOrgId && personId) {
      // @ts-expect-error
      ElasticsearchAPI.getActivitiesForContributorPerson(
        userSub,
        props.currentProxyPerson,
        currentOrgId,
        personId,
        props.filterName,
        (newActivities) => {
          if (isMounted) {
            setActivities(newActivities);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
    // reload page when new activities fetched
  }, [
    currentOrgId,
    isMounted,
    personId,
    props.currentProxyPerson,
    props.filterName,
    userSub,
  ]);

  const onChangeFilter = useCallback(
    (e) => {
      // @ts-expect-error
      propsSetFilterName(e.target.value);
    },
    [propsSetFilterName]
  );

  const person: ExtendedPerson = useMemo(() => props.person, [props.person]);

  const incomingFeedbackList = useMemo(() => {
    // only get feedback for which the current person is one of the subjects
    return props.feedbackList?.filter(
      (f) =>
        // @ts-expect-error
        f.subject_people?.length > 0 &&
        // @ts-expect-error
        f.subject_people.findIndex((p) => peopleObjectsAreEqual(p, person)) !==
          -1
    );
  }, [props.feedbackList, person]);

  const onClickAcceptCredit = useCallback((activity) => {
    setCurrentActivity(activity);
    setEditContributionModal(true);
  }, []);

  const onActivityUpdated = useCallback(
    (activity) => {
      setActivities(
        // @ts-expect-error
        activities.map((a) => (activity.id === a.id ? activity : a))
      );
    },
    [activities]
  );

  const onSubmitContribution = useCallback(
    (contribution) => {
      const updatedActivity = {
        // @ts-expect-error
        ...currentActivity,
        // @ts-expect-error
        contributions: currentActivity.contributions.map((c) =>
          c.id?.toString() === contribution.id?.toString() ? contribution : c
        ),
      };
      return onActivityUpdated(updatedActivity);
    },
    [currentActivity, onActivityUpdated]
  );

  if (errorMessage) {
    return (
      <Card>
        <CardBody>{errorMessage}</CardBody>
      </Card>
    );
  }

  if (!activities || !props.person) {
    return <Loading />;
  }

  // helper function for submitting data to server
  const prepTagsForSubmitCallback = (o) => prepTagsForSubmit(o, currentOrgId);

  const relevantContributions = getContributionsForPerson(activities, person);

  const experienceSkillsFromContributions =
    getUniqueSkillWordCloudsFromContribution(
      relevantContributions,
      true,
      // @ts-expect-error
      SKILL_TYPE_EXPERIENCE
    );

  const behaviorSkillsFromContributions =
    getUniqueSkillWordCloudsFromContribution(
      relevantContributions,
      true,
      // @ts-expect-error
      SKILL_TYPE_BEHAVIOR
    );

  // @ts-expect-error
  const hasActivities = activities?.length > 0;

  // feedback is a historic feature from our first client's first cycle; it's no longer used
  // given how heavy-weight it was for people to fill out, so we default it to off on the
  // person dashboard, but it's defaulted on in the person's perf report.
  const showFeedback = false;

  return (
    <Row>
      <Col md={8} className="order-2 order-md-1">
        <Row className="pb-4 align-items-center">
          <Col>
            <PrependedInput iconClassName={'fe fe-search'}>
              <Input
                type="search"
                className="form-control form-control-prepended dropdown-toggle list-search"
                placeholder={formatMessage({
                  id: 'app.views.person.person_dashboard.filter_by_activity_name',
                  defaultMessage: 'Filter by activity name',
                })}
                value={props.filterName}
                onChange={onChangeFilter}
              />
            </PrependedInput>
          </Col>
        </Row>
        {props.filterName && !hasActivities && (
          <div className="text-center mb-4">
            <span className="text-muted">
              <FormattedMessage
                id="app.views.person.person_dashboard.no_results_found"
                defaultMessage="No results found"
              />
            </span>
          </div>
        )}
        {hasActivities && (
          <>
            {isMe && currentActivity && (
              <ModalContributionEditor
                isOpen={editContributionModal}
                onClosed={onContributionModalClosed}
                toggle={toggleEditContributionModal}
                activity={currentActivity}
                contributionIndex={
                  // @ts-expect-error
                  currentActivity?.contributions &&
                  // @ts-expect-error
                  currentActivity.contributions.findIndex((c) =>
                    peopleObjectsAreEqual(getContributionPerson(c), person)
                  )
                }
                onSubmitActivity={onActivityUpdated}
                callback={onSubmitContribution}
                hideConfettiModal={true}
                hideFeedbackRequestPrompt={
                  !isEnabled(
                    props.features,
                    consts.FLAGS
                      .ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS
                  )
                }
              />
            )}
            <PersonTimelineActivities
              person={person}
              activities={activities}
              openCreateActivityDialog={() =>
                // @ts-expect-error
                props.setCreateActivityIsOpen(true)
              }
              showFeedback={showFeedback}
              feedbackList={showFeedback ? incomingFeedbackList : undefined}
              onClickAcceptCredit={isMe ? onClickAcceptCredit : undefined}
            />
          </>
        )}
        {!props.filterName && !hasActivities && (
          <PersonProfileActivities
            isMe={isMe}
            // @ts-expect-error
            openCreateActivityDialog={props.openCreateActivityDialog}
            person={person}
            activities={activities}
            setActivities={setActivities}
            showEmptyStateIfEmpty={!props.filterName}
            showFeedback={showFeedback}
            feedbackList={showFeedback ? incomingFeedbackList : undefined}
          />
        )}
      </Col>
      <Col md={4} className="order-1 order-md-2">
        {isMe && activities && (
          <PersonProfileCompleteness
            person={person}
            activities={activities}
            editIntro={() => setEditIntroIsOpen(true)}
            editSkills={() => setEditSkillsIsOpen(true)}
            editBehaviors={() => setEditBehaviorsIsOpen(true)}
            // @ts-expect-error
            createActivity={() => props.setCreateActivityIsOpen(true)}
          />
        )}
        {!!person.id && props.features?.operating_manual?.enabled && (
          <PersonProfileIntroSummary
            isMe={isMe}
            person={person}
            isOpen={editIntroIsOpen}
            onClosed={() => setEditIntroIsOpen(false)}
            // @ts-expect-error
            successCallback={props.setPerson}
          />
        )}
        {props.features.skills_and_behaviors?.enabled && (
          <PersonProfileSkillsSummary
            contributions={relevantContributions}
            feedbackList={showFeedback ? incomingFeedbackList : undefined}
            isMe={isMe}
            isOpen={editSkillsIsOpen}
            onClosed={() => setEditSkillsIsOpen(false)}
            // @ts-expect-error
            person={person}
            prepTagsForSubmit={prepTagsForSubmitCallback}
            skillsFromContributions={experienceSkillsFromContributions}
            // @ts-expect-error
            successCallback={props.setPerson}
            type={SKILL_TYPE_EXPERIENCE}
          />
        )}
        {props.features.skills_and_behaviors?.enabled && (
          <PersonProfileSkillsSummary
            contributions={relevantContributions}
            feedbackList={showFeedback ? incomingFeedbackList : undefined}
            isMe={isMe}
            isOpen={editBehaviorsIsOpen}
            onClosed={() => setEditBehaviorsIsOpen(false)}
            // @ts-expect-error
            person={person}
            prepTagsForSubmit={prepTagsForSubmitCallback}
            skillsFromContributions={behaviorSkillsFromContributions}
            // @ts-expect-error
            successCallback={props.setPerson}
            type={SKILL_TYPE_BEHAVIOR}
          />
        )}
        {
          // @ts-expect-error
          props.features.credentials?.enabled && (
            <PersonProfileCredentialsSummary
              isMe={isMe}
              prepTagsForSubmit={prepTagsForSubmitCallback}
              // @ts-expect-error
              credentialIssuances={person.credential_issuances}
              contributions={relevantContributions}
              successCallback={(updatedCredentials) => {
                props.setPerson({
                  ...person,
                  // @ts-expect-error
                  credential_issuances: updatedCredentials,
                });
              }}
            />
          )
        }
        {
          // @ts-expect-error
          props.features.awards?.enabled &&
            // @ts-expect-error
            person.award_issuances?.length > 0 && (
              <PersonProfileAwardsSummary
                // @ts-expect-error
                awardIssuances={person.award_issuances}
              />
            )
        }
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(PersonDashboard));
