import * as consts from '../../consts/consts';

import { Button, Card, CardBody } from 'reactstrap';
import {
  CONTRIBUTION_EDITOR_ATTRIBUTES,
  getQueryWithType,
} from '../Widgets/Inputs/ValidatedInput';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  contributionIsClaimed,
  contributionIsEmpty,
  getActivityType,
  getContributionPerson,
  getMyContributionOrContributionComment,
} from '../../utils/models/Activity';
import { filterUniqueById, prepTagsForSubmit } from '../../utils/util/util';
import {
  getInvalidPeopleError,
  getPersonOrInvitationObjectForSending,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';

import AvatarGroup from '../Widgets/People/AvatarGroup';
import Confetti from 'react-dom-confetti';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import Modal from '../../components/SafeModal';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { continuousFeedbackRequestsAreEnabled } from '../../utils/util/features';
import trophyImg from '../../assets/img/illustrations/trophy.png';
import { useAuth0 } from '@auth0/auth0-react';

const confettiConfig = {
  angle: '90',
  spread: '140',
  startVelocity: '56',
  elementCount: '200',
  dragFriction: '0.09',
  duration: '3440',
  stagger: 0,
  width: '10px',
  height: '14px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const confettiContainerStyle = {
  position: 'fixed',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  pointerEvents: 'none',
};

const ModalContributionEditor: React.FC<Props> = (props: Props) => {
  const skillTagsRef = useRef();
  const intl = useIntl();
  const { formatMessage } = intl;
  const [contributionHasChanged, setContributionHasChanged] = useState(false);
  const [confettiShouldFire, setConfettiShouldFire] = useState(false);
  const [showConfettiModal, setShowConfettiModal] = useState(false);
  const [hasAddedContributors, setHasAddedContributors] = useState(false);
  const toggleConfettiModal = () => setShowConfettiModal(!showConfettiModal);

  const orgId = props.currentOrganizationId;
  const propsToggle = props.toggle;
  const propsCallback = props.callback;
  const propsOnClosed = props.onClosed;
  // @ts-expect-error
  const validDomains = props.currentOrganization?.email_domains;
  const propsOnSubmitActivity = props.onSubmitActivity;
  const propsSetHasUnsavedChanges = props.setHasUnsavedChanges;
  const [apiSkillsRecommendations, setApiSkillsRecommendations] = useState([]);

  const { user } = useAuth0();
  const userSub = user?.sub;

  const activityType = useMemo(
    () => getActivityType(props.activity, intl),
    [props.activity, intl]
  );

  const currentIndex = useMemo(
    () => props.contributionIndex,
    [props.contributionIndex]
  );
  const contribution = useMemo(() => {
    return props.isOpen || showConfettiModal
      ? // @ts-expect-error
        props.activity.contributions[currentIndex]
      : undefined;
  }, [
    currentIndex,
    // @ts-expect-error
    props.activity.contributions,
    props.isOpen,
    showConfettiModal,
  ]);
  const contributionId = useMemo(() => contribution?.id, [contribution?.id]);

  // @ts-expect-error
  const meId = useMemo(() => props.me?.id, [props.me?.id]);
  const person = useMemo(
    () => getContributionPerson(contribution),
    [contribution]
  );
  const personPreferredName = useMemo(
    () => person?.given_name,
    [person?.given_name]
  );
  const personFullName = useMemo(() => person?.full_name, [person]);
  const isMyContribution = useMemo(() => {
    return peopleObjectsAreEqual(person, props.me);
  }, [person, props.me]);

  // reset confetti so it can be fired when clicking the trophy
  useEffect(() => {
    setConfettiShouldFire(false);
  }, [confettiShouldFire]);

  const onConfettiModalOpened = useCallback(() => {
    // show confetti when modal opens
    setConfettiShouldFire(true);
  }, []);

  const onOpened = useCallback(() => {
    // reset modal for reopening later
    setShowConfettiModal(false);
    setContributionHasChanged(false);
    setConfettiShouldFire(false);
    setHasAddedContributors(false);
  }, []);

  const onClosed = useCallback(() => {
    if (propsOnClosed) {
      propsOnClosed();
    }
  }, [propsOnClosed]);

  const onSubmitContribution = useCallback(
    (newContribution) => {
      if (newContribution) {
        propsCallback(newContribution);

        // if this is NOT in Perf mode (i.e. someone doing a performance cycle), show confetti
        // modal which will prompt for another contribution
        if (!props.hideConfettiModal) {
          setShowConfettiModal(true);
        }
      }
    },
    [propsCallback, props.hideConfettiModal]
  );

  const isRoleFieldRequired = useMemo(
    () => isMyContribution || !contributionIsClaimed(contribution),
    [isMyContribution, contribution]
  );

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (isRoleFieldRequired && !obj.contributor_role) {
        errors['contributor_role'] = formatMessage({
          id: 'app.views.activities.modal_contribution_editor.role_required',
          defaultMessage: 'A role is required.',
        });
      }

      if (!obj.description) {
        errors['description'] = formatMessage({
          id: 'app.views.activities.modal_contribution_editor.description_required',
          defaultMessage: 'A description is required.',
        });
      }

      if (!(obj.skills?.length > 0)) {
        errors['skills'] = formatMessage({
          id: 'app.views.activities.modal_contribution_editor.skills_required',
          defaultMessage: 'At least one skill is required.',
        });
      }

      // no requested people required, but throw error if they are invalid
      errors['requested_people'] =
        obj.requested_people?.length > 0
          ? getInvalidPeopleError(
              intl.formatMessage,
              obj.requested_people,
              validDomains
            )
          : null;

      return errors;
    },
    [isRoleFieldRequired, validDomains, intl, formatMessage]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      const preparedObject = {
        ...(object.id
          ? { id: object.id }
          : {
              ...object,
              activity: object.activity?.id
                ? object.activity.id
                : object.activity,
              contributor_person: object.contributor_person
                ? object.contributor_person?.id
                  ? object.contributor_person.id
                  : object.contributor_person
                : object.invitation
                ? undefined
                : meId,
              invitation: object.invitation
                ? object.invitation?.id
                  ? object.invitation.id
                  : {
                      organization: object.invitation.organization,
                      recipient_person: {
                        ...object.invitation,
                        organization: undefined,
                      },
                    }
                : undefined,
            }),
        activity: object.activity?.id ? object.activity.id : object.activity,
        skills: prepTagsForSubmit(object.skills, orgId),
        contributor_person: object.contributor_person?.id
          ? object.contributor_person.id
          : object.contributor_person,
        contributor_role: object.contributor_role,
        description: object.description,
        // send person id or, if a new/invited user, send dict of new fields
        requested_people: object.requested_people?.map((p) =>
          p.id ? p.id : p
        ),
      };

      return preparedObject;
    },
    [meId, orgId]
  );

  const handleMention = useCallback((newMention) => {
    if (newMention._index === 'skills') {
      // @ts-expect-error
      skillTagsRef.current.addTag(newMention);
    }
  }, []);

  const obj = useMemo(
    () => getMyContributionOrContributionComment(meId, contribution),
    [meId, contribution]
  );

  const cancelConfirmationText = useMemo(
    () =>
      isMyContribution
        ? formatMessage({
            id: 'app.views.activities.modal_contribution_editor.every_contribution_matters',
            defaultMessage:
              'Every contribution matters!\n\nAre you sure you want to discard your changes and not accept credit?',
          })
        : formatMessage(
            {
              id: 'app.views.activities.modal_contribution_editor.every_contribution_matters_for_x',
              defaultMessage:
                'Every contribution matters!\n\nAre you sure you want to discard your changes and not give {personFullName} credit?',
            },
            {
              personFullName: person?.full_name ?? '',
            }
          ),
    [isMyContribution, person, formatMessage]
  );

  const onChange = useCallback(() => {
    setContributionHasChanged(true);
  }, []);

  // if a description was already provided previously, header should say "Edit contribution"
  // so it's clear you're editing something existing.
  const submitText = useMemo(
    () =>
      isMyContribution
        ? contribution?.description
          ? formatMessage({
              id: 'app.views.activities.modal_contribution_editor.save',
              defaultMessage: 'Save',
            })
          : formatMessage({
              id: 'app.views.activities.modal_contribution_editor.accept_credit',
              defaultMessage: 'Accept credit',
            })
        : formatMessage({
            id: 'app.views.activities.modal_contribution_editor.give_credit',
            defaultMessage: 'Give credit',
          }),
    [isMyContribution, contribution?.description, formatMessage]
  );

  const acceptedGaveCreditText = useMemo(() => {
    return isMyContribution
      ? formatMessage({
          id: 'app.views.activities.modal_contribution_editor.credit_accepted',
          defaultMessage: 'Credit accepted!',
        })
      : formatMessage({
          id: 'app.views.activities.modal_contribution_editor.credit_given',
          defaultMessage: 'Credit given!',
        });
  }, [isMyContribution, formatMessage]);

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    const objectType = 'contribution';
    const recommendationType = 'skills';

    if (contributionId) {
      ConfirmAPI.getObject(
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.RECOMMENDATIONS,
        // ConfirmAPI.getObject() automatically caches its return value in local
        // storage using the endpoint as a key. In order to leverage this behavior
        // we make the endpoint unique by adding API parameters to it instead of
        // using separate query parameters.
        [contributionId.toString(), objectType, recommendationType].join('-'),
        (recommendations) => {
          if (props.isOpen && recommendations) {
            setApiSkillsRecommendations(recommendations);
          }
        },
        () => {
          setApiSkillsRecommendations([]);
        },
        {
          organization_id: orgId,
        }
      );
    }
    // @ts-expect-error
  }, [contributionId, props.currentProxyPerson, props.isOpen, userSub, orgId]);

  const localSkillRecommendations = useMemo(() => {
    // get recommended skills (recommended for the given person)
    let skills =
      props.contributionsWithRecommendations &&
      props.contributionsWithRecommendations?.find(
        // @ts-expect-error
        (c) => c.id === contributionId
        // @ts-expect-error
      )?.recommended_skills;

    if (!skills) {
      skills = [];
    }

    // add my declared skills from current org (or public domain, i.e.
    // no org) to recommendations
    const mySkillsForOrg =
      props.mySkills?.length > 0
        ? props.mySkills.filter(
            (s) =>
              // @ts-expect-error
              !s.organization_id ||
              // @ts-expect-error
              s.organization_id.toString() ===
                props.currentOrganizationId.toString()
          )
        : null;
    // @ts-expect-error
    if (mySkillsForOrg?.length > 0) {
      skills = skills.concat(mySkillsForOrg);
    }

    // add all other skills on any other contributions for this activity
    // @ts-expect-error
    if (props.activity?.contributions?.length > 0) {
      skills = skills.concat(
        // @ts-expect-error
        props.activity.contributions.reduce((acc, g) => {
          return g.skills ? acc.concat(g.skills) : acc;
        }, [])
      );
    }

    return skills;
  }, [
    props.contributionsWithRecommendations,
    props.mySkills,
    // @ts-expect-error
    props.activity.contributions,
    props.currentOrganizationId,
    contributionId,
  ]);

  const skillRecommendations = useMemo(() => {
    return filterUniqueById(
      localSkillRecommendations.concat(apiSkillsRecommendations)
    ).map((g) =>
      ElasticsearchAPI.defaultSelectorMapFunction({
        _index: 'skills',
        _source: g,
      })
    );
  }, [localSkillRecommendations, apiSkillsRecommendations]);

  const modalTitle = useMemo(() => {
    if (isMyContribution) {
      if (contribution?.description) {
        return formatMessage({
          id: 'app.views.activities.modal_contribution_editor.edit_contribution',
          defaultMessage: 'Edit contribution',
        });
      } else {
        return formatMessage({
          id: 'app.views.activities.modal_contribution_editor.accept_credit',
          defaultMessage: 'Accept credit',
        });
      }
    } else {
      return formatMessage({
        id: 'app.views.activities.modal_contribution_editor.give_credit',
        defaultMessage: 'Give credit',
      });
    }
  }, [contribution?.description, isMyContribution, formatMessage]);

  const showFeedbackRequestPrompt = useMemo(
    () =>
      // @ts-expect-error
      continuousFeedbackRequestsAreEnabled(props.features) &&
      !props.hideFeedbackRequestPrompt,
    // @ts-expect-error
    [props.features, props.hideFeedbackRequestPrompt]
  );

  const inputs = useMemo(
    () => [
      {
        required: true,
        name: 'contributor_role',
        // we add link so it's clear what a person is joining, particularly
        // if they added an activity in their performance cycle self reflection step
        label: (
          <>
            {formatMessage(
              {
                id: 'app.views.activities.modal_contribution_editor.what_was_your_role',
                defaultMessage:
                  "What was {isMyContribution, select, true {your role} other {{personFullName}'s role}} in {activity}?",
              },
              {
                personFullName,
                isMyContribution: !!isMyContribution,
                // @ts-expect-error
                activity: props.activity?.id ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    // @ts-expect-error
                    href={consts.ACTIVITIES().path + '/' + props.activity.id}
                  >
                    {
                      // @ts-expect-error
                      props.activity.name
                    }
                  </a>
                ) : (
                  // @ts-expect-error
                  <span className="fw-bold">{props.activity.name}</span>
                ),
              }
            )}
          </>
        ),
        placeholder: isMyContribution
          ? formatMessage(
              {
                id: 'app.views.activities.modal_contribution_editor.your_role_in_this',
                defaultMessage: 'Your role in this {activityType}',
              },
              { activityType: activityType?.name ?? '' }
            )
          : formatMessage(
              {
                id: 'app.views.activities.modal_contribution_editor.persons_role_in_this',
                defaultMessage: "{personName}'s role in this {activityType}",
              },
              {
                personName: personPreferredName,
                activityType: activityType?.name ?? '',
              }
            ),

        helperText: formatMessage({
          id: 'app.views.activities.modal_contribution_editor.role_helper_text',
          defaultMessage: 'For example, "Lead programmer" or "Project advisor"',
        }),
      },
      {
        ...CONTRIBUTION_EDITOR_ATTRIBUTES,
        className: 'mb-n4',
        required: true,
        name: 'description',
        label: isMyContribution
          ? intl.formatMessage({
              id: 'app.views.activities.modal_contribution_editor.describe_your_contribution',
              defaultMessage: 'Describe your contribution.',
            })
          : intl.formatMessage({
              id: 'app.views.activities.modal_contribution_editor.describe_the_contribution',
              defaultMessage: 'Describe the contribution.',
            }),
        // NOTE: placeholder can't be changed after Froala instantiated
        placeholder: intl.formatMessage({
          id: 'app.views.activities.modal_contribution_editor.what_work_was_done',
          defaultMessage: 'What work was done?',
        }),
        onMention: handleMention,
      },
      {
        required: true,
        name: 'skills',
        renderCreateOption: (x) => ({
          name: x,
          object: {
            name: (
              <span>
                <FormattedMessage
                  id="app.views.activities.modal_contribution_editor.create_x"
                  defaultMessage='<span>Create "</span>{x}<span>"</span>'
                  values={{
                    span: (chunks) => (
                      <span className="fw-normal">{chunks}</span>
                    ),
                    x: x,
                  }}
                />
              </span>
            ),
          },
        }),
        type: INPUT_TYPES.TAGS_INPUT,
        label: isMyContribution
          ? formatMessage({
              id: 'app.views.activities.modal_contribution_editor.what_skills_or_behaviors_did_you_use',
              defaultMessage: 'What skills or behaviors did you use?',
            })
          : formatMessage(
              {
                id: 'app.views.activities.modal_contribution_editor.what_skills_or_behaviors_did_x_use',
                defaultMessage:
                  'What skills or behaviors did {personPreferredName} use?',
              },
              { personPreferredName }
            ),
        innerRef: skillTagsRef,
        elasticsearchOptions: {
          url: 'get-skills-by-name',
          getQuery: getQueryWithType,
        },
        recommendations: skillRecommendations,
      },
      // allow for requesting feedback inline
      ...(showFeedbackRequestPrompt
        ? [
            {
              required: false,
              name: 'requested_people',
              type: INPUT_TYPES.PEOPLE_EDITOR,
              label: isMyContribution
                ? formatMessage({
                    id: 'app.views.activities.modal_contribution_editor.who_should_give_you_feedback',
                    defaultMessage:
                      'Who should give you feedback on this, if anyone?',
                  })
                : formatMessage(
                    {
                      id: 'app.views.activities.modal_contribution_editor.who_should_give_x_feedback',
                      defaultMessage:
                        'Who should give {personPreferredName} feedback on this, if anyone?',
                    },
                    { personPreferredName }
                  ),
              helperText: isMyContribution
                ? formatMessage({
                    id: 'app.views.activities.modal_contribution_editor.feedback_helper_text',
                    defaultMessage:
                      'Confirm will request feedback on your behalf.',
                  })
                : formatMessage(
                    {
                      id: 'app.views.activities.modal_contribution_editor.feedback_helper_text_for_x',
                      defaultMessage:
                        "Confirm will request feedback on {personPreferredName}'s behalf.",
                    },
                    { personPreferredName }
                  ),
              // @ts-expect-error
              suggestions: props.activity.contributions?.map(
                getContributionPerson
              ),
              showInviteSuggestions: true,
            },
          ]
        : []),
    ],
    [
      activityType?.name,
      handleMention,
      intl,
      isMyContribution,
      personFullName,
      personPreferredName,
      // @ts-expect-error
      props.activity.contributions,
      // @ts-expect-error
      props.activity.id,
      // @ts-expect-error
      props.activity.name,
      showFeedbackRequestPrompt,
      skillRecommendations,
      formatMessage,
    ]
  );

  const transformInviteContributorsBeforeSubmit = useCallback((object) => {
    return {
      id: object.id,
      contributions: object.contributions
        ? [
            ...object.contributions.map((c) =>
              getPersonOrInvitationObjectForSending('contributor_person', c)
            ),
          ]
        : [],
    };
  }, []);

  const onSubmitContributors = useCallback(
    (activity) => {
      propsOnSubmitActivity(activity);
      setHasAddedContributors(true);
    },
    [propsOnSubmitActivity]
  );

  const onValidateInviteContributors = useCallback(
    (obj) => {
      const errors = {};

      errors['contributions'] =
        obj.contributions?.length > 0
          ? getInvalidPeopleError(
              intl.formatMessage,
              obj.contributions,
              validDomains
            )
          : 'Please enter a name.';

      return errors;
    },
    [validDomains, intl]
  );

  const renderForm = (inputs, submitButton) => {
    return (
      <div className="row">
        <div className="col">
          <div className="react-tags">
            <div
              className="react-tags__selected"
              aria-relevant="additions removals"
              aria-live="polite"
            ></div>
            <div className="react-tags__search">
              <div className="react-tags__search-wrapper">{inputs}</div>
            </div>
          </div>
          <div className="text-muted small mb-0 mt-2"></div>
        </div>
        <div className="col-auto ms-n3">{submitButton}</div>
      </div>
    );
  };

  const finalInputs = useMemo(() => {
    const isUpdatedContribution =
      !contributionIsEmpty(contribution, !isMyContribution) ||
      contributionHasChanged;

    return !isUpdatedContribution
      ? [inputs[0]]
      : isRoleFieldRequired
      ? inputs
      : inputs.slice(1);
  }, [
    contribution,
    contributionHasChanged,
    inputs,
    isMyContribution,
    isRoleFieldRequired,
  ]);

  return (
    <>
      <Modal
        onOpened={onConfettiModalOpened}
        isOpen={showConfettiModal}
        toggle={toggleConfettiModal}
      >
        <div className="text-center modal-body">
          <button
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label={formatMessage({
              id: 'app.views.activities.modal_contribution_editor.aria_label.close',
              defaultMessage: 'Close',
            })}
            onClick={toggleConfettiModal}
          />
          <div className="row">
            <div className="col-4 offset-4 px-0">
              <img
                onClick={() => {
                  setConfettiShouldFire(false);
                  setConfettiShouldFire(true);
                }}
                src={trophyImg}
                alt="trophy"
                className="mw-100 mt-2 mb-2"
                role="button"
              />
            </div>
          </div>
          <div
            // @ts-expect-error
            style={confettiContainerStyle}
          >
            <Confetti
              className={'react-dom-confetti-overlay'}
              active={confettiShouldFire}
              // @ts-expect-error
              config={confettiConfig}
            />
          </div>
          <h2 className="mb-1">{acceptedGaveCreditText}</h2>
          <div className="mb-4 text-muted">
            {
              // @ts-expect-error
              props.activity.name
            }
          </div>
          <Card className="mb-0">
            <CardBody>
              {!hasAddedContributors && (
                <>
                  <AvatarGroup
                    size="md"
                    className="d-xs-flex mb-3"
                    // @ts-expect-error
                    people={props.activity.contributions.map(
                      getContributionPerson
                    )}
                  />
                  <h4 className="mb-4">
                    <FormattedMessage
                      id="app.views.activities.modal_contribution_editor.is_anyone_missing"
                      defaultMessage="{givenName}, is anyone missing from this {activityType}?"
                      values={{
                        // @ts-expect-error
                        givenName: props.me.given_name,
                        // @ts-expect-error
                        activityType: getActivityType(props.activity, intl)
                          .name,
                      }}
                    />
                  </h4>
                  <div className="text-start">
                    <ValidatedForm
                      className="mb-0"
                      method="PATCH"
                      url="activities"
                      callback={onSubmitContributors}
                      onValidate={onValidateInviteContributors}
                      // @ts-expect-error
                      submitButtonClassName={'btn btn-primary'}
                      submitText={formatMessage({
                        id: 'app.views.activities.modal_contribution_editor.submit_text.give_credit',
                        defaultMessage: 'Give credit',
                      })}
                      object={{
                        // @ts-expect-error
                        id: props.activity.id,
                        contributions: [],
                      }}
                      transformObjectBeforeSubmit={
                        transformInviteContributorsBeforeSubmit
                      }
                      buttonClassName={'mt-0'}
                      inlineSubmitButton={true}
                      renderForm={renderForm}
                      inputs={[
                        {
                          type: INPUT_TYPES.PEOPLE_EDITOR,
                          name: 'contributions',
                          inline: true,
                          inForm: true,
                          // @ts-expect-error
                          suggestions: props.activity.recommended_contributors,
                          // @ts-expect-error
                          excludeList: props.activity.contributions?.map(
                            getContributionPerson
                          ),
                          formGroupClassName: 'mb-0',
                          showInviteInstructions: true,
                          showInviteSuggestions: true,
                        },
                      ]}
                    />
                  </div>
                </>
              )}
              {hasAddedContributors && (
                <>
                  <AvatarGroup
                    size="md"
                    className="d-xs-flex mb-3"
                    // @ts-expect-error
                    people={props.activity.contributions.map(
                      getContributionPerson
                    )}
                  />
                  <h4 className="mb-4">
                    <FormattedMessage
                      id="app.views.activities.modal_contribution_editor.thanks_for_sharing_credit"
                      defaultMessage="Thanks for sharing credit!"
                    />
                  </h4>
                  <Button color="primary" onClick={toggleConfettiModal}>
                    <FormattedMessage
                      id="app.views.activities.modal_contribution_editor.close_action"
                      defaultMessage="Close"
                    />
                  </Button>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </Modal>
      <ModalEditor
        // @ts-expect-error
        isOpen={props.isOpen}
        onOpened={onOpened}
        onClosed={onClosed}
        // @ts-expect-error
        toggle={propsToggle}
        url="contributions"
        title={modalTitle}
        cancelConfirmationText={cancelConfirmationText}
        object={obj}
        onChange={onChange}
        callback={onSubmitContribution}
        transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        onValidate={onValidate}
        submitText={submitText}
        anchorTrigger={props.anchorTrigger}
        inputs={finalInputs}
        setHasUnsavedChanges={propsSetHasUnsavedChanges}
      ></ModalEditor>
    </>
  );
};

type Props = InferProps<typeof ModalContributionEditor_propTypes>;

const ModalContributionEditor_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  currentOrganizationId: PropTypes.number.isRequired,
  me: PropTypes.object.isRequired,
  mySkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  activity: PropTypes.object.isRequired,
  onSubmitActivity: PropTypes.func.isRequired,
  contributionIndex: PropTypes.number.isRequired,
  contributionsWithRecommendations: PropTypes.arrayOf(PropTypes.object),
  callback: PropTypes.func.isRequired,
  anchorTrigger: PropTypes.string,
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func,
  toggle: PropTypes.func,
  hideConfettiModal: PropTypes.bool,
  hideFeedbackRequestPrompt: PropTypes.bool,
  setHasUnsavedChanges: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { features, currentOrganization, me } = state;

  return {
    features,
    currentOrganization,
    me,
    currentOrganizationId: currentOrganization?.id,
    mySkills: me?.declared_skills,
  };
};

export default connect(mapStateToProps)(React.memo(ModalContributionEditor));
