import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { InitOrganization, Person, ReduxState } from '../../../types';
import React, { FC, useMemo } from 'react';
import {
  generateAggregateColumns,
  getIconForFieldName,
  usePeopleInOrganizationForFiltering,
} from '../People/Filters/common';

import FilterablePeopleTable from '../People/FilterablePeopleTable';
import Loading from '../Loading';
import { connect } from 'react-redux';

export type FilterType = {
  _index: string;
  name: string;
  [x: string]: unknown;
};

export type IncludeExcludeFilterType = {
  include: FilterType[];
  exclude: FilterType[];
};

type Props = {
  disabled?: boolean;
  onChange?: (filters: IncludeExcludeFilterType) => void;
  initialValue?: IncludeExcludeFilterType;
  currentOrganization?: InitOrganization;
  currentProxyPerson?: Person;
  hidePersonFilters?: boolean;
  hideFilterCagories?: string[];
  includeFilterPlaceholder?: string;
  excludeFilterPlaceholder?: string;
  bookmarkUrl?: boolean;
};

const IncludeExcludeFilter: FC<Props> = ({
  disabled = false,
  currentOrganization,
  currentProxyPerson,
  onChange = () => {
    /* DO NOTHING */
  },
  initialValue = { include: [], exclude: [] },
  hidePersonFilters = true,
  hideFilterCagories = [],
  includeFilterPlaceholder,
  excludeFilterPlaceholder,
  bookmarkUrl = false,
}) => {
  const { formatMessage } = useIntl();
  const [rows, error] = usePeopleInOrganizationForFiltering({
    currentOrganization,
    currentProxyPerson,
  });

  const SELECTABLE_COLUMNS = useMemo(
    () => [
      {
        field: 'location',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.location.text',
          defaultMessage: 'Location',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.location.popover_content',
          defaultMessage: 'Location',
        }),
      },
      {
        field: 'function',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.function.text',
          defaultMessage: 'Function',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.function.popover_content',
          defaultMessage: 'Function',
        }),
      },

      {
        field: 'cost_center',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.cost_center.text',
          defaultMessage: 'Cost center',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.cost_center.popover_content',
          defaultMessage: 'Cost center',
        }),
      },
      {
        field: 'department',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.department.text',
          defaultMessage: 'Department',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.department.popover_content',
          defaultMessage: 'Department',
        }),
      },
      {
        field: 'business_unit',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.business_unit.text',
          defaultMessage: 'Business unit',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.business_unit.popover_content',
          defaultMessage: 'Business unit',
        }),
      },
      {
        field: 'level',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.level.text',
          defaultMessage: 'Level description',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.level.popover_content',
          defaultMessage: 'Level desc',
        }),
      },
    ],
    [formatMessage]
  );

  // show managers list for the "mananger and above" filter functionality
  const managersList = useMemo(() => {
    if (!(rows?.length > 0)) {
      return [];
    }

    return rows.reduce((arr, row) => {
      return [...arr, ...(row.chain_of_command_list || [])];
    }, []);
  }, [rows]);

  const columns = useMemo(() => {
    const columnsThatHaveAtLeastOneDatapointInRows = SELECTABLE_COLUMNS.filter(
      (it) => !hideFilterCagories.includes(it.field)
    )
      .filter((column) => {
        return rows?.some((row) => {
          return row[column.field];
        });
      })
      .map((c) => {
        return {
          ...c,
          filterIcon: getIconForFieldName(c.field),
        };
      });
    return hidePersonFilters
      ? columnsThatHaveAtLeastOneDatapointInRows
      : generateAggregateColumns(
          managersList,
          columnsThatHaveAtLeastOneDatapointInRows,
          formatMessage
        );
  }, [
    SELECTABLE_COLUMNS,
    managersList,
    formatMessage,
    rows,
    hidePersonFilters,
    hideFilterCagories,
  ]);

  if (error) {
    return (
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.widgets.inputs.include_exclude_filter.error.text"
            defaultMessage="An issue occurred while loading the filters."
          />
        </CardBody>
      </Card>
    );
  }

  if (!rows && !error) {
    return <Loading />;
  }

  return (
    <>
      <Row>
        <Col>
          <FilterablePeopleTable
            disabled={disabled}
            filtersVerticalDisplay={true}
            className="shadow-none mb-0 border-0"
            customStyles={{
              includeClassName: 'col ps-0',
              excludeClassName: 'col ps-0',
            }}
            bookmarkUrl={bookmarkUrl}
            hideExportButton={true}
            showTable={false}
            showToggleFilters={false}
            arrayValuesUsedForFormatting={true}
            tableClassName="exclude-include-filters-table"
            includeFilterPlaceholder={
              includeFilterPlaceholder ??
              formatMessage({
                id: 'app.views.widgets.inputs.include_exclude_filter.include_filter_placeholder',
                defaultMessage: 'Filter by category',
              })
            }
            excludeFilterPlaceholder={excludeFilterPlaceholder}
            headerContent={null}
            rows={rows}
            columns={columns}
            onFiltersChange={onChange}
            initialFiltersValue={initialValue}
          ></FilterablePeopleTable>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: ReduxState): object => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};
export default connect(mapStateToProps)(IncludeExcludeFilter);
