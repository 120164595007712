import { Card, CardBody, Col, Row } from 'reactstrap';
import { Organization, Person, ReduxState } from 'types';
import React, { FC, useEffect, useState } from 'react';

import { BASIC_PERSON_FIELDS } from '../Widgets/Inputs/ValidatedInputTypes';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import FilterInput from '../Widgets/Search/FilterInput';
import { FormattedMessage } from 'react-intl';
import Loading from '../Widgets/Loading';
import PageError from '../Layout/Pages/Errors/PageError';
import PersonList from '../Person/PersonList';
import { connect } from 'react-redux';
import { getPersonFromElasticsearchDocument } from '../../utils/models/Person';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  currentOrganization: Organization;
  inModal?: boolean;
  currentProxyPerson: Person;
}

const PeopleDirectory: FC<Props> = ({ inModal = false, ...props }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [people, setPeople] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filterName, setFilterName] = useState('');

  const { user } = useAuth0();
  const userSub = user?.sub;
  const currentOrgId = props.currentOrganization?.id;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const MAX_PEOPLE_TO_SHOW = 9999;

    if (userSub && currentOrgId) {
      const queryParams = {
        size: MAX_PEOPLE_TO_SHOW,
        sort: [
          {
            status: {
              unmapped_type: 'keyword',
              order: 'asc',
            },
            'full_name.raw': {
              unmapped_type: 'keyword',
              order: 'asc',
            },
          },
        ],
        source_includes: [...BASIC_PERSON_FIELDS, 'business_unit'],
      };

      ElasticsearchAPI.search(
        // only cache if no query provided (so we don't take up too much
        // memory story search results as searches are done)
        filterName ? undefined : userSub,
        props.currentProxyPerson,
        currentOrgId,
        'get-people-by-name',
        filterName
          ? {
              ...queryParams,
              name: filterName,
            }
          : queryParams,
        (hits) => {
          if (isMounted) {
            const newPeople = hits.map(getPersonFromElasticsearchDocument);
            setPeople(newPeople);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [isMounted, userSub, filterName, currentOrgId, props.currentProxyPerson]);

  if (errorMessage) {
    console.error('Could not load all people: ' + errorMessage);
    return <PageError message={errorMessage} />;
  }

  if (typeof people === 'undefined') {
    return <Loading />;
  }

  const onChangeFilter = (e) => {
    setFilterName(e.target.value);
  };

  const peopleContent = (
    <>
      {/* @ts-expect-error */}
      {people.length === 0 && (
        <div className="text-center mb-4">
          <span className="text-muted">
            <FormattedMessage
              id="app.views.people.people_directory.no_results_found"
              defaultMessage="No results found"
            />
          </span>
        </div>
      )}
      <PersonList people={people} />
    </>
  );

  return (
    <Row className="justify-content-md-center">
      <Col md="12" className="order-2 order-md-1">
        <FilterInput
          value={filterName}
          onChange={onChangeFilter}
          autoFocus={inModal}
        />
        {inModal && <div className="mt-4">{peopleContent}</div>}
        {!inModal && (
          <Card className="mt-4 mb-0">
            <CardBody>{peopleContent}</CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(PeopleDirectory));
