import * as consts from '../../consts/consts';

import {
  ACTIVITY_DEFAULT_DESCRIPTION,
  contributionIsEmpty,
  fromLegacyVisibilityToSimplified,
  getActivityDateSubtitle,
  getActivityPermissionsSubtitle,
  getActivityStage,
  getActivityType,
  getContributionPerson,
  getUniqueSkillWordCloudsFromContribution,
} from '../../utils/models/Activity';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  peopleIdsAreEqual,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';

import { ACTIVITY_EDITOR_FIELDS } from './ModalActivityEditorButton';
import ActivityHierarchy from './ActivityHierarchy';
import ActivityListCard from './ActivityListCard';
import Avatar from '../Widgets/People/Avatar';
import AvatarGroup from '../Widgets/People/AvatarGroup';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import ContributionsManager from './ContributionsManager';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import EmojiBar from '../Widgets/EmojiBar';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import Loading from '../../views/Widgets/Loading';
import Modal from '../../components/SafeModal';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import ModalEditorButton from '../Widgets/Modals/ModalEditorButton';
import ModalFeedbackEditorButton from '../Feedback/ModalFeedbackEditorButton';
import Page from '../Layout/Pages/Page';
import RichTextEditor from '../Widgets/Inputs/RichTextEditor';
import WordCloud from '../Widgets/WordCloud';
import addContributionImage from '../../assets/img/illustrations/create.png';
import { atLeastOneContinuousFeedbackFeatureIsEnabled } from '../../utils/util/features';
import { connect } from 'react-redux';
import { getQueryWithType } from '../Widgets/Inputs/ValidatedInput';
import { loadOrRender } from '../../utils/util/formatter';
import { loadTasks } from '../../actions';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

const ActivityPage: React.FC<Props> = (props: Props) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth0();

  // @ts-expect-error
  const activityId = params.id;

  const [activity, setActivity] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  // anchorTrigger should automatically show the modal on load if the
  // current page has the anchor hash set to the trigger
  const showCreatedActivityModal = location.hash === '#created';

  const [editActivityModal, setEditActivityModal] = useState(false);
  const toggleEditActivityModal = () =>
    setEditActivityModal(!editActivityModal);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setConfirmDeleteModal(!confirmDeleteModal);
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);

  // for modal contribution editor in contributions manager
  const [addMyContributionModalIsOpen, setAddMyContributionModalIsOpen] =
    useState(false);
  const toggleAddMyContributionModal = () =>
    setAddMyContributionModalIsOpen(!addMyContributionModalIsOpen);

  const userSub = user?.sub;
  // @ts-expect-error
  const currentOrgId = props.currentOrganization?.id;
  const loadTasks = props.loadTasks;

  const intl = useIntl();
  const { formatMessage, locale } = intl;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch activities associated with this person
    if (userSub && typeof activity === 'undefined' && activityId) {
      ConfirmAPI.getObject(
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.ACTIVITIES,
        activityId,
        (data) => {
          if (isMounted) {
            setActivity(data);
          }
        },
        (message) => {
          setErrorMessage(message);
        },
        props.currentProxyPerson
          ? // @ts-expect-error
            { proxy: props.currentProxyPerson.email }
          : undefined
      );
    }
  }, [isMounted, userSub, activityId, activity, props.currentProxyPerson]);

  const createEditButton = useCallback((buttonProps) => {
    return (
      <Button color="link" className="p-0" onClick={buttonProps.onClick}>
        <span
          className={
            'me-2 ' + (buttonProps.icon ? buttonProps.icon : 'fe fe-edit')
          }
        ></span>
        <small>
          {buttonProps.text ? (
            buttonProps.text
          ) : (
            <FormattedMessage
              id="app.activity_page.activity.create_or_edit.button.text"
              defaultMessage="Edit"
            />
          )}
        </small>
      </Button>
    );
  }, []);

  const onChangeActivity = useCallback(
    (e) => {
      // @ts-expect-error
      setActivity({ ...activity, [e.target.name]: e.target.value });
    },
    [activity]
  );

  const onSubmitContribution = useCallback(
    (contribution) => {
      // @ts-expect-error
      const contributions = activity?.contributions
        ? // @ts-expect-error
          activity.contributions
        : [];

      const hasContribution =
        contributions.findIndex((c) => c.id === contribution.id) !== -1;

      const hasContributionWithoutId =
        contributions.findIndex((c) => !c.id) !== -1;

      const newContributions = hasContribution
        ? contributions.map((c) =>
            c?.id === contribution?.id ? contribution : c
          )
        : hasContributionWithoutId
        ? contributions.map((c) => (!c.id ? contribution : c))
        : [contribution, ...contributions];

      // replace the relevant contribution (which is either a blank contribution
      // if it's a new contribution for yourself, or replace by id, or if neither, add it)
      const newActivity = {
        // @ts-expect-error
        ...activity,
        contributions: newContributions,
      };
      setActivity(newActivity);

      // save updated activity to the cache so reloading later retains the contribution update
      ConfirmAPI.setObjectInCache(
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.ACTIVITIES,
        newActivity?.id,
        newActivity
      );

      // refresh tasks (which may go down by one if the above was claiming a contribution
      // @ts-expect-error
      loadTasks(userSub, props.currentProxyPerson?.email);

      // go from any #<anchor> page to just the current activity page
      // (but don't reload it so we can see the confetti, so use window.history.replaceState
      // instead of history.replace)
      const url = consts.ACTIVITIES().path + '/' + activityId;
      if (location.pathname !== url) {
        window.history.replaceState(
          null,
          // @ts-expect-error
          null,
          location.pathname + location.search
        );
      }
    },
    [
      activity,
      userSub,
      props.currentProxyPerson,
      loadTasks,
      activityId,
      location.pathname,
      location.search,
    ]
  );

  const onSubmitActivity = useCallback(
    (updatedActivity) => {
      if (updatedActivity) {
        setActivity(updatedActivity);

        // save updated activity to the cache so reloading later retains the contribution update
        ConfirmAPI.setObjectInCache(
          userSub,
          // @ts-expect-error
          props.currentProxyPerson,
          ConfirmAPI.OBJECT_TYPES.ACTIVITIES,
          updatedActivity?.id,
          updatedActivity
        );
      }
    },
    [props.currentProxyPerson, userSub]
  );

  const onCreatedActivityModalClosed = useCallback(() => {
    // go to page that shows acccept credit modal
    history.replace(
      consts.ACTIVITIES().path + '/' + activityId + '#accept-credit'
    );
  }, [activityId, history]);

  const confirmDelete = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      '/activities/' + activityId,
      {},
      (response, error, hardErrorMessage = null) => {
        if (error) {
          // failure; keep modal open
          if (hardErrorMessage) {
            // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
            // leave this message blank as those errors will get surfaced below
            setDeleteValidationErrors(hardErrorMessage);
          } else {
            setDeleteValidationErrors(error);
          }
        } else {
          setConfirmDeleteModal(false);

          // go to dashboard and show toast
          toast.success(
            intl.formatMessage({
              id: 'app.activity_page.activity.action.deleted.toast.text',
              defaultMessage: 'Activity deleted!',
            })
          );
          history.replace('/');
        }
      },
      null
    );
  }, [activityId, history, intl]);

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (!obj.date_started && !obj.date_completed) {
        errors['date_started'] = intl.formatMessage({
          id: 'app.activity_page.activity.validation.error.date_started.required',
          defaultMessage: 'A start date or completion date is required.',
        });
        errors['date_completed'] = intl.formatMessage({
          id: 'app.activity_page.activity.validation.error.date_completed.required',
          defaultMessage: 'A start date or completion date is required.',
        });
      }

      if (
        obj.date_started &&
        obj.date_completed &&
        obj.date_started > obj.date_completed
      ) {
        errors['date_completed'] = intl.formatMessage({
          id: 'app.activity_page.activity.validation.error.date_completed.before_start',
          defaultMessage:
            'The completion date cannot be before the start date.',
        });
      }

      return errors;
    },
    [intl]
  );

  const transformObjectBeforeSubmit = useCallback((object) => {
    if (object.parent?.id) {
      return {
        ...object,
        parent: object.parent.id,
      };
    } else {
      return object;
    }
  }, []);

  // for callout if someone invited the current user to contribute to this activity
  const myContribution = useMemo(() => {
    // @ts-expect-error
    return activity?.contributions?.find(
      (c) =>
        c.contributor_person?.id &&
        peopleObjectsAreEqual(c.contributor_person, props.me)
    );
  }, [activity, props.me]);

  const finalActivity = useMemo(
    () =>
      activity
        ? {
            // @ts-expect-error
            ...activity,
            // sort contributions with current user's contribution last (otherwise oldest first)
            // @ts-expect-error
            contributions: activity.contributions.sort((a, b) => {
              // @ts-expect-error
              return peopleIdsAreEqual(a.contributor_person?.id, props.me?.id)
                ? 1
                : // @ts-expect-error
                peopleIdsAreEqual(b.contributor_person?.id, props.me?.id)
                ? -1
                : a.id - b.id;
            }),
          }
        : null,
    [activity, props.me]
  );

  const obj = useMemo(
    () => ({
      // @ts-expect-error
      id: activity?.id,
      visibility:
        // @ts-expect-error
        fromLegacyVisibilityToSimplified(activity?.visibility, formatMessage) ??
        undefined,
      // @ts-expect-error
      name: activity?.name
        ? // @ts-expect-error
          activity.name
        : formatMessage({
            id: 'app.activity_page.activity.type.untitled.name',
            defaultMessage: 'Untitled activity',
          }),
      // @ts-expect-error
      type: activity?.type,
      // @ts-expect-error
      stage: activity?.stage,
      // @ts-expect-error
      date_started: activity?.date_started ? activity.date_started : null,
      // @ts-expect-error
      date_completed: activity?.date_completed ? activity.date_completed : null,
      // @ts-expect-error
      parent: activity?.parent,
    }),
    [activity, formatMessage]
  );

  const loadOrRenderOutput = loadOrRender(activity, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  // @ts-expect-error
  const contributors = activity?.contributions?.map(getContributionPerson);

  const subtitle = (
    <>
      {getActivityPermissionsSubtitle(activity, formatMessage)}
      {getActivityDateSubtitle(activity, false, locale)}
      <EmojiBar
        className="mb-0"
        showIconsOnly={true}
        objectId={activityId?.toString()}
        contentType="activity"
        // @ts-expect-error
        reactions={activity?.reactions}
      />
    </>
  );

  const invitationSender = !peopleIdsAreEqual(
    myContribution?.invitation?.sender_person?.id,
    // @ts-expect-error
    props.me?.id
  )
    ? myContribution?.invitation?.sender_person
    : null;

  const showClaimAlert =
    myContribution?.id && contributionIsEmpty(myContribution, false);
  const type = getActivityType(activity, intl);
  const stage = getActivityStage(activity);

  const activityEditDropdownButton = (
    <>
      <ModalEditor
        isOpen={editActivityModal}
        toggle={toggleEditActivityModal}
        method="PATCH"
        url="activities"
        title={
          <FormattedMessage
            id="app.activity_page.editor.edit.title"
            defaultMessage="Edit {heading}"
            values={{ heading: type?.heading }}
          />
        }
        submitText={formatMessage({
          id: 'app.views.activities.activity_page.submit_text.save',
          defaultMessage: 'Save',
        })}
        object={obj}
        callback={onSubmitActivity}
        onValidate={onValidate}
        inputs={[
          ACTIVITY_EDITOR_FIELDS(intl)['name'],
          ACTIVITY_EDITOR_FIELDS(intl)['type'],
          //ACTIVITY_EDITOR_FIELDS(intl)['stage'],
          ACTIVITY_EDITOR_FIELDS(intl)['date_started'],
          ACTIVITY_EDITOR_FIELDS(intl)['date_completed'],
          //ACTIVITY_EDITOR_FIELDS(intl)['parent'],
          ACTIVITY_EDITOR_FIELDS(intl)['visibility'],
        ]}
      />
      <ConfirmationDialogModal
        isOpen={confirmDeleteModal}
        onClosed={() => setDeleteValidationErrors(null)}
        toggle={toggleConfirmDeleteModal}
        confirmCallback={confirmDelete}
        title={intl.formatMessage(
          {
            id: 'app.activity_page.activity.editor.modal.confirm_delete.title',
            defaultMessage: 'Delete {activityType}?',
          },
          { activityType: type?.name }
        )}
        description={intl.formatMessage(
          {
            id: 'app.activity_page.activity.editor.modal.confirm_delete.description',
            defaultMessage:
              'Are you sure that you want to delete this {activityType}?',
          },
          { activityType: type?.name }
        )}
        confirmText={intl.formatMessage(
          {
            id: 'app.activity_page.activity.editor.modal.confirm_delete.confirm_text',
            defaultMessage: 'Delete {activityType}',
          },
          { activityType: type?.name }
        )}
        validationErrors={deleteValidationErrors}
      />
      <UncontrolledDropdown>
        <DropdownToggle
          tag="div"
          role="button"
          className="dropdown-ellipses text-primary text-decoration-none"
        >
          <Button color="link" className="p-0">
            <span className={'me-2 fe fe-edit'}></span>
            <small>
              <FormattedMessage
                id="app.activity_page.activity.edit.button.text"
                defaultMessage="Edit"
              />
            </small>
          </Button>
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={toggleEditActivityModal}>
            {activityId ? (
              <FormattedMessage
                id="app.activity_page.activity.edit.button.sub.item.edit.text"
                defaultMessage="Edit {activityType}"
                values={{ activityType: type?.name }}
              />
            ) : (
              <FormattedMessage
                id="app.activity_page.activity.edit.button.sub.item.add.text"
                defaultMessage="Add {activityType}"
                values={{ activityType: type?.name }}
              />
            )}
          </DropdownItem>
          {activityId && (
            <DropdownItem onClick={toggleConfirmDeleteModal}>
              <FormattedMessage
                id="app.activity_page.activity.edit.button.sub.item.delete.text"
                defaultMessage="Delete {activityType}"
                values={{ activityType: type?.name }}
              />
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );

  const hierarchyEditorButton = (
    <ModalEditorButton
      method="PATCH"
      url="activities"
      title={intl.formatMessage(
        {
          id: 'app.activity_page.activity.editor.hierarchy.button.title',
          defaultMessage: '{activityTypeHeading} Details?',
        },
        { activityTypeHeading: type?.heading }
      )}
      submitText={intl.formatMessage({
        id: 'app.activity_page.activity.editor.hierarchy.button.submit_text',
        defaultMessage: 'Save',
      })}
      buttonComponentGenerator={createEditButton}
      object={{
        id: activityId,
        // @ts-expect-error
        parent: activity?.parent,
      }}
      transformObjectBeforeSubmit={transformObjectBeforeSubmit}
      callback={onSubmitActivity}
      inputs={[
        {
          name: 'parent',
          label: intl.formatMessage({
            id: 'app.activity_page.activity.editor.hierarchy.inputs.parent.label',
            defaultMessage: 'Parent Activity',
          }),
          type: INPUT_TYPES.SELECT,
          elasticsearchOptions: {
            url: 'get-activities-by-name',
            index: 'activities',
            getQuery: getQueryWithType,
          },
        },
      ]}
    />
  );

  const activityType = type?.name ? type.name : 'activity';

  return (
    <>
      {!activity && <Loading />}
      <Page
        headerRowClassName="align-items-top"
        avatarIcon={type?.icon}
        pretitle={
          // @ts-expect-error
          (stage?.prefix ? stage.prefix : '') +
          type?.heading +
          // @ts-expect-error
          (stage?.suffix ? stage.suffix : '')
        }
        subtitle={subtitle}
        editableObject={activity}
        editableObjectButton={activityEditDropdownButton}
        onChange={onChangeActivity}
      >
        <>
          {contributors && (
            <AvatarGroup
              className="d-none d-md-inline-block"
              people={contributors}
            />
          )}
          {contributors &&
            atLeastOneContinuousFeedbackFeatureIsEnabled(props.features) && (
              <ModalFeedbackEditorButton
                buttonClassName="ms-3"
                feedback={{
                  subject_people: contributors,
                  activities: [activity],
                }}
              />
            )}
        </>
        <Modal
          // prevent Esc from closing editor (to avoid issues e.g.
          // when someone escapes file dialog and presses twice)
          keyboard={false}
          // prevent hiding when clicking outside
          backdrop="static"
          isOpen={showCreatedActivityModal}
        >
          <ModalBody className="text-center">
            <div className="mt-4 mb-3 text-muted">
              <FormattedMessage
                id="app.activity_page.activity.modal.add_to_resume.top.text"
                defaultMessage="Almost done..."
              />
            </div>
            <img
              alt=""
              src={addContributionImage}
              className="mb-4"
              style={{ width: '45%' }}
            />
            <h2 className="mb-1">
              <FormattedMessage
                id="app.activity_page.activity.modal.add_to_resume.header.text"
                defaultMessage={
                  'Add {isPlural, plural, =0 {this} other {these}} {activityType} to your resume.'
                }
                values={{
                  isPlural: activityType === 'metrics' ? 1 : 0,
                  activityType,
                }}
              />
            </h2>
            <div className="mb-4 text-muted">
              {
                // @ts-expect-error
                activity?.name
              }
            </div>
            <Button
              color="primary"
              className="mb-4"
              onClick={onCreatedActivityModalClosed}
            >
              <FormattedMessage
                id="app.activity_page.activity.modal.add_to_resume.button.text"
                defaultMessage="Add to resume"
              />
            </Button>
          </ModalBody>
        </Modal>
        <Row>
          <Col md={8}>
            {showClaimAlert && (
              <Alert className="alert-light py-4">
                <Row>
                  {invitationSender && (
                    <Col className="col-auto">
                      <Avatar person={invitationSender} />
                    </Col>
                  )}
                  {!invitationSender && (
                    <Col className="col-auto">
                      {/* @ts-expect-error */}
                      <Avatar person={props.me} />
                    </Col>
                  )}
                  <Col>
                    <Row>
                      <Col className="ms-n3 mb-2 mb-md-0">
                        <h4 className="mt-1 mb-1">
                          {invitationSender?.id && (
                            <>
                              <FormattedMessage
                                id="app.activity_page.activity.contribution.sender.text"
                                defaultMessage="{sender} wants to give you credit for this {activityType}."
                                values={{
                                  sender: (
                                    <Link to={invitationSender.url}>
                                      {invitationSender.full_name}
                                    </Link>
                                  ),
                                  activityType: type?.name,
                                }}
                              />
                            </>
                          )}
                          {!invitationSender?.id && (
                            <>
                              <FormattedMessage
                                id="app.activity_page.activity.contribution.not_sender.text"
                                defaultMessage="You may have contributed to this {activityType}."
                                values={{ activityType: type?.name }}
                              />
                            </>
                          )}
                        </h4>
                        <p className="mb-0">
                          <FormattedMessage
                            id="app.activity_page.activity.contribution.tell_us.text"
                            defaultMessage="Can you tell us a little about what you did?"
                          />
                        </p>
                      </Col>
                      <Col className="col-12 col-md-auto ms-n3">
                        <Button
                          color="primary"
                          onClick={toggleAddMyContributionModal}
                        >
                          <FormattedMessage
                            id="app.activity_page.activity.button.accept_credit"
                            defaultMessage="Accept credit"
                          />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Alert>
            )}
            {activity && (
              <Card className="activity-card">
                <RichTextEditor
                  autoFocus={true}
                  method="PATCH"
                  url="activities"
                  title={intl.formatMessage(
                    {
                      id: 'app.activity_page.activity.card.title',
                      defaultMessage: 'About this {activityType}',
                    },
                    { activityType: type?.name }
                  )}
                  name="description"
                  defaultValue={ACTIVITY_DEFAULT_DESCRIPTION}
                  placeholder={intl.formatMessage({
                    id: 'app.activity_page.activity.card.placeholder',
                    defaultMessage: 'Enter a description',
                  })}
                  saveId={activityId}
                  // @ts-expect-error
                  value={activity?.description}
                  config={{
                    charCounterMax: consts.ACTIVITY_DESCRIPTION_MAX_LENGTH,
                    heightMin: 300,
                  }}
                  callback={onSubmitActivity}
                  emptyStateTitle={intl.formatMessage({
                    id: 'app.activity_page.activity.card.empty_state.title',
                    defaultMessage: 'No description',
                  })}
                  emptyStateSubtitle={intl.formatMessage(
                    {
                      id: 'app.activity_page.activity.card.emptyStateSubtitle',
                      defaultMessage:
                        'Please provide a description for this {activityType}.',
                    },
                    { activityType: type?.name }
                  )}
                />
              </Card>
            )}
            {activity && (
              <ContributionsManager
                activity={finalActivity}
                editButtonGenerator={createEditButton}
                addMyContributionModalIsOpen={addMyContributionModalIsOpen}
                toggleAddMyContributionModal={toggleAddMyContributionModal}
                onSubmitActivity={onSubmitActivity}
                onSubmitContribution={onSubmitContribution}
              />
            )}
          </Col>
          <Col md={4}>
            {
              // @ts-expect-error
              (activity?.parent || activity?.children?.length > 0) && (
                <ActivityHierarchy
                  // @ts-expect-error
                  activity={activity}
                  setActivity={setActivity}
                  editButton={hierarchyEditorButton}
                />
              )
            }
            <Row>
              <Col>
                {
                  // @ts-expect-error
                  activity?.contributions && (
                    <Card>
                      <CardHeader>
                        <CardHeaderTitle icon={consts.ICONS.SKILL}>
                          <FormattedMessage
                            id="app.activity_page.activity.contributions.header.title"
                            defaultMessage="Skills and behaviors"
                          />
                        </CardHeaderTitle>
                      </CardHeader>
                      <CardBody>
                        <WordCloud
                          className="text-center"
                          emptyText={intl.formatMessage({
                            id: 'app.activity_page.activity.contributions.body.empty_text',
                            defaultMessage:
                              'Tag skills in contributions to see them here.',
                          })}
                          objects={getUniqueSkillWordCloudsFromContribution(
                            // @ts-expect-error
                            activity?.contributions
                          )}
                          pathPrefix={consts.SKILLS().path}
                        />
                      </CardBody>
                    </Card>
                  )
                }
                {
                  // @ts-expect-error
                  activity?.contributions?.length > 0 && currentOrgId && (
                    <ActivityListCard
                      title={intl.formatMessage({
                        id: 'app.activity_page.activity.contributions.body.activity_list_card.title',
                        defaultMessage: 'Related activities',
                      })}
                      loaderFunction={(p, c, e) =>
                        ElasticsearchAPI.getActivitiesRelatedToActivity(
                          userSub,
                          props.currentProxyPerson,
                          currentOrgId,
                          activity,
                          p,
                          c,
                          // @ts-expect-error
                          e
                        )
                      }
                      hideIfEmpty={true}
                    />
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Page>
    </>
  );
};

type Props = InferProps<typeof ActivityPage_propTypes>;

const ActivityPage_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  features: PropTypes.object.isRequired,
  loadTasks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, me, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
    features,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTasks: (userSub, proxy) => dispatch(loadTasks(userSub, proxy)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ActivityPage));
