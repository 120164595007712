import './App.scss';
// import Chart.js defaults from Dashkit
import './vendor/good-themes/dashkit/js/charts';

import { FormattedMessage, useIntl } from 'react-intl';
import {
  INVALID_STATE,
  ONE_SECOND_IN_MILLISECONDS,
  TIME_ERROR_MESSAGE_INCIPIT,
  UNAUTHED_REDIRECT_KEY,
  VERIFY_EMAIL_ERROR_MESSAGE,
} from './consts/consts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SHOULD_SHOW_FORCED_AUTH_MODAL_KEY,
  getShouldShowForcedAuthModal,
  reverseLocalStorageFullKey,
  setHasSeenSelectAccountPrompt,
  setShouldShowForcedAuthModal,
} from './utils/models/User';
import { compressToUTF16, decompress, decompressFromUTF16 } from 'lz-string';
import {
  getCustomSessionTTL,
  hasCustomSessionTTL,
} from './utils/models/Organization';
import {
  getTwoNamesForLshus,
  getTwoNamesForUsana,
  getTwoNamesFromName,
  log,
} from './utils/util/util';
import { loadApp, setAuthUser } from './actions';
import { useHistory, useLocation } from 'react-router-dom';

import { AUTH0_PARAMS } from './utils/api/AuthProvider';
import Analytics from './views/Layout/Analytics';
import ConfirmAPI from './utils/api/ConfirmAPI';
import ElasticsearchAPI from './utils/api/ElasticsearchAPI';
import ForcedAuthModal from './views/Widgets/Modals/ForcedAuthModal';
import { FullStoryAPI } from 'react-fullstory';
// @ts-expect-error
import { IntercomAPI } from './vendor/react-intercom';
import Layout from '../../app/src/views/Layout/Layout';
import Loading from './views/Widgets/Loading';
import LogRocket from 'logrocket';
import PageMaintenance from './views/Layout/Pages/Errors/PageMaintenance';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { ReduxState } from 'types';
import config from './utils/util/config';
import { connect } from 'react-redux';
import { mokeyPatchForTranslationPluginErrorMessage } from './hacks/hacks';
import { processRipplingData } from 'utils/util/MergeRipplingUtils';
import { redirectLoginOptionsGenerator } from './utils/util/utiltsx';
import { retryOnRecoverableErrors } from 'utils/api/axiosRetry';
import track from 'react-tracking';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfirmIntl } from './locale/ConfirmIntlContext';

// @ts-expect-error
const tracking = config.getTracking();

// This is different than the standard localStorage key generator
// since it is used for un-authed users
const getLocalStorageFullKey = (key) => {
  return (
    '@@confirm@@::' + process.env.REACT_APP_VERSION + '::UnAuthorized::' + key
  );
};

mokeyPatchForTranslationPluginErrorMessage();

const App = (props) => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  // set props to local variables for useEffect
  const setAuthUser = props.setAuthUser;
  const loadApp = props.loadApp;
  const history = useHistory();
  const location = useLocation();
  const redirect_key = getLocalStorageFullKey(UNAUTHED_REDIRECT_KEY);
  const intl = useConfirmIntl();
  const { formatMessage } = useIntl();

  const [appIsInitialized, setAppIsInitialized] = useState(false);
  const [customSessionTTLIsInitialized, setCustomSessionTTLIsInitialized] =
    useState(false);
  const [localShouldShowForcedAuthModal, setLocalShouldShowForcedAuthModal] =
    useState(undefined);
  const [isMaintenance, setIsMaintenance] = useState(false);

  // we use useRef here so we don't pass the location into the useEffect that calls redirectLoginOptionsGenerator
  // which would cause the below to re-render every time the location changed
  const isRefreshingToken = useRef(false);
  const currentLocation = useRef(null);
  useEffect(() => {
    // @ts-expect-error
    currentLocation.current = location;
  }, [location]);

  const isSignup = location.pathname === '/signup';

  // Event listener used to clear the modal to force login once
  // the external window is closed post Auth.
  window.addEventListener('storage', (event) => {
    // Event listener on local storage, this is only triggered if *another* browser
    // tab/window changes the local storage value in the `event`.
    const key = event.key;
    if (!key) {
      // If there is no key it's pointless to continue, we know we need an event
      // with key SHOULD_SHOW_FORCED_AUTH_MODAL_KEY.
      return;
    }

    const localStorageFullKey = reverseLocalStorageFullKey(key);
    if (
      localStorageFullKey &&
      localStorageFullKey.endsWith(SHOULD_SHOW_FORCED_AUTH_MODAL_KEY)
    ) {
      if (event.newValue === null) {
        // We udpate the current state of App, localStorage has already been updated
        // by the external popup window since we got here in this EventListener.
        // @ts-expect-error
        setLocalShouldShowForcedAuthModal(false);
      }
    }
  });

  // Copy the value for re-login from localstorage into the current component.
  // Once, at first render.
  useEffect(() => {
    // Force a boolean.
    // @ts-expect-error
    setLocalShouldShowForcedAuthModal(!!getShouldShowForcedAuthModal());
  }, []);

  const checkIfMaintanence = useCallback((error) => {
    // this is a special set the load balancer to respond 503 with an additional marker
    // response body  {'maintenance': true} when we are in maintenance mode.
    // Added a body to distinguish between a normal 503 and our maintenance mode.
    if (error?.response?.status === 503 && error?.response?.data?.maintenance) {
      setIsMaintenance(true);
    }
  }, []);

  useEffect(() => {
    if (
      !(
        isAuthenticated &&
        appIsInitialized &&
        props.currentOrganization &&
        !customSessionTTLIsInitialized
      )
    ) {
      return;
    }

    [ConfirmAPI, ElasticsearchAPI].forEach((api) => {
      api.interceptors.response.use(
        async (res) => {
          // ----------------------------------------
          // Custom code for early token refresh.
          // This is run in a response interceptor to give the request an opportunity
          // to complete sucessfully before forcing a re-login and exchanging a new token.
          // If there are other competing requests (i.e. typeaheads) on the page the first
          // response over SessionTTL will force a logout making any other request possibly fail.
          // More info in utils/util/CustomSessionTTL.js.

          try {
            if (hasCustomSessionTTL(props.currentOrganization)) {
              // This is needed to ensure we have the claims.
              await getAccessTokenSilently(AUTH0_PARAMS);

              const claims = await getIdTokenClaims();
              if (!claims || 'auth_time' in claims === false) {
                console.error(
                  'No claims found or missing auth_time, skipping early token refresh.' +
                    ' We MUST have claims here.'
                );
                return res;
              }

              const now = Math.floor(Date.now() / ONE_SECOND_IN_MILLISECONDS);
              const customSessionTTL = getCustomSessionTTL(
                props.currentOrganization
              );
              // @ts-expect-error
              if (now - claims?.auth_time > customSessionTTL) {
                setHasSeenSelectAccountPrompt(false);
                // We need to keep these 2 in lockstep.
                // One is local used to trigger a react re-render.
                // The other is in localstorage for the popup window to use.
                // @ts-expect-error
                setLocalShouldShowForcedAuthModal(true);
                setShouldShowForcedAuthModal(true);
              }
            }
          } catch (e) {
            console.error(e);
          }

          return res;
        },
        (error) => {
          checkIfMaintanence(error);
          return retryOnRecoverableErrors(api, error);
        }
      );
    });

    setCustomSessionTTLIsInitialized(true);
  }, [
    appIsInitialized,
    customSessionTTLIsInitialized,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    localShouldShowForcedAuthModal, // Trigger a re-run if this changes.
    props.currentOrganization,
    checkIfMaintanence,
  ]);

  useEffect(() => {
    // don't do anything else until we've authenticated and the
    // app is NOT already initialized
    if (
      appIsInitialized ||
      !isAuthenticated ||
      !getAccessTokenSilently ||
      !loginWithRedirect ||
      !setAuthUser ||
      !user
    ) {
      return;
    }

    log('Authenticated as Auth0 user: ');
    log(user);

    // don't ask for which account when auto-refreshing session and checking if still
    // logged into third party used to authenticate
    setHasSeenSelectAccountPrompt(true);

    // NOTE: the below should be called ONLY ONCE for the given session (hence the async use and
    // checking appIsInitialized
    // set ConfirmAPI so it always gets token silently (which shows auth screen if token has expired)
    // (note that it will NOT send a request to auth0 if the token has not yet expired
    [ConfirmAPI, ElasticsearchAPI].forEach((api) => {
      api.interceptors.request.use(
        async (req) => {
          let token = null;
          try {
            // @ts-expect-error
            token = await getAccessTokenSilently(AUTH0_PARAMS);
          } catch (e) {
            console.info(e);
            if (
              // @ts-expect-error
              e.error === 'login_required' ||
              // @ts-expect-error
              e.error === 'consent_required'
            ) {
              // We need to clear state if we are re-auth, a user could have
              // interrupted the re-auth process and have invalid state.
              // @ts-expect-error
              setLocalShouldShowForcedAuthModal(false);
              setShouldShowForcedAuthModal(false);

              await loginWithRedirect(
                // @ts-expect-error
                redirectLoginOptionsGenerator(currentLocation.current, isSignup)
              );
            }
            isRefreshingToken.current = true;
            throw e;
          }

          isRefreshingToken.current = false;
          req.headers.Authorization = 'Bearer ' + token;
          return req;
        },
        (error) => Promise.reject(error)
      );

      api.interceptors.request.use(async (req) => {
        const locale = intl.finalLocale;
        if (locale) {
          req.headers['Accept-Language'] = `${locale};q=0.9, en;q=0.8, *;q=0.5`;
        }
        return req;
      });
    });

    // note: Apple uses first_name / last_name instead of given_name / family_name,
    // so change it here so we have a standard user throughout the app
    const userToSave = user;
    if (userToSave.first_name) {
      userToSave.given_name = userToSave.first_name;
    }
    if (userToSave.last_name) {
      userToSave.family_name = userToSave.last_name;
    }

    // if no given/family name passed in, parse it out
    // (and should match how auth0backend.py does it on the backend, so update BOTH if you update this)
    if (userToSave.name && !userToSave.given_name && !userToSave.family_name) {
      // [HOTFIX] For LSUHS we throw here an 80/20 to split the name
      // based on what we are getting from Microsoft for them.
      if (user.email && user.email.endsWith('lsuhs.edu')) {
        const names = getTwoNamesForLshus(userToSave.name);
        userToSave.given_name = names[0];
        userToSave.family_name = names[1];
      } else if (user.email && user.email.endsWith('usanainc.com')) {
        const names = getTwoNamesForUsana(userToSave.name);
        userToSave.given_name = names[0];
        userToSave.family_name = names[1];
      } else {
        // parse it manually; this is the case as of 4/2020 for Apple on Auth0
        // not passing in the proper thing (this parses "David Isaac Murray" into "David Isaac" first and "Murray" last)
        const names = getTwoNamesFromName(userToSave.name);
        userToSave.given_name = names[0];
        userToSave.family_name = names[1];
      }
    }

    // fetch user profile and set app to initialized
    setAuthUser(userToSave);
    setAppIsInitialized(true);

    // remove redirect URL upon successful navigation
    localStorage.removeItem(redirect_key);

    log('App initialized.');
  }, [
    appIsInitialized,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    setAuthUser,
    user,
    redirect_key,
    isSignup,
    intl.finalLocale,
  ]);

  const ripplingData = processRipplingData(location.search);

  // We should re-initialize the app ONLY if a new proxy user is set
  // (otherwise, it should only call once for a give auth0 user even
  // if their authentication token expires/renews)
  useEffect(() => {
    if (appIsInitialized) {
      log(
        `Sending /init-app request for ${
          props.currentProxyPerson?.email ?? 'authenticated user'
        } - proxy organization ${props.currentProxyOrganization?.id ?? 'none'}`
      );
      loadApp(
        props.currentProxyPerson?.email,
        props.currentProxyOrganization?.id,
        !!ripplingData,
        (_returnData, error) => checkIfMaintanence(error)
      );
    }
  }, [
    appIsInitialized,
    checkIfMaintanence,
    ripplingData,
    loadApp,
    props.currentProxyPerson,
    props.currentProxyOrganization,
  ]);

  const isReload =
    new URLSearchParams(location.search).get('reloaded') === 'true';

  useEffect(() => {
    if (history && location && !isReload && props.isUnableToLoadDueToError) {
      // wait 3 seconds and reload this page as the error may be related to a push,
      // and append a reloaded=true query parameter so we don't do this indefinitely
      // and do a full page reload
      setTimeout(() => {
        const searchObj = new URLSearchParams(location.search);
        searchObj.set('reloaded', 'true');
        history.replace({
          pathname: location.pathname,
          search: '?' + searchObj.toString(),
        });
      }, 3000);
      return;
    }
  }, [history, location, isReload, props.isUnableToLoadDueToError]);

  if (isMaintenance) {
    // if ?maintenance=true is in url, show maintenance page
    // (without header). This is needed to allow AWS Amplify to
    // use a url param as a way to show the maintenance page)
    return <PageMaintenance />;
  }

  if (error) {
    if (error.message === INVALID_STATE && !isReload) {
      console.error('Retrying due to authentication error: ' + error.message);

      // Update the query string to add reload, and remove auth information
      const searchObj = new URLSearchParams(location.search);
      searchObj.set('reloaded', 'true');
      searchObj.delete('code');
      searchObj.delete('state');

      // Need to use browser redirect to trigger new auth process
      // NOTE: redirect with react-router insufficient
      const redirectURL =
        window.location.origin + location.pathname + '?' + searchObj.toString();
      // @ts-expect-error
      window.location = redirectURL;

      return;
    } else {
      // remove redirect URL if auth process fails
      localStorage.removeItem(redirect_key);

      if (error.message === VERIFY_EMAIL_ERROR_MESSAGE) {
        const toggleSupport = () => {
          IntercomAPI('show');
        };

        const errorMessage = (
          <>
            <FormattedMessage
              id="app.app.verify_your_account"
              defaultMessage="Click 'Verify your account' in the email you just received to <span>log in</span>."
              values={{
                span: (chunks) => (
                  <span
                    className="text-primary"
                    role="button"
                    onClick={loginWithRedirect}
                  >
                    {chunks}
                  </span>
                ),
              }}
            />
            <br />
            <br />
            <FormattedMessage
              id="app.app.check_spam"
              defaultMessage="If you don't see it, check your spam folder. Still having trouble? <span>Let us know</span>"
              values={{
                span: (chunks) => (
                  <span
                    className="text-primary"
                    role="button"
                    onClick={toggleSupport}
                  >
                    {chunks}
                  </span>
                ),
              }}
            />
          </>
        );

        const errorHeading = (
          <FormattedMessage
            id="app.app.check_your_email"
            defaultMessage="Check your email"
          />
        );

        return (
          // @ts-expect-error
          <Layout errorHeading={errorHeading} errorMessage={errorMessage} />
        );
      } else if (error.message?.startsWith(TIME_ERROR_MESSAGE_INCIPIT)) {
        const toggleSupport = () => {
          IntercomAPI('show');
        };

        const errorMessage = (
          <>
            <FormattedMessage
              id="app.app.manually_set_computer_time"
              defaultMessage="
            Have you manually set your computer time? If so, please sync your
            machine clocks with the OS automatic time settings.
            "
            />
            <br />
            <br />
            <FormattedMessage
              id="app.app.still_having_trouble"
              defaultMessage="Still having trouble? <span>Let us know</span>"
              values={{
                span: (chunks) => (
                  <span
                    className="text-primary"
                    role="button"
                    onClick={toggleSupport}
                  >
                    {chunks}
                  </span>
                ),
              }}
            />
          </>
        );

        const errorHeading = (
          <FormattedMessage
            id="app.app.invalid_computer_date_time_detected"
            defaultMessage="Invalid computer date/time detected."
          />
        );

        return (
          // @ts-expect-error
          <Layout errorHeading={errorHeading} errorMessage={errorMessage} />
        );
      }

      console.error('Authentication error: ' + error.message);
      return <Layout errorMessage={error.message} />;
    }
  }

  if (isReload && props.isUnableToLoadDueToError) {
    console.error('App is unable to load due to backend error.');

    const errorMessage = (
      <FormattedMessage
        id="app.app.error_loading_account"
        defaultMessage="Please wait a moment and try again. If this continues, contact customer support."
      />
    );

    return <Layout errorMessage={errorMessage} />;
  }

  if (isLoading || isRefreshingToken.current) {
    return (
      <Loading
        message={formatMessage({
          id: 'app.app.message.initializing',
          defaultMessage: 'Initializing...',
        })}
      />
    );
  }

  if (!isAuthenticated) {
    log('Authenticating...');
    let destination = location;

    // Check for previously stored redirect URL
    const redirectLocation = localStorage.getItem(redirect_key);

    if (redirectLocation) {
      try {
        destination = JSON.parse(decompressFromUTF16(redirectLocation));
      } catch (e) {
        // presumably because item is stored with old (non-UTF16) format,
        // fallback to former decompression method
        destination = JSON.parse(decompress(redirectLocation));
      }
    } else {
      // adding redirect to localStorage to be used later (if needed)
      localStorage.setItem(
        redirect_key,
        compressToUTF16(JSON.stringify(location)) // use UTF16 version so it works on FF / IE
      );
    }

    // We need to skip auto-login if we are getting here via ForcedAuthModal
    // logout's call to let Auth0 show the login prompt.
    if (!localShouldShowForcedAuthModal) {
      // We need to clear state if we are re-auth, a user could have
      // interrupted the re-auth process and have invalid state.
      if (localShouldShowForcedAuthModal !== false) {
        // Strict boolean check to avoid infinite re-renderings.
        // @ts-expect-error
        setLocalShouldShowForcedAuthModal(false);
      }
      setShouldShowForcedAuthModal(false);

      // log in with redirect
      console.debug('[app][call] loginWithRedirect');
      // @ts-expect-error
      loginWithRedirect(redirectLoginOptionsGenerator(destination, isSignup));
      console.debug('[app][response] loginWithRedirect');
    }
  }

  if (!appIsInitialized || props.isLoadingApp) {
    return (
      <Loading
        message={formatMessage({
          id: 'app.app.loading_your_data',
          defaultMessage: 'Loading your data...',
        })}
      />
    );
  }

  // Below means we are logged in or have an error

  if (ripplingData && window.location.pathname !== '/integrations') {
    // We need to ensure to redirect to the integrations page
    // before any other redirect (Welcome, Campaign, ...).

    // Re-build original querystring.
    const params = new URLSearchParams();
    params.append('code', ripplingData.code);
    params.append('redirect_uri', ripplingData.redirect_uri);
    window.location.href = '/integrations?' + params.toString();
    return (
      <Loading
        message={formatMessage({
          id: 'app.app.message.initializing',
          defaultMessage: 'Initializing...',
        })}
      />
    );
  }

  return (
    <>
      <ForcedAuthModal
        toggle={() => {
          // @ts-expect-error
          setLocalShouldShowForcedAuthModal(!localShouldShowForcedAuthModal);
        }}
        isOpen={!!localShouldShowForcedAuthModal}
        // @ts-expect-error
        returnTo={currentLocation.current}
        ttl={
          props.currentOrganization
            ? getCustomSessionTTL(props.currentOrganization)
            : undefined
        }
      />
      {<Analytics />}
      {<Layout />}
    </>
  );
};

App.propTypes = {
  setAuthUser: PropTypes.func.isRequired,
  loadApp: PropTypes.func.isRequired,
  isUnableToLoadDueToError: PropTypes.bool,
  isLoadingApp: PropTypes.bool,
  currentProxyPerson: PropTypes.object,
};

// These props come from the application's
// state when it is started
const mapStateToProps = (state: ReduxState) => {
  const {
    isUnableToLoadDueToError,
    isLoadingApp,
    currentProxyPerson,
    currentOrganization,
    currentProxyOrganization,
  } = state;

  return {
    isUnableToLoadDueToError,
    isLoadingApp,
    currentProxyPerson,
    currentProxyOrganization,
    currentOrganization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthUser: (user) => dispatch(setAuthUser(user)),
    loadApp: (proxy, proxyOrg, isInRipplingInstallFlow, callback) =>
      dispatch(loadApp(proxy, proxyOrg, isInRipplingInstallFlow, callback)),
  };
};

// all tracking in app will be passed through here
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  track(
    {},
    {
      dispatch: (data) => {
        // NOTE: conventions for data tracking object:
        // - action: string
        // - details: JSON object

        if (tracking.useLogRocketEventTracking) {
          LogRocket.track(data.action, data.metadata);
        }

        if (tracking.useFullStoryEventTracking) {
          FullStoryAPI('event', data.action, data.metadata);
        }

        if (tracking.useIntercomEventTracking) {
          IntercomAPI('trackEvent', data.action, data.metadata);
        }

        if (tracking.useGoogleAnalyticsTracking) {
          const ev = {
            category: 'app',
            action: data.action,
          };

          if (data.metadata) {
            if (typeof data.metadata['label'] === 'undefined') {
              ev['label'] = JSON.stringify(data.metadata);
            } else {
              ev['label'] = data.metadata['label'];
            }
          }

          ReactGA.event(ev);
        }

        if (tracking.useConsoleEventTracking) {
          console.log(data);
        }
      },
    }
    // @ts-expect-error
  )(React.memo(App))
);
