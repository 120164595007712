import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { FormattedMessage, useIntl, type IntlShape } from 'react-intl';
import LikertScaleInput, { LIKERT_SCALE_AS_ARRAY } from './LikertScaleInput';
import PropTypes, { InferProps } from 'prop-types';
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  SKILL_TYPE_BEHAVIOR,
  SKILL_TYPE_EXPERIENCE,
} from '../../../utils/models/Skill';
import {
  getUniqueHtmlId,
  renderValidationError,
} from '../../../utils/util/formatter';

import ButtonOptionsInput from './ButtonOptionsInput';
import Checkbox from './Checkbox';
import CheckboxMulti from './CheckboxMulti';
import Confetti from 'react-dom-confetti';
import ContactMethodEditor from './ContactMethodEditor';
import DatePicker from './DatePicker';
import EmployeeNPSInput from './EmployeeNPSInput';
import HideFromRecipientDescriptor from './HideFromRecipientDescriptor';
import { INPUT_TYPES } from './ValidatedInputTypes';
import IncludeExcludeFilter from './IncludeExcludeFilter';
import IncludeExcludeFilterDescriptor from '../People/Filters/IncludeExcludeFilterDescriptor';
import InputTranslationEditor from './InputTranslationEditor';
import Loading from '../Loading';
import MediaUploaderInput from './MediaUploaderInput';
import MultipleChoiceInput from './MultipleChoiceInput';
import ObjectsDropdown from '../Dropdowns/ObjectsDropdown';
import PeopleEditor from '../Forms/PeopleEditor';
import PlacesInput from './PlacesInput';
import PrependedInput from './PrependedInput';
import QuestionsEditor from './QuestionsEditor';
import ReactTagsInput from './ReactTagsInput';
import RichTextEditor from './RichTextEditor';
import RichTextViewer from './RichTextViewer';
import SelectInput from './SelectInput';
import StarRatingInput from './StarRatingInput';
import SwitchBank from './SwitchBank';
import SwitchInput from './SwitchInput';
import SwitchInputWithTimeFrameSelector from './SwitchInputWithTimeFrameSelector';
import TableEditor from './TableEditor';
import TextareaAutosize from 'react-textarea-autosize';
import ToggleInput from './ToggleInput';
import ValidatedAutosuggest from './ValidatedAutosuggest';
import { joinJsonPath } from '../../../utils/util/jsonpath';
import { stripInvalidHtmlSelectorCharacters } from '../../../utils/util/util';
import { toast } from 'react-toastify';
import { useCampaignFlow } from '../../Administration/CampaignFlowContext';
import { UserGeneratedInputType, UserGeneratedInputTypeAttribute } from 'types';

export const DEFAULT_CHECKED_VALUE = 'Y';
export const DEFAULT_UNCHECKED_VALUE = 'N';

export const CONTRIBUTION_EDITOR_ATTRIBUTES = {
  type: INPUT_TYPES.RICH_TEXT_EDITOR,
  minRows: 2,
  config: {
    charCounterMax: 500,
  },
  multiLine: true,
  showToolbar: true,
  toolbarInline: true,
};

// we need to keep these in sync with the INPUT_TYPES above for
// use by the campaign editor
const UGC_PLACEHOLDER_ATTRIBUTE = (
  formatMessage: IntlShape['formatMessage'],
  options: object = {}
): UserGeneratedInputTypeAttribute => ({
  name: 'placeholder',
  label: formatMessage({
    id: 'app.views.widgets.inputs.validate_input.ugc_placeholder.placeholder',
    defaultMessage: 'Placeholder',
  }),
  type: INPUT_TYPES.TEXT,
  // @ts-expect-error
  translationNamespace: options?.translationNamespace,
  // @ts-expect-error
  jsonPath: joinJsonPath(options?.jsonPath, '.placeholder'),
});

export const formatToInteger = (value) => {
  // if value is valid integer return it, otherwise return undefined
  return Number.isInteger(parseInt(value)) ? parseInt(value) : undefined;
};

export const formatToPositiveInteger = (value) => {
  const res = formatToInteger(value);
  // if a number, return undefined if negative
  if (typeof res !== 'undefined') {
    return res < 0 ? undefined : res;
  }
  return res;
};

export const formatToFloat = (value) => {
  // if value is valid float return it, otherwise return undefined
  return Number.isFinite(parseFloat(value)) ? parseFloat(value) : undefined;
};

export const withRichTextViewer = (input) => ({
  ...input,
  label: input.label && typeof input.label === 'string' && (
    <RichTextViewer expanded={true} model={input.label} />
  ),
  helperText: input.helperText && typeof input.helperText === 'string' && (
    <RichTextViewer expanded={true} model={input.helperText} />
  ),
  helperHover: input.helperHover && typeof input.helperHover === 'string' && (
    <RichTextViewer expanded={true} model={input.helperHover} />
  ),
});

export const isIncompleteDecimalNumber = (value) =>
  value && value.endsWith('.');

const UGC_MIN_SELECTIONS_ATTRIBUTE = (
  formatMessage: IntlShape['formatMessage']
): UserGeneratedInputTypeAttribute => ({
  name: 'minSelections',
  label: formatMessage({
    id: 'app.views.widgets.inputs.validate_input.ugc_min_selections.label',
    defaultMessage: 'Min selections',
  }),
  placeholder: 'Optional',
  type: INPUT_TYPES.TEXT,
  // value must be an integer
  formatValueOnSubmit: formatToInteger,
});

const UGC_MAX_SELECTIONS_ATTRIBUTE = (
  formatMessage: IntlShape['formatMessage']
): UserGeneratedInputTypeAttribute => ({
  name: 'maxSelections',
  label: formatMessage({
    id: 'app.views.widgets.inputs.validate_input.ugc_max_selections.label',
    defaultMessage: 'Max selections',
  }),
  placeholder: 'Optional',
  type: INPUT_TYPES.TEXT,
  // value must be an integer
  formatValueOnSubmit: formatToInteger,
});

const UGC_MAX_LENGTH_ATTRIBUTE = (
  formatMessage: IntlShape['formatMessage']
): UserGeneratedInputTypeAttribute => ({
  name: 'maxLength',
  label: formatMessage({
    id: 'app.views.widgets.inputs.validate_input.ugc_max_lenght.label',
    defaultMessage: 'Max length',
  }),
  type: INPUT_TYPES.TEXT,
  // value must be an integer
  formatValueOnSubmit: formatToInteger,
});

const UGC_MIN_SKILLS_ATTRIBUTE = (
  formatMessage: IntlShape['formatMessage']
): UserGeneratedInputTypeAttribute => ({
  name: 'minLength',
  label: formatMessage({
    id: 'app.views.widgets.inputs.validate_input.ugc_min_skills.label',
    defaultMessage: 'Minimum skills',
  }),
  type: INPUT_TYPES.TEXT,
  inputType: 'number',
  minValue: 0,
  // value must be an integer
  formatValueOnSubmit: formatToPositiveInteger,
  displayIf: (question) => {
    return !!question?.required;
  },
});

const UGC_OPTIONS_ATTRIBUTE = (
  formatMessage: IntlShape['formatMessage'],
  options = {}
): UserGeneratedInputTypeAttribute => ({
  // note: DROPDOWN and LIKERT use the name "objects" but
  // MULTIPLE_CHOICE uses "options"
  name: 'objects',
  label: formatMessage({
    id: 'app.views.widgets.inputs.validate_input.ugc_options.objects.label',
    defaultMessage: 'Options',
  }),
  defaultValue: [],
  type: INPUT_TYPES.TABLE_EDITOR,
  columns: [
    {
      field: 'id',
      name: formatMessage({
        id: 'app.views.widgets.inputs.validate_input.ugc_options.objects.columns.id.name',
        defaultMessage: 'ID',
      }),
      // we can't pass the function in directly here
      // (it gets called with the wrong context)
      formatValueOnSubmit: (val) => stripInvalidHtmlSelectorCharacters(val),
      columnClassName: 'w-25',
      sortable: false,
      popoverContent: formatMessage({
        id: 'app.views.widgets.inputs.validate_input.ugc_options.objects.columns.id.popover_content',

        defaultMessage: 'Unique ID for CSV exports, etc.',
      }),
      type: INPUT_TYPES.TEXT,
      generateDefaultValue:
        // @ts-expect-error
        options.generateDefaultValue ?? (() => getUniqueHtmlId()),
      // @ts-expect-error
      filterOnly: !options.allowEditingId,
      // @ts-expect-error
      editable: !!options.allowEditingId,
    },
    {
      field: 'name',
      name: formatMessage({
        id: 'app.views.widgets.inputs.validate_input.ugc_options.objects.columns.name.name',
        defaultMessage: 'Option',
      }),
      columnClassName: 'w-75',
      sortable: false,
      type: INPUT_TYPES.TEXT,
      generateDefaultValue: (index) =>
        formatMessage(
          {
            id: 'app.views.widgets.inputs.validate_input.ugc_options.objects.columns.name.defaultValue',
            defaultMessage: 'Option {optionIndex, number}',
          },
          { optionIndex: index + 1 }
        ),
      placeholder: formatMessage({
        id: 'app.views.widgets.inputs.validate_input.ugc_options.objects.columns.name.placeholder',
        defaultMessage: 'Enter an option',
      }),
      // @ts-expect-error
      translationNamespace: options?.translationNamespace,
      jsonPath: joinJsonPath(
        // @ts-expect-error
        options?.jsonPath,
        // @ts-expect-error
        options?.jsonPathSuffixOverride ?? '.options'
      ),
      jsonPathItemGenerator: (basePath, obj) =>
        `${basePath}[?(@.id=='${obj?.id}')].name`,
    },
  ],
});

// NOTE: order is intentional (from what we want them to
// discover first to last, e.g. see Likert first before
// other kinds of multiple choice options)
export const USER_GENERATED_INPUT_TYPE_OPTIONS = (
  formatMessage: IntlShape['formatMessage'],
  hideAdvancedFeatures: boolean = false,
  options: object = {}
): UserGeneratedInputType[] => [
  ...(hideAdvancedFeatures
    ? []
    : [
        {
          id: INPUT_TYPES.SECTION,
          name: formatMessage({
            id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.section.name',
            defaultMessage: 'Section header',
          }),
        },
      ]),
  {
    id: INPUT_TYPES.TEXTAREA,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.text_area.name',
      defaultMessage: 'Plain text',
    }),
    // @ts-expect-error
    attributes: [
      UGC_PLACEHOLDER_ATTRIBUTE(formatMessage, options),
      UGC_MAX_LENGTH_ATTRIBUTE(formatMessage),
    ],
  },
  {
    id: INPUT_TYPES.RICH_TEXT_EDITOR,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.rich_text_editor.name',
      defaultMessage: 'Rich text (links, photos, etc.)',
    }),
    // @ts-expect-error
    attributes: [
      UGC_PLACEHOLDER_ATTRIBUTE(formatMessage, options),
      UGC_MAX_LENGTH_ATTRIBUTE(formatMessage),
    ],
  },
  {
    id: INPUT_TYPES.PEOPLE_EDITOR,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.people_editor.name',
      defaultMessage: 'People picker',
    }),
    // @ts-expect-error
    attributes: [
      UGC_PLACEHOLDER_ATTRIBUTE(formatMessage, options),
      UGC_MIN_SELECTIONS_ATTRIBUTE(formatMessage),
      UGC_MAX_SELECTIONS_ATTRIBUTE(formatMessage),
    ],
  },
  {
    id: INPUT_TYPES.LIKERT,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.name',
      defaultMessage: 'Likert scale',
    }),
    attributes: [
      {
        name: 'scale',
        label: formatMessage({
          id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.scale.label',
          defaultMessage: 'Heading labels',
        }),
        // NOTE: this variable must come from
        // LikertScaleInput.js instead of Performance.js
        // to avoid circular dependencies that cause
        // this variable to be undefined
        defaultValue: LIKERT_SCALE_AS_ARRAY(formatMessage),
        type: INPUT_TYPES.TABLE_EDITOR,
        // @ts-expect-error
        addingDisabled: true,
        deletingDisabled: true,
        reorderingDisabled: true,
        columns: [
          {
            name: formatMessage({
              id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.scale.columns.name.name',
              defaultMessage: 'Label',
            }),
            field: 'name',
            columnClassName: 'w-50',
            sortable: false,
            type: INPUT_TYPES.TEXT,
            // @ts-expect-error
            translationNamespace: options?.translationNamespace,
            // @ts-expect-error
            jsonPath: joinJsonPath(options?.jsonPath, '.scale'),
            jsonPathItemGenerator: (basePath, obj) =>
              `${basePath}[?(@.value==${obj?.value})].name`,
          },
          {
            name: formatMessage({
              id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.scale.columns.value.name',
              defaultMessage: 'Value',
            }),
            field: 'value',
            columnClassName: 'w-25',
            sortable: false,
            type: INPUT_TYPES.TEXT,
            editable: false,
          },
        ],
      },
      {
        ...UGC_OPTIONS_ATTRIBUTE(formatMessage),
        // @ts-expect-error
        addingDisabled: options.disableAddingQuestions,
        // @ts-expect-error
        deletingDisabled: options.disableRemovingQuestions,
        label: formatMessage({
          id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.options.label',
          defaultMessage: 'Questions',
        }),
        // values for each likert item are name, value, and helperText
        columns: [
          {
            name: formatMessage({
              id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.options.columns.value.name',
              defaultMessage: 'Question',
            }),
            field: 'value',
            columnClassName: 'w-50',
            type: INPUT_TYPES.TEXT,
            generateDefaultValue: (index) => `Question ${index + 1}`,
            placeholder: formatMessage({
              id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.options.columns.value.placeholder',
              defaultMessage: 'Enter a question',
            }),
            // @ts-expect-error
            translationNamespace: options?.translationNamespace,
            // @ts-expect-error
            jsonPath: joinJsonPath(options?.jsonPath, '.objects'),
            jsonPathItemGenerator: (basePath, obj) =>
              `${basePath}[?(@.name=='${obj?.name}')].value`,
          },
          {
            name: formatMessage({
              id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.options.columns.helper_text.name',
              defaultMessage: 'Hover helper text',
            }),
            field: 'helperText',
            columnClassName: 'w-25',
            type: INPUT_TYPES.TEXT,
            placeholder: formatMessage({
              id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.options.columns.helper_text.placeholder',
              defaultMessage: 'Optional',
            }),
            // @ts-expect-error
            translationNamespace: options?.translationNamespace,
            // @ts-expect-error
            jsonPath: joinJsonPath(options?.jsonPath, '.objects'),
            jsonPathItemGenerator: (basePath, obj) =>
              `${basePath}[?(@.name=='${obj?.name}')].helperText`,
          },
          {
            name: 'ID',
            popoverContent: formatMessage({
              id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.likert_scale.attributes.options.columns.id.popover_content',
              defaultMessage: 'Unique ID for CSV exports, etc.',
            }),
            field: 'name',
            // we can't pass the function in directly here
            // (it gets called with the wrong context)
            formatValueOnSubmit: (val) =>
              stripInvalidHtmlSelectorCharacters(val),
            columnClassName: 'w-25',
            type: INPUT_TYPES.TEXT,
            generateDefaultValue: () => getUniqueHtmlId(),
            filterOnly: true,
            editable: false,
          },
        ],
      },
    ],
  },
  {
    id: INPUT_TYPES.MULTIPLE_CHOICE,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.multiple_choice.name',
      defaultMessage: 'Multiple choice',
    }),
    // @ts-expect-error
    attributes: [
      {
        ...UGC_OPTIONS_ATTRIBUTE(formatMessage, options),
        // note: DROPDOWN and LIKERT use the name "objects" but
        // MULTIPLE_CHOICE uses "options"
        name: 'options',
        placeholder: formatMessage({
          id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.multiple_choice.attributes.options.placeholder',
          defaultMessage: 'Enter an option',
        }),
      },
    ],
  },
  {
    id: INPUT_TYPES.DROPDOWN,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.dropdown.name',
      defaultMessage: 'Dropdown list',
    }),

    // @ts-expect-error
    attributes: [
      UGC_OPTIONS_ATTRIBUTE(formatMessage, {
        ...options,
        jsonPathSuffixOverride: '.objects',
      }),
    ],
  },
  {
    id: INPUT_TYPES.DATE_PICKER,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.date_picker.name',
      defaultMessage: 'Date picker',
    }),
    // @ts-expect-error
    attributes: [UGC_PLACEHOLDER_ATTRIBUTE(formatMessage, options)],
  },
  {
    id: INPUT_TYPES.SWITCH,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.switch.name',
      defaultMessage: 'Yes/no toggle',
    }),
    // @ts-expect-error
    attributes: [],
  },
  {
    id: INPUT_TYPES.SKILLS,
    name: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.ugc_input_type_options.skills.name',
      defaultMessage: 'Skills',
    }),
    // @ts-expect-error
    attributes: [UGC_MIN_SKILLS_ATTRIBUTE(formatMessage)],
  },
];

export const getQueryForHeader = (q, features) => {
  const excludeList = [];

  if (!features.activities?.enabled) {
    // @ts-expect-error
    excludeList.push('activities');
  }
  if (!features.skills_and_behaviors?.enabled) {
    // @ts-expect-error
    excludeList.push('skills');
  }
  if (!features.credentials?.enabled) {
    // @ts-expect-error
    excludeList.push('credentials');
  }

  return {
    name: q,
    exclude: excludeList?.length > 0 ? excludeList.join(',') : undefined,
  };
};

export const getQueryWithoutType = (q) =>
  q
    ? { name: q, source_includes: ['id', 'name', 'visibility'] }
    : { source_includes: ['id', 'name', 'visibility'] };

export const getQueryWithType = (q) =>
  q
    ? { name: q, source_includes: ['id', 'name', 'type', 'visibility'] }
    : { source_includes: ['id', 'name', 'type', 'visibility'] };

export const getExperienceSkillWithType = (q) => ({
  ...getQueryWithType(q),
  type: SKILL_TYPE_EXPERIENCE.id,
});

export const getBehaviorSkillWithType = (q) => ({
  ...getQueryWithType(q),
  type: SKILL_TYPE_BEHAVIOR.id,
});

const confettiConfig = {
  angle: '90',
  spread: '30',
  startVelocity: '10',
  elementCount: '30',
  dragFriction: '0.09',
  duration: '250',
  stagger: 0,
  width: '10px',
  height: '14px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const ValidatedInput: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const validationErrors = props.validationErrors ? props.validationErrors : {};
  // @ts-expect-error
  const currentLength = useMemo(() => props?.value?.length || 0, [props.value]);
  const campaignContext = useCampaignFlow();

  const supportsMaxLength =
    props.type === INPUT_TYPES.TEXT ||
    props.type === INPUT_TYPES.TEXTAREA ||
    props.type === INPUT_TYPES.RICH_TEXT_EDITOR ||
    props.type === INPUT_TYPES.MENTION_EDITOR;

  const className =
    (props.className ? props.className : '') +
    (validationErrors &&
    validationErrors[props.name] &&
    validationErrors[props.name]?.length > 0
      ? ' is-invalid'
      : '');

  let inputElement = null;
  const propsOnChange = props.onChange;
  const propsOnValidChange = props.onValidChange;
  const propsName = props.name;
  const propsOnBackspace = props.onBackspace;

  const onChangeObject = useCallback(
    (object) => {
      if (propsOnChange) {
        propsOnChange({
          target: {
            name: propsName,
            value: object,
          },
        });
      }
    },
    [propsOnChange, propsName]
  );

  const onValidChange = useCallback(
    (validationMessage) => {
      if (propsOnValidChange) {
        propsOnValidChange(propsName, validationMessage);
      }
    },
    [propsOnValidChange, propsName]
  );

  const onKeyDown = useCallback(
    (e) => {
      // "which" is used by Froala (RichTextEditor), others use keyCode
      if (propsOnBackspace && (e.keyCode === 8 || e.which === 8)) {
        propsOnBackspace(e);
      }
    },
    [propsOnBackspace]
  );

  if (props.type === INPUT_TYPES.SECTION) {
    // we only show a label but no other content, so just return a fragment
    // @ts-expect-error
    inputElement = (
      <div
        // @ts-expect-error
        role={props.role}
        // @ts-expect-error
        style={props.style}
        // @ts-expect-error
        className={props.className}
      ></div>
    );
  } else if (props.type === INPUT_TYPES.TEXTAREA) {
    const defaultValue = props.defaultValue ? props.defaultValue : '';
    // @ts-expect-error
    inputElement = (
      <TextareaAutosize
        // @ts-expect-error
        required={props.required}
        // @ts-expect-error
        ref={props.innerRef}
        // @ts-expect-error
        minRows={props.minRows}
        // @ts-expect-error
        maxRows={props.maxRows}
        // @ts-expect-error
        role={props.role}
        // @ts-expect-error
        style={props.style}
        name={props.name}
        // @ts-expect-error
        placeholder={props.placeholder}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        className={'form-control' + (className ? ' ' + className : '')}
        // @ts-expect-error
        onChange={props.disableOnChange ? undefined : props.onChange}
        // @ts-expect-error
        autoComplete={props.autoComplete}
        // @ts-expect-error
        autoFocus={props.autoFocus}
        // @ts-expect-error
        disabled={props.disabled || props.isSubmitting}
        onKeyDown={onKeyDown}
        // @ts-expect-error
        maxLength={props.maxLength}
      />
    );
  } else if (props.type === INPUT_TYPES.RICH_TEXT_EDITOR) {
    const defaultValue = props.defaultValue ? props.defaultValue : '';
    // @ts-expect-error
    inputElement = (
      // note: we don't use a RichTextViewer when props.disabled is true because
      // we need a visual display of the editor (e.g. in the case of previewing
      // the set of custom questions that a person sent in a feedback request
      // that has custom questions)
      <RichTextEditor
        inForm={true}
        required={props.required}
        ref={props.innerRef}
        minRows={props.minRows}
        maxRows={props.maxRows}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        name={props.name}
        placeholder={props.placeholder}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        className={className}
        onChange={onChangeObject}
        autoComplete={props.autoComplete}
        onMention={props.onMention}
        autoFocus={props.autoFocus}
        mentionSearchUrl={props.mentionSearchUrl}
        // @ts-expect-error
        config={props.config}
        // @ts-expect-error
        events={props.events}
        disabled={props.disabled || props.isSubmitting}
        onKeyDown={onKeyDown}
        showResetToDefaultButton={props.showResetToDefaultButton}
        showToolbar={props.showToolbar}
        toolbarBottom={props.toolbarBottom}
        toolbarInline={props.toolbarInline}
        toolbarVisibleWithoutSelection={props.toolbarVisibleWithoutSelection}
        disableUnsavedChangesPrompt={props.disableUnsavedChangesPrompt}
        onValidChange={onValidChange}
      />
    );
  } else if (props.type === INPUT_TYPES.MENTION_EDITOR) {
    const defaultValue = props.defaultValue ? props.defaultValue : '';
    const config = {
      // @ts-expect-error
      ...props.config,
      // restrict formatting to just mention linking
      pluginsEnabled: ['link', 'charCounter'],
      shortcutsEnabled: ['undo', 'redo'],
      pastePlain: true,
      htmlAllowedTags: ['a', 'p'],
      htmlAllowedAttrs: ['href', 'placeholder'],
      htmlAllowedEmptyTags: ['textarea'],
      htmlAllowedStyleProps: [],
      multiLine: props.multiLine,
      buttons: [],
      linkEditButtons: [],
      toolbarButtons: [],
      paragraphStyles: {},
    };
    const events = {
      // @ts-expect-error
      ...props.events,
      keydown: (e) => {
        switch (e.keyCode) {
          case 66: // ctrl+B or ctrl+b (bold)
          case 98:
          case 73: // ctrl+I or ctrl+i (italics)
          case 105:
          case 85: // ctrl+U or ctrl+u (underline)
          case 117:
            if (e.ctrlKey) {
              e.preventDefault();
            }
            break;
          default:
            break;
        }
      },
    };

    // @ts-expect-error
    inputElement = (
      <RichTextEditor
        inForm={true}
        showToolbar={false}
        required={props.required}
        ref={props.innerRef}
        minRows={props.minRows}
        maxRows={props.maxRows}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        name={props.name}
        placeholder={props.placeholder}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        className={'mention-editor ' + className}
        onChange={onChangeObject}
        autoComplete={props.autoComplete}
        onMention={props.onMention}
        autoFocus={props.autoFocus}
        mentionSearchUrl={props.mentionSearchUrl}
        config={config}
        events={events}
        disabled={props.disabled || props.isSubmitting}
        onKeyDown={onKeyDown}
        showResetToDefaultButton={props.showResetToDefaultButton}
        disableUnsavedChangesPrompt={props.disableUnsavedChangesPrompt}
      />
    );
  } else if (props.type === INPUT_TYPES.SWITCH) {
    // default to unchecked value or false if no default provided
    const defaultValue =
      typeof props.defaultValue !== 'undefined'
        ? props.defaultValue
        : props.uncheckedValue;
    // @ts-expect-error
    inputElement = (
      <SwitchInput
        switchLabel={props.switchLabel}
        switchLabelClassName={props.switchLabelClassName}
        required={props.required}
        // @ts-expect-error
        role={props.role}
        // @ts-expect-error
        style={props.style}
        name={props.name}
        // @ts-expect-error
        centered={props.centered}
        // @ts-expect-error
        value={
          // use checkedValue vs uncheckedValue if they are provided,
          // else assume that checkedValue is true and uncheckedValue is false
          typeof props.value !== 'undefined'
            ? typeof props.checkedValue !== 'undefined' &&
              typeof props.uncheckedValue !== 'undefined'
              ? props.value === props.checkedValue
                ? props.checkedValue
                : props.uncheckedValue
              : props.value
            : defaultValue
        }
        checkedValue={props.checkedValue}
        uncheckedValue={props.uncheckedValue}
        onChange={onChangeObject}
        className={className}
        disabled={props.disabled || props.isSubmitting}
        // @ts-expect-error
        enableHelp={props.enableHelp}
        // @ts-expect-error
        hoverHelperText={props.hoverHelperText}
      />
    );
  } else if (props.type === INPUT_TYPES.CHECKBOX) {
    // @ts-expect-error
    inputElement = (
      <Checkbox
        // @ts-expect-error
        required={props.required}
        name={props.name}
        // @ts-expect-error
        label={props.checkLabel}
        // @ts-expect-error
        value={props.value ?? props.defaultValue}
        onChange={onChangeObject}
        className={className}
        // @ts-expect-error
        disabled={props.disabled || props.isSubmitting}
        // @ts-expect-error
        checkedValue={props.checkedValue}
        // @ts-expect-error
        uncheckedValue={props.uncheckedValue}
        // @ts-expect-error
        indeterminateValue={props.indeterminateValue}
        // @ts-expect-error
        withIndeterminate={props.withIndeterminate}
      />
    );
  } else if (props.type === INPUT_TYPES.CHECKBOX_MULTI) {
    // @ts-expect-error
    inputElement = (
      <CheckboxMulti
        // @ts-expect-error
        required={props.required}
        name={props.name}
        // @ts-expect-error
        value={props.value ?? props.defaultValue}
        // @ts-expect-error
        options={props.options}
        onChange={onChangeObject}
        className={className}
        // @ts-expect-error
        disabled={props.disabled || props.isSubmitting}
        // @ts-expect-error
        rowProps={props.rowProps}
      />
    );
  } else if (props.type === INPUT_TYPES.MEDIA_UPLOADER) {
    // @ts-expect-error
    inputElement = (
      // @ts-expect-error
      <MediaUploaderInput value={props.value} onChange={onChangeObject} />
    );
  } else if (props.type === INPUT_TYPES.SWITCH_WITH_TIMEFRAME_SELECTOR) {
    // @ts-expect-error
    const defaultValue = props.defaultValue(campaignContext);
    // @ts-expect-error
    inputElement = (
      <SwitchInputWithTimeFrameSelector
        // @ts-expect-error
        switchLabel={props.switchLabel}
        // @ts-expect-error
        required={props.required}
        // @ts-expect-error
        role={props.role}
        // @ts-expect-error
        style={props.style}
        name={props.name}
        // @ts-expect-error
        centered={props.centered}
        value={props.value ? props.value : defaultValue}
        // @ts-expect-error
        quarterHelperText={props.quarterHelperText}
        checkedValue={props.checkedValue}
        uncheckedValue={props.uncheckedValue}
        onChange={onChangeObject}
        className={className}
        // @ts-expect-error
        disabled={props.disabled || props.isSubmitting}
        // @ts-expect-error
        enableHelp={props.enableHelp}
        // @ts-expect-error
        hoverHelperText={props.hoverHelperText}
      />
    );
  } else if (props.type === INPUT_TYPES.TOGGLE) {
    const defaultValue = props.defaultValue ? props.defaultValue : undefined;
    // @ts-expect-error
    inputElement = (
      <ToggleInput
        required={props.required}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        name={props.name}
        // @ts-expect-error
        objects={props.objects}
        selectText={props.selectText}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        onChange={props.onChange}
        className={className}
        alignRight={
          props.alignRight ? props.alignRight : props.isHorizontalLayout
        }
        autoFocus={props.autoFocus}
        disabled={props.disabled || props.isSubmitting}
        renderItem={props.renderItem}
        renderOption={props.renderOption}
        showName={props.showName}
      />
    );
  } else if (props.type === INPUT_TYPES.DROPDOWN) {
    const defaultValue = props.defaultValue ? props.defaultValue : undefined;
    // @ts-expect-error
    inputElement = (
      <ObjectsDropdown
        required={props.required}
        onValidChange={onValidChange}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        name={props.name}
        // @ts-expect-error
        objects={props.objects}
        selectText={props.selectText}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        onChange={props.onChange}
        className={className}
        buttonClassName={
          props.buttonClassName
            ? props.buttonClassName
            : props.isHorizontalLayout
            ? 'btn-sm'
            : ''
        }
        dropdownItemClassName={props.dropdownItemClassName}
        alwaysShowSelectText={props.alwaysShowSelectText}
        alignRight={
          props.alignRight ? props.alignRight : props.isHorizontalLayout
        }
        autoFocus={props.autoFocus}
        disabled={props.disabled || props.isSubmitting}
        renderToggle={props.renderToggle}
        renderItem={props.renderItem}
        renderOption={props.renderOption}
        valueFromAttribute={props.valueFromAttribute}
      />
    );
  } else if (props.type === INPUT_TYPES.SELECT) {
    const defaultValue = props.defaultValue ? props.defaultValue : undefined;
    // @ts-expect-error
    inputElement = (
      <div className="w-100">
        <SelectInput
          // @ts-expect-error
          id={props.inputElementId}
          required={props.required}
          isDemoMode={props.isDemoMode}
          className={className}
          innerRef={props.innerRef}
          name={props.name}
          // @ts-expect-error
          value={props.value ? props.value : defaultValue}
          // @ts-expect-error
          options={props.options}
          onChange={onChangeObject}
          elasticsearchOptions={props.elasticsearchOptions}
          autoFocus={props.autoFocus}
          disabled={props.disabled || props.isSubmitting}
          clearable={props.clearable}
          // @ts-expect-error
          searchable={props.searchable}
          defaultOptions={props.defaultOptions}
          loadOptions={props.loadOptions}
          isOptionSelected={props.isOptionSelected}
          placeholder={props.placeholder}
        />
      </div>
    );
  } else if (props.type === INPUT_TYPES.AUTOSUGGEST) {
    const defaultValue = props.defaultValue ? props.defaultValue : '';
    // @ts-expect-error
    inputElement = (
      <ValidatedAutosuggest
        required={props.required}
        className={'form-control' + (className ? ' ' + className : '')}
        innerRef={props.innerRef}
        name={props.name}
        value={props.value ? props.value : defaultValue}
        suggestions={props.suggestions}
        onInputChange={props.onChange}
        onSuggestionSelected={onChangeObject}
        elasticsearchOptions={props.elasticsearchOptions}
        autoFocus={props.autoFocus}
        disabled={props.disabled || props.isSubmitting}
        getSuggestionValue={props.getSuggestionValue}
      />
    );
  } else if (props.type === INPUT_TYPES.TAGS_INPUT) {
    const defaultValue = props.defaultValue ? props.defaultValue : [];
    // @ts-expect-error
    inputElement = (
      <div className={'w-100' + (className ? ' ' + className : '')}>
        <ReactTagsInput
          className={props.inputClassName}
          required={props.required}
          onValidChange={onValidChange}
          innerRef={props.innerRef}
          name={props.name}
          // @ts-expect-error
          value={props.value ? props.value : defaultValue}
          allowNew={props.allowNew}
          allowNewOnlyIfNoSuggestions={props.allowNewOnlyIfNoSuggestions}
          onInputChange={props.onInputChange}
          suggestions={props.suggestions}
          recommendations={props.recommendations}
          callback={onChangeObject}
          minQueryLength={1}
          elasticsearchOptions={props.elasticsearchOptions}
          excludeTagMatchFunction={props.excludeTagMatchFunction}
          renderCreateOption={props.renderCreateOption}
          autoFocus={props.autoFocus}
          autoComplete={props.autoComplete}
          disabled={props.disabled || props.isSubmitting}
          placeholder={props.placeholder}
          showPlaceholderAfterFirstTag={props.showPlaceholderAfterFirstTag}
          excludeListErrorMessage={props.excludeListErrorMessage}
          excludeListErrorMessageFunction={
            props.excludeListErrorMessageFunction
          }
          // @ts-expect-error
          disableLengthCheck={props.disableLengthCheck}
          maxSuggestionsLength={props.maxSuggestionsLength}
        />
      </div>
    );
  } else if (props.type === INPUT_TYPES.DATE_PICKER) {
    const defaultValue = props.defaultValue ? props.defaultValue : '';
    // @ts-expect-error
    inputElement = (
      <DatePicker
        required={props.required}
        name={props.name}
        // @ts-expect-error
        value={typeof props.value !== 'undefined' ? props.value : defaultValue}
        onChange={onChangeObject}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        className={className}
        inputClassName={props.inputClassName}
        autoFocus={props.autoFocus}
        // @ts-expect-error
        size={props.size}
        disabled={props.disabled || props.isSubmitting}
        hideClearButton={props.hideClearButton}
        placeholder={props.placeholder}
        format={props.format}
        enableTime={props.enableTime}
        // @ts-expect-error
        dateFormat={props.dateFormat}
        // @ts-expect-error
        altInput={props.altInput}
        // @ts-expect-error
        altFormat={props.altFormat}
        minDate={props.minDate}
        maxDate={props.maxDate}
        onValidChange={onValidChange}
      />
    );
  } else if (props.type === INPUT_TYPES.MULTIPLE_CHOICE) {
    const defaultValue = props.defaultValue ? props.defaultValue : null;
    // @ts-expect-error
    inputElement = (
      <MultipleChoiceInput
        required={props.required}
        onValidChange={onValidChange}
        name={props.name}
        title={props.title}
        // value could be 0 but still defined, so check for undefined
        // separately, in case we get an object, we default to the "id"
        value={
          typeof props.value !== 'undefined'
            ? // @ts-expect-error
              props.value?.id ?? props.value
            : defaultValue
        }
        onChange={onChangeObject}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        className={className}
        // @ts-expect-error
        options={props.options}
        allowCustom={props.allowCustom}
        customOptionText={props.customOptionText}
        // @ts-expect-error
        customOptionLabel={props.customOptionLabel}
        customOptionPlaceholder={props.customOptionPlaceholder}
        disabled={props.disabled || props.isSubmitting}
      />
    );
  } else if (props.type === INPUT_TYPES.BUTTON_OPTIONS) {
    const defaultValue = props.defaultValue ? props.defaultValue : null;
    // @ts-expect-error
    inputElement = (
      <ButtonOptionsInput
        required={props.required}
        name={props.name}
        title={props.title}
        // value could be 0 but still defined, so check for undefined
        // @ts-expect-error
        value={typeof props.value !== 'undefined' ? props.value : defaultValue}
        onChange={onChangeObject}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        className={className}
        // @ts-expect-error
        options={props.options}
        allowCustom={props.allowCustom}
        customOptionText={props.customOptionText}
        // @ts-expect-error
        customOptionLabel={props.customOptionLabel}
        customOptionPlaceholder={props.customOptionPlaceholder}
        buttonClassName={props.buttonClassName}
        selectedButtonClassName={props.selectedButtonClassName}
        disabled={props.disabled || props.isSubmitting}
      />
    );
  } else if (props.type === INPUT_TYPES.PEOPLE_EDITOR) {
    const defaultValue = props.defaultValue ? props.defaultValue : [];
    // @ts-expect-error
    inputElement = (
      <PeopleEditor
        isDemoMode={props.isDemoMode}
        required={props.required}
        onValidChange={onValidChange}
        name={props.name}
        className={className}
        inputClassName={props.inputClassName}
        inline={props.inline}
        inForm={props.inForm}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        suggestions={props.suggestions}
        excludeList={props.excludeList}
        callback={onChangeObject}
        onInviteEmailFieldToggle={props.onInviteEmailFieldToggle}
        autoFocus={props.autoFocus}
        allowSelf={props.allowSelf}
        disabled={props.disabled || props.isSubmitting}
        placeholder={props.placeholder}
        showSuggestionsAsPopover={props.showSuggestionsAsPopover}
        showPlaceholderAfterFirstTag={props.showPlaceholderAfterFirstTag}
        showInviteInstructions={props.showInviteInstructions}
        showInviteSuggestions={props.showInviteSuggestions}
        maxSuggestions={props.maxSuggestions}
        allowExternalPeople={props.allowExternalPeople}
        excludeListErrorMessage={props.excludeListErrorMessage}
        excludeListErrorMessageFunction={props.excludeListErrorMessageFunction}
        minSelections={props.minSelections}
        maxSelections={props.maxSelections}
        elasticsearchOptions={props.elasticsearchOptions}
      />
    );
  } else if (props.type === INPUT_TYPES.PLACES_INPUT) {
    const defaultValue = props.defaultValue ? props.defaultValue : null;
    // @ts-expect-error
    inputElement = (
      <PlacesInput
        required={props.required}
        ref={props.innerRef}
        minRows={props.minRows}
        maxRows={props.maxRows}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        name={props.name}
        placeholder={props.placeholder}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        className={'form-control' + (className ? ' ' + className : '')}
        onChange={onChangeObject}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        disabled={props.disabled || props.isSubmitting}
      />
    );
  } else if (props.type === INPUT_TYPES.CONTACT_METHOD_EDITOR) {
    const defaultValue = props.defaultValue ? props.defaultValue : [];
    // @ts-expect-error
    inputElement = (
      <ContactMethodEditor
        required={props.required}
        name={props.name}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        onChange={onChangeObject}
        // @ts-expect-error
        role={props.role}
        style={props.style}
        className={className}
        autoFocus={props.autoFocus}
        disabled={props.disabled || props.isSubmitting}
      />
    );
  } else if (props.type === INPUT_TYPES.LIKERT) {
    const defaultValue = props.defaultValue ? props.defaultValue : {};
    // @ts-expect-error
    inputElement = (
      <LikertScaleInput
        required={props.required}
        onValidChange={onValidChange}
        name={props.name}
        // @ts-expect-error
        value={props.value ? props.value : defaultValue}
        onChange={onChangeObject}
        objects={props.objects}
        // @ts-expect-error
        role={props.role}
        // @ts-expect-error
        style={props.style}
        className={className}
        autoFocus={props.autoFocus}
        disabled={props.disabled || props.isSubmitting}
        scale={props.scale}
      />
    );
  } else if (
    props.type === INPUT_TYPES.EMPLOYEE_NPS ||
    props.type === INPUT_TYPES.EMPLOYEE_NPS_PREVIEW
  ) {
    const valueStr = String(props.value);
    // @ts-expect-error
    inputElement = (
      <EmployeeNPSInput
        // @ts-expect-error
        required={props.required}
        value={valueStr}
        onChange={
          props.type === INPUT_TYPES.EMPLOYEE_NPS_PREVIEW
            ? undefined
            : onChangeObject
        }
        // @ts-expect-error
        organization={props.organization}
      />
    );
  } else if (props.type === INPUT_TYPES.SWITCHBANK) {
    const defaultValue =
      typeof props.defaultValue !== 'undefined'
        ? props.defaultValue
        : props.uncheckedValue;
    // @ts-expect-error
    inputElement = (
      <SwitchBank
        required={props.required}
        name={props.name}
        // @ts-expect-error
        value={props.value}
        onChange={onChangeObject}
        // @ts-expect-error
        role={props.role}
        // @ts-expect-error
        style={props.style}
        className={className}
        autoFocus={props.autoFocus}
        disabled={props.disabled || props.isSubmitting}
        checkedValue={props.checkedValue}
        uncheckedValue={props.uncheckedValue}
        defaultValue={defaultValue}
        switches={props.switches}
      />
    );
  } else if (props.type === INPUT_TYPES.PERCENTAGE_INPUT) {
    const defaultValue = props.defaultValue ? props.defaultValue : '';
    const value = props.value || defaultValue;
    const style = {
      ...props.style,
      width: '3.5rem',
    };
    // @ts-expect-error
    inputElement = (
      <>
        {/* @ts-expect-error */}
        <Input
          required={props.required}
          innerRef={props.innerRef}
          // @ts-expect-error
          role={props.role}
          style={style}
          name={props.name}
          type={props.type ? props.type : 'text'}
          placeholder={props.placeholder}
          value={value}
          className={`display-inline ${className}`}
          onChange={props.onChange}
          autoComplete={props.autoComplete}
          autoFocus={props.autoFocus}
          disabled={props.disabled || props.isSubmitting}
          maxLength={props.maxLength || 3}
          onKeyDown={onKeyDown}
        />
      </>
    );
  } else if (props.type === INPUT_TYPES.CUSTOM_INPUT) {
    // @ts-expect-error
    inputElement = props.component;
  } else if (props.type === INPUT_TYPES.CUSTOM_COMPONENT) {
    // @ts-expect-error
    inputElement = <props.component {...props} />;
  } else if (props.type === INPUT_TYPES.TABLE_EDITOR) {
    const defaultValue = props.defaultValue ? props.defaultValue : [];
    // @ts-expect-error
    const value = (props.value || defaultValue).map((v) => ({
      ...v,
      jsonPath: props.jsonPath,
    }));
    const columns = props.disabled
      ? props.columns?.map((c) => ({ ...c, disabled: true }))
      : props.columns;

    // @ts-expect-error
    inputElement = (
      <TableEditor
        value={value}
        onChange={onChangeObject}
        // @ts-expect-error
        columns={columns}
        addText={props.addText}
        // @ts-expect-error
        addingDisabled={props.addingDisabled}
        // @ts-expect-error
        deletingDisabled={props.deletingDisabled}
        // @ts-expect-error
        reorderingDisabled={props.reorderingDisabled}
        // call it isReadOnly as disabled makes more sense for simple
        // input controls, not for complex ones like this
        isReadOnly={props.disabled}
        showActions={props.showActions}
      />
    );
  } else if (props.type === INPUT_TYPES.QUESTIONS_EDITOR) {
    const defaultValue = props.defaultValue ? props.defaultValue : [];
    const value = props.value || defaultValue;
    // @ts-expect-error
    inputElement = (
      <QuestionsEditor
        // @ts-expect-error
        campaign={props.campaign}
        // @ts-expect-error
        value={value}
        onChange={onChangeObject}
        // @ts-expect-error
        addText={props.addText}
        canBePrivate={props.canBePrivate}
        canBeAnonymous={props.canBeAnonymous}
        // call it isReadOnly as disabled makes more sense for simple
        // input controls, not for complex ones like this
        isReadOnly={props.disabled}
        // @ts-expect-error
        preserveExistingAnswers={props.preserveExistingAnswers}
        // @ts-expect-error
        excludeSpecialQuestions={props.excludeSpecialQuestions}
        // @ts-expect-error
        questionDefaults={props.questionDefaults}
        submitButtonType={props.submitButtonType}
        inModal={props.inModal}
        translationNamespace={props.translationNamespace}
        jsonPath={props.jsonPath}
      />
    );
  } else if (props.type === INPUT_TYPES.INCLUDE_EXCLUDE_FILTER) {
    const defaultValue = props.defaultValue ? props.defaultValue : null;
    const value = props.value || defaultValue;
    // @ts-expect-error
    inputElement = (
      <IncludeExcludeFilter
        campaign={props.campaign}
        // @ts-expect-error
        initialValue={value}
        onChange={onChangeObject}
        addText={props.addText}
        // @ts-expect-error
        disabled={props.disabled}
      />
    );
  } else if (props.type === INPUT_TYPES.STAR_RATING) {
    const defaultValue = props.defaultValue ? props.defaultValue : undefined;
    const value = props.value || defaultValue;
    // @ts-expect-error
    inputElement = (
      <StarRatingInput
        // @ts-expect-error
        value={value}
        onChange={onChangeObject}
        disabled={props.disabled}
      />
    );
  } else {
    const defaultValue = props.defaultValue ? props.defaultValue : '';
    // @ts-expect-error
    inputElement = (
      <>
        {/* @ts-expect-error */}
        <Input
          // @ts-expect-error
          id={props.inputElementId}
          required={props.required}
          innerRef={props.innerRef}
          // @ts-expect-error
          role={props.role}
          style={props.style}
          name={props.name}
          type={props.inputType ?? (props.type ? props.type : 'text')}
          min={props.minValue}
          placeholder={props.placeholder}
          value={props.value ? props.value : defaultValue}
          className={className}
          onChange={props.onChange}
          autoComplete={props.autoComplete}
          autoFocus={props.autoFocus}
          disabled={props.disabled || props.isSubmitting}
          maxLength={props.maxLength}
          onKeyDown={onKeyDown}
          // @ts-expect-error
          onBlur={props.blur}
        />
      </>
    );
  }

  // get names that could be passed back from the backend that need to show errors on the frontend
  // if seen
  const validatorNames = props.matchingValidatorNames
    ? [...props.matchingValidatorNames, props.name]
    : [props.name];

  const descriptors = (
    <>
      {props.label && (
        <Label
          className={
            'mb-1' + (props.labelClassName ? ' ' + props.labelClassName : '')
          }
        >
          {props.labelIcon && <i className={props.labelIcon + ' me-2'}></i>}
          <HideFromRecipientDescriptor {...props}>
            {props.label}
          </HideFromRecipientDescriptor>
          {/* @ts-expect-error */}
          {props.helperHover && (
            <>
              <i
                id={`helper-hover-${props.name}`}
                className="ms-2 fe fe-help-circle text-primary small"
              />
              <UncontrolledPopover
                placement="top"
                trigger="hover"
                target={`helper-hover-${props.name}`}
              >
                {/* @ts-expect-error */}
                {props.helperHover}
              </UncontrolledPopover>
            </>
          )}
        </Label>
      )}
      {/* @ts-expect-error */}
      <IncludeExcludeFilterDescriptor {...props} />
      {props.helperText && (
        <small
          className={
            'form-text text-muted' + (props.isHorizontalLayout ? ' mb-0' : '')
          }
        >
          {props.helperText}
        </small>
      )}
    </>
  );

  const inputObjects = useMemo(() => {
    // @ts-expect-error
    const inner = props.appendedText ? (
      <Row className="align-items-center">
        <Col className="col-auto">{inputElement}</Col>
        {/* @ts-expect-error */}
        <Col className="col-auto ps-0">{props.appendedText}</Col>
      </Row>
    ) : (
      inputElement
    );

    const innerWithTranslationEditor = (
      <Row>
        <Col>{inner}</Col>
        <InputTranslationEditor
          // @ts-expect-error
          type={props.type}
          // @ts-expect-error
          translationNamespace={props.translationNamespace}
          // @ts-expect-error
          translationVisibility={props.translationVisibility}
          // @ts-expect-error
          jsonPath={props.jsonPath}
          // @ts-expect-error
          disabled={props.disabled}
          // @ts-expect-error
          value={props.value}
        />
      </Row>
    );

    return (
      <PrependedInput
        className="mb-3"
        inputGroupTextClassName={props.inputGroupTextClassName}
        prependedText={props.prependedText}
        iconClassName={props.iconClassName}
      >
        {innerWithTranslationEditor}
      </PrependedInput>
    );
  }, [
    inputElement,
    // @ts-expect-error
    props.appendedText,
    props.disabled,
    props.iconClassName,
    props.inputGroupTextClassName,
    props.jsonPath,
    props.prependedText,
    props.translationNamespace,
    props.translationVisibility,
    props.type,
    props.value,
  ]);

  const maxLengthPopoverId = 'max-length-' + (props.name ? props.name : 'null');

  const postscript = (
    <>
      {/* @ts-expect-error */}
      <div className="mt-2 text-muted small">{props.postscript}</div>
    </>
  );

  const [fetchedAISuggestion, setFetchedAISuggestion] = useState(null);
  const [isLoadingAISuggestion, setIsLoadingAISuggestion] = useState(false);

  const sendAISuggestionToInput = useCallback(
    (data) => {
      if (!data?.response) {
        console.error('Unexpected: AI suggestion has no response object.');
        toast.error(
          formatMessage({
            id: 'app.views.widgets.inputs.validate_input.toast.error.no_response_from_ai_suggestion',
            defaultMessage:
              'No response from AI suggestion was available. Please try again later.',
          })
        );
        return;
      }

      setFetchedAISuggestion(data.response);
    },
    [formatMessage]
  );

  const typeIntoInput = useCallback(
    (suggestion) => {
      // programmatically populate the input with the new suggestion,
      // adding each character one at a time to simulate a human typing
      // the suggestion
      let i = 0;
      const interval = setInterval(() => {
        if (i < suggestion.length) {
          const newInputValue = suggestion.substring(0, i + 1);
          // @ts-expect-error
          propsOnChange({
            target: {
              name: propsName,
              value: newInputValue,
            },
          });
          i++;
        } else {
          clearInterval(interval);
        }
      }, 2);
    },
    [propsOnChange, propsName]
  );

  useEffect(() => {
    if (isLoadingAISuggestion && fetchedAISuggestion) {
      setIsLoadingAISuggestion(false);

      const notEnoughInfoMessage =
        'There is not enough information to generate a recommendation.';
      let newValue = fetchedAISuggestion;

      // If original text OR the AI suggestion is "not enough information..."
      // then don't append the original text to output
      if (
        props.value && // don't append if original text is empty
        props.value !== notEnoughInfoMessage && // or is "not enough info..."
        fetchedAISuggestion !== notEnoughInfoMessage
      ) {
        // @ts-expect-error
        newValue +=
          '\n\n' +
          formatMessage({
            id: 'app.views.widgets.inputs.validate_input.ai_suggestion.original_text',
            defaultMessage: 'Original text:',
          }) +
          '\n' +
          props.value;
      }

      typeIntoInput(newValue);
    }
  }, [
    isLoadingAISuggestion,
    fetchedAISuggestion,
    props.value,
    propsOnChange,
    typeIntoInput,
    formatMessage,
  ]);

  const propsFetchAISuggestion = props.fetchAISuggestion;
  const fetchAISuggestion = useCallback(() => {
    if (fetchedAISuggestion || isLoadingAISuggestion) {
      return;
    }
    setIsLoadingAISuggestion(true);
    // @ts-expect-error
    propsFetchAISuggestion(sendAISuggestionToInput, () => {
      toast.warning(
        formatMessage({
          id: 'app.views.widgets.inputs.validated_input.fetch_ai_warning',
          defaultMessage:
            'Our AI engine is overloaded right now. Please try again in a moment.',
        })
      );
      setIsLoadingAISuggestion(false);
    });
  }, [
    propsFetchAISuggestion,
    sendAISuggestionToInput,
    fetchedAISuggestion,
    isLoadingAISuggestion,
    formatMessage,
  ]);

  const element = (
    <FormGroup
      style={props.formGroupStyle ? props.formGroupStyle : {}}
      className={
        'form-group' +
        (props.formGroupClassName ? ' ' + props.formGroupClassName : '') +
        (props.isHorizontalLayout
          ? ' mb-0 position-relative'
          : ' position-relative') // position-relative needed for max char limit indicator layout
      }
    >
      {props.isHorizontalLayout && (
        <Row className="align-items-center">
          <Col>{descriptors}</Col>
          <Col className="col-auto">{inputObjects}</Col>
        </Row>
      )}
      {!props.isHorizontalLayout && (
        <>
          <div>{descriptors}</div>
          <div>{inputObjects}</div>
        </>
      )}
      {props.fetchAISuggestion && (
        <div className="small text-muted mt-2">
          <i className="fe fe-zap mr-2"></i>{' '}
          <FormattedMessage
            id="app.views.widgets.inputs.validate_input.use_ai_suggestion"
            defaultMessage="Use AI suggestion:"
          />{' '}
          <div
            role={!fetchedAISuggestion && 'button'}
            className="d-inline-block text-primary"
            onClick={fetchAISuggestion}
          >
            <Confetti
              className={'react-dom-confetti-overlay'}
              active={!!fetchedAISuggestion}
              // @ts-expect-error
              config={confettiConfig}
            />
            {fetchedAISuggestion && (
              <span className="text-muted">
                <i className="fe fe-check" />{' '}
                <FormattedMessage
                  id="app.views.widgets.inputs.validate_input.suggestion_provided"
                  defaultMessage="Suggestion provided."
                />{' '}
              </span>
            )}
            {!fetchedAISuggestion && isLoadingAISuggestion && (
              <Loading
                className="d-inline ms-2 text-muted"
                message={formatMessage({
                  id: 'app.views.widgets.inputs.validate_input.fetching_suggestion',
                  defaultMessage: 'Fetching suggestion...',
                })}
                small={true}
              />
            )}
            {!fetchedAISuggestion && !isLoadingAISuggestion && (
              <>
                {props.fetchAISuggestionPlaceholder ??
                  formatMessage({
                    id: 'app.views.widgets.inputs.validate_input.view_ai_suggestion.placeholder',
                    defaultMessage: 'View AI suggestion',
                  })}
              </>
            )}
          </div>
        </div>
      )}
      {supportsMaxLength && props.maxLength && !props.hideMaxLengthIndicator && (
        <>
          <div
            id={maxLengthPopoverId}
            className="text-muted small"
            style={{ position: 'absolute', right: 0 }}
          >
            {currentLength}
            <FormattedMessage
              id="app.views.widgets.inputs.validated_input.slash"
              defaultMessage="/"
            />
            {props.maxLength}
            {props.maxLengthHelperText && (
              <>
                {' '}
                <i
                  className="fe fe-help-circle small"
                  style={{ position: 'relative', top: 1 }}
                />
              </>
            )}
          </div>
          {supportsMaxLength && props.maxLengthHelperText && (
            <UncontrolledPopover trigger="hover" target={maxLengthPopoverId}>
              {props.maxLengthHelperText}
            </UncontrolledPopover>
          )}
        </>
      )}
      {validatorNames.map((name, nameIndex) => (
        <Fragment key={nameIndex}>
          {/* @ts-expect-error */}
          {renderValidationError(validationErrors[name])}
        </Fragment>
      ))}
      {postscript}
    </FormGroup>
  );

  // if wrapper function passed in, output through that wrapper function
  if (props.wrapperFunction) {
    return props.wrapperFunction(element);
  } else {
    return element;
  }
};

const ValidatedInput_propTypes = {
  autoFocus: PropTypes.bool,
  iconClassName: PropTypes.string,
  prependedText: PropTypes.string,
  inputGroupTextClassName: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  dropdownItemClassName: PropTypes.string,
  alwaysShowSelectText: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  formGroupClassName: PropTypes.string,
  formGroupStyle: PropTypes.object,
  labelIcon: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placeholder: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ),
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ),
  ]),
  checkedValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  uncheckedValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  allowNew: PropTypes.bool,
  allowNewOnlyIfNoSuggestions: PropTypes.bool,
  matchingValidatorNames: PropTypes.arrayOf(PropTypes.string),
  validationErrors: PropTypes.object,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  disableUnsavedChangesPrompt: PropTypes.bool,
  onSuggestionSelected: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  recommendations: PropTypes.arrayOf(PropTypes.object),
  autoComplete: PropTypes.string,
  innerRef: PropTypes.object,
  style: PropTypes.object,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  objects: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  inline: PropTypes.bool,
  inForm: PropTypes.bool,
  showToolbar: PropTypes.bool,
  toolbarBottom: PropTypes.bool,
  toolbarInline: PropTypes.bool,
  toolbarVisibleWithoutSelection: PropTypes.bool,
  excludeList: PropTypes.arrayOf(PropTypes.object),
  onInviteEmailFieldToggle: PropTypes.func,
  elasticsearchOptions: PropTypes.object,
  selectText: PropTypes.string,
  wrapperFunction: PropTypes.func,
  required: PropTypes.bool,
  onValidChange: PropTypes.func,
  onMention: PropTypes.func,
  mentionSearchUrl: PropTypes.string,
  allowSelf: PropTypes.bool,
  renderToggle: PropTypes.func,
  renderItem: PropTypes.func,
  renderOption: PropTypes.func,
  disabled: PropTypes.bool,
  excludeTagMatchFunction: PropTypes.func,
  renderCreateOption: PropTypes.func,
  title: PropTypes.string,
  allowCustom: PropTypes.bool,
  isHorizontalLayout: PropTypes.bool,
  alignRight: PropTypes.bool,
  labelClassName: PropTypes.string,
  switchLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  switchLabelClassName: PropTypes.string,
  checkLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputClassName: PropTypes.string,
  hideClearButton: PropTypes.bool,
  showName: PropTypes.bool,
  showSuggestionsAsPopover: PropTypes.bool,
  showPlaceholderAfterFirstTag: PropTypes.bool,
  onBackspace: PropTypes.func,
  showInviteInstructions: PropTypes.bool,
  showInviteSuggestions: PropTypes.bool,
  maxSuggestions: PropTypes.number,
  maxLength: PropTypes.number,
  maxLengthHelperText: PropTypes.string,
  hideMaxLengthIndicator: PropTypes.bool,
  allowExternalPeople: PropTypes.bool,
  customOptionText: PropTypes.string,
  customOptionPlaceholder: PropTypes.string,
  clearable: PropTypes.bool,
  multiLine: PropTypes.bool,
  selectedButtonClassName: PropTypes.string,
  excludeListErrorMessage: PropTypes.string,
  excludeListErrorMessageFunction: PropTypes.func,
  switches: PropTypes.arrayOf(PropTypes.object),
  valueFromAttribute: PropTypes.string,
  showResetToDefaultButton: PropTypes.bool,
  format: PropTypes.string,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  isOptionSelected: PropTypes.func,
  postscript: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  columns: PropTypes.arrayOf(PropTypes.object),
  addText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  campaign: PropTypes.object,
  enableTime: PropTypes.bool,
  isDemoMode: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  fetchAISuggestion: PropTypes.func,
  fetchAISuggestionPlaceholder: PropTypes.string,
  scale: PropTypes.arrayOf(PropTypes.object),
  addingDisabled: PropTypes.bool,
  deletingDisabled: PropTypes.bool,
  showActions: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  canBePrivate: PropTypes.bool,
  canBeAnonymous: PropTypes.bool,
  reorderingDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  maxSuggestionsLength: PropTypes.number,
  minSelections: PropTypes.number,
  maxSelections: PropTypes.number,
  rowProps: PropTypes.object,
  excludeSpecialQuestions: PropTypes.arrayOf(PropTypes.string),
  inputType: PropTypes.string,
  questionDefaults: PropTypes.shape({
    positive_skills: PropTypes.object,
    negative_skills: PropTypes.object,
    positive_comments: PropTypes.object,
    negative_comments: PropTypes.object,
    rating: PropTypes.object,
  }),
  minValue: PropTypes.number,
  submitButtonType: PropTypes.string,
  translationNamespace: PropTypes.string,
  translationVisibility: PropTypes.string,
  inModal: PropTypes.bool,
  organization: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  jsonPath: PropTypes.string,
  getSuggestionValue: PropTypes.func, // For ValidatedAutosuggest only.
};

type Props = InferProps<typeof ValidatedInput_propTypes>;

export default React.memo(ValidatedInput);
