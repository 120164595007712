import * as consts from '../../consts/consts';

import { Button, Card, Col, Progress, Row } from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getPhaseByType,
  getRelationships,
  toPersonWithSurvey,
} from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_HIDE_INFLUENCE_FROM_MANAGERS,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED,
  PHASE_TYPE_EVALUATION,
  getCampaignHasFeatureEnabled,
  getCurrentPerformancePreviewPathPrefix,
  getDirectReportsEligibleForFeedback,
  getEvaluationPhaseOpenResponsesHelperText,
  getPhaseOpenResponseQuestions,
  getStepNumber,
  isRatedByManager,
  perfCampaignCallback,
  updateLatestPerfStep,
} from '../../utils/models/Performance';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getPersonDisplayTitle,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';
import { useHistory, useLocation, withRouter } from 'react-router';

import Avatar from '../Widgets/People/Avatar';
import { Link, type RouteComponentProps } from 'react-router-dom';
import PerformancePage from './PerformancePage';
import PerformancePersonAssessment from './PerformancePersonAssessment';
import PersonPerformance from '../Person/PersonPerformance';
import PropTypes from 'prop-types';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import { ReduxState } from 'types';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { applyCustomFiltersToQuestions } from '../Widgets/People/Filters/common';
import { connect } from 'react-redux';
import { relationshipIsCompleted } from '../../utils/models/Relationship';
import { setCurrentPerfSurveyResponse } from '../../actions';

const PerformanceStepAssessDirectReports: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;

  // for sorting, true means ascending, false means descending, undefined means don't sort
  const [sortByName, setSortByName] = useState(true);
  const [sortByStatus, setSortByStatus] = useState(undefined);
  const [sortByRating, setSortByRating] = useState(undefined);

  const toggleSortByName = () => {
    setSortByName(!sortByName);
    setSortByStatus(undefined);
    setSortByRating(undefined);
  };
  const toggleSortByStatus = () => {
    // @ts-expect-error
    setSortByName(undefined);
    // @ts-expect-error
    setSortByStatus(!sortByStatus);
    setSortByRating(undefined);
  };
  const toggleSortByRating = () => {
    // @ts-expect-error
    setSortByName(undefined);
    // @ts-expect-error
    setSortByStatus();
    // @ts-expect-error
    setSortByRating(!sortByRating);
  };

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const relevantRelationships = getRelationships(
    campaign,
    RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR
  );

  // NOTE: we should never sort this inline as the
  // order of these direct reports is use to determine
  // which person you are reviewing when you click to
  // review
  const defaultSortedDirectReports = useMemo(
    () =>
      getDirectReportsEligibleForFeedback(
        props.me,
        campaign,
        // @ts-expect-error
        campaign.relationships,
        // @ts-expect-error
        campaign.survey_responses,
        props.demoPeople
      ),
    [campaign, props.demoPeople, props.me]
  );

  // This index is the 0 based number in the (sorted?)
  // list of direct reports the manager needs to evaluate.
  // A value from 0+ means we are in the detail page,
  // undefined means we are in list view.
  const currentDirectReportIndex = useMemo(
    () =>
      new URLSearchParams(location.search).get('index')
        ? // @ts-expect-error
          parseInt(new URLSearchParams(location.search).get('index'))
        : undefined,
    [location.search]
  );

  const currentDirectReport = useMemo(
    () =>
      typeof currentDirectReportIndex !== 'undefined'
        ? defaultSortedDirectReports[currentDirectReportIndex]
        : undefined,
    [currentDirectReportIndex, defaultSortedDirectReports]
  );

  const currentDirectReportSurveyResponse = useMemo(() => {
    if (!currentDirectReport) {
      return undefined;
    }

    // @ts-expect-error
    if (!campaign?.survey_responses) {
      return undefined;
    }

    // @ts-expect-error
    return campaign?.survey_responses.find((sr) =>
      peopleObjectsAreEqual(sr.person, currentDirectReport)
    );
    // @ts-expect-error
  }, [currentDirectReport, campaign.survey_responses]);

  const sortedDirectReports = useMemo(
    () =>
      // NOTE: we MUST use [... here to deep copy so the
      // original order persists, otherwise when sorting the
      // buttons will go to the wrong person as we use the index
      // id based on the default order
      [...defaultSortedDirectReports].sort((a, b) => {
        if (typeof sortByName !== 'undefined') {
          return sortByName
            ? a.full_name.localeCompare(b.full_name)
            : b.full_name.localeCompare(a.full_name);
        }

        const relationshipA = relevantRelationships?.find(
          (r) =>
            !r.dataset &&
            peopleObjectsAreEqual(r.from_person, props.me) &&
            peopleObjectsAreEqual(r.to_person, a)
        );
        const relationshipB = relevantRelationships?.find(
          (r) =>
            !r.dataset &&
            peopleObjectsAreEqual(r.from_person, props.me) &&
            peopleObjectsAreEqual(r.to_person, b)
        );

        if (typeof sortByStatus !== 'undefined') {
          const a = relationshipA?.rating_comments ? 1 : 0;
          const b = relationshipB?.rating_comments ? 1 : 0;
          return sortByStatus ? b - a : a - b;
        }

        if (typeof sortByRating !== 'undefined') {
          const a = relationshipA?.rating ? relationshipA.rating : -1;
          const b = relationshipB?.rating ? relationshipB.rating : -1;
          return sortByRating ? b - a : a - b;
        }
      }),
    [
      defaultSortedDirectReports,
      props.me,
      relevantRelationships,
      sortByName,
      sortByRating,
      sortByStatus,
    ]
  );
  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const propsDemoPeople = props.demoPeople;

  useEffect(() => {
    // scroll to top so we see everything intended (in case we scrolled below before)
    window.scrollTo(0, 0);
  }, [currentDirectReport]);

  const sortedDirectReportNondatasetRelationships = useMemo(
    () =>
      sortedDirectReports.map((dr) => {
        return relevantRelationships?.find(
          (r) =>
            !r.dataset &&
            peopleObjectsAreEqual(r.from_person, props.me) &&
            peopleObjectsAreEqual(r.to_person, dr)
        );
      }),
    [sortedDirectReports, relevantRelationships, props.me]
  );

  const ratingRequired = useMemo(
    () =>
      !getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      ),
    [campaign]
  );

  const directReportFeedbackComplete = useCallback(
    (r) => {
      if (!ratingRequired) {
        return relationshipIsCompleted(r, campaign);
      }

      return r?.rating_comments?.length > 0;
    },
    [ratingRequired, campaign]
  );

  const directReportsRemaining = useMemo(
    () =>
      sortedDirectReportNondatasetRelationships.filter(
        (r) => !directReportFeedbackComplete(r)
      )?.length,
    [sortedDirectReportNondatasetRelationships, directReportFeedbackComplete]
  );

  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();

  const callback = useCallback(
    (data) => {
      if (data) {
        // when done with all direct reports, go to next step
        if (directReportsRemaining === 0) {
          propsSetCurrentPerfSurveyResponse(
            updateLatestPerfStep(
              props.currentPerfSurveyResponse,
              currentStepNumber
            )
          );

          perfCampaignCallback(
            props.me,
            props.currentOrganization,
            campaign,
            history,
            propsDemoPeople,
            data,
            formatMessage
          );
        } else {
          history.push(
            previewPathPrefix +
              consts.PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS().path
          );
        }
      }
    },
    [
      directReportsRemaining,
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      campaign,
      history,
      propsDemoPeople,
      previewPathPrefix,
      formatMessage,
    ]
  );

  const setCurrentDirectReport = (p) => {
    const defaultSortPersonIndex = defaultSortedDirectReports.findIndex((p2) =>
      peopleObjectsAreEqual(p, p2)
    );

    history.push(
      previewPathPrefix +
        consts.PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS().path +
        '?index=' +
        defaultSortPersonIndex
    );
  };

  const object = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? // @ts-expect-error
          props.currentPerfSurveyResponse.id
        : undefined,
      // @ts-expect-error
      campaign: campaign.id,
      step: currentStepNumber,
      responses: {},
    }),
    // @ts-expect-error
    [currentStepNumber, campaign.id, props.currentPerfSurveyResponse]
  );

  const currentDirectReportSurvey = useMemo(() => {
    return (
      // @ts-expect-error
      campaign?.survey_responses &&
      // @ts-expect-error
      campaign.survey_responses.find(
        (sr) =>
          sr?.person &&
          currentDirectReport &&
          peopleObjectsAreEqual(sr?.person, currentDirectReport)
      )
    );
  }, [currentDirectReport, campaign]);

  const showInfluenceScore = useMemo(() => {
    return !getCampaignHasFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_HIDE_INFLUENCE_FROM_MANAGERS
    );
  }, [campaign]);

  const currentDirectReportRelationships = useMemo(() => {
    return (
      currentDirectReport &&
      // @ts-expect-error
      campaign?.relationships?.filter(
        (r) =>
          peopleObjectsAreEqual(r.to_person, currentDirectReport) &&
          [
            RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
            RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT,
            RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT,
            RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR,
            RELATIONSHIP_TYPES.REPORTS_TO,
            RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR,
            ...(showInfluenceScore
              ? [RELATIONSHIP_TYPES.ENERGIZED_BY, RELATIONSHIP_TYPES.ADVISED_BY]
              : []),
          ].indexOf(r.type) !== -1
      )
    );
    // @ts-expect-error
  }, [currentDirectReport, campaign?.relationships, showInfluenceScore]);

  const proceedButton = useMemo(
    () => (
      <>
        {!currentDirectReport && directReportsRemaining > 0 && (
          <Row>
            <Col>
              <span className="text-muted me-4">
                <FormattedMessage
                  id="app.views.performance.performance_step_assess_direct_reports.direct_reports_remaining"
                  defaultMessage="{directReportsRemaining, plural, one {1 direct report left} other {{directReportsRemaining} direct reports left}} "
                  values={{ directReportsRemaining }}
                />
              </span>
              <Button className="mt-0" color="primary" disabled>
                <FormattedMessage
                  id="app.views.performance.performance_step_assess_direct_reports.button.continue"
                  defaultMessage="
                Continue
              "
                />
              </Button>
            </Col>
          </Row>
        )}
        {!currentDirectReport && directReportsRemaining === 0 && (
          <ValidatedForm
            method={object.id ? 'PATCH' : 'POST'}
            url={
              // @ts-expect-error
              campaign.status === CAMPAIGN_STATUSES.DEMO
                ? undefined
                : 'survey-responses'
            }
            buttonIsBlock={false}
            object={object}
            inputs={[]}
            callback={callback}
            submitText={formatMessage({
              id: 'app.views.performance.performance_step_assess_direct_reports.submit_text.save_and_continue',
              defaultMessage: 'Save and continue',
            })}
          />
        )}
      </>
    ),
    [
      callback,
      currentDirectReport,
      directReportsRemaining,
      object,
      // @ts-expect-error
      campaign.status,
      formatMessage,
    ]
  );

  const openResponsesHelperText = useMemo(() => {
    const phase = getPhaseByType(campaign, PHASE_TYPE_EVALUATION);
    const text = getEvaluationPhaseOpenResponsesHelperText(phase);

    if (text) {
      return text;
    }

    return (
      'Answer the following questions about ' +
      currentDirectReport?.given_name +
      '.'
    );
  }, [currentDirectReport?.given_name, campaign]);

  const promotionPacketsRequired = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED
      ),
    [campaign]
  );

  const currentDirectReportWithSurvey = useMemo(
    () => toPersonWithSurvey(currentDirectReport, currentDirectReportSurvey),
    [currentDirectReport, currentDirectReportSurvey]
  );

  // @ts-expect-error
  const unfilteredPhaseOpenResponseQuestions = getPhaseOpenResponseQuestions(
    getPhaseByType(campaign, PHASE_TYPE_EVALUATION)
  );

  const phaseOpenResponseQuestions = useMemo(() => {
    if (typeof currentDirectReportIndex !== 'undefined') {
      return applyCustomFiltersToQuestions({
        questions: unfilteredPhaseOpenResponseQuestions,
        campaign,
        targetPerson: currentDirectReportWithSurvey,
      });
    }

    return null;
  }, [
    campaign,
    currentDirectReportIndex,
    currentDirectReportWithSurvey,
    unfilteredPhaseOpenResponseQuestions,
  ]);

  if (
    typeof currentDirectReportIndex !== 'undefined' &&
    unfilteredPhaseOpenResponseQuestions &&
    unfilteredPhaseOpenResponseQuestions.length > 0 &&
    (!phaseOpenResponseQuestions || phaseOpenResponseQuestions.length === 0)
  ) {
    console.error('No phaseOpenResponseQuestions found');
  }

  return (
    <PerformancePage
      campaign={campaign}
      title={
        currentDirectReport
          ? currentDirectReport.full_name
          : formatMessage({
              id: 'app.views.performance.performance_step_assess_direct_reports.assess_team',
              defaultMessage: 'Assess team',
            })
      }
      person={currentDirectReport}
      subtitle={
        currentDirectReport
          ? getPersonDisplayTitle(formatMessage, currentDirectReport)
          : undefined
      }
    >
      {proceedButton}
      {currentDirectReport && (
        <>
          <div className="mb-4">
            <FormattedMessage
              id="app.views.performance.performance_step_assess_direct_reports.review_previous_review"
              defaultMessage="
            Review {directReportName}'s <link>previous review</link>, any feedback below, then answer the final questions at the bottom
            of this page."
              values={{
                directReportName: currentDirectReport.given_name,
                link: (chunks) => (
                  <a
                    href={
                      currentDirectReport?.url +
                      consts.PERFORMANCE_PREVIOUS().path
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </div>

          <PersonPerformance
            isInReviewFlow={true}
            person={currentDirectReportWithSurvey}
            campaign={campaign}
            surveyResponse={currentDirectReportSurvey}
            incomingRelationshipsWithFeedback={currentDirectReportRelationships}
            showManagerOnlyPerformanceDetails={true}
            showManagerResponses={false}
          />

          <div className="header mb-4">
            <div className="header-body">
              <Row>
                <Col>
                  <h2 className="header-title">
                    <FormattedMessage
                      id="app.views.performance.performance_step_assess_direct_reports.manager_evaluation"
                      defaultMessage="Manager evaluation"
                    />
                  </h2>
                  <span className="header-subtitle small">
                    <RichTextViewer
                      model={openResponsesHelperText}
                      expanded={true}
                    />
                  </span>
                </Col>
              </Row>
            </div>
          </div>
          <PerformancePersonAssessment
            // @ts-expect-error
            campaign={campaign}
            setCampaign={setCampaign}
            // subtract 1 because we don't consider the step done until we submit
            // ALL the peers (not just this individual one)
            currentStepNumber={currentStepNumber - 1}
            relationshipType={RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR}
            callback={callback}
            person={currentDirectReport}
            personSurveyResponse={currentDirectReportSurveyResponse}
            allowContributionFeedback={false}
            isDirectReport={true}
            incomingRelationshipsWithFeedback={currentDirectReportRelationships}
            openResponseQuestions={phaseOpenResponseQuestions}
          />
        </>
      )}
      {!currentDirectReport && (
        <>
          {!(sortedDirectReports?.length > 0) && (
            <div className="mb-4">
              <FormattedMessage
                id="app.views.performance.performance_step_assess_direct_reports.no_direct_reports"
                defaultMessage="
              You do not have any direct reports. You are free to skip this
              step!
            "
              />
            </div>
          )}
          {sortedDirectReports?.length > 0 && (
            <>
              <Card>
                <table className="table table-sm card-table">
                  <thead>
                    <tr>
                      <th
                        onClick={toggleSortByName}
                        scope="col"
                        role="button"
                        style={{ width: '40%' }}
                      >
                        <FormattedMessage
                          id="app.views.performance.performance_step_assess_direct_reports.team_member"
                          defaultMessage="
                        Team member
                      "
                        />
                      </th>
                      <th
                        onClick={toggleSortByStatus}
                        scope="col"
                        role="button"
                        style={{ width: '18%' }}
                      >
                        <FormattedMessage
                          id="app.views.performance.performance_step_assess_direct_reports.status"
                          defaultMessage="
                        Status
                      "
                        />
                      </th>
                      {ratingRequired && (
                        <th
                          onClick={toggleSortByRating}
                          scope="col"
                          className="text-end"
                          role="button"
                          style={{ width: '15%' }}
                        >
                          <FormattedMessage
                            id="app.views.performance.performance_step_assess_direct_reports.rating"
                            defaultMessage="
                          Rating
                        "
                          />
                        </th>
                      )}
                      <th scope="col" style={{ width: '27%' }}></th>
                    </tr>
                  </thead>
                  <tbody className="fs-base">
                    {sortedDirectReports.map((p, index) => {
                      const r =
                        sortedDirectReportNondatasetRelationships[index];
                      const feedbackComplete = directReportFeedbackComplete(r);
                      const mustBeRatedByManager = isRatedByManager(
                        // @ts-expect-error
                        campaign?.survey_responses?.find(
                          (sr) => sr.person.id === p.id
                        )?.configs
                      );

                      return (
                        <tr
                          role="button"
                          key={index}
                          onClick={() => setCurrentDirectReport(p)}
                        >
                          <th scope="row">
                            <div className="flex-nowrap row align-items-center">
                              <div className="col-auto">
                                <Avatar size="xs" person={p} />
                              </div>
                              <div className="col">
                                <div className="p-0 row">
                                  <div className="ms-n3 col">
                                    <h4 className="m-0 text-dark">
                                      {p.full_name}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </th>
                          <td>
                            {!r && (
                              <FormattedMessage
                                id="app.views.performance.performance_step_assess_direct_reports.not_started"
                                defaultMessage="Not started"
                              />
                            )}
                            {r && !feedbackComplete && (
                              <Progress
                                className="progress-sm"
                                value={
                                  r?.rating !== null
                                    ? 75
                                    : r?.negative_comments?.length > 0
                                    ? 50
                                    : r?.positive_comments?.length > 0
                                    ? 25
                                    : 0
                                }
                              />
                            )}
                            {feedbackComplete && (
                              <span className="text-primary">
                                <FormattedMessage
                                  id="app.views.performance.performance_step_assess_direct_reports.done"
                                  defaultMessage="Done"
                                />
                              </span>
                            )}
                          </td>
                          {ratingRequired && mustBeRatedByManager && (
                            <td className="text-end">
                              {(!r ||
                                typeof r === 'undefined' ||
                                r?.rating === null) && (
                                <span>
                                  <FormattedMessage
                                    id="app.views.performance.performance_step_assess_direct_reports.not_rated"
                                    defaultMessage="Not rated"
                                  />
                                </span>
                              )}
                              {r && r?.rating !== null && (
                                <>
                                  <span>
                                    <i className="fe fe-edit-3 text-primary me-2 small"></i>
                                  </span>
                                  {r?.rating}
                                </>
                              )}
                            </td>
                          )}
                          {ratingRequired && !mustBeRatedByManager && (
                            <td className="text-end">
                              <span>
                                <FormattedMessage
                                  id="app.views.performance.performance_step_assess_direct_reports.not_eligible_for_rating"
                                  defaultMessage="-"
                                />
                              </span>
                            </td>
                          )}
                          <td className="text-end">
                            {!r && (
                              <Button className="btn-sm" color="primary">
                                <FormattedMessage
                                  id="app.views.performance.performance_step_assess_direct_reports.start_review"
                                  defaultMessage="
                                Start review
                              "
                                />
                              </Button>
                            )}
                            {r &&
                              (feedbackComplete ? (
                                <Button
                                  className="btn-sm"
                                  color="outline-primary"
                                >
                                  <FormattedMessage
                                    id="app.views.performance.performance_step_assess_direct_reports.edit_review"
                                    defaultMessage="
                                  Edit review
                                "
                                  />
                                </Button>
                              ) : (
                                <Button className="btn-sm" color="primary">
                                  <FormattedMessage
                                    id="app.views.performance.performance_step_assess_direct_reports.continue"
                                    defaultMessage="
                                  Continue
                                "
                                  />
                                </Button>
                              ))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card>
              {promotionPacketsRequired && (
                <Row>
                  <Col className="col-auto">
                    <FormattedMessage
                      id="app.views.performance.performance_step_assess_direct_reports.want_to_nominate"
                      defaultMessage="
                    Want to nominate someone for promotion? <link>Create a promotion packet</link>"
                      values={{
                        link: (chunks) => (
                          <Link
                            to={consts.PROMOTION_PACKETS(formatMessage).path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {chunks}
                          </Link>
                        ),
                      }}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
    </PerformancePage>
  );
};

const PerformanceStepAssessDirectReports_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = PropTypes.InferProps<
  typeof PerformanceStepAssessDirectReports_propTypes
> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
    features,
  } = state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
    features,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PerformanceStepAssessDirectReports)));
