import { Campaign, Organization } from 'types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { FC, Fragment, useContext, useMemo } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import { GoldStarsCard } from './PersonPerformance/GoldStarsCard';
import GoldStarsDistributionChart from './PersonPerformance/Trajectory/GoldStarsDistributionChart';
import { HeadsUpCard } from './PersonPerformance/HeadsUpCard';
import { InfluenceCard } from './PersonPerformance/InfluenceCard';
import InfluenceDistributionChart from './PersonPerformance/Trajectory/InfluenceDistributionChart';
import NetworkRecognitionHistoryChart from './PersonPerformance/Trajectory/NetworkRecognitionHistoryChart';
import { PageSection } from './components/PageSection';
import PerformanceProfileContext from './PersonPerformance/PersonPerformanceContext';
import { SkillsCard } from './PersonPerformance/SkillsCard';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  data: any;
  currentPerson: any;
  campaign: Campaign;
  currentOrganization: Organization;
  firstName: string;
  showManagerOnlyPerformanceDetails: boolean;
  performanceData: any;
  displayDistributions: boolean;
}

export const NetworkRecognitionRow: FC<Props> = ({
  data,
  currentPerson,
  campaign,
  currentOrganization,
  firstName,
  showManagerOnlyPerformanceDetails,
  performanceData,
  displayDistributions,
}) => {
  const { formatMessage } = useIntl();

  const performanceProfileContext = useContext(PerformanceProfileContext);

  const campaignId = campaign.id;

  const selectedCampaignData = useMemo(() => {
    if (!data || data.length === 0) return null;
    if (campaignId) return data.find((item) => item.campaign.id === campaignId);
    return null;
  }, [data, campaignId]);

  const positiveONA = useMemo(() => {
    if (!selectedCampaignData) return null;
    return [
      ...(selectedCampaignData.gold_stars ?? []),
      ...(selectedCampaignData.influence ?? []),
    ];
  }, [selectedCampaignData]);

  const componentsLayout = [
    ...(displayDistributions && performanceProfileContext.displayCharts
      ? [
          { xs: 12, lg: 6, xl: 4 },
          { xs: 12, lg: 6, xl: 4 },
          { xs: 12, lg: 12, xl: 4 },
        ]
      : []),
    { xs: 12, xl: 8 },
    { xs: 12, xl: 4 },
  ];

  const components = useMemo(() => {
    return [
      ...(displayDistributions && performanceProfileContext.displayCharts
        ? [
            <Card key="influence-distrbution" className="card-fill">
              <CardHeader>
                <CardHeaderTitle>
                  <FormattedMessage
                    id="app.views.person.networkrecognitionrow.influence_distribution"
                    defaultMessage="Influence distribution"
                  />
                </CardHeaderTitle>
              </CardHeader>
              <CardBody>
                <InfluenceDistributionChart person={currentPerson} />
              </CardBody>
            </Card>,
            <Card key="gold-stars-distrbution" className="card-fill">
              <CardHeader>
                <CardHeaderTitle>
                  <FormattedMessage
                    id="app.views.person.networkrecognitionrow.gold_stars_distribution"
                    defaultMessage="Gold stars distribution"
                  />
                </CardHeaderTitle>
              </CardHeader>
              <CardBody>
                <GoldStarsDistributionChart person={currentPerson} />
              </CardBody>
            </Card>,
            <NetworkRecognitionHistoryChart
              key="network-recognition-history"
              person={currentPerson}
              showManagerOnlyPerformanceDetails={
                showManagerOnlyPerformanceDetails
              }
              performanceData={performanceData}
            />,
          ]
        : []),
      <Fragment key="gold-stars">
        <GoldStarsCard
          organization={currentOrganization}
          person={currentPerson}
          goldStars={selectedCampaignData?.gold_stars}
        />
        {selectedCampaignData?.heads_ups_display &&
          selectedCampaignData?.heads_ups_display !== 'hidden' && (
            <HeadsUpCard
              firstName={firstName}
              organization={currentOrganization}
              person={currentPerson}
              headsUp={selectedCampaignData?.heads_ups}
              headsUpsDisplay={selectedCampaignData.heads_ups_display}
            />
          )}
      </Fragment>,
      <Fragment key="influence">
        <InfluenceCard
          organization={currentOrganization}
          person={currentPerson}
          influence={selectedCampaignData?.influence}
        />
        <SkillsCard
          organization={currentOrganization}
          person={currentPerson}
          // @ts-expect-error
          positiveONA={positiveONA}
        />
      </Fragment>,
    ];
  }, [
    displayDistributions,
    currentOrganization,
    currentPerson,
    selectedCampaignData,
    positiveONA,
    firstName,
    performanceData,
    showManagerOnlyPerformanceDetails,
    performanceProfileContext.displayCharts,
  ]);

  return (
    <PageSection
      id="ona-summary-table"
      title={formatMessage({
        id: 'app.views.person.network_recognition_row.title.network_recognition',
        defaultMessage: 'Network recognition',
      })}
      titleIcon="share-2"
      titleIconColor="text-primary"
      widths={componentsLayout}
    >
      {components}
    </PageSection>
  );
};
