// this should be re-used in all MapStateToProps functions,

import { type IntlShape } from 'react-intl';
import { NamedExoticComponent, ReactNode } from 'react';

// This is models.UserConfigs.
// Add any missing key.
export interface UserConfigs {
  settings: object;
}

export type AttachedContentType = 'packet';

export type AttachedContentTypes = Record<AttachedContentType, number>;

// just add fields as needed
export interface ReduxState {
  me: Me;
  authUser: AuthUser;
  features: Features;
  campaigns: Campaign[];
  currentOrganization: Organization;
  originalOrganization: Organization;
  currentProxyPerson: Person;
  currentProxyOrganization: Organization;
  myConfigs: UserConfigs;
  settings: OrganizationSettings;
  attachedContentTypes: AttachedContentTypes;
  isUnableToLoadDueToError: boolean;
  isLoadingApp: boolean;
  currentPerfSurveyResponse: any;
  demoPeople: any[];
}

// Flexible type for redux actions
export type Action<T extends string, P> = {
  type: T;
} & P;

// Common Types

export interface AgendaItem {
  id: string;
  key: string;
  title: string;
  created_at: string;
  completed_at?: string;
  sort_key?: string;
}

interface App {
  square_image: string | null;
  display_name: string;
  categories: Array<string> | null;
  color: string | null;
  id: number;
  image: string | null;
  internal_name: string;
}

export interface AppIntegration {
  id: number;
  app: App;
  last_synced_on?: string;
  created_at: string;
}

export interface AuthUser {
  sub: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  'https://app.confirmhr.com/hris_id': string;
  'https://app.confirmhr.com/roles': Array<string>;
  'https://example.com/geoip': object;
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  updated_at: string;
}

interface CampaignProperties {
  is_engagement_survey_only: boolean;
  has_survey: boolean;
}

export interface Campaign {
  id: number;
  name: string;
  phases: { type: string }[];
  coverage_start_date: string;
  coverage_end_date: string;
  properties?: CampaignProperties;
  wizard_type?: string;
  campaign_question_locks?: { [key: string]: string[] };
}

export interface CampaignConfigs {
  introduction_ui_phase?: object;
  hidden_phase_S?: object;
  hidden_phase_O?: object;
  hidden_phase_E?: object;
  hidden_phase_C?: object;
  hidden_phase_R?: object;
  created_with_default_questions?: boolean;
}

export interface CampaignWithConfigs extends Campaign {
  dataset_id?: number;
  configs: CampaignConfigs;
}

export type NewCampaign = Omit<Campaign, 'id'>;

export interface NewCampaignWithConfigs extends NewCampaign {
  configs: CampaignConfigs;
}

type DatasetType = 'P';
export interface Dataset {
  data: DatasetRecord[];
  id: number;
  name: string;
  type: DatasetType;
  mappings: DatasetMappings;
}

export interface DatasetRecord {
  email: string;
}

export interface DatasetMappings {
  admins: { [key: string]: unknown };
  keys: { [key: string]: string };
  transforms: { [key: string]: unknown };
  values: { [key: string]: unknown };
}

export enum PersonProfileDeltasMode {
  ALL = 'all',
  EXCEPT_SELF = 'except-self',
  OFF = 'off',
}

export interface RouteConfig {
  name: string;
  path: string;
}

export type RouteConfigFn = (
  formatMessage: IntlShape['formatMessage']
) => RouteConfig;

export type RouteConfigFnWithDefault = (
  formatMessage?: IntlShape['formatMessage']
) => RouteConfig;

export interface Features {
  people?: {
    org_chart?: boolean;
    list_view?: boolean;
  };
  objectives?: {
    version_20?: boolean;
    rollup_enabled?: boolean;
    enabled?: boolean;
  };
  continuous_feedback?: {
    enabled?: boolean;
    recognition_enabled?: boolean;
    private_notes_enabled?: boolean;
    giving_feedback_enabled?: boolean;
    show_in_dashboard?: boolean;
    feedback_requests_enabled?: boolean;
    feedback_request_custom_questions_enabled?: boolean;
  };
  talent_matrices?: {
    enabled?: boolean;
  };
  activities?: {
    show_in_dashboard?: boolean;
    enabled?: boolean;
  };
  pulse_checks?: {
    show_in_dashboard?: boolean;
    enabled?: boolean;
  };
  promotion_packets?: {
    enabled?: boolean;
    feedback_requests_enabled?: boolean;
  };
  autosave_forms?: {
    enabled?: boolean;
  };
  skills_and_behaviors?: {
    enabled?: boolean;
  };
  skills_talent_inventory?: {
    enabled?: boolean;
  };
  operating_manual?: {
    enabled?: boolean;
  };
  development_plans?: {
    enabled?: boolean;
  };
  performance_profile_v2?: {
    enabled?: boolean;
  };
  performance_profile_enable_charts?: {
    enabled?: boolean;
  };
  performance_profile_enable_distributions?: {
    enabled?: boolean;
  };
  cycle_admin?: {
    peer_360_options_enabled?: boolean;
  };
  resumes?: {
    enabled?: boolean;
  };
  one_on_ones?: {
    enabled?: boolean;
  };
  goals?: {
    enabled?: boolean;
  };
  nudges?: {
    enabled?: boolean;
  };
}

export interface InitOrganization {
  id: number;
  name: string;
  email_domains?: string[] | null;
  status: string;
  custom_session_ttl: number;
  instant_messaging: string;
}

export interface InitProxyPerson {
  full_name: string;
  user_id: number;
  id: number;
  avatar: string;
  title?: null;
  given_name: string;
  family_name: string;
  email: string;
  url: string;
  status: string;
}

export interface DirectReportsEntity {
  id: number;
  email: string;
  given_name?: string | null;
  family_name: string;
  full_name: string;
  url: string;
  avatar?: string | null;
  title: string;
  status: string;
}

export interface Manager {
  id: number;
  email: string;
  given_name: string;
  family_name: string;
  full_name: string;
  url: string;
  avatar: string;
  title: string;
  status: string;
}

export interface Organization {
  id?: number;
  name: string;
  status?: string;
  is_hrbp?: boolean;
  is_system_admin?: boolean;
  instant_messaging?: string;
}

export interface BasicPerson {
  id?: number;
  email?: string;
  given_name: string;
  family_name: string;
  full_name: string;
  avatar: string;
  title: string;
}

export interface AnonymousPerson extends BasicPerson {
  id: undefined;
  email: undefined;
}

export interface Person extends BasicPerson {
  id: number;
  email: string;
  url: string;
  status: string;
  is_customer_provisioned?: boolean;
}

export interface PotentiallyAdminablePerson extends Person {
  is_adminable?: boolean;
  is_below_in_chain_of_command?: boolean;
}

// Matches AdminOnlyPersonSerializer from the backend
export interface AdminOnlyPersonMetadata {
  cost_center?: string;
  cost_center_id?: string;
  country?: string;
  level?: string;
  level_id?: string;
  termination_date?: string | null;
}

// Matches ExtendedPersonSerializer from the backend, but
// including AdminOnlyPersonMetadata so we properly render
// whether or not the given viewer has those additional
// fields viewable to them
export interface ExtendedPerson
  extends PotentiallyAdminablePerson,
    AdminOnlyPersonMetadata {
  user_id: number;
  preferred_given_name: string;
  legal_given_name: string;
  profile: Profile;
  is_below_in_chain_of_command: boolean;
  is_direct_report_in_campaign: boolean;
  is_adminable: boolean;
  is_active: boolean;
  latest_hire_date: string;
  function?: string;
  location: string;
  home_location?: null;
  department?: string;
  business_unit?: string;
  direct_reports?: DirectReportsEntity[] | null;
  manager: Manager | null;
  declared_skills?: null[] | null;
  declared_credentials?: null[] | null;
  credential_issuances?: null[] | null;
  award_issuances?: null[] | null;
  locale?: string | null;
  slug?: string | null;
  business_unit_id?: string;
  department_id?: string;
}

// Me from backend uses ExtendedPersonSerializer, but
// we define here separately to avoid confusion and
// allow for future changes to just me that won't
// affect ExtendedPerson
export interface Me extends ExtendedPerson {}

export interface ContactMethod {
  id: string;
  value: string;
}

export interface Profile {
  intro?: string;
  last_development_conversation_at?: string; // format: 2024-09-02T07:00:00Z
  most_reachable_via?: ContactMethod[];
  name_pronunciation?: string;
  operating_manual?: Record<string, string>;
  pronouns?: string;
  wants_to_mentor_since?: string; // format: 2024-09-03T22:26:43.742000Z
}

// Keep in sync with RELATIONSHIP_TYPE in Relationship.js
export enum RelationshipType {
  REPORTS_TO = 'R',
  USED_TO_REPORT_TO = 'U',
  USED_TO_HAVE_AS_DIRECT_REPORT = 'T',
  ENERGIZED_BY = 'E',
  ADVISED_BY = 'A',
  HAS_AS_STAKEHOLDER = 'S',
  GIVES_GOLD_STAR_TO = 'G',
  GIVES_HEADS_UP_ABOUT = 'H',
  NEEDS_AS_HIGH_PRIORITY_PEER = 'P',
  IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR = 'I',
  HAS_DIRECT_REPORT_FEEDBACK_FOR = 'D',
  SELF = 'L',
}

export interface Relationship {
  id: number;
  from_person: Person;
  to_person?: Person;
  is_anonymous?: boolean;
  positive_skills?: Skill[];
  positive_comments?: string;
  negative_skills?: Skill[];
  negative_comments?: string;
  type: RelationshipType;
  rating_comments?: string;
  ona_source_type?: string;
}

export interface Skill {
  id?: string | number;
  name: string;
  type: string;
}

export interface Tab {
  path: string;
  name: JSX.Element;
  content: JSX.Element;
}

// ElasticSearch Responses

export interface ElasticSearchResponse<Source = unknown> {
  took: number;
  timed_out: boolean;
  _shards: Shards;
  hits: Hits<Source>;
}

export interface Hit<Source = unknown> {
  _index: string;
  _type: string;
  _id: string;
  _score: number | null;
  _source: Source;
  sort: number[];
}

export interface Hits<Source = unknown> {
  total: Total;
  max_score: number | null;
  hits: Hit<Source>[];
}

export interface Shards {
  total: number;
  successful: number;
  skipped: number;
  failed: number;
}

export interface Total {
  value: number;
  relation: string;
}

// ConfirmAPI Responses

export interface CurrentActiveResponse {
  campaign?: Campaign;
  phase_permissions?: Record<string, unknown>;
  completions?: Record<string, unknown>;
  is_first_time_participant?: boolean;
}

export interface OrgChartRelatives {
  manager: Person | null;
  above_manager: Array<Person>;
  peers: Array<Person>;
  direct_reports: Array<Person>;
}

export interface MessageDescriptor {
  id: string;
  defaultMessage: string;
  description?: string | Record<string, unknown>;
}

export interface Quarter {
  start: string;
  end: string;
}

export type TimeFrameType = 'QUARTER' | 'YEAR';

export type TimeFrame = {
  type: TimeFrameType;
  start: Date;
  end: Date;
};

export type ApiTimeFrame = {
  type: TimeFrameType;
  start: string; // yyyy-MM-dd
  end: string; // yyyy-MM-dd
};

export interface InternationalizationSettings {
  enabled: boolean;
  default_locale: string;
  supported_locales: string[];
}

export interface DevelopmentPlanTemplateDescriptor {
  id: string;
  name: string;
  value: string;
}

// TODO: add more settings as you use this across the app
export interface OrganizationSettings {
  objectives_restrict_visibility_to_match_editability?: boolean;
  objectives_timeframe: TimeFrameType;
  objectives_timeframe_default_start_date: string;
  disable_enps_full_export: boolean;
  company_objectives_link?: string;
  agenda_item_templates?: string;
  feedback_request_templates?: string[];
  internationalization: InternationalizationSettings;
  development_plans_templates: DevelopmentPlanTemplateDescriptor[];
  default_promotion_packet_questions?: any[];
  default_promotion_include_description_in_csv_exports?: boolean;
  default_promotion_packet_description?: string;
  default_promotion_effective_date?: string;
  default_promotion_level_options?: any[];
  default_promotion_url_input_attributes?: any[];
  default_promotion_approvers?: object[];
  promotion_packet_custom_questions?: object[];
  performance_profile_deltas_mode: PersonProfileDeltasMode;
}

export interface Task {
  time?: Date;
  timeText?: ReactNode;
  sender?: Person;
  listDescription?: string;
  acceptText?: string;
  declineText?: string;
  type: string;
  object?: {
    id: number;
  };
  acceptPopoverContent?: string;
  declinePopoverContent?: string;
  popupDescription?: string;
}

export interface Comment {
  created_at: string;
  content_type: number;
  object_id: number;
  parent_comment_id?: number;
  author_person: Person;
  on_behalf_of_person?: Person;
  type: string;
  body: string;
}

export interface Reaction {
  author_person: Person;
  content_type: number;
  object_id: number;
  emoji: string;
}

export interface ContributionComment {
  id: number;
  created_at: string;
  updated_at: string;
  author_person: Person;
  contributor_role: string;
  description: string;
  skills: Skill[];
  reactions: Reaction[];
}

export interface Contribution {
  id: number;
  contributor_person: Person;
  description?: string;
  skills: Skill[];
  contribution_comments: ContributionComment[];
  reactions: Reaction[];
  requested_people?: Person[] | null;
}

export interface Activity {
  id: number;
  organization: number;
  created_at: string;
  updated_at: string;
  date_started?: string | null;
  date_completed?: string | null;
  type: string;
  stage: string;
  visibility: string;
  parent?: number | null;
  name: string;
  description: string;
  contributions: Contribution[];
  reactions: Reaction[];
}

export interface PulseCheck {
  id: number;
  created_at: string;
  updated_at: string;
  person: Person;
  rating: number;
  rating_comments?: string;
}

export interface ColumnConfig {
  name: string | ReactNode;
  field: string;
  sortable: boolean;
  popoverContent: ReactNode;
  type: string;
  generateDefaultValue?:
    | ((value: unknown) => string)
    | ((length: number, value: unknown) => number);
  formatValueOnSubmit?:
    | ((value: unknown) => string)
    | ((value: unknown) => number | undefined);
  suspendFormatValueOnSubmit?: ((value: unknown) => boolean) | boolean;
  component?: ReactNode | NamedExoticComponent<any>;
  columnClassName?: string;
  filterOnly?: boolean;
}

export interface ValidatedInputConfig {
  name: string;
  label?: string | ReactNode;
  helperText?: string | ReactNode;
  type: string;
  required?: boolean;
  placeholder?: string | number;
  options?: Array<{ value: string; label: string }>;
  validation?: string;
  validationMessage?: string;
  defaultValue?:
    | string
    | number
    | boolean
    | ((context: CampaignFlowContextType) => object);
  formGroupClassName?: string;
  formGroupStyle?: object;
  format?: string;
  autoFocus?: boolean;
  onChangeSideEffects?: (
    currentValue: object,
    newValue: object,
    intl: IntlShape,
    organizationSettings: OrganizationSettings
  ) => object;
  preserveExistingAnswers?: boolean;
  objects?: object[];
  addText?: string | ReactNode;
  columns?: ColumnConfig[];
  quarterHelperText?: string | ReactNode;
  disableOnChange?: boolean;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
  canBePrivate?: boolean;
  canBeAnonymous?: boolean;
  jsonRoot?: string;
  enableTime?: boolean;
  showActions?: boolean;
  component?: ReactNode | NamedExoticComponent;
}

export interface CollapsiblePhaseConfig {
  type: string;
  title: string | ReactNode;
  helperText: string | ReactNode;
  inputs: ValidatedInputConfig[];
  disableToggle?: boolean;
  excludeSpecialQuestions?: string[];
  questionDefaults?: Record<string, unknown>;
}

export interface CampaignFlowContextType {
  phaseSelfSetObjectivesLegacy: boolean;
  phaseSelfStartDate: string;
  objectivesOrganizationTimeFrameType: TimeFrameType;
  objectivesOrganizationTimeFrameDefaultStartDateMMDD: string;
}

export interface CampaignEditorPhaseContextType {
  phaseType: string;
  campaignId: number;
}

export interface ObjectDropdownOption {
  id: string;
  name: string;
}

export interface Phase {
  type: string;
  was_auto_deactivated?: boolean;
  name?: string;
  description?: string;
  assess_organization?: boolean;
  employee_nps_question_enabled?: boolean;
  disable_network?: boolean;
  disable_callouts?: boolean;
  assess_manager?: boolean;
  peer_feedback?: boolean;
}

// ----------------------------------------
// From here till the closing comment, there is a lot
// of guess work on these types. There is a chance they
// are not sematically correct but for now we are just
// extracting type information to please the typechecker.
// In a second phase we can revisit and define types
// that actually make sense.

export interface CampaignQuestionObjectsType {
  name: string;
  value?: any;
  id?: string;
  helperText?: string;
}

export interface CampaignQuestion {
  canBeAnonymous?: boolean;
  canBePrivate?: boolean;
  filter?: object[];
  helperText?: string;
  helperTextLearnMorePopover?: string;
  label: string;
  maxLength?: number;
  maxRows?: number;
  minRows?: number;
  name?: string;
  objects?: CampaignQuestionObjectsType[];
  organization?: number;
  required?: boolean;
  scale?: object[];
  type?: string;
}

export interface QuestionDefaults {
  positive_skills?: object;
  negative_skills?: object;
  positive_comments?: object;
  negative_comments?: object;
  rating?: object;
}

export interface QuestionOptions {
  type: string;
  label?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  minRows?: number;
  maxRows?: number;
  objects?: object[];
}

export interface QuestionType extends QuestionDefaults {
  name: string;
  heading: string;
  subheading: string;
  icon: string;
  description: string;
  generateQuestionId: boolean;
  allowedQuestionTypes?: string[];
  questionOptions?: QuestionOptions;
}

export interface UserGeneratedInputTypeAttribute {
  columns?: object[];
  defaultValue?: any;
  displayIf?: (question) => boolean;
  formatValueOnSubmit?: (value) => any;
  inputType?: string;
  jsonPath?: string;
  label: string;
  minValue?: number;
  name: string;
  placeholder?: string;
  translationNamespace?: string;
  type: string;
}

// There is a chance that this type and CampaginQuestion
// are ultimately the same type.
export interface UserGeneratedInputType {
  canBeAnonymous?: boolean | null;
  canBePrivate?: boolean | null;
  attributes?: UserGeneratedInputTypeAttribute[];
  id?: string;
  label?: string;
  name?: string;
  type?: string;
  // Sometimes used as required, sometimes optional;
  objects?: CampaignQuestionObjectsType[];
  // Sometimes used as required, sometimes optional;
  options?: {
    id: string;
    name: string;
    helperText: string;
  }[];
  // Code does parseInt on it.
  minSelections?: string;
  // Code does parseInt on it.
  maxSelections?: string;
}

// Closing comment.
// ----------------------------------------
