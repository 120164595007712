import { Optional } from 'utility-types';
import { type IntlShape } from 'react-intl';
import { Features } from 'types';
import {
  continuousFeedbackGivingFeedbackIsEnabled,
  continuousFeedbackRequestsAreEnabled,
} from 'utils/util/features';
import * as consts from '../../consts/consts';

export enum NudgeScheduleType {
  Once = 'O',
  Recurring = 'R',
}

export enum NudgeMethod {
  Email = 'E',
  Slack = 'S',
  MsTeams = 'M',
}

export enum NudgeStatus {
  Active = 'A',
  Paused = 'P',
}

export enum NudgeRecipientsType {
  Everybody = 'E',
  Filter = 'F',
  DirectReports = 'D',
  DirectReportsAndBelow = 'B',
  SpecificPeople = 'P',
  Self = 'S',
}
export interface NudgeData {
  // include/exclude filter
  recipients_filter?: object;
  // list of people ids
  recipients_people?: { id: number; email?: string; full_name?: string }[];
}

export interface Nudge {
  id: number;
  key: string;
  organization_id: number;
  subject: string;
  text: string;
  action_url?: string;
  methods: NudgeMethod[];
  schedule_type: NudgeScheduleType;
  scheduled_timezone: string;
  scheduled_time: string;
  scheduled_recurrence: string;
  status: NudgeStatus;
  recipients_type: NudgeRecipientsType;
  last_sent?: string | null;
  data?: NudgeData;
}

export type EditableNudge = Optional<
  Nudge,
  'id' | 'scheduled_time' | 'scheduled_recurrence' | 'methods'
>;

export interface NudgeForm extends EditableNudge, NudgeData {
  medium?: string;
}

export interface NudgeType {
  id: string;
  name: string;
  subject: string;
  text: string;
  schedule_type?: NudgeScheduleType;
  scheduled_recurrence?: string;
  icon: string;
  url?: string;
  isEnabled: (features: Features) => boolean;
}

type NudgeTypeCallback = (
  formatMessage: IntlShape['formatMessage']
) => NudgeType;

export const NUDGE_TYPE_CHECK_IN: NudgeTypeCallback = (
  formatMessage: IntlShape['formatMessage']
) => ({
  id: 'check-in',
  name: formatMessage({
    id: 'app.views.nudges.nudgetypes.check_in.name',
    defaultMessage: 'Do weekly check-in',
  }),
  subject: formatMessage({
    id: 'app.views.nudges.nudgetypes.check_in.subject',
    defaultMessage: 'Weekly check-in reminder',
  }),
  text: formatMessage({
    id: 'app.views.nudges.nudgetypes.check_in.text',
    defaultMessage:
      'Just a quick reminder to do your weekly check-in. Complete the "How has your week been?" in the top right of the Confirm homepage.',
  }),
  schedule_type: NudgeScheduleType.Recurring,
  scheduled_recurrence: '0 0 12 ? * MON *',
  icon: consts.ICONS.PULSE_CHECKS,
  url: process.env.REACT_APP_CONFIRM_APP_URL + '/',
  isEnabled: (features: Features) => !!features.pulse_checks?.enabled,
});

export const NUDGE_TYPE_UPDATE_OBJECTIVES: NudgeTypeCallback = (
  formatMessage: IntlShape['formatMessage']
) => ({
  id: 'check-in',
  name: formatMessage({
    id: 'app.views.nudges.nudgetypes.update_objectives.name',
    defaultMessage: 'Update objectives',
  }),
  subject: formatMessage({
    id: 'app.views.nudges.nudgetypes.update_objectives.subject',
    defaultMessage: 'Update your objectives',
  }),
  text: formatMessage({
    id: 'app.views.nudges.nudgetypes.update_objectives.text',
    defaultMessage:
      'Time to update your objectives. Go to Confirm and make any changes.',
  }),
  icon: consts.ICONS.OBJECTIVE,
  url: process.env.REACT_APP_CONFIRM_APP_URL + '/profile/objectives',
  isEnabled: (features: Features) => !!features.objectives?.enabled,
});

export const NUDGE_TYPE_GIVE_FEEDBACK_TO_DIRECT_REPORTS: NudgeTypeCallback = (
  formatMessage: IntlShape['formatMessage']
) => ({
  id: 'give-feedback-to-direct-reports',
  name: formatMessage({
    id: 'app.views.nudges.nudgetypes.give_feedback_to_direct_reports.name',
    defaultMessage: 'Give feedback to direct reports',
  }),
  subject: formatMessage({
    id: 'app.views.nudges.nudgetypes.give_feedback_to_direct_reports.subject',
    defaultMessage: 'Give feedback to your team',
  }),
  text: formatMessage({
    id: 'app.views.nudges.nudgetypes.give_feedback_to_direct_reports.text',
    defaultMessage:
      "Don't forget to give feedback to your team. A few minutes can make a big difference.",
  }),
  icon: consts.ICONS.FEEDBACK,
  url: process.env.REACT_APP_CONFIRM_APP_URL + '/feedback/received',
  isEnabled: continuousFeedbackGivingFeedbackIsEnabled,
});

export const NUDGE_TYPE_REQUEST_FEEDBACK: NudgeTypeCallback = (
  formatMessage: IntlShape['formatMessage']
) => ({
  id: 'request-feedback',
  name: formatMessage({
    id: 'app.views.nudges.nudgetypes.request_feedback.name',
    defaultMessage: 'Request feedback from others',
  }),
  subject: formatMessage({
    id: 'app.views.nudges.nudgetypes.request_feedback.subject',
    defaultMessage: 'Request feedback',
  }),
  text: formatMessage({
    id: 'app.views.nudges.nudgetypes.request_feedback.text',
    defaultMessage:
      'Please ask for feedback from your colleagues. It helps you grow and doing so periodically reduces recency bias.',
  }),
  icon: consts.ICONS.REQUEST,
  url: process.env.REACT_APP_CONFIRM_APP_URL + '/feedback/sent',
  isEnabled: continuousFeedbackRequestsAreEnabled,
});

export const NUDGE_TYPE_CUSTOM: NudgeTypeCallback = (
  formatMessage: IntlShape['formatMessage']
) => ({
  id: 'custom',
  name: formatMessage({
    id: 'app.views.nudges.nudgetypes.custom.name',
    defaultMessage: 'Create a custom nudge',
  }),
  subject: '',
  text: '',
  icon: consts.ICONS.NOTE,
  isEnabled: () => true,
});

export function nudgeTemplateById(
  id: string,
  formatMessage: IntlShape['formatMessage']
) {
  const allTemplates = [
    NUDGE_TYPE_CHECK_IN,
    NUDGE_TYPE_UPDATE_OBJECTIVES,
    NUDGE_TYPE_GIVE_FEEDBACK_TO_DIRECT_REPORTS,
    NUDGE_TYPE_REQUEST_FEEDBACK,
    NUDGE_TYPE_CUSTOM,
  ];

  const allTemplateInstances = allTemplates.map((template) =>
    template(formatMessage)
  );

  return allTemplateInstances.find((template) => template.id === id);
}

export const nudgeRecipientsType = (
  formatMessage: IntlShape['formatMessage'],
  isSuperAdmin: boolean,
  isSystemAdmin: boolean,
  isHrbp: boolean,
  hasDirectReports: boolean,
  orgName: string
) => [
  ...(isSystemAdmin || isSuperAdmin
    ? [
        {
          id: NudgeRecipientsType.Everybody,
          name: formatMessage(
            {
              id: 'app.views.nudges.modalnudgeeditorbutton.recipients_type.everybody',
              defaultMessage: 'Everyone at {orgName}',
            },
            {
              orgName: orgName,
            }
          ),
        },
        // Commented for the first release,
        // we need to implement the recipient calculation
        // based on the filter
        // {
        //   id: NudgeRecipientsType.Filter,
        //   name: formatMessage({
        //     id: 'app.views.nudges.modalnudgeeditorbutton.recipients_type.filter',
        //     defaultMessage: 'Filter',
        //   }),
        // },
      ]
    : []),
  ...(hasDirectReports || isSuperAdmin || isSystemAdmin || isHrbp
    ? [
        {
          id: NudgeRecipientsType.SpecificPeople,
          name: formatMessage({
            id: 'app.views.nudges.modalnudgeeditorbutton.recipients_type.specific_people',
            defaultMessage: 'Only these people',
          }),
        },
      ]
    : []),
  ...(hasDirectReports
    ? [
        {
          id: NudgeRecipientsType.DirectReports,
          name: formatMessage({
            id: 'app.views.nudges.modalnudgeeditorbutton.recipients_type.direct_reports',
            defaultMessage: 'My direct reports',
          }),
        },
        {
          id: NudgeRecipientsType.DirectReportsAndBelow,
          name: formatMessage({
            id: 'app.views.nudges.modalnudgeeditorbutton.recipients_type.direct_reports_and_below',
            defaultMessage: 'My direct reports and everyone below them',
          }),
        },
      ]
    : []),
  {
    id: NudgeRecipientsType.Self,
    name: formatMessage({
      id: 'app.views.nudges.modalnudgeeditorbutton.recipients_type.self',
      defaultMessage: 'Just me',
    }),
  },
];
