import React, { FC, PropsWithChildren } from 'react';

interface LoadingProps {
  className?: string;
  message?: string;
  small?: boolean;
  dataTestId?: string;
  active?: boolean;
  layout?: LoadingLayout;
}

type LoadingLayout = 'inline' | 'overlay';

const Loading: React.FC<LoadingProps> = ({
  className = '',
  message = '',
  small = false,
  dataTestId = 'loader',
  active = true,
  layout = 'inline',
}: LoadingProps) => {
  if (!active) return null;

  const combinedClassName =
    (small ? '' : 'p-3 text-center') +
    // mt-3 used for larger loading, but for small, default
    // to no margin as this is typically used on buttons
    (className ? ' ' + className : small ? '' : ' mt-3');

  // for loads that could potentially be longer, we use a spinner
  // and show a message; if no message, this means it should be a
  // quick load, so we use a pulse which is less prominent
  return (
    <LoadingLayout layout={layout}>
      <div className={combinedClassName} data-testid={dataTestId}>
        {message && (
          <>
            {small && (
              <>
                <i
                  className="spinner-border me-2"
                  style={{
                    width: '0.8rem',
                    height: '0.8rem',
                  }}
                />
                <span>{message}</span>
              </>
            )}
            {!small && (
              <>
                <div className="spinner-border text-muted m-auto"></div>
                <div className="mt-3">{message}</div>
              </>
            )}
          </>
        )}
        {!message && <div className="sk-pulse m-auto"></div>}
      </div>
    </LoadingLayout>
  );
};

const LoadingLayout: FC<PropsWithChildren<{ layout: LoadingLayout }>> = ({
  layout,
  children,
}) => {
  if (layout === 'inline') {
    return <>{children}</>;
  }
  return (
    <div
      style={{
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.025)',
      }}
    >
      <div
        className="filtering-spinner"
        style={{
          zIndex: 1001,
          position: 'absolute',
          top: '30%',
          left: '50%',
          height: '120px',
          width: '230px',
          backgroundColor: 'rgba(255,255,255,0.95)',
          borderRadius: '10px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Loading;
