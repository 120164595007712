import { Col, Container, Row } from 'reactstrap';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC } from 'react';

import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import PerformanceNav from './PerformanceNav';
import { ReduxState } from 'types';
import { type RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPerformancePhases } from '../../utils/models/Performance';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router';

const PerformancePage: FC<Props & RouteComponentProps> = (props) => {
  const { formatMessage } = useIntl();

  if (!props.campaign) {
    return <Loading />;
  }

  const navPhases =
    props.phases ||
    getPerformancePhases(
      props.me,
      props.currentOrganization,
      props.campaign,
      props.demoPeople,
      formatMessage
    );

  return (
    <Container>
      <Row>
        <Col md={3}>
          <PerformanceNav
            // @ts-expect-error
            campaign={props.campaign}
            history={props.history}
            phases={navPhases}
          />
        </Col>
        <Col>
          <Page
            containerClassName="p-0"
            className={props.className}
            title={props.title}
            plainTextTitle={props.plainTextTitle}
            person={props.person}
            subtitle={props.subtitle}
          >
            {props.children}
          </Page>
        </Col>
      </Row>
    </Container>
  );
};

const PerformancePage_propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  plainTextTitle: PropTypes.string,
  person: PropTypes.object,
  subtitle: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  history: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  phases: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, demoPeople } = state;

  return {
    me,
    currentOrganization,
    demoPeople,
  };
};

type Props = InferProps<typeof PerformancePage_propTypes>;

export default connect(mapStateToProps)(
  withRouter(React.memo(PerformancePage))
);
