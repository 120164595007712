export const formatPeopleForCSV = (array, columns) => {
  return array.map((row) => {
    // need to convert to prevent issues when folks use "'s
    return Object.keys(row).reduce((result, key) => {
      const value = row[key];
      if (typeof value === 'undefined' || value === null) {
        return result;
      }

      const column = columns.find(
        (c) => !c.hideFromCSV && !c.filterOnly && c.field === key
      );

      // skip if not in CSV
      if (!column) {
        return result;
      }

      if (column.csvFormat) {
        const output = column.csvFormat(value);

        if (output && output.constructor == Object) {
          // we allow a single object outputting multiple values
          return {
            ...result,
            ...output,
          };
        } else {
          return {
            ...result,
            [key]: output,
          };
        }
      }

      return {
        ...result,
        [key]:
          typeof value === 'string'
            ? value.replace(/"/g, '""')
            : // first element in array is value (second is what should be displayed visually,
            // others can be ignored but may be used elsewhere)
            Array.isArray(value)
            ? value[0]
            : value?.toString(),
      };
    }, {});
  });
};
