import { isRawValueFollowedByOutputValueArrayOfTwo } from 'utils/util/util';
import {
  getCalibrationStatusDescriptionFromValue,
  getReportAcknowledgementStatusDescriptionFromValue,
  getReportReleaseStatusDescriptionFromValue,
  RATING_IGNORE_DUE_TO_NO_MANAGER,
  RATING_NOT_ALLOWED,
  RATING_NOT_ELIGIBLE_TO_MANAGER_REVIEW,
  RATING_NOT_PARTICIPATING,
  RATING_NOT_YET_COMPLETE,
} from '../helpers';
import { WHITELISTED_FIELDS_FOR_SENSITIVE_DATA_OMISSION } from 'utils/models/Person';
import { NOT_AVAILABLE } from 'consts/consts';

const convertIneligibleRatingToText = (rating, formatMessage) => {
  if (rating === RATING_NOT_PARTICIPATING) {
    return formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.not participating',
      defaultMessage: 'Not participating',
    });
  } else if (rating === RATING_NOT_YET_COMPLETE) {
    return formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.rating_incomplete',
      defaultMessage: 'Rating incomplete',
    });
  } else if (rating === RATING_IGNORE_DUE_TO_NO_MANAGER) {
    return formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.no_manager',
      defaultMessage: 'No manager',
    });
  } else if (rating === RATING_NOT_ALLOWED) {
    return formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.no_rating_allowed',
      defaultMessage: 'No rating allowed',
    });
  } else if (rating === RATING_NOT_ELIGIBLE_TO_MANAGER_REVIEW) {
    return formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.not_eligible_to_receive_rating',
      defaultMessage: 'Ineligible to receive rating',
    });
  }
  return rating;
};

export const formatForCSV = (array, formatMessage) => {
  return array.map((person) => {
    // need to convert to prevent issues when folks use "'s
    return Object.keys(person).reduce((result, key) => {
      let value = person[key];

      // for final_rating and final_rating_comments, if they were modified
      // in this session we want to include those in the export
      if (key == 'final_rating') {
        value = convertIneligibleRatingToText(value, formatMessage);
      } else if (key == 'draft_rating') {
        value = convertIneligibleRatingToText(value, formatMessage);
      } else if (key == 'original_calibration_flags') {
        if (Array.isArray(value)) {
          // if just 1 item, output it, otherwise output as a list
          if (value.length === 1) {
            value = value[0];
          } else {
            // output as list with numbers at beginning and new lines
            // between each item
            value = value.map((v, index) => `${index + 1}. ${v}`).join('\n\n');
          }
        } else {
          console.error('Unexpected value type for original_calibration_flags');
        }
      } else if (key == 'calibration_status') {
        value = getCalibrationStatusDescriptionFromValue(value, formatMessage);
      } else if (key == 'report_release_status') {
        value = getReportReleaseStatusDescriptionFromValue(
          value,
          formatMessage
        );
      } else if (key == 'report_acknowledgement_status') {
        value = getReportAcknowledgementStatusDescriptionFromValue(
          value,
          formatMessage
        );
      } else if (key == 'incoming_peers' || key == 'outgoing_peers') {
        value = (value ?? []).map((p) => p.full_name).join(', ');
      }

      // For custom responses, e.g. upward feedback:
      // if value is object, convert to human-readable string
      if (value && typeof value === 'object') {
        // if an array of 2, first value is raw value and second is
        // friendly value for display in table, so take first value
        if (isRawValueFollowedByOutputValueArrayOfTwo(value)) {
          // handle multiple choice, and dropdown questions -- don't show
          // unfriendly uuid, instead show user-friendly display value
          // it would best to use the question type here, but it's not
          // passed down to this point
          value = value[1];
        } else {
          // if this is an array of objects, could be a people editor response
          // (which is a list of objects)
          if (
            Array.isArray(value) &&
            value.every((v) => typeof v === 'object')
          ) {
            value = value
              .map((v) => {
                if (v.email && v.full_name) {
                  return `${v.full_name} (${v.email})`;
                } else if (v.full_name) {
                  return v.full_name;
                } else if (v.name) {
                  return v.name;
                } else {
                  return v;
                }
              })
              .join(', ');
          } else {
            const displayValueForCSV = (v) => {
              if (isRawValueFollowedByOutputValueArrayOfTwo(v)) {
                if (v[0] && v[0].startsWith && v[0].startsWith('id_')) {
                  return v[1];
                } else {
                  return `(${v[0]}) ${v[1]}`;
                }
              } else {
                return v;
              }
            };

            // this is a dict; key-values are name: <value which could be array like above>
            value = Object.keys(value)
              .map((k) => (k ? `${k}: ${displayValueForCSV(value[k])}` : ''))
              .join('\n\n');
          }
        }
      }

      if (
        !!person.sensitive_data_omitted &&
        !WHITELISTED_FIELDS_FOR_SENSITIVE_DATA_OMISSION.includes(key)
      ) {
        value = NOT_AVAILABLE;
      }

      return {
        ...result,
        [key]: typeof value === 'string' ? value.replace(/"/g, '""') : value,
      };
    }, {});
  });
};
