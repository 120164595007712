import * as consts from '../../../consts/consts';

import {
  BREAKDOWN_FIELDS,
  createBreakdownData,
  extractPeopleFromSurveyResponses,
  getPersonField,
  getStatsFromRelationship,
  processDistributionData,
  processPeople,
} from '../../../utils/models/Takeaways';
import {
  Card,
  CardBody,
  Col,
  NavLink,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import CardRowDashboard, { CARD_TYPES } from './CardRowDashboard';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  aggregateSankeyData,
  formatChordTableData,
  formatNetworkTableData,
  formatSankeyTableData,
  prepNetworkData,
} from '../../../utils/models/Visualization';
import {
  getPhaseIndexByType,
  getPhaseIsOpen,
} from '../../../utils/models/Campaign';
import { myOptionFilter, numberWithCommas } from '../../../utils/util/util';

import Confetti from 'react-dom-confetti';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
// @ts-expect-error
import { IntercomAPI } from '../../../vendor/react-intercom';
import Loading from '../Loading';
import { PHASE_TYPE_SELF } from '../../../utils/models/Performance';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import SelectInput from '../Inputs/SelectInput';
import Sticky from '../Sticky';
import VisualizationTableCard from '../Cards/VisualizationTableCard';
import { connect } from 'react-redux';
import { loadOrRender } from '../../../utils/util/formatter';
import { sampleData } from './sample_visualization_data';
import trophyImg from '../../../assets/img/illustrations/trophy.png';
import { withRouter } from 'react-router';
import { type RouteComponentProps } from 'react-router-dom';

const fakeData = {};

const colorConfig = {
  influence: '#2c7be5',
  gold_star: '#f6c343',
  heads_up: '#e63757',
  tenure: '#95aac9',
};

const confettiConfig = {
  angle: '90',
  spread: '140',
  startVelocity: '56',
  elementCount: '200',
  dragFriction: '0.09',
  duration: '3440',
  stagger: 0,
  width: '10px',
  height: '14px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const confettiContainerStyle = {
  position: 'relative',
  top: '-140px',
  right: '60px',
  bottom: '0',
  left: '0',
  pointerEvents: 'none',
};

const toggleSupport = () => {
  IntercomAPI('show');
};

const getNavSectionButton = (
  hashUrl,
  icon,
  title,
  subtitle,
  mdWidth = 'col-md-4'
) => (
  <Col className={'col-12 mb-3 ' + mdWidth}>
    <div
      role="button"
      className="nounderline py-4 mb-0 align-items-center w-100 lift card hover-shadow"
      onClick={() => scrollTo(hashUrl)}
    >
      <div className="avatar">
        <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
          <i className={icon} />
        </div>
      </div>
      <h3 className="mb-0 mt-3 text-dark">{title}</h3>
      <p className="small text-muted mb-0">{subtitle}</p>
    </div>
  </Col>
);

const scrollTo = (id) => {
  if (id === 'TOP') {
    window.scrollTo(0, 0);
    return;
  }
  // @ts-expect-error
  document.getElementById(id).scrollIntoView(true);
  window.scrollBy(0, -60);
};

const NextButton = (props) => {
  const [textDecoration, setTextDecoration] = useState('none');
  return (
    <div
      onClick={() => scrollTo(props.target)}
      onMouseEnter={() => setTextDecoration('underline')}
      onMouseLeave={() => setTextDecoration('none')}
      role="button"
      style={{
        display: 'inline-block',
        textDecoration: textDecoration,
      }}
    >
      {props.children}
    </div>
  );
};

const VisualizationsDashboard: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [confettiShouldFire, setConfettiShouldFire] = useState(false);
  const [data, setData] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const [activeFilters, setActiveFilters] = useState({});

  const green = '#00d97e';
  const blue = '#2c7be5';
  const darkGrey = '#6e84a3';
  const red = '#e63757';
  const yellow = '#f6c343';
  const teal = '#39afd1';
  const lightGrey = '#edf2f9';
  const dark = '#12263f';

  const palette = useMemo(() => {
    return [green, blue, darkGrey, red, yellow, teal, lightGrey, dark];
  }, []);

  // show confetti on page load
  useEffect(() => {
    setConfettiShouldFire(true);
  }, []);

  // reset confetti so it can be fired when clicking the trophy
  useEffect(() => {
    setConfettiShouldFire(false);
  }, [confettiShouldFire]);

  const campaign = useMemo(() => {
    // @ts-expect-error
    if (data?.campaign) {
      // @ts-expect-error
      return data.campaign;
    } else {
      return undefined;
    }
  }, [data]);

  const shouldShowInfluence = useMemo(
    () => campaign?.should_see_influence !== false,
    [campaign]
  );

  const [allPeople, filterOptions] = useMemo(() => {
    if (
      // @ts-expect-error
      !data?.survey_responses ||
      !campaign?.coverage_end_date ||
      // @ts-expect-error
      !data?.relationships
    ) {
      return [{}, {}];
    }
    // @ts-expect-error
    const [allPeople, filterOptions] = extractPeopleFromSurveyResponses(
      // @ts-expect-error
      data?.survey_responses,
      campaign?.coverage_end_date,
      campaign
    );
    // @ts-expect-error
    data?.relationships.forEach((r) => getStatsFromRelationship(r, allPeople));
    return [allPeople, filterOptions];
    // @ts-expect-error
  }, [data?.survey_responses, data?.relationships, campaign]);

  const people = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(allPeople).filter(([, person]) => {
          for (const [filter, values] of Object.entries(activeFilters)) {
            const value = getPersonField(person, filter);
            // @ts-expect-error
            if (!values.find((x) => x.value === value)) {
              return false;
            }
          }
          return true;
        })
      ),
    [allPeople, activeFilters]
  );

  const [
    distributionData,
    influenceLeaderboard,
    breakoutInfluencerLeaderboard,
  ] = useMemo(() => {
    if (!people) {
      return [{}, [], []];
    }
    return [
      createBreakdownData(formatMessage, people, campaign),
      ...processPeople(people),
    ].slice(3);
  }, [people, campaign, formatMessage]);

  const [loading, setLoading] = useState(true);
  useEffect(
    function loadTakeawaysData() {
      const params = {
        // @ts-expect-error
        organization: props.currentOrganization?.id,
        proxy: props.currentProxyPerson
          ? // @ts-expect-error
            props.currentProxyPerson.email
          : undefined,
      };

      // campaign is optional (but should only be checked if explicitly passed in)
      // @ts-expect-error
      if (props.campaign) {
        // @ts-expect-error
        params.campaign = props.campaign.id;
      }

      // fetch people from the backend based on your permissions
      // @ts-expect-error
      if (props.currentOrganization?.id) {
        setLoading(true);
        ConfirmAPI.getUrlWithCache(
          '/performance/admin/get-current-campaign',
          'performance-admin-get-current-campaign',
          null, // this is too much data, don't cache it
          null,
          params,
          (data) => {
            setLoading(false);
            if (data) {
              if (fakeData) {
                // @ts-expect-error
                setData(fakeData);
              } else {
                setData(data);
              }
            }
          },
          (message) => {
            if (message?.data?.detail) {
              setErrorMessage(message.data.detail);
              return;
            }
            setErrorMessage(message);
          }
        );
      }
    },
    // @ts-expect-error
    [props.campaign, props.currentOrganization, props.currentProxyPerson]
  );

  const onSelectFilter = useCallback(
    (values, filter) => {
      const newActiveFilters = Object.assign({}, activeFilters);
      if (!values) {
        delete newActiveFilters[filter];
      } else {
        newActiveFilters[filter] = values;
      }
      setActiveFilters(newActiveFilters);
    },
    [activeFilters, setActiveFilters]
  );

  const organizationName = 'FocalPoint';

  const rows = useMemo(
    () => [
      [
        {
          type: CARD_TYPES.SIMPLE_CARD,
          title: (
            <FormattedMessage
              id="app.views.widgets.dashboards.visualizations_dashboard.overview_title"
              defaultMessage="{organizationName} Q2 2022 cycle overview"
              values={{
                organizationName: organizationName,
              }}
            />
          ),
          datakey: 'introduction',
        },
      ],
      [
        {
          id: 'gold-stars-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.gold_stars_header',
            defaultMessage: 'Gold stars',
          }),
          datakey: 'gold_stars_header',
        },
      ],
      [
        {
          type: CARD_TYPES.SANKEY,
          title: (
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.gold_stars_given_header"
                defaultMessage="At {organizationName} today, 21% of employees recognize someone from a different pre-merger entity as influential."
                values={{
                  organizationName: organizationName,
                }}
              />
            </span>
          ),
          color: colorConfig.gold_star,
          fieldSingular: 'gold star',
          fieldPlural: 'gold stars',
          datakey: 'sankey',
        },
      ],
      [
        {
          id: 'influence-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.influence_header.connectivity',
            defaultMessage: 'Connectivity',
          }),
          datakey: 'influence_header',
        },
      ],
      [
        {
          type: CARD_TYPES.CHORD,
          title: (
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_is_distributed_widely"
                defaultMessage="At {organizationName}, influence is distributed widely across the network"
                values={{
                  organizationName: organizationName,
                }}
              />
            </span>
          ),
          color: colorConfig.gold_star,
          fieldSingular: 'gold star',
          fieldPlural: 'gold stars',
          datakey: 'chord',
        },
      ],
      [
        {
          id: 'influence-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.influence_header.connectivity',
            defaultMessage: 'Connectivity',
          }),
          datakey: 'influence_header',
        },
      ],
      [
        {
          type: CARD_TYPES.CHORD,
          title: (
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_is_distributed_widely"
                defaultMessage="At {organizationName}, influence is distributed widely across the network"
                values={{
                  organizationName: organizationName,
                }}
              />
            </span>
          ),
          color: colorConfig.gold_star,
          fieldSingular: 'gold star',
          fieldPlural: 'gold stars',
          datakey: 'chord_levels',
        },
      ],
      [
        {
          id: 'influence-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.influence_header.connectivity',
            defaultMessage: 'Connectivity',
          }),
          datakey: 'influence_header',
        },
      ],
      [
        {
          type: CARD_TYPES.CHORD,
          title: (
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_is_distributed_widely"
                defaultMessage="At {organizationName}, influence is distributed widely across the network"
                values={{
                  organizationName: organizationName,
                }}
              />
            </span>
          ),
          color: colorConfig.gold_star,
          fieldSingular: 'gold star',
          fieldPlural: 'gold stars',
          datakey: 'chord_levels_team',
        },
      ],
      [
        {
          id: 'influence-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.influence_header.connectivity',
            defaultMessage: 'Connectivity',
          }),
          datakey: 'influence_header',
        },
      ],
      [
        {
          type: CARD_TYPES.CHORD,
          title: (
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_is_distributed_widely"
                defaultMessage="At {organizationName}, influence is distributed widely across the network"
                values={{
                  organizationName: organizationName,
                }}
              />
            </span>
          ),
          color: colorConfig.gold_star,
          fieldSingular: 'gold star',
          fieldPlural: 'gold stars',
          datakey: 'chord_function_team_source',
        },
      ],
      [
        {
          id: 'influence-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.influence_header.connectivity',
            defaultMessage: 'Connectivity',
          }),
          datakey: 'influence_header',
        },
      ],
      [
        {
          type: CARD_TYPES.CHORD,
          title: (
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_is_distributed_widely"
                defaultMessage="At {organizationName}, influence is distributed widely across the network"
                values={{
                  organizationName: organizationName,
                }}
              />
            </span>
          ),
          color: colorConfig.gold_star,
          fieldSingular: 'gold star',
          fieldPlural: 'gold stars',
          datakey: 'chord_level_team_source',
        },
      ],
      [
        {
          id: 'influence-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.influence_header.network',
            defaultMessage: 'Network',
          }),
          datakey: 'network_header',
        },
      ],
      [
        {
          type: CARD_TYPES.NETWORK,
          title: (
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.network_of_influence"
                defaultMessage="{organizationName}'s network of influence"
                values={{
                  organizationName: organizationName,
                }}
              />
            </span>
          ),
          color: colorConfig.gold_star,
          fieldSingular: 'gold star',
          fieldPlural: 'gold stars',
          datakey: 'network',
        },
      ],
      [
        {
          id: 'skills-and-behaviors-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.skills_and_behaviors_header',
            defaultMessage: 'Skills and behaviors',
          }),
          datakey: 'skills_and_behaviors_header',
        },
      ],
      [
        {
          id: 'final-section-header',
          type: CARD_TYPES.HEADER,
          title: formatMessage({
            id: 'app.views.widgets.dashboards.visualizations_dashboard.final_section_header',
            defaultMessage: "That's all!",
          }),
          datakey: 'final_section',
        },
      ],
    ],
    [organizationName, formatMessage]
  );

  const distributionStats = useMemo(
    () => processDistributionData(distributionData),
    [distributionData]
  );

  const [
    numTotalGoldStarEdges,
    numTotalHeadsUpEdges,
    numInfluenceEdges,
    numTotalEnergizedEdges,
    numTotalHelpAndAdviceEdges,
  ] = useMemo(
    () => [
      distributionStats.gold_star.totalValue,
      distributionStats.heads_up.totalValue,
      distributionStats.influence.totalValue,
      distributionStats.energizes.totalValue,
      distributionStats.advises.totalValue,
    ],
    [distributionStats]
  );

  const getHelpTooltip = useCallback((uniqueId, popoverContent) => {
    const id = 'help-tooltip-' + uniqueId;

    return (
      <>
        <i
          id={id}
          className={consts.ICONS.HELP + ' ms-2 text-primary position-relative'}
          style={{
            top: 2,
          }}
        />
        <UncontrolledPopover placement="top" trigger="hover" target={id}>
          {popoverContent}
        </UncontrolledPopover>
      </>
    );
  }, []);

  const cardsData = useMemo(() => {
    return {
      introduction: (
        <>
          <Row id="overview">
            <Col>
              <div>
                <Row>
                  <Col className="col-2">
                    <img
                      onClick={() => {
                        setConfettiShouldFire(false);
                        setConfettiShouldFire(true);
                      }}
                      src={trophyImg}
                      alt="trophy"
                      className="mw-100 m-0"
                      role="button"
                      style={{
                        position: 'relative',
                        top: '-1rem',
                      }}
                    />
                    {/* @ts-expect-error */}
                    <div style={confettiContainerStyle}>
                      <Confetti
                        className={'react-dom-confetti-overlay'}
                        active={confettiShouldFire}
                        // @ts-expect-error
                        config={confettiConfig}
                      />
                    </div>
                  </Col>
                  <Col className="col-12 col-md-5">
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.congratulations"
                        defaultMessage="Congratulations on navigating this cycle!"
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.below_are_key_takeaways"
                        defaultMessage="
                      Below are key takeaways, analytics, and recommendations
                      for your team.
                    "
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.questions_or_concerns"
                        defaultMessage="Questions or concerns? <link>Let us know</link>!"
                        values={{
                          link: (chunks) => (
                            <span
                              role="button"
                              className="text-primary"
                              onClick={toggleSupport}
                            >
                              {chunks}
                            </span>
                          ),
                        }}
                      />
                    </p>
                  </Col>
                  <Col className="col-12 col-md-5">
                    <div className="comment-body">
                      <h4>
                        <i className="fe fe-users me-2" />
                        <FormattedMessage
                          id="app.views.widgets.dashboards.visualizations_dashboard.participation"
                          defaultMessage="
                        Participation
                      "
                        />
                      </h4>
                      <p>
                        <FormattedMessage
                          id="app.views.widgets.dashboards.visualizations_dashboard.people_participated"
                          defaultMessage="1278 of 1290 people participated. <link>View details</link>"
                          values={{
                            link: (chunks) => (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={consts.PARTICIPATION_DASHBOARD().path}
                              >
                                {chunks}
                              </a>
                            ),
                          }}
                        />
                      </p>
                      <h4>
                        <i className="fe fe-bar-chart me-2" />
                        <FormattedMessage
                          id="app.views.widgets.dashboards.visualizations_dashboard.data_gathered_heading"
                          defaultMessage="Data gathered"
                        />
                      </h4>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.data_gathered_details"
                        defaultMessage="11,203 connections across 1290 people."
                      />{' '}
                      <span id="data-gathered-details" className="text-primary">
                        <FormattedMessage
                          id="app.views.widgets.dashboards.visualizations_dashboard.view_details"
                          defaultMessage="View details"
                        />
                      </span>
                      <UncontrolledPopover
                        placement="top"
                        trigger="hover"
                        target={'data-gathered-details'}
                      >
                        <FormattedMessage
                          id="app.views.widgets.dashboards.visualizations_dashboard.data_gathered_heading_colon"
                          defaultMessage="
                        Data gathered:
                        "
                        />
                        <ul className="mb-0">
                          {shouldShowInfluence && (
                            <li>
                              <FormattedMessage
                                id="app.views.widgets.dashboards.visualizations_dashboard.influence_details"
                                defaultMessage="{numInfluenceEdges} <link>influence</link> connections ({numTotalEnergizedEdges} from giving energy, {numTotalHelpAndAdviceEdges} from help and advice)"
                                values={{
                                  numInfluenceEdges:
                                    numberWithCommas(numInfluenceEdges),
                                  link: (chunks) => (
                                    <NavLink
                                      role="button"
                                      className="p-0 d-inline text-primary"
                                      onClick={() =>
                                        scrollTo('influence-header')
                                      }
                                    >
                                      {chunks}
                                    </NavLink>
                                  ),
                                  numTotalEnergizedEdges: numberWithCommas(
                                    numTotalEnergizedEdges
                                  ),
                                  numTotalHelpAndAdviceEdges: numberWithCommas(
                                    numTotalHelpAndAdviceEdges
                                  ),
                                }}
                              />
                            </li>
                          )}
                          <li>
                            <FormattedMessage
                              id="app.views.widgets.dashboards.visualizations_dashboard.gold_stars_details"
                              defaultMessage="{numTotalGoldStarEdges} <link>gold star</link> given"
                              values={{
                                link: (chunks) => (
                                  <NavLink
                                    role="button"
                                    className="p-0 d-inline text-primary"
                                    onClick={() =>
                                      scrollTo('gold-stars-header')
                                    }
                                  >
                                    {chunks}
                                  </NavLink>
                                ),
                                numTotalGoldStarEdges: numberWithCommas(
                                  numTotalGoldStarEdges
                                ),
                              }}
                            />
                          </li>
                          <li>
                            <FormattedMessage
                              id="app.views.widgets.dashboards.visualizations_dashboard.heads_ups_details"
                              defaultMessage="{numTotalHeadsUpEdges} <link>heads ups</link> given"
                              values={{
                                numTotalHeadsUpEdges:
                                  numberWithCommas(numTotalHeadsUpEdges),
                                link: (chunks) => (
                                  <NavLink
                                    role="button"
                                    className="p-0 d-inline text-primary"
                                    onClick={() => scrollTo('concerns-header')}
                                  >
                                    {chunks}
                                  </NavLink>
                                ),
                              }}
                            />
                          </li>
                          <li>
                            <FormattedMessage
                              id="app.views.widgets.dashboards.visualizations_dashboard.much_more"
                              defaultMessage="Much more (see below)"
                            />
                          </li>
                        </ul>
                      </UncontrolledPopover>
                    </div>
                  </Col>
                </Row>
                <div>
                  <h3 className="border-bottom pb-3 mb-4 mt-0">
                    <i className={consts.ICONS.RECOMMENDATION + ' me-2'} />
                    <FormattedMessage
                      id="app.views.widgets.dashboards.visualizations_dashboard.view_key_takeaways"
                      defaultMessage="
                    View key takeaways
                  "
                    />
                  </h3>
                  <Row className="mb-n3">
                    {/* @ts-expect-error */}
                    {getNavSectionButton(
                      'gold-stars-header',
                      consts.ICONS.GOLD_STAR,
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.team_integration"
                        defaultMessage="Team Integration"
                      />
                    )}
                    {/* @ts-expect-error */}
                    {getNavSectionButton(
                      'influence-header',
                      consts.ICONS.INFLUENCE,
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.connectivity"
                        defaultMessage="Connectivity"
                      />
                    )}
                    {/* @ts-expect-error */}
                    {getNavSectionButton(
                      'skills-and-behaviors-header',
                      consts.ICONS.SKILL + ' text-success',
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.network"
                        defaultMessage="Network"
                      />
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </>
      ),
      gold_stars_header: (
        <Row className="justify-content-between py-4" id="impact">
          <Col>
            <h2 className="header-title">
              <i className={consts.ICONS.GOLD_STAR + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.team_integration"
                defaultMessage="Team Integration"
              />
            </h2>
          </Col>
          <Col className="col-auto">
            <NextButton target="influence-header">
              <span className="text-muted">
                <FormattedMessage
                  id="app.views.widgets.dashboards.visualizations_dashboard.next"
                  defaultMessage="Next: "
                />
              </span>{' '}
              <i className={consts.ICONS.INFLUENCE} />{' '}
              <span className="fw-bold text-dark">
                <FormattedMessage
                  id="app.views.widgets.dashboards.visualizations_dashboard.connectivity"
                  defaultMessage="Connectivity"
                />
              </span>
            </NextButton>
          </Col>
        </Row>
      ),
      chord: {
        content: (
          <>
            <p>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_captures"
                defaultMessage="Influence captures who is gone to for help, advice, or energy."
              />
            </p>
            <h3 className="border-bottom pt-4 pb-3 my-4">
              <i className={consts.ICONS.INFLUENCE + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.team_connections"
                defaultMessage="Team connections"
              />
            </h3>
            <div>
              <VisualizationTableCard
                data={formatChordTableData(sampleData.chordData)}
              />
            </div>
          </>
        ),
        field: 'gold_star',
        xAxis: 'Gold stars',
        yAxis: 'Number of people',
        colors: palette,
        data: sampleData.chordData,
      },
      chord_levels: {
        content: (
          <>
            <p>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_captures"
                defaultMessage="Influence captures who is gone to for help, advice, or energy."
              />
            </p>
            <h3 className="border-bottom pt-4 pb-3 my-4">
              <i className={consts.ICONS.INFLUENCE + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.level_connections"
                defaultMessage="Level connections"
              />
            </h3>
            <div>
              <VisualizationTableCard
                data={formatChordTableData(sampleData.chordLevelData)}
              />
            </div>
          </>
        ),
        field: 'gold_star',
        xAxis: 'Gold stars',
        yAxis: 'Number of people',
        colors: palette,
        data: sampleData.chordLevelData,
      },
      chord_levels_team: {
        content: (
          <>
            <p>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_captures"
                defaultMessage="Influence captures who is gone to for help, advice, or energy."
              />
            </p>
            <h3 className="border-bottom pt-4 pb-3 my-4">
              <i className={consts.ICONS.INFLUENCE + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.level_connections_by_team"
                defaultMessage="Level connections by team"
              />
            </h3>
            <div>
              <VisualizationTableCard
                data={formatChordTableData(sampleData.chordLevelTeamData)}
              />
            </div>
          </>
        ),
        field: 'gold_star',
        xAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.gold_stars"
            defaultMessage="Gold stars"
          />
        ),
        yAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.number_of_people"
            defaultMessage="Number of people"
          />
        ),
        colors: [green, blue],
        margin: {
          top: 75,
          right: 30,
          bottom: 75,
          left: 30,
        },
        data: sampleData.chordLevelTeamData,
      },
      chord_function_team_source: {
        content: (
          <>
            <p>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_captures"
                defaultMessage="Influence captures who is gone to for help, advice, or energy."
              />
            </p>
            <h3 className="border-bottom pt-4 pb-3 my-4">
              <i className={consts.ICONS.INFLUENCE + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.function_connections_by_team"
                defaultMessage="Function connections by team"
              />
            </h3>
            <div>
              <VisualizationTableCard
                data={formatChordTableData(
                  sampleData.chordFunctionTeamSourceData
                )}
              />
            </div>
          </>
        ),
        field: 'gold_star',
        xAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.gold_stars"
            defaultMessage="Gold stars"
          />
        ),
        yAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.number_of_people"
            defaultMessage="Number of people"
          />
        ),
        colors: [green, blue, 'grey', 'grey', 'grey', 'grey', 'grey'],
        data: sampleData.chordFunctionTeamSourceData,
      },
      chord_level_team_source: {
        content: (
          <>
            <p>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_captures"
                defaultMessage="Influence captures who is gone to for help, advice, or energy."
              />
            </p>
            <h3 className="border-bottom pt-4 pb-3 my-4">
              <i className={consts.ICONS.INFLUENCE + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.level_connections_by_team"
                defaultMessage="Level connections by team"
              />
            </h3>
            <div>
              <VisualizationTableCard
                data={formatChordTableData(sampleData.chordLevelTeamSourceData)}
              />
            </div>
          </>
        ),
        field: 'gold_star',
        xAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.gold_stars"
            defaultMessage="Gold stars"
          />
        ),
        yAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.number_of_people"
            defaultMessage="Number of people"
          />
        ),
        colors: [green, blue, 'grey', 'grey', 'grey', 'grey', 'grey'],
        data: sampleData.chordLevelTeamSourceData,
      },
      sankey: {
        content: (
          <>
            <p>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.team_integration_captures"
                defaultMessage="Team integration captures the percentage of influence that is given to a different pre-merger entity"
              />
            </p>
            <Row>
              {aggregateSankeyData(
                formatSankeyTableData(sampleData.sankeyData)
              ).map((d, i) => {
                return (
                  <Col key={i}>
                    {/* @ts-expect-error */}
                    <h6 className="text-uppercase text-muted mb-2">{d.name}</h6>
                    {/* @ts-expect-error */}
                    <span className="h2 mb-0">{d.value + '%'}</span>
                  </Col>
                );
              })}
            </Row>
            <h3 className="border-bottom pt-4 pb-3 my-4">
              <i className={consts.ICONS.INFLUENCE + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influence_by_team"
                defaultMessage="Influence by team"
              />
            </h3>
            <div>
              <VisualizationTableCard
                data={formatSankeyTableData(sampleData.sankeyData)}
              />
            </div>
          </>
        ),
        field: 'gold_star',
        xAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.gold_stars"
            defaultMessage="Gold stars"
          />
        ),
        yAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.number_of_people"
            defaultMessage="Number of people"
          />
        ),
        data: sampleData.sankeyData,
      },
      network: {
        content: (
          <>
            <p>
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.influencers_reach"
                defaultMessage="Influencers reach the largest possible share of the network with the fewest number of direct connections. Below are those who stand out."
              />
            </p>
            <Row>
              <Col>
                <h6 className="text-uppercase text-muted mb-2">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.visualizations_dashboard.average"
                    defaultMessage="Average"
                  />
                </h6>
                <span className="h2 mb-0">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.visualizations_dashboard.four"
                    defaultMessage="4"
                  />
                </span>
              </Col>
              <Col>
                <h6 className="text-uppercase text-muted mb-2">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.visualizations_dashboard.median"
                    defaultMessage="Median"
                  />
                </h6>
                <span className="h2 mb-0">{'3'}</span>
              </Col>
              <Col>
                <h6 className="text-uppercase text-muted mb-2">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.visualizations_dashboard.max"
                    defaultMessage="Max"
                  />
                </h6>
                <span className="h2 mb-0">{'397'}</span>
              </Col>
              <Col>
                <h6 className="text-uppercase text-muted mb-2">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.visualizations_dashboard.total"
                    defaultMessage="Total"
                  />
                </h6>
                <span className="h2 mb-0">{'3023'}</span>
              </Col>
            </Row>
            <h3 className="border-bottom pt-4 pb-3 my-4">
              <i className={consts.ICONS.GOLD_STAR + ' me-2'} />
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.top_bridge_makers"
                defaultMessage="Top bridge makers"
              />
            </h3>
            <div>
              <VisualizationTableCard
                data={formatNetworkTableData(
                  prepNetworkData(sampleData.networkData)
                )}
                network={true}
              />
            </div>
          </>
        ),
        field: 'gold_star',
        xAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.gold_stars"
            defaultMessage="Gold stars"
          />
        ),
        yAxis: (
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.number_of_people"
            defaultMessage="Number of people"
          />
        ),
        data: prepNetworkData(sampleData.networkData),
      },
      influence_header: (
        <>
          <Row className="justify-content-between py-4" id="influence">
            <Col>
              <h2 className="header-title">
                <i className={consts.ICONS.INFLUENCE + ' me-2'} />
                <FormattedMessage
                  id="app.views.widgets.dashboards.visualizations_dashboard.connectivity"
                  defaultMessage="Connectivity"
                />{' '}
                {getHelpTooltip(
                  'influence',
                  <div className="mb-n3">
                    <h4>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.how_is_influence_measured"
                        defaultMessage="How is influence measured?"
                      />
                    </h4>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.infleunce_represents"
                        defaultMessage="Influence represents who is recognized as a provider of help, advice, and/or energy."
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.visualizations_dashboard.specifcally_we_asked"
                        defaultMessage='Specifically, we asked each person, "Who at {organizationName} motivated or energized you?" and "Who at {organizationName} did you go to for help and advice?"'
                        values={{
                          organizationName: organizationName,
                        }}
                      />
                    </p>
                  </div>
                )}
              </h2>
            </Col>
            <Col className="col-auto">
              <NextButton target="skills-and-behaviors-header">
                <span className="text-muted">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.visualizations_dashboard.next"
                    defaultMessage="Next: "
                  />
                </span>{' '}
                <i className={consts.ICONS.SKILL + ' text-success'} />{' '}
                <span className="fw-bold text-dark">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.visualizations_dashboard.network"
                    defaultMessage="Network"
                  />
                </span>
              </NextButton>
            </Col>
          </Row>
        </>
      ),
      network_header: (
        <>
          <Row className="justify-content-between py-4" id="influence">
            <Col>
              <h2 className="header-title">
                <i className={consts.ICONS.SKILL + ' text-success me-2'} />
                <FormattedMessage
                  id="app.views.widgets.dashboards.visualizations_dashboard.network"
                  defaultMessage="Network"
                />
              </h2>
            </Col>
          </Row>
        </>
      ),
      top_influencers: {
        content: (
          <>
            <p className="mb-4">
              <FormattedMessage
                id="app.views.widgets.dashboards.visualizations_dashboard.infleuncers_reach"
                defaultMessage="
              Influencers reach the largest possible share of the network with
              the fewest number of direct connections. Below are those who stand
              out.
            "
              />
            </p>
          </>
        ),
        breakout_influencers: breakoutInfluencerLeaderboard,
        super_influencers: influenceLeaderboard,
        low_influencers: influenceLeaderboard
          ? // @ts-expect-error
            influenceLeaderboard.slice().reverse()
          : [],
      },
      final_section: (
        <div className="pt-4">
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.thats_all"
            defaultMessage="<boldSpan>That's all!</boldSpan> Questions, comments, or concerns? <primarySpan>Let us know</primarySpan>!"
            values={{
              boldSpan: (chunks) => <span className="fw-bold">{chunks}</span>,
              primarySpan: (chunks) => (
                <span
                  className="text-primary"
                  role="button"
                  onClick={toggleSupport}
                >
                  {chunks}
                </span>
              ),
            }}
          />
        </div>
      ),
    };
  }, [
    breakoutInfluencerLeaderboard,
    confettiShouldFire,
    getHelpTooltip,
    influenceLeaderboard,
    numInfluenceEdges,
    numTotalEnergizedEdges,
    numTotalGoldStarEdges,
    numTotalHeadsUpEdges,
    numTotalHelpAndAdviceEdges,
    palette,
    shouldShowInfluence,
  ]);

  // NOTE: this order is important as it matches the Performance Admin, so
  // keeping it consistent for a better UX
  const filterList = useMemo(
    () =>
      Object.keys(BREAKDOWN_FIELDS(formatMessage)).filter(
        (f) => Object.keys(filterOptions[f] || {}).length > 0
      ),
    [filterOptions, formatMessage]
  );

  // Note: mb-0 with pt-3 below required to prevent visual glitch when scrolling for sticky filters
  const beforeContent = useMemo(
    () =>
      filterList?.length ? (
        <>
          {' '}
          <p className="mb-0 text-muted">
            <i
              className="fe fe-filter"
              style={{ position: 'relative', top: '1px' }}
            />{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.visualizations_dashboard.filter_this_report_by"
              defaultMessage="Filter this report by:"
            />
          </p>
          <Sticky
            position="top"
            unstuckClasses="pt-3 mb-4"
            stuckClasses="card card-body mb-0 p-3"
            unstuckStyles={{
              zIndex: 1,
            }}
            stuckStyles={{
              zIndex: 1,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Row className="mb-n3 ps-0 pe-3">
              {filterList.map((filter, index) => {
                const name = filter;
                const options = Object.entries(filterOptions[filter] || {}).map(
                  ([k, v]) => ({
                    place_id: v,
                    object: v,
                    value: v,
                    label: k,
                    name: k,
                  })
                );
                const selectText =
                  BREAKDOWN_FIELDS(formatMessage)[filter] + '...';
                const loadOptions = (input, callback) => {
                  if (input) {
                    callback(myOptionFilter(options, input));
                  } else {
                    callback(options);
                  }
                };
                const onChange = (value) => onSelectFilter(value, name);
                return (
                  <Col key={index} className="col-6 col-md-2 mb-3 pe-0">
                    <SelectInput
                      className="w-100"
                      clearable={true}
                      name={name}
                      loadOptions={loadOptions}
                      onChange={onChange}
                      placeholder={selectText}
                      defaultOptions={true}
                      multiple={true}
                    />
                  </Col>
                );
              })}
            </Row>
          </Sticky>
        </>
      ) : undefined,
    [filterList, filterOptions, onSelectFilter, formatMessage]
  );

  const loadOrRenderOutput = loadOrRender(undefined, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (loading) {
    return (
      <Loading
        // @ts-expect-error
        message={
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.loading_cycle_data"
            defaultMessage="Loading cycle data..."
          />
        }
      />
    );
  }

  if (
    // @ts-expect-error
    props.campaign &&
    getPhaseIsOpen(
      // @ts-expect-error
      props.campaign,
      // @ts-expect-error
      getPhaseIndexByType(props.campaign, PHASE_TYPE_SELF)
    ) &&
    // @ts-expect-error
    !data?.campaign?.is_admin
  ) {
    return (
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.available_on_conclusion"
            defaultMessage="
          Takeaways dashboard will be available on conclusion of this cycle.
        "
          />
        </CardBody>
      </Card>
    );
  }
  // @ts-expect-error
  if (props.campaign && !data?.campaign) {
    return (
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.widgets.dashboards.visualizations_dashboard.do_not_have_access"
            defaultMessage="
          You do not have access to this feature. To get access, contact the
          administrator for Confirm at your organization.
        "
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <CardRowDashboard
        rows={rows}
        beforeContent={beforeContent}
        data={cardsData}
      />
    </>
  );
};

const VisualizationsDashboard_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof VisualizationsDashboard_propTypes> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, me, currentProxyPerson } = state;

  return {
    currentOrganization,
    me,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(
  withRouter(React.memo(VisualizationsDashboard))
);
