import React, { FC, useCallback, useEffect, useState } from 'react';

import ActivityCard from '../Activities/ActivityCard';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import EmptyState from '../Widgets/EmptyState';
import ModalActivityEditorButton from '../Activities/ModalActivityEditorButton';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

const SkillActivities: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [showCreateActivityDialog, setShowCreateActivityDialog] =
    useState(false);
  const [activities, setActivities] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuth0();
  const userSub = user?.sub;

  // @ts-expect-error
  const currentOrgId = props.currentOrganization?.id;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch activities from ElasticSearch
    if (userSub && props.skillId && currentOrgId) {
      ElasticsearchAPI.getActivitiesForSkill(
        userSub,
        props.currentProxyPerson,
        currentOrgId,
        props.skillId,
        (newActivities) => {
          if (isMounted) {
            setActivities(newActivities);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [
    isMounted,
    userSub,
    currentOrgId,
    props.skillId,
    props.currentProxyPerson,
  ]);

  const openCreateActivityDialog = useCallback(() => {
    setShowCreateActivityDialog(true);
  }, []);

  const onCreateActivityDialogClosed = useCallback(() => {
    setShowCreateActivityDialog(false);
  }, []);

  const loadOrRenderOutput = loadOrRender(activities, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return (
    <>
      {/* @ts-expect-error */}
      {activities?.length === 0 && (
        <EmptyState
          title={formatMessage({
            id: 'app.views.skills.skill_activities.title.no_activities_found',
            defaultMessage: 'No activities found',
          })}
          subtitle={formatMessage({
            id: 'app.views.skills.skill_activities.subtitle.nobody_has_recorded_any_activities',
            defaultMessage:
              'Nobody has recorded any activities using this skill.',
          })}
        />
      )}
      <ModalActivityEditorButton
        isOpen={showCreateActivityDialog}
        onClosed={onCreateActivityDialogClosed}
        hideButton={true}
      />
      {/* @ts-expect-error */}
      {activities?.length > 0 &&
        // @ts-expect-error
        activities?.map((ac, acIndex) => (
          <ActivityCard
            key={acIndex}
            showPrompt={true}
            activity={ac}
            focalSkill={props.skill}
            setActivity={(a) =>
              // @ts-expect-error
              setActivities(activities.map((a2) => (a2.id === a.id ? a : a2)))
            }
            openCreateActivityDialog={openCreateActivityDialog}
          />
        ))}
    </>
  );
};

const SkillActivities_propTypes = {
  skillId: PropTypes.string.isRequired,
  skill: PropTypes.object,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object.isRequired,
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

type Props = PropTypes.InferProps<typeof SkillActivities_propTypes>;

export default connect(mapStateToProps)(React.memo(SkillActivities));
