import * as d3 from 'd3';

import { Card, CardBody } from 'reactstrap';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import EmptyState from '../EmptyState';
import { OrgChart } from 'd3-org-chart';
import { analyzeGraph } from 'graph-cycles';
import defaultAvatar from '../../../assets/img/illustrations/avatars/avatar-default.png';
import { getPersonDisplayString } from '../../../utils/models/Person';
import { useIntl } from 'react-intl';
import IncludeExcludeFilter, {
  IncludeExcludeFilterType,
} from '../Inputs/IncludeExcludeFilter';
import { Person } from 'types';
import { getPersonIdToManagerIdMap } from 'utils/models/OrgChart';

const detectCycle = (data: any[]) => {
  const grouped = data.reduce((acc, p) => {
    if (!p.id) {
      return acc;
    }
    if (p.parentId) {
      return [...acc, [p.id.toString(), [p.parentId.toString()]]];
    } else {
      return [...acc, [p.id.toString(), []]];
    }
  }, []);

  const analysis = analyzeGraph(grouped);
  return [grouped, analysis];
};

interface PersonWithManagerAndParentIds extends Person {
  id: number;
  manager: Person | null;
  parentIds: Array<string>;
}

const getIsMatchForFilter = (p: PersonWithManagerAndParentIds, filter: any) => {
  // if index type is "person" then match based on id
  if (filter._index === 'person') {
    return p.id === filter.object.id;
  }

  // if filter is "manager", then match if manager id is the same
  if (filter._index === 'manager') {
    return p.manager?.id === filter.object.id;
  }

  // if filter is "below_<ID>" then match if the id is within the
  // person's parentIds array
  if (filter._index.startsWith('below_')) {
    const id = filter._index.split('below_')[1];
    return p.parentIds.includes(id);
  }

  // otherwise match based on name of filter
  return p[filter._index] === filter.name;
};

const getIsMatchForFilters = (d: any, filters: IncludeExcludeFilterType) => {
  // if this field doesn't have personData, it could be a root, for example, so skip it
  if (!d.personData) {
    return false;
  }

  const p = d.personData;

  // if no include or exclude filters, return false
  // (no matches, so not everything gets highlighted/expanded)
  if (!filters.include.length && !filters.exclude.length) {
    return false;
  }

  // if there are exclude filters but no include filters
  if (!filters.include.length && filters.exclude.length) {
    // if not found in the exclude filter, return true
    if (
      !filters.exclude.some((filter) => {
        return getIsMatchForFilter(p, filter);
      })
    ) {
      return true;
    }
    return false;
  }

  // if found in the exclude filter, return false
  if (
    filters.exclude.some((filter) => {
      return getIsMatchForFilter(p, filter);
    })
  ) {
    return false;
  }

  // if found in the include filter, return true
  if (
    filters.include.some((filter) => {
      return getIsMatchForFilter(p, filter);
    })
  ) {
    return true;
  }

  // if no matches, return false
  return false;
};

export const OrgChartComponent = ({ data = [] }: { data?: any[] }) => {
  const { formatMessage } = useIntl();
  const HIDDEN_FILTER_CATEGORIES: string[] = ['level'];

  const [peopleFilters, setPeopleFilters] = useState<IncludeExcludeFilterType>(
    () => ({
      include: [],
      exclude: [],
    })
  );
  const [filteredData, setFilteredData] = useState<any[]>(() => [...data]);

  const d3Container = useRef(null);
  const [chart, setChart] = useState<OrgChart>(null);
  const [error, setError] = useState<string>('');
  const [chartIsInitialized, setChartIsInitialized] = useState<boolean>(false);

  const adaptIncludeExcludeOnChange = useCallback(
    (filters: IncludeExcludeFilterType) => {
      setPeopleFilters(filters);

      const newData: any[] = [...filteredData];

      // Clear previous higlighting
      chart.clearHighlighting();

      // Mark all previously expanded nodes for collapse
      newData.forEach((d) => (d._expanded = false));

      // Loop over data and check if input value matches any name
      newData.forEach((d) => {
        const isMatch: boolean = getIsMatchForFilters(d, filters);

        // If matches, mark node as highlighted, else don't
        d._highlighted = isMatch;
        d._expanded = isMatch;
      });

      // note: we don't call chart.data(newData).render().fit()
      // manually to avoid strange zoom effects; updating this
      // data will update the chart automatically
      setFilteredData(newData);
    },
    [filteredData, chart]
  );

  useEffect(() => {
    if (!error && filteredData?.length && d3Container.current) {
      if (!chart) {
        setChart(new OrgChart());
        return;
      }

      // style from https://github.com/bumbeishvili/org-chart style "Clean (Design by Anton)"
      try {
        chart
          .container(d3Container.current)
          .data(filteredData)
          .svgHeight(window.innerHeight)
          .nodeHeight(() => 85)
          .nodeWidth(() => {
            return 220;
          })
          .childrenMargin(() => 50)
          .compactMarginBetween(() => 25)
          .compactMarginPair(() => 50)
          .neightbourMargin(() => 25)
          .siblingsMargin(() => 25)
          .buttonContent(({ node }) => {
            return `<div style="px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9"> <span style="font-size:9px">${
              node.children
                ? `<i class="fas fa-angle-up"></i>`
                : `<i class="fas fa-angle-down"></i>`
            }</span> ${node.data._directSubordinates}  </div>`;
          })
          // https://github.com/bumbeishvili/org-chart/issues/216
          // hide top level fake root node to allow multiple top-level nodes
          .nodeUpdate(function (this: any, d) {
            if (d.data.parentId == null) {
              d3.select(this).style('display', 'none');
            }
          })
          .linkUpdate(function (this: any, d) {
            d3.select(this)
              .attr('stroke', (d) =>
                d.data._highlighted || d.data._upToTheRootHighlighted
                  ? '#152785'
                  : '#E4E2E9'
              )
              .attr('stroke-width', (d) =>
                d.data._highlighted || d.data._upToTheRootHighlighted ? 5 : 1
              );

            if (d.data._highlighted || d.data._upToTheRootHighlighted) {
              d3.select(this).raise();
            }
            // https://github.com/bumbeishvili/org-chart/issues/216
            // hide top level fake root node to allow multiple top-level nodes
            if (d.data.parentId == null) {
              d3.select(this).style('display', 'none');
            }
          })
          .nodeContent(function (d) {
            const color = '#FFFFFF';
            return `
        <div style="cursor:default;font-family: 'Inter', sans-serif;background-color:${color}; position:absolute;margin-top:-1px; margin-left:-1px;width:${d.width}px;height:${d.height}px;border-radius:10px;border: ${d.data._highlighted || d.data._upToTheRootHighlighted ? '5px solid #152785' : '1px solid #E4E2E9'}">
           <div style="background-color:${color};position:absolute;margin-top:-25px;margin-left:${15}px;border-radius:100px;width:50px;height:50px;" ></div>
           <img src=" ${
             d.data.imageUrl
           }" style="position:absolute;margin-top:-20px;margin-left:${20}px;border-radius:100px;width:40px;height:40px;" />

          <div style="color:#08011E;position:absolute;right:20px;top:17px;font-size:10px;"><i class="fas fa-ellipsis-h"></i></div>

          <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:32px"> <a style="color:#08011E;" href="${
            d.data.profileUrl
          }" target="_blank" rel="noopener noreferrer">${d.data.name}</a> </div>
          <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;"> ${
            d.data.positionName
          } </div>
       </div>
`;
          })
          .render();
        // if there are less than 100 people AND no filters set, expand all by default
        if (
          filteredData?.length < 100 &&
          peopleFilters.include.length === 0 &&
          peopleFilters.exclude.length === 0
        ) {
          chart.expandAll();
        }

        setChartIsInitialized(true);
      } catch (err) {
        const errorMessage = JSON.stringify(
          err,
          Object.getOwnPropertyNames(err)
        );
        if (errorMessage.includes('cycle')) {
          const [grouped, analysis] = detectCycle(filteredData);
          console.error(
            'Error rendering the organization chart - Cycle analysis:',
            JSON.stringify(analysis),
            JSON.stringify(grouped)
          );
          setError(
            formatMessage({
              id: 'app.views.widgets.charts.org_chart.title.cycle_detected',
              defaultMessage:
                'Cycle detected in the organization chart. Please contact customer support to resolve this issue.',
            })
          );
        } else {
          console.log('Uncaught error rendering organization chart', err);
          setError(
            formatMessage({
              id: 'app.views.widgets.charts.org_chart.title.error',
              defaultMessage: 'Error generating the organization chart',
            })
          );
        }
      }
    }
  }, [chart, error, formatMessage, filteredData, peopleFilters]);

  if (error) {
    return (
      <Card>
        <CardBody>
          <EmptyState title={error} />
        </CardBody>
      </Card>
    );
  }

  // chart must be initialized before showing filters to ensure that filters update
  // UI if prepopulated from bookmark urls
  return (
    <>
      {!!data.length && (
        <div>
          {chartIsInitialized && (
            <IncludeExcludeFilter
              initialValue={peopleFilters}
              onChange={adaptIncludeExcludeOnChange}
              hidePersonFilters={false}
              hideFilterCagories={HIDDEN_FILTER_CATEGORIES}
              includeFilterPlaceholder={formatMessage({
                id: 'app.views.widgets.charts.org_chart.filter.include_placeholder',
                defaultMessage: 'Filter by name or anything else',
              })}
              excludeFilterPlaceholder={formatMessage({
                id: 'app.views.widgets.charts.org_chart.filter.exclude_placeholder',
                defaultMessage: 'Exclude anything',
              })}
              bookmarkUrl={true}
            />
          )}
          <div ref={d3Container} />
        </div>
      )}
      {!data.length && (
        <Card>
          <CardBody>
            <EmptyState
              title={formatMessage({
                id: 'app.views.widgets.charts.org_chart.title.empty_chart',
                defaultMessage: 'Empty chart',
              })}
              subtitle={formatMessage({
                id: 'app.views.widgets.charts.org_chart.subtitle.the_people_list_cannot_be_displayed',
                defaultMessage:
                  'The people list cannot be displayed as a org-chart',
              })}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

type ConfirmOrgChartProps = {
  people: any[];
};
const ConfirmOrgChart: FC<ConfirmOrgChartProps> = ({ people }) => {
  const { formatMessage } = useIntl();

  // create dictionary of people for efficient referencing
  const peopleDict = useMemo(
    () =>
      people.reduce((acc, p) => {
        acc[p.id.toString()] = p;
        return acc;
      }, {}),
    [people]
  );

  // for any manager that is not found in the dataset, replace the
  // manager with null
  const peopleWithValidManagersOrNoManager = useMemo(() => {
    return people.map((p) => {
      if (p.manager?.id && !peopleDict[p.manager.id.toString()]) {
        return {
          ...p,
          manager: null,
        };
      }
      return p;
    });
  }, [people, peopleDict]);

  const peopleWithValidManagersOrNoManagerDict = useMemo(
    () =>
      peopleWithValidManagersOrNoManager.reduce((acc, p) => {
        acc[p.id.toString()] = p;
        return acc;
      }, {}),
    [peopleWithValidManagersOrNoManager]
  );

  const inferredCEO = useMemo(() => {
    // there can only be one CEO in the dataset, and in some cases this person
    // reports to themselves; in other cases, they report to a cofounder who reports
    // back to them as a cycle; the org chart only works if there's exactly one
    // "root", i.e. one node without a parentId attached, so we need to guess
    // who this person is by traversing the tree for each person and count the longest
    // length from the bottom
    if (!(peopleWithValidManagersOrNoManager?.length > 0)) {
      return [[], null];
    }

    // if there is only one Person, treat them as the CEO
    if (peopleWithValidManagersOrNoManager.length === 1) {
      return peopleWithValidManagersOrNoManager[0];
    }

    let currentCeo = null;
    let longestChainOfCommandLength = 0;

    peopleWithValidManagersOrNoManager.forEach((p) => {
      // ignore anyone without a manager
      if (!p?.manager?.id) {
        return;
      }

      let currentPerson = p;
      let currentChainLength = 1;

      const chainOfCommandSet = new Set();
      chainOfCommandSet.add(p);

      // if we hit a cycle exit out, take the length so far
      while (currentPerson.manager?.id) {
        const nextManager =
          peopleWithValidManagersOrNoManagerDict[
            currentPerson.manager.id.toString()
          ];
        if (!nextManager) {
          // there's a missing manager in the dataset, so this person's
          // whole chain is not renderable, so go to the next person
          return;
        }

        // if we've seen this manager before, there's a cycle, so break out,
        // but set current person to next manager for assessing who CEO is
        // if their title is CEO as a tiebreaker (to handle the case where
        // cofounders report to each other to ensure CEO shows at the top)
        if (chainOfCommandSet.has(nextManager)) {
          if (nextManager?.title === 'CEO') {
            currentPerson = nextManager;
          }
          break;
        }

        chainOfCommandSet.add(nextManager);
        currentChainLength++;
        currentPerson = nextManager;
      }

      if (currentChainLength > longestChainOfCommandLength) {
        currentCeo = currentPerson;
        longestChainOfCommandLength = currentChainLength;
      }
    });

    return currentCeo;
  }, [
    peopleWithValidManagersOrNoManager,
    peopleWithValidManagersOrNoManagerDict,
  ]);

  const hasManagerThatExistsOrHasDirectReport = useCallback(
    (p) => {
      // if this person has a manager that exists in the dataset, return true
      if (
        p.manager?.id &&
        peopleWithValidManagersOrNoManagerDict[p.manager.id.toString()]
      ) {
        return true;
      }
      // if anyone reports to this person, return true
      if (
        peopleWithValidManagersOrNoManager.some(
          (person) => person.manager?.id === p.id
        )
      ) {
        return true;
      }
    },
    [peopleWithValidManagersOrNoManagerDict, peopleWithValidManagersOrNoManager]
  );

  // show fake root to support orgs that may have multiple
  // top level people, and also to support if there's a cycle
  // at the top level, for them to both report to nobody and
  // thus show at the top level
  const fakeRoot = useMemo(() => {
    return {
      id: 'fakeRoot',
      parentId: null,
      full_name: 'fakeRoot',
    };
  }, []);

  const ceoReportsToId = useMemo(() => {
    return inferredCEO?.manager?.id;
  }, [inferredCEO]);

  const personIdToManagerIdMap: Record<string, string> = useMemo(
    () => getPersonIdToManagerIdMap(peopleWithValidManagersOrNoManager),
    [peopleWithValidManagersOrNoManager]
  );

  const getParentIds = useCallback(
    (personId) => {
      const parentIds: string[] = [];
      const visitedIds = new Set();
      let currentPersonId = personId;

      while (personIdToManagerIdMap[currentPersonId]) {
        if (visitedIds.has(currentPersonId)) {
          break;
        }
        visitedIds.add(currentPersonId);

        const nextManagerId: string = personIdToManagerIdMap[currentPersonId];
        if (!nextManagerId) {
          break;
        }
        parentIds.push(nextManagerId);
        currentPersonId = nextManagerId;
      }

      return parentIds;
    },
    [personIdToManagerIdMap]
  );

  const data = useMemo(
    () => [
      fakeRoot,
      ...peopleWithValidManagersOrNoManager
        // keep people who either are:
        // the ceo
        // have a manager who is also on the list
        .filter(hasManagerThatExistsOrHasDirectReport)
        .map((p) => {
          return {
            personData: {
              ...p,
              parentIds: getParentIds(p.id),
            },
            name: p.full_name,
            imageUrl: p.avatar ? p.avatar : defaultAvatar,
            //area: p.department,
            profileUrl: p.url,
            //office: p.location,
            //tags: null,
            //isLoggedUser: null,
            positionName: getPersonDisplayString(formatMessage, p),
            id: p.id,
            // CEO should have no manager, but also if CEO
            // reports to someone, remove that cycle and instead
            // have top level report to no manager, and ensure
            // that only there is one root which is the top
            // level fake root
            parentId:
              p?.id === ceoReportsToId
                ? fakeRoot?.id
                : p === inferredCEO
                ? fakeRoot?.id
                : p.manager?.id
                ? p.manager?.id
                : fakeRoot?.id,
            //size: null
          };
        }),
    ],
    [
      fakeRoot,
      peopleWithValidManagersOrNoManager,
      hasManagerThatExistsOrHasDirectReport,
      getParentIds,
      formatMessage,
      ceoReportsToId,
      inferredCEO,
    ]
  );

  return <OrgChartComponent data={data} />;
};

export default React.memo(ConfirmOrgChart);
