import * as consts from '../../../consts/consts';

import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ConfirmForm from './ConfirmForm';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import ValidatedInput from '../Inputs/ValidatedInput';
import { isEqual } from 'lodash';
import { renderValidationError } from '../../../utils/util/formatter';
import { toast } from 'react-toastify';
import { unloadWarning } from '../../../utils/util/util';
import { useAutosave } from './hooks';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useFeatures } from '../../../utils/util/features';
import { useOrganizationSettings } from '../../../utils/util/hooks';

const TIME_TO_WAIT_TO_AUTOSAVE_IN_MS = 1000;

const ValidatedForm = (props) => {
  // Feature Toggled Autosave
  const features = useFeatures();
  const intl = useIntl();
  const organizationSettings = useOrganizationSettings();
  const [object, setObject] = useState(props.object);
  const [inputsValidationErrors, setInputValidationErrors] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [objectHasUnsavedChanges, setObjectHasUnsavedChanges] = useState(false);
  const [triggerCallbackData, setTriggerCallbackData] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFirstRun = useRef(true);
  const propsCallback = props.callback;
  const propsSetHasUnsavedChanges = useMemo(
    () => props.setHasUnsavedChanges,
    [props.setHasUnsavedChanges]
  );
  const propsOnChange = props.onChange;
  const propsOnValidate = props.onValidate;
  const propsSubmitOnChange = props.submitOnChange;
  const internalOnlyRef = useRef();
  const formRef = useMemo(
    () => (props.formRef ? props.formRef : internalOnlyRef),
    [props.formRef]
  );

  const lastSavedObject = useRef(props.object);

  const autosaveTimeoutId = useRef(null);

  // autosave is for live forms that should periodically save
  const propsSetAutosaveStatus = props.setAutosaveStatus;

  // partial-autosave is for forms that should save immediately but not submit
  const draftAutosaveEnabled =
    props.draftAutosaveEnabled && (features?.autosave_forms?.enabled ?? true);

  // Disable legacy unsaved changes prompt if
  const propsDisableUnsavedChangesPrompt =
    props.disableUnsavedChangesPrompt || draftAutosaveEnabled;

  const propsDraftAutosaveExtraFormKeysToCleanupOnSubmit =
    props.draftAutosaveExtraFormKeysToCleanupOnSubmit;

  if (draftAutosaveEnabled && !props.uniqueFormKey) {
    throw new Error(
      'uniqueFormKey identifier must be provided if you want to enable the automatic unsaved changes functionality'
    );
  }

  const {
    stash: saveDraft,
    discard: discardDraft,
    state: draftFetchState,
    latestObjectFetched: latestDraftObjectFetched,
    deleteRemote: deleteDraftFromServer,
    hasPendingChanges: draftHasPendingChangesToSync,
    isChangedFromSubmitted: isInDraftState,
  } = useAutosave({
    key: props.uniqueFormKey ?? '',
    enabled: draftAutosaveEnabled,
    inputs: props.inputs,
    submittedObject: props.object,
    extraKeysToCleanupOnDeleteRemote:
      propsDraftAutosaveExtraFormKeysToCleanupOnSubmit,
  });

  useEffect(() => {
    if (draftFetchState === 'SUCCESS') {
      setObject(latestDraftObjectFetched);
    }
    if (draftFetchState === 'ERROR') {
      toast.error(
        intl.formatMessage({
          id: 'app.views.widgets.forms.validated_form.autosave.loading.error',
          defaultMessage:
            'Error loading previously draft data. Please refresh the page to load the latest. If the problem persists, please contact support.',
        })
      );
    }
  }, [draftFetchState, latestDraftObjectFetched, intl]);

  // update object if new one passed in
  useDeepCompareEffectNoCheck(() => {
    // NOTE: we DON'T call propsSetHasUnsavedChanges(false) here because
    // we want to keep the unsaved changes state if the user has unsaved changes
    // but this useEffect will get called if propsSetHasUnsavedChanges is
    // a dependency
    setObjectHasUnsavedChanges(false);
    setObject(props.object);
  }, [props.object]);

  // we allow passing in an override hasUnsavedChanges externally for when multiple
  // forms or fields are working together
  const hasUnsavedChanges = useMemo(() => {
    if (propsDisableUnsavedChangesPrompt) {
      return false;
    }

    return typeof props.hasUnsavedChanges !== 'undefined'
      ? props.hasUnsavedChanges
      : objectHasUnsavedChanges;
  }, [
    objectHasUnsavedChanges,
    propsDisableUnsavedChangesPrompt,
    props.hasUnsavedChanges,
  ]);

  const shouldShowPrompt =
    (!propsDisableUnsavedChangesPrompt &&
      hasUnsavedChanges &&
      !draftAutosaveEnabled) ||
    draftHasPendingChangesToSync;

  const propsSetShouldShowPrompt = props.setShouldShowPrompt;
  useEffect(() => {
    propsSetShouldShowPrompt?.(shouldShowPrompt);
  }, [propsSetShouldShowPrompt, shouldShowPrompt]);

  // show prompt if clicking back button or navigating away via refresh
  // (note: <Prompt /> below doesn't cover refresh but this does)
  // TODO: figure out why sometimes in some situations clicking back
  // still removes data from the page, i.e. doesn't fully cancel it
  useEffect(() => unloadWarning(shouldShowPrompt), [shouldShowPrompt]);

  // if object changed and props.submitOnChange is set, call it
  // NOTE: we check first run to avoid calling the function on initialization
  useEffect(() => {
    if (propsSubmitOnChange) {
      // eslint-disable-next-line no-unused-vars
      if (!isEqual(lastSavedObject.current, object)) {
        lastSavedObject.current = object;
        formRef.current?.props.onSubmit();
        setObjectHasUnsavedChanges(false);
        if (propsSetHasUnsavedChanges) {
          propsSetHasUnsavedChanges(false);
        }
      }
    } else if (isFirstRun.current) {
      isFirstRun.current = false;
    } else if (propsSetAutosaveStatus) {
      // only resave if it has changed
      if (hasUnsavedChanges && !isEqual(lastSavedObject.current, object)) {
        // set short timer (so we don't save EVERY keystroke)
        // and when reached, try auto-saving
        if (autosaveTimeoutId?.current) {
          clearTimeout(autosaveTimeoutId.current);
        }

        propsSetAutosaveStatus(props.autosaveSavingComponent);
        autosaveTimeoutId.current = setTimeout(() => {
          lastSavedObject.current = object;
          return formRef.current?.props.onSubmit();
        }, TIME_TO_WAIT_TO_AUTOSAVE_IN_MS);
      }
    } else {
      // remove any existing autosave timeout (this is
      // for the case when we disable the autosave feature,
      // e.g. temporarily when showing prompt to delete goal)
      if (autosaveTimeoutId?.current) {
        clearTimeout(autosaveTimeoutId.current);
      }
    }
  }, [
    object,
    propsSubmitOnChange,
    propsSetAutosaveStatus,
    hasUnsavedChanges,
    props.object,
    formRef,
    propsSetHasUnsavedChanges,
    props.autosaveSavingComponent,
    props.autosaveSavedComponent,
  ]);

  const onInputValidChange = useCallback(
    (name, validationMessage) => {
      if (validationMessage) {
        const oldMessage = inputsValidationErrors[name];
        if (oldMessage !== validationMessage) {
          setInputValidationErrors({
            ...inputsValidationErrors,
            [name]: validationMessage,
          });
        }
      } else {
        if (inputsValidationErrors[name]) {
          const errors = { ...inputsValidationErrors };
          delete errors[name];
          setInputValidationErrors(errors);
        }
      }
    },
    [inputsValidationErrors]
  );

  const onSubmitCallback = useCallback(
    (data, error, hardErrorMessage = null) => {
      setValidationErrors({});

      if (error || hardErrorMessage) {
        if (propsSetAutosaveStatus) {
          propsSetAutosaveStatus(props.autosaveErrorComponent);
        }

        // show validation errors if error is set
        if (error?.data) {
          setValidationErrors(error.data);
        }

        // note: only error or HardErrorMessage should be set
        propsCallback(null, error, hardErrorMessage);
      } else {
        if (propsSetAutosaveStatus) {
          // only show a saved indicator if the save was successful
          propsSetAutosaveStatus(props.autosaveSavedComponent);
        }

        // ensure we don't see "You have unsaved changes" dialog anymore
        // since save was successful
        setObjectHasUnsavedChanges(false);
        if (propsSetHasUnsavedChanges) {
          propsSetHasUnsavedChanges(false);
        }

        if (draftAutosaveEnabled) {
          // delete draft from server and only then proceed with callback
          // to avoid having concurrency issues where the draft gets loaded
          // before the draft is deleted
          deleteDraftFromServer(() => setTriggerCallbackData(data));
        } else {
          setTriggerCallbackData(data);
        }
      }
    },
    [
      propsSetAutosaveStatus,
      propsCallback,
      props.autosaveErrorComponent,
      props.autosaveSavedComponent,
      propsSetHasUnsavedChanges,
      draftAutosaveEnabled,
      deleteDraftFromServer,
    ]
  );

  useEffect(() => {
    // we trigger this in a callback to ensure that setHasUnsavedChanges
    // is set to true before calling the callback, else we may inadvertently
    // show the "You have unsaved changes" dialog after the person clicks submit
    // if it takes the user to a new page
    if (typeof triggerCallbackData !== 'undefined') {
      // ensure we don't see "You have unsaved changes" dialog anymore
      // since save was successful
      setObjectHasUnsavedChanges(false);
      if (propsSetHasUnsavedChanges) {
        propsSetHasUnsavedChanges(false);
      }

      const callbackDataValue = triggerCallbackData;

      // don't call callback more than once
      setTriggerCallbackData(undefined);

      // success; return success data
      propsCallback(callbackDataValue);
    }
  }, [
    hasUnsavedChanges,
    propsCallback,
    propsSetHasUnsavedChanges,
    triggerCallbackData,
  ]);

  const clear = useCallback(() => {
    setValidationErrors({});
    setInputValidationErrors({});
  }, []);

  const propsOnInputsChange = props.onInputsChange;
  useEffect(() => {
    if (propsOnInputsChange) {
      propsOnInputsChange(object, clear);
    }
  }, [props.inputs, object, clear, propsOnInputsChange]);

  const onInputChange = useCallback(
    (e, onChangeSideEffects) => {
      // for autosaving properly (and not double-autosaving when, say,
      // creating something and then passing back the created object to
      // this component as a prop right after)
      setObjectHasUnsavedChanges(true);
      if (propsSetHasUnsavedChanges) {
        propsSetHasUnsavedChanges(true);
      }

      // Additional side effects in case we need to update other fields
      const sideEffects = onChangeSideEffects
        ? onChangeSideEffects(
            object,
            e.target.value,
            intl,
            organizationSettings
          )
        : {};

      const newObject = {
        ...object,
        [e.target.name]: e.target.value,
        ...sideEffects,
      };
      setObject(newObject);
      if (propsOnChange) {
        propsOnChange(newObject);
      }
      if (draftAutosaveEnabled) {
        saveDraft(newObject);
      }
    },
    [
      propsSetHasUnsavedChanges,
      object,
      propsOnChange,
      draftAutosaveEnabled,
      saveDraft,
      intl,
      organizationSettings,
    ]
  );

  // returns false if error, true if all is good
  const onValidate = useCallback(
    (obj) => {
      const filteredInputValidationErrors = props.inputs?.reduce(
        (acc, input) => {
          const e = inputsValidationErrors[input.name];
          if (e) {
            return {
              ...acc,
              [input.name]: inputsValidationErrors[input.name],
            };
          }
          return acc;
        },
        {}
      );
      const errors = {
        ...filteredInputValidationErrors,
        // custom validation messages will take precedence
        ...propsOnValidate(obj),
      };

      // only get errors that exist
      for (let i in errors) {
        if (errors[i]) {
          setValidationErrors(errors);
          return false;
        }
      }

      return true;
    },
    [propsOnValidate, inputsValidationErrors, props.inputs]
  );

  const onIsSubmittingChange = (submitting) => setIsSubmitting(submitting);

  const anInputHasManualAutofocus =
    props.inputs && props.inputs.findIndex((i) => i.autoFocus === true) !== -1;

  // if any validation errors don't have a corresponding input,
  // show them at the bottom
  const matchingValidatorNames =
    props.inputs && props.matchingValidatorNames
      ? props.matchingValidatorNames.concat(props.inputs.map((i) => i.name))
      : [];

  const errorsAreArray = Array.isArray(validationErrors);
  const unattachedValidationErrors = errorsAreArray
    ? validationErrors
    : Object.keys(validationErrors)
        .filter((name) => matchingValidatorNames.indexOf(name) === -1)
        .reduce((acc, name) => {
          acc[name] = validationErrors[name];
          return acc;
        }, {});

  let children = props.children;

  const inputElements = useMemo(
    () =>
      props.inputs?.map((input, index) => {
        let value = input?.name && object && object[input.name];
        const output = (
          <ValidatedInput
            disableUnsavedChangesPrompt={propsDisableUnsavedChangesPrompt}
            key={index}
            innerRef={input.innerRef}
            {...input}
            disabled={props.readOnly || input.disabled}
            value={value}
            onChange={(e) => onInputChange(e, input.onChangeSideEffects)}
            onValidChange={onInputValidChange}
            validationErrors={errorsAreArray ? undefined : validationErrors}
            // enable autofocus if specified manually for cases where form
            // is hidden manually somehow and later shown (and not explicitly set to false)
            autoFocus={
              (!anInputHasManualAutofocus &&
                props.autoFocus &&
                index === 0 &&
                input.autoFocus !== false) ||
              input.autoFocus
            }
            // if isSubmitting is provided externally, defer to that instead
            // of dictating it ourselves
            isSubmitting={
              typeof props.isSubmitting !== 'undefined'
                ? props.isSubmitting
                : isSubmitting
            }
            organization={props.organization}
            translationNamespace={
              props.translationNamespace || input.translationNamespace
            }
            translationVisibility={props.translationVisibility}
            jsonPath={input.jsonPath}
          />
        );

        return output;
      }),
    [
      props.inputs,
      props.translationNamespace,
      props.translationVisibility,
      propsDisableUnsavedChangesPrompt,
      props.readOnly,
      props.autoFocus,
      props.isSubmitting,
      props.organization,
      object,
      onInputValidChange,
      errorsAreArray,
      validationErrors,
      anInputHasManualAutofocus,
      isSubmitting,
      onInputChange,
    ]
  );

  // if no children provided automatically, render children based on fields passed into object,
  // and set focus into first child when created
  if (!children && props.inputs) {
    children = props.renderInputs ? (
      props.renderInputs(inputElements)
    ) : (
      <>{inputElements}</>
    );
  }

  const formContents = children && children.length > 1 ? children[0] : children;
  const belowFormContents = useMemo(
    () =>
      props.belowFormContents ||
      (children && children.length >= 2 ? children[1] : ''),
    [children, props.belowFormContents]
  );

  const renderedUnattachedErrors =
    Object.keys(unattachedValidationErrors).length > 0 &&
    props.renderValidationErrors(
      renderValidationError(unattachedValidationErrors)
    );

  // submitOnChange means we don't have a submit button, we're just changing the objects
  // as they change (e.g. a notification form), so we should show errors as toasts instead
  // of on the form itself. Similarly, setAutosaveStatus means we're autosaving (but with
  // a time delay, e.g. for a text form where we only want to save after a few seconds of
  // inactivity).
  const showErrorsAsToasts =
    props.submitOnChange || props.setAutosaveStatus ? true : false;
  const leavePromptMessage = consts.UNSAVED_CHANGES_PROMPT;

  const propsPreSubmit = props.preSubmit;
  const preSubmit = useCallback(() => {
    if (propsPreSubmit) {
      propsPreSubmit();
    }
  }, [propsPreSubmit]);

  const prompt = useMemo(
    () => <Prompt when={shouldShowPrompt} message={leavePromptMessage} />,
    [shouldShowPrompt, leavePromptMessage]
  );

  const output = useMemo(
    () =>
      props.inForm ? (
        <>
          <div>
            {formContents}
            {renderedUnattachedErrors}
          </div>
          {belowFormContents}
        </>
      ) : (
        <>
          {prompt}
          <ConfirmForm
            url={props.url}
            action={props.action}
            method={props.method}
            submitPromise={props.submitPromise}
            formRef={formRef}
            object={object}
            submitText={props.submitText ? props.submitText : props.title}
            inlineSubmitButton={props.inlineSubmitButton}
            renderForm={props.renderForm}
            renderValidationErrors={props.renderValidationErrors}
            className={props.className}
            buttonClassName={props.buttonClassName}
            buttonColor={props.buttonColor}
            buttonIsBlock={props.buttonIsBlock}
            buttonType={props.buttonType}
            onValidate={onValidate}
            preSubmit={preSubmit}
            transformObjectBeforeSubmit={props.transformObjectBeforeSubmit}
            callback={onSubmitCallback}
            doNotSendOrganization={props.doNotSendOrganization}
            showErrorsAsToasts={showErrorsAsToasts}
            hideSubmitButton={props.hideSubmitButton || props.submitOnChange}
            confirmationDialog={props.confirmationDialog}
            disabled={props.disabled}
            disabledHoverText={props.disabledHoverText}
            onIsSubmittingChange={onIsSubmittingChange}
            noValidate={props.noValidate}
            isInDraftState={isInDraftState}
            discardDraft={discardDraft}
            draftFetchState={draftFetchState}
          >
            {formContents}
          </ConfirmForm>
          {!showErrorsAsToasts ? renderedUnattachedErrors : undefined}
          {belowFormContents}
        </>
      ),
    [
      props.inForm,
      props.url,
      props.action,
      props.method,
      props.submitPromise,
      props.submitText,
      props.title,
      props.inlineSubmitButton,
      props.renderForm,
      props.renderValidationErrors,
      props.className,
      props.buttonClassName,
      props.buttonColor,
      props.buttonIsBlock,
      props.buttonType,
      props.transformObjectBeforeSubmit,
      props.doNotSendOrganization,
      props.hideSubmitButton,
      props.submitOnChange,
      props.confirmationDialog,
      props.disabled,
      props.disabledHoverText,
      props.noValidate,
      formContents,
      renderedUnattachedErrors,
      belowFormContents,
      prompt,
      formRef,
      object,
      onValidate,
      preSubmit,
      onSubmitCallback,
      showErrorsAsToasts,
      isInDraftState,
      discardDraft,
      draftFetchState,
    ]
  );

  return output;
};

ValidatedForm.defaultProps = {
  autoFocus: true,
  object: {},
  draftAutosaveEnabled: false,
  draftAutosaveExtraFormKeysToCleanupOnSubmit: [],
  matchingValidatorNames: [],
  renderValidationErrors: (x) => x,
  autosaveSavingComponent: (
    <span className="small">
      <i
        className="spinner-border"
        style={{
          width: '0.8rem',
          height: '0.8rem',
          position: 'relative',
          top: '-2px',
        }}
      />
      <span className="ms-2">
        <FormattedMessage
          id="app.views.widgets.forms.validated_form.saving"
          defaultMessage="Saving..."
        />
      </span>
    </span>
  ),
  autosaveSavedComponent: (
    <span className="small">
      <i className="fe fe-check me-2" />
      <FormattedMessage
        id="app.views.widgets.forms.validated_form.saved"
        defaultMessage="Saved"
      />
    </span>
  ),
  autosaveErrorComponent: (
    <span className="small text-danger">
      <i
        className="fe fe-x-circle me-2"
        style={{ position: 'relative', top: '1px' }}
      />
      <FormattedMessage
        id="app.views.widgets.forms.validated_form.error"
        defaultMessage="Error"
      />
    </span>
  ),
  onValidate: () => ({}),
};

ValidatedForm.propTypes = {
  formRef: PropTypes.object,
  title: PropTypes.string,
  autoFocus: PropTypes.bool,
  focusRef: PropTypes.object,
  callback: PropTypes.func.isRequired,
  renderInputs: PropTypes.func,
  renderForm: PropTypes.func,
  renderValidationErrors: PropTypes.func,
  object: PropTypes.object,
  inputs: PropTypes.arrayOf(PropTypes.object),
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonIsBlock: PropTypes.bool,
  buttonType: PropTypes.string,
  url: PropTypes.string,
  action: PropTypes.string,
  method: PropTypes.string,
  submitPromise: PropTypes.func,
  onValidate: PropTypes.func,
  preSubmit: PropTypes.func,
  matchingValidatorNames: PropTypes.arrayOf(PropTypes.string),
  transformObjectBeforeSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hasUnsavedChanges: PropTypes.bool,
  setHasUnsavedChanges: PropTypes.func,
  setShouldShowPrompt: PropTypes.func,
  anchorTrigger: PropTypes.string,
  style: PropTypes.object,
  inlineSubmitButton: PropTypes.bool,
  inForm: PropTypes.bool,
  onChange: PropTypes.func,
  hideSubmitButton: PropTypes.bool,
  translationNamespace: PropTypes.string,
  translationVisibility: PropTypes.string,
  submitOnChange: PropTypes.bool,
  disableUnsavedChangesPrompt: PropTypes.bool,
  doNotSendOrganization: PropTypes.bool,
  organization: PropTypes.object,
  setAutosaveStatus: PropTypes.func,
  draftAutosaveEnabled: PropTypes.bool,
  uniqueFormKey: PropTypes.string,
  draftAutosaveExtraFormKeysToCleanupOnSubmit: PropTypes.arrayOf(
    PropTypes.string
  ),
  confirmationDialog: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  noValidate: PropTypes.bool,
  buttonColor: PropTypes.string,
  jsonPath: PropTypes.string,
  onInputsChange: PropTypes.func,
};

export default React.memo(ValidatedForm);
