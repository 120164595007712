import * as consts from '../../consts/consts';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Slide, ToastContainer, toast } from 'react-toastify';
import {
  useConfirmIntl,
  useLanguageSettings,
} from '../../locale/ConfirmIntlContext';

import EnvironmentAlert from './EnvironmentAlert';
import Header from './Header';
import HeaderAlert from '../Dashboard/HeaderAlert';
import Loading from '../Widgets/Loading';
import MainLeftNav from './MainLeftNav';
import Page403 from './Pages/Errors/Page403';
import Page404 from './Pages/Errors/Page404';
import Page500 from './Pages/Errors/Page500';
import PageChurnedOrganization from './Pages/Errors/PageChurnedOrganization';
import PageNoOrganization from './Pages/Errors/PageNoOrganization';
import PageUserNotProvisioned from './Pages/Errors/PageUserNotProvisioned';
import PropTypes from 'prop-types';
import ProtectedRoute from './ProtectedRoute';
import { ReduxState } from 'types';
import SimpleErrorPage from '../Error/SimpleErrorPage';
import { connect } from 'react-redux';
import generateRoutes from '../../routes';
import { isValidLocale } from '../../locale/messages';
import { setLoggedOutLocaleInLocalStorage } from '../../utils/models/User';
import { useIntl } from 'react-intl';

const Layout: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const { setCurrentLocale } = useConfirmIntl();
  const location = useLocation();
  const languageSettings = useLanguageSettings();

  // set locale / language based on user's preference if specified,
  // and store in universal logged out storage so that in the future
  // on the same machine, if logged out, we show loading in their
  // most recent language, etc.
  useEffect(() => {
    const locale = languageSettings?.current_locale;
    if (setCurrentLocale && isValidLocale(locale)) {
      setLoggedOutLocaleInLocalStorage(locale);
      // @ts-expect-error
      setCurrentLocale(locale);
    }
  }, [languageSettings?.current_locale, setCurrentLocale]);

  // needed to show simplified header and no left nav in performance cycle flow
  // for a focused user experience without distractions
  const isInPerformanceCycleFlow = useMemo(
    () =>
      (location.pathname.startsWith(consts.PREVIEW_CAMPAIGN().path) ||
        location.pathname.startsWith(consts.PERFORMANCE().path)) &&
      !(
        // hack: ignore perf-admin pages
        (
          location.pathname.startsWith(
            consts.TEAM_PERFORMANCE_DASHBOARD_HEADER_LINK(formatMessage).path
          ) ||
          location.pathname.startsWith(
            consts.TEAM_PERFORMANCE_DASHBOARD().path
          ) ||
          location.pathname.startsWith(
            consts.PERFORMANCE_ADMINISTRATION_PEER_SELECTION().path
          ) ||
          location.pathname.startsWith(consts.PARTICIPATION_DASHBOARD().path) ||
          location.pathname.startsWith(consts.RATINGS_DASHBOARD().path) ||
          location.pathname.startsWith(consts.TAKEAWAYS_DASHBOARD().path) ||
          location.pathname.startsWith(
            consts.VISUALIZATIONS_DASHBOARD_NEW().path
          )
        )
      ),
    [location.pathname, formatMessage]
  );

  // don't show any header items on the welcome page
  const isOnWelcomePageOrUnderMaintenance = useMemo(
    () =>
      location.pathname.startsWith(consts.WELCOME(formatMessage).path) ||
      location.pathname === consts.MAINTENANCE(formatMessage).path,
    [location.pathname, formatMessage]
  );

  const showIfEnabled = useCallback(
    (p, q) => ((props.features ?? {})[p]?.enabled ? [q] : []),
    [props.features]
  );

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const isOrgSystemAdmin = useMemo(
    // @ts-expect-error
    () => props.currentOrganization?.is_system_admin ?? false,
    // @ts-expect-error
    [props.currentOrganization?.is_system_admin]
  );

  // an "HRBP" is either an org-level system admin or a user with enhanced visibility
  // via the "admins" key defined in the org "settings" field
  const isHRBP = useMemo(
    // @ts-expect-error
    () => props.currentOrganization?.is_hrbp ?? false,
    // @ts-expect-error
    [props.currentOrganization?.is_hrbp]
  );

  const routes = props.errorMessage
    ? null
    : generateRoutes(props.features, formatMessage);

  if (!props.features && !props.errorMessage) {
    return <Loading />;
  }

  return (
    <div className="app">
      {!isInPerformanceCycleFlow &&
        !isOnWelcomePageOrUnderMaintenance &&
        !props.errorMessage && (
          <MainLeftNav
            showIfEnabled={showIfEnabled}
            isSuperAdmin={isSuperAdmin}
            isOrgSystemAdmin={isOrgSystemAdmin}
          />
        )}
      <main className="main-content pb-8">
        <EnvironmentAlert />
        <HeaderAlert />
        <Header
          isInPerformanceCycleFlow={isInPerformanceCycleFlow}
          isOnWelcomePageOrUnderMaintenance={isOnWelcomePageOrUnderMaintenance}
          showIfEnabled={showIfEnabled}
          isSuperAdmin={isSuperAdmin}
          setIsSuperAdmin={setIsSuperAdmin}
          isOrgSystemAdmin={isOrgSystemAdmin}
          errorMessage={props.errorMessage}
        />
        {props.errorMessage && (
          <SimpleErrorPage
            // @ts-expect-error
            errorHeading={props.errorHeading}
            errorMessage={props.errorMessage}
          />
        )}
        {!props.errorMessage && (
          <Switch>
            <Route
              exact
              path={consts.NO_ORGANIZATION(formatMessage).path}
              // @ts-expect-error
              name={consts.NO_ORGANIZATION(formatMessage).name}
            >
              <PageNoOrganization />
            </Route>
            <Route
              exact
              path={consts.CHURNED_ORGANIZATION(formatMessage).path}
              // @ts-expect-error
              name={consts.CHURNED_ORGANIZATION(formatMessage).name}
            >
              <PageChurnedOrganization />
            </Route>
            <Route
              exact
              path={consts.USER_NOT_PROVISIONED(formatMessage).path}
              // @ts-expect-error
              name={consts.USER_NOT_PROVISIONED(formatMessage).name}
            >
              <PageUserNotProvisioned />
            </Route>
            <Route
              exact
              path={consts.ERROR_500(formatMessage).path}
              // @ts-expect-error
              name={consts.ERROR_500(formatMessage).name}
              status={500}
            >
              <Page500 />
            </Route>
            {/* @ts-expect-error */}
            {routes.map((route, index) => {
              const permissions = route.permissionsCallback
                ? route.permissionsCallback({
                    isHRBP,
                    isOrgSystemAdmin,
                    isSuperAdmin,
                  })
                : { isEnabled: true };
              return (
                <Route
                  key={index}
                  path={route.path}
                  // @ts-expect-error
                  name={route.name}
                  exact={route.exact}
                  render={({ match }) => (
                    <ProtectedRoute
                      routes={routes}
                      match={match}
                      component={
                        permissions.isEnabled ? route.component : Page403
                      }
                      isHRBP={isHRBP}
                    />
                  )}
                />
              );
            })}
            <Route
              path={consts.ERROR_404(formatMessage).path}
              // @ts-expect-error
              name={consts.ERROR_404(formatMessage).name}
              status={404}
            >
              <Page404 />
            </Route>
          </Switch>
        )}
      </main>
      <ToastContainer
        icon={false}
        transition={Slide}
        position={toast.POSITION.BOTTOM_RIGHT}
        bodyClassName={() => 'text-white'}
        theme="light"
      />
    </div>
  );
};

const Layout_propTypes = {
  me: PropTypes.object,
  features: PropTypes.object,
  errorHeading: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

type Props = PropTypes.InferProps<typeof Layout_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { me, features, currentOrganization, currentProxyPerson } = state;

  return {
    me,
    features,
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(Layout));
