import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getCurrentPhaseIndex,
  getCustomQuestionsByPhase,
  getPerfCampaignIsClosed,
  getPerfCampaignIsInReportingPhaseOrClosed,
  getPhaseByType,
  getPhaseName,
  getPhaseOpenedInThePast,
  hasPhase,
  replaceRelationships,
} from '../../../utils/models/Campaign';
import { Campaign, Me, Organization } from '../../../types';
import {
  CompletionStats,
  LIKERT_SCALE,
  PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK,
  PERFORMANCE_FEATURE_ASSESS_MANAGER,
  PERFORMANCE_FEATURE_DISABLE_AUTO_CALIBRATION,
  PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS,
  PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PERFORMANCE_FEATURE_HIDE_TIME_AT_ORGANIZATION,
  PERFORMANCE_FEATURE_HIDE_TIME_IN_ROLE,
  PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_PEER_FEEDBACK,
  PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_CALLOUTS,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_ALLOW_COMMENTS,
  PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_ALLOW_COMMENTS,
  PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_REPORTING,
  PHASE_TYPE_SELF,
  WIZARD_TYPE_PERFORMANCE,
  buildCompletionLookup,
  getCampaignFeature,
  getCampaignHasFeatureEnabled,
  getCampaignRatings,
  getPerformanceFeatureEnabled,
  getPhaseDateHasPassed,
  getPhaseDateHasStarted,
  getRatingName,
  getWizardType,
} from '../../../utils/models/Performance';
import {
  EVALUATION_PHASE_QUESTION_PREFIX,
  PEER_FEEDBACK_QUESTION_PREFIX,
  RATING_IGNORE_DUE_TO_NO_MANAGER,
  RATING_NOT_ALLOWED,
  RATING_NOT_ELIGIBLE_TO_MANAGER_REVIEW,
  RATING_NOT_PARTICIPATING,
  RATING_NOT_YET_COMPLETE,
  SELF_PHASE_QUESTION_PREFIX,
  UPWARD_FEEDBACK_QUESTION_PREFIX,
  addTeamData,
  generateColumnsForCustomQuestions,
  getFilterSerializedId,
  getItemFromField,
  getManagementTier,
  getPersonFromPeopleDataset,
  getRatingDisplayComponent,
  isFieldExcluded,
  isPersonType,
  isRealNumericRating,
  itemsForFieldNameAreEqual,
  matchesFilter,
  personMatchesFilters,
  potentialFieldsInCsvOrUITable,
} from './TeamDashboard/helpers';
import FilterablePeopleTableColumnSelectorModal, {
  useFilterableTableColumnSelectorModal,
} from '../People/FilterablePeopleTable/FilterablePeopleTableColumnSelectorModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICONS, PERFORMANCE, PROMOTION_PACKETS } from '../../../consts/consts';
import { Link, useLocation, withRouter } from 'react-router-dom';
import {
  OMITTED_FIELD_KEY_PREFIX,
  POSITION_CHANGE_REASONS,
  WHITELISTED_FIELDS_FOR_SENSITIVE_DATA_OMISSION,
  getPersonDisplayTitle,
  getPositionChangeReasonDisplayName,
  isCurrentEmployee,
  isInactive,
  peopleObjectsAreEqual,
} from '../../../utils/models/Person';
import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import TeamDashboardEditablePeerList, {
  TeamDashboardEditModalCallbackResponse,
} from './TeamDashboard/TeamDashboardEditablePeerList';
import { capitalize, loadOrRender } from '../../../utils/util/formatter';
import { clone, isEmpty, isEqual, throttle } from 'lodash';
import {
  formatCSVONAKeyLabelByType,
  getFilterAbbreviationForType,
  getFilterTypeForAbbreviation,
} from './TeamDashboard/common';
import {
  getPrettyDate,
  getSearchParamsAsObjectOfArrays,
  numberWithCommas,
  sortByFieldFunc,
  stripHtml,
} from '../../../utils/util/util';

import AdditionalProgressCard from './TeamDashboard/AdditionalProgressCard';
import Avatar from '../People/Avatar';
import AvatarGroup from '../People/AvatarGroup';
import { Bar } from 'react-chartjs-2';
import CalibrationFlags from '../CalibrationFlags';
import CompletionDoughnut from './components/CompletionDoughnut';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import GoldStarCell from './TeamDashboard/GoldStarCell';
import HeadsUpCell from './TeamDashboard/HeadsUpCell';
import { INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
import InfluenceCell from './TeamDashboard/InfluenceCell';
import Loading from '../Loading';
import ModalDatasetRecordEditor from '../Modals/ModalDatasetRecordEditor';
import ModalEditor from '../Modals/ModalEditor';
import ObjectsDropdown from '../Dropdowns/ObjectsDropdown';
import OmittedElement from './TeamDashboardComponents/OmittedElement';
import { PACKET_STATUSES } from '../../../utils/models/PromotionPackets';
import PromotionPacket from '../../PromotionPackets/PromotionPacket';
import { RELATIONSHIP_TYPES } from '../../../utils/models/RelationshipUtils';
import ReactTagsInput from '../Inputs/ReactTagsInput';
import ScrollableUncontrolledPopover from '../ScrollableUncontrolledPopover';
import TagsList from '../TagsList';
import TeamDashboardCSVDownload from './TeamDashboard/TeamDashboardCSVDownload';
import TeamDashboardReleaseReports from './TeamDashboard/TeamDashboardReleaseReports';
import TerminatedToggle from './TeamDashboard/TerminatedToggle';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';
import { fetchTestData } from '../../../utils/util/testData';
import { getIconForFieldName } from '../People/Table/commons';
import { getPersonIsParticipatingInPhase } from '../../../utils/models/Relationship';
import { getUserIsSuperAdmin } from '../../../utils/models/User';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useDebouncedState } from 'utils/util/hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

// we append this url suffix to profiles so that links from
// here go directly to the person's performance report (for
// quick/easy access for both HRBPs, managers, manager's manager, etc.)
const PERSON_PERFORMANCE_URL_SUFFIX = '/performance?manager=true';

const ICON_SELF_RESPONSE_QUESTION = <i className="fe fe-arrow-left" />;
const ICON_UPWARD_RESPONSE_QUESTION = <i className="fe fe-arrow-up" />;
const ICON_PEER_RESPONSE_QUESTION = <i className="fe fe-arrow-right" />;
const ICON_DOWNWARD_RESPONSE_QUESTION = <i className="fe fe-arrow-down" />;

const INITIAL_TABLE_SIZE = 25;
const TABLE_INFINITE_LOAD_INCREMENT = 100;
const TABLE_INFINITE_LOAD_INTERVAL = 100;

interface TeamDashboardTypes {
  me: Me;
  campaignIndex?: number;
  campaignId?: number;
  campaign?: object;
  title?: string;
  children?: ReactNode | ReactNode[];
  history: object;
  demoPeople: object[];
  reload: number;
  currentOrganization?: Organization;
}

const TeamDashboard: FC<TeamDashboardTypes> = (props) => {
  const intl = useIntl();

  const { user } = useAuth0();
  const userSub = user?.sub;
  const isSuperAdmin = getUserIsSuperAdmin(user);

  const { formatMessage, locale } = intl;
  const [tableLimitMultiplyFactor, setTableLimitMultiplyFactor] = useState(1);
  const [tableLimit, setTableLimit] = useState(INITIAL_TABLE_SIZE);

  const location = useLocation();
  const [currentPromotionPacketId, setCurrentPromotionPacketId] =
    useState(null);

  // campaign data to use across the UI for performance scores, statuses, etc.
  const [campaign, setCampaign] = useState<Campaign | undefined>(undefined);

  // if shouldIgnoreCampaignMetadataUnlessUndefined is set, it means we don't want to use the data from
  // the campaign data snapshot in terms of a give person's metadata and who their
  // manager(s) are and instead want to use the person objects
  const [
    shouldIgnoreCampaignMetadataUnlessUndefined,
    setShouldIgnoreCampaignMetadataUnlessUndefined,
  ] = useState(undefined);

  // campaign data (if any) used to say who is the current manager and show each person's
  // metadata (e.g. cost center, department, etc.) - we default this to NULL, which means
  // use the live data instead of the data from the campaign "snapshot", as people use
  // this UI even months after Perf when many changes have happened as a means to view and
  // track people's past performance and search across other metadata in there
  const [personDatasourceCampaign, setPersonDatasourceCampaign] =
    useState(null);

  const [filteringInProgress, setFilteringInProgress] = useState(false);
  const showFilteringInProgress = useCallback(() => {
    setFilteringInProgress(true);
  }, []);

  const [showIneligiblePeople, setShowIneligiblePeople] = useDebouncedState(
    true,
    showFilteringInProgress
  );
  const [showTerminatedPeople, setShowTerminatedPeople] = useDebouncedState(
    true,
    showFilteringInProgress
  );
  const [showAllResponses, setShowAllResponses] = useState(false);
  const [currentFinalRatingPerson, setCurrentFinalRatingPerson] =
    useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [data, setData] = useState<{ [key: string]: any } | null | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [includeFilters, setIncludeFilters] = useDebouncedState(
    [],
    showFilteringInProgress
  );
  const [excludeFilters, setExcludeFilters] = useDebouncedState(
    [],
    showFilteringInProgress
  );
  const [showFilters, setShowFilters] = useState(
    location.search.indexOf('filters') !== -1 ||
      location.search.indexOf('exclude') !== -1
  );
  const toggleFilters = useCallback(
    () => setShowFilters(!showFilters),
    [showFilters]
  );
  const [filterTiers, setFilterTiers] = useDebouncedState(
    [],
    showFilteringInProgress
  );
  const [sortByFields, setSortByFields] = useState([
    'given_name',
    'family_name',
  ]);
  const [sortAtoZ, setSortAtoZ] = useState(true);
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState<
    number | undefined
  >(undefined);
  const [othersPhaseShowAltProgressBars, setOthersPhaseShowAltProgressBars] =
    useState(false);
  const [histogramIsExpanded, setHistogramIsExpanded] = useState(false);
  const toggleHistogramIsExpanded = useCallback(
    () => setHistogramIsExpanded(!histogramIsExpanded),
    [histogramIsExpanded]
  );

  // we need this so admins can see people below them in chain of command
  // when filtering by someone above them in chain of command, given
  // people can't see their own survey results
  // (e.g. Paige filters "Suzy's full team", and Suzy is above Paige in the chain of
  // command, and she and needs to see in there the people below her, i.e. Paige's
  // direct reports and below)
  const [currentUserPersonWithManager, setCurrentUserPersonWithManager] =
    useState(undefined);

  const resetTableLimit = useCallback(() => {
    setTableLimit(INITIAL_TABLE_SIZE);
    setTableLimitMultiplyFactor(1);
  }, []);

  const toggleFilterTier = useCallback(
    (tier) => {
      // @ts-expect-error
      if (filterTiers.indexOf(tier) === -1) {
        // @ts-expect-error
        setFilterTiers([...filterTiers, tier]);
      } else {
        setFilterTiers(filterTiers.filter((i) => i !== tier));
      }
      resetTableLimit();
    },
    [filterTiers, resetTableLimit, setFilterTiers]
  );

  const [showFinalRatingModalEditor, setShowFinalRatingModalEditor] =
    useState(false);
  const [finalRatingModalProposedScore, setFinalRatingModalProposedScore] =
    useState(undefined);
  const [
    finalRatingModalProposedScoreComments,
    setFinalRatingModalProposedScoreComments,
  ] = useState(undefined);

  useEffect(() => {
    if (props.campaign) {
      // @ts-expect-error
      setShouldIgnoreCampaignMetadataUnlessUndefined(!props.campaign.id);
      // @ts-expect-error
      setPersonDatasourceCampaign(props.campaign);
      setData(undefined);
      resetTableLimit();
    }
  }, [props.campaign, resetTableLimit]);

  const onUpdatePacket = useCallback(
    (newPacket) => {
      if (newPacket) {
        setData({
          ...data,
          packets: data?.packets?.map((p) =>
            p.id === newPacket.id ? newPacket : p
          ),
        });
      }
    },
    [data]
  );

  useEffect(() => {
    const phaseQueryString = new URLSearchParams(location.search).get('phase')
      ? // @ts-expect-error
        parseInt(new URLSearchParams(location.search).get('phase'))
      : undefined;

    // fix: stop phase from resetting when updating final rating
    if (currentPhaseIndex === undefined) {
      setCurrentPhaseIndex(
        phaseQueryString
          ? // 0-indexed
            phaseQueryString - 1
          : campaign
          ? getCurrentPhaseIndex(campaign)
          : undefined
      );
    }
  }, [campaign, currentPhaseIndex, location.search]);

  const isPrivateMode =
    new URLSearchParams(location.search).get('private') === 'true';

  const disableConversationAcknowledgements = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS
      ),
    [campaign]
  );

  const calculateUnsortedPeople = useCallback(() => {
    // don't run this function until everything necessary is set/populated
    if (
      // @ts-expect-error
      props.campaigns === undefined ||
      data === undefined ||
      shouldIgnoreCampaignMetadataUnlessUndefined === undefined ||
      !props.demoPeople ||
      !props.me
    ) {
      return undefined;
    }

    if (
      !props.me ||
      !data?.campaign ||
      !data?.survey_responses ||
      !data?.relationships
    ) {
      return [];
    }

    const surveyResponseLookup = data.survey_responses.reduce((acc, sr) => {
      acc[sr.person.id] = sr;
      return acc;
    }, {});

    const completion_stats: CompletionStats = data?.completion_stats;
    const enpsResponsesLookup = completion_stats?.enps
      ? buildCompletionLookup(data.completion_stats.enps)
      : {};
    const engagementSurveyResponsesLookup = completion_stats?.engagement_survey
      ? buildCompletionLookup(data.completion_stats.engagement_survey)
      : {};

    setCampaign(data.campaign);

    const selfPhaseIndex = data.campaign?.phases?.findIndex(
      (p) => p.type === PHASE_TYPE_SELF
    );
    const evaluationPhaseIndex = data.campaign?.phases?.findIndex(
      (p) => p.type === PHASE_TYPE_EVALUATION
    );
    const othersPhaseIndex = data.campaign?.phases?.findIndex(
      (p) => p.type === PHASE_TYPE_OTHERS
    );
    const calibrationPhaseIndex = data.campaign?.phases?.findIndex(
      (p) => p.type === PHASE_TYPE_CALIBRATION
    );

    const phasesNotOpenTextDict = {
      [PHASE_TYPE_SELF]:
        selfPhaseIndex === -1 ||
        getPhaseOpenedInThePast(data.campaign, selfPhaseIndex)
          ? undefined
          : formatMessage(
              {
                id: 'app.views.widgets.dashboards.team_dashboard.phase_text.opens_on_date',
                defaultMessage: 'Opens on {date}',
              },
              {
                date: getPrettyDate({
                  dateString: data.campaign.phases[selfPhaseIndex]?.start_date,
                  locale,
                }),
              }
            ),
      [PHASE_TYPE_OTHERS]:
        othersPhaseIndex === -1 ||
        getPhaseOpenedInThePast(data.campaign, othersPhaseIndex)
          ? undefined
          : formatMessage(
              {
                id: 'app.views.widgets.dashboards.team_dashboard.phase_text.opens_on_date',
                defaultMessage: 'Opens on {date}',
              },
              {
                date: getPrettyDate({
                  dateString:
                    data.campaign.phases[othersPhaseIndex]?.start_date,
                  locale,
                }),
              }
            ),
      [PHASE_TYPE_EVALUATION]:
        evaluationPhaseIndex === -1 ||
        getPhaseOpenedInThePast(data.campaign, evaluationPhaseIndex)
          ? undefined
          : formatMessage(
              {
                id: 'app.views.widgets.dashboards.team_dashboard.phase_text.opens_on_date',
                defaultMessage: 'Opens on {date}',
              },
              {
                date: getPrettyDate({
                  dateString:
                    data.campaign.phases[evaluationPhaseIndex]?.start_date,
                  locale,
                }),
              }
            ),
      [PHASE_TYPE_CALIBRATION]:
        calibrationPhaseIndex === -1 ||
        getPhaseOpenedInThePast(data.campaign, calibrationPhaseIndex)
          ? undefined
          : formatMessage(
              {
                id: 'app.views.widgets.dashboards.team_dashboard.phase_text.opens_on_date',
                defaultMessage: 'Opens on {date}',
              },
              {
                date: getPrettyDate({
                  dateString:
                    data.campaign.phases[calibrationPhaseIndex]?.start_date,
                  locale,
                }),
              }
            ),
      [PHASE_TYPE_REPORTING]: getPerfCampaignIsInReportingPhaseOrClosed(
        data.campaign
      )
        ? undefined
        : formatMessage(
            {
              id: 'app.views.widgets.dashboards.team_dashboard.phase_text.opens_on_date',
              defaultMessage: 'Opens on {date}',
            },
            {
              date: getPrettyDate({
                dateString:
                  data.campaign.phases[data.campaign.phases.length - 1]
                    ?.start_date,
                locale,
              }),
            }
          ),
    };

    const userPerson = props.me;

    const currentUsersManagerRelationship = data.relationships.find(
      (r) =>
        r.dataset &&
        r.type === RELATIONSHIP_TYPES.REPORTS_TO &&
        peopleObjectsAreEqual(userPerson, r.from_person)
    );

    if (currentUsersManagerRelationship) {
      setCurrentUserPersonWithManager({
        ...currentUsersManagerRelationship.from_person,
        manager: currentUsersManagerRelationship.to_person,
      });
    } else {
      // populate it (instead of leaving undefined) still so the people table loads
      // @ts-expect-error
      setCurrentUserPersonWithManager(null);
    }

    // get hashmap of person to manager for quick management tier generation
    const peopleManagerIdMap = data.relationships.reduce((acc, r) => {
      // if this person is currentUserPersonWithManager (e.g. for the case of
      // an HR admin that can administer someone above them in the chain of command,
      // e.g. Paige viewing Suzy's direct reports), include that connection in the
      // tiering
      if (
        r === currentUsersManagerRelationship ||
        (r.dataset && r.type === RELATIONSHIP_TYPES.REPORTS_TO)
      ) {
        acc[r.from_person.id] = r.to_person.id;
      }

      return acc;
    }, {});

    // this is a pair of hashmaps as follows:
    // 0: incoming relationships for people claimed by users
    // 1: incoming relationships for people NOT claimed by users
    // 2: outgoing relationships for people claimed by users
    // 3: outgoing relationships for people NOT claimed by users
    // mapped to the provided user id or person id, respectively
    // NOTE: we do the above because there are situations where
    // a person with two email addresses will have their survey
    // responses using one of the email address's corresponding
    // person, but when completing relationships, e.g. peer
    // and direct report feedback, will use the person associated
    // with the email address of the user they're logged into
    const relationshipsMaps = data.relationships.reduce(
      (acc, r) => {
        if (r.to_person.id in acc[1]) {
          acc[1][r.to_person.id].push(r);
        } else {
          acc[1][r.to_person.id] = [r];
        }

        if (r?.from_person) {
          // add to outgoing list hashed on PERSON id
          if (r.from_person.id in acc[3]) {
            acc[3][r.from_person.id].push(r);
          } else {
            acc[3][r.from_person.id] = [r];
          }
        }

        return acc;
      },
      [{}, {}, {}, {}]
    );

    // source metadata if campaign is set, unless it's the most
    // recent campaign and we want to ignore campaign metadata
    const shouldSourceMetadataFromCampaignUnlessUndefined =
      !shouldIgnoreCampaignMetadataUnlessUndefined ||
      // @ts-expect-error
      (props.campaigns?.length > 0 &&
        // @ts-expect-error
        data?.campaign?.id !== props.campaigns[0].id);

    const peopleWithoutTeamData = data.survey_responses.map((sr) =>
      getPersonFromPeopleDataset(
        formatMessage,
        shouldSourceMetadataFromCampaignUnlessUndefined,
        data.campaign,
        phasesNotOpenTextDict,
        sr,
        // TODO: get management tier based on dataset and NOT campaign
        // when personDatasourceCampaign is not set
        getManagementTier(sr.person.id, peopleManagerIdMap),
        // anchor on user if one exists per the above
        // relationshipsMaps comments, else person (to ensure
        // people with multiple email addresses aka people
        // are attributed properly)
        relationshipsMaps[1][sr.person.id],
        relationshipsMaps[3][sr.person.id],
        data.survey_responses,
        data.packets,
        props.demoPeople,
        props.campaignIndex,
        surveyResponseLookup,
        disableConversationAcknowledgements,
        data.completion_stats,
        enpsResponsesLookup,
        engagementSurveyResponsesLookup
      )
    );

    return addTeamData(peopleWithoutTeamData, peopleManagerIdMap);
  }, [
    // @ts-expect-error
    props.campaigns,
    props.demoPeople,
    props.me,
    props.campaignIndex,
    data,
    shouldIgnoreCampaignMetadataUnlessUndefined,
    disableConversationAcknowledgements,
    locale,
    formatMessage,
  ]);

  // we store unsortedPeople as a state so we can modify contents, specifically,
  // when calibrating a specific row (changing final rating / final rating comments)
  const [unsortedPeople, setUnsortedPeople] = useState(undefined);
  useEffect(() => {
    setUnsortedPeople(calculateUnsortedPeople());
  }, [calculateUnsortedPeople]);

  const unpopulatedFieldNames = useMemo(() => {
    // @ts-expect-error
    if (!(unsortedPeople?.length > 0)) {
      return new Set();
    }

    let newPotentialFieldsInCsvOrUITable = new Set(
      potentialFieldsInCsvOrUITable
    );

    // @ts-expect-error
    unsortedPeople.forEach((p) => {
      // if the field exists, remove it from the list of items to filter
      newPotentialFieldsInCsvOrUITable =
        // @ts-expect-error
        newPotentialFieldsInCsvOrUITable.filter(
          (field) =>
            !(field in p) ||
            // note: "0" is valid so just check null, empty string, and undefined
            p[field] === null ||
            p[field] === '' ||
            typeof p[field] === 'undefined'
        );
    });

    return newPotentialFieldsInCsvOrUITable;
  }, [unsortedPeople]);

  const ratings = useMemo(() => {
    return getCampaignRatings(campaign, true);
  }, [campaign]);

  const showPreviousFinalRating = useMemo(
    () =>
      data?.survey_responses?.find(
        (sr) => typeof sr.configs?.previous_final_rating !== 'undefined'
      )
        ? true
        : false,
    [data]
  );

  const isEngagementSurveyOnly =
    campaign?.properties?.is_engagement_survey_only ?? false;

  // show time at company for everyone for now
  const showTimeAtCompany = useMemo(
    () =>
      (campaign
        ? !getCampaignHasFeatureEnabled(
            campaign,
            PERFORMANCE_FEATURE_HIDE_TIME_AT_ORGANIZATION
          )
        : false) && !isEngagementSurveyOnly,
    [campaign, isEngagementSurveyOnly]
  );

  const showTimeInRole = useMemo(
    () =>
      (campaign
        ? !getCampaignHasFeatureEnabled(
            campaign,
            PERFORMANCE_FEATURE_HIDE_TIME_IN_ROLE
          )
        : false) && !isEngagementSurveyOnly,
    [campaign, isEngagementSurveyOnly]
  );

  const people = useMemo(() => {
    if (unsortedPeople === undefined) {
      return undefined;
    }

    // @ts-expect-error
    return unsortedPeople.sort((a, b) => sortByFieldFunc(a, b, sortByFields));
  }, [sortByFields, unsortedPeople]);

  const selfPhaseIndex = useMemo(
    () => campaign?.phases?.findIndex((p) => p.type === PHASE_TYPE_SELF) ?? -1,
    [campaign?.phases]
  );

  const othersPhaseIndex = useMemo(
    () =>
      campaign?.phases?.findIndex((p) => p.type === PHASE_TYPE_OTHERS) ?? -1,
    [campaign?.phases]
  );

  const evaluationPhaseIndex = useMemo(
    () =>
      campaign?.phases?.findIndex((p) => p.type === PHASE_TYPE_EVALUATION) ??
      -1,
    [campaign?.phases]
  );

  const calibrationPhaseIndex = useMemo(
    () =>
      campaign?.phases?.findIndex((p) => p.type === PHASE_TYPE_CALIBRATION) ??
      -1,
    [campaign?.phases]
  );

  const explicitReportingPhaseIndex = useMemo(
    () =>
      campaign?.phases?.findIndex((p) => p.type === PHASE_TYPE_REPORTING) ?? -1,
    [campaign?.phases]
  );

  const numerals = ['', 'I', 'II', 'III'];
  const selfPhaseExists = selfPhaseIndex !== -1 ? 1 : 0;
  const othersPhaseExists = othersPhaseIndex !== -1 ? 1 : 0;

  const selfPhaseNumeral = numerals[selfPhaseExists];
  const othersPhaseNumeral =
    othersPhaseIndex !== -1 ? numerals[1 + selfPhaseExists] : '';
  const evaluationPhaseNumeral =
    evaluationPhaseIndex !== -1
      ? numerals[1 + selfPhaseExists + othersPhaseExists]
      : '';

  // reporting phase is "implied" as after the very last phase
  // use explicit phase if it exists
  const reportingPhaseIndex = useMemo(
    () =>
      explicitReportingPhaseIndex !== -1
        ? explicitReportingPhaseIndex
        : campaign?.phases?.length,
    [campaign?.phases?.length, explicitReportingPhaseIndex]
  );

  const currentPhaseText = useMemo(() => {
    return typeof currentPhaseIndex === 'number'
      ? getPhaseName(campaign, currentPhaseIndex, formatMessage)
      : undefined;
  }, [campaign, currentPhaseIndex, formatMessage]);

  const showPeerFeedbackColumns = useMemo(() => {
    if (!campaign) {
      return false;
    }
    return (
      currentPhaseIndex === othersPhaseIndex &&
      getPerformanceFeatureEnabled(campaign, PERFORMANCE_FEATURE_PEER_FEEDBACK)
    );
  }, [campaign, currentPhaseIndex, othersPhaseIndex]);

  const showProgressColumns = useMemo(() => {
    return (
      // don't show progress columns during calibration
      calibrationPhaseIndex !== -1 ||
      (currentPhaseIndex !== calibrationPhaseIndex &&
        currentPhaseIndex !== reportingPhaseIndex) ||
      // don't show progress columns on reporting phase
      // unless there was an evaluation phase, indicating there
      // are reports to give to participants and conversations to share
      evaluationPhaseIndex !== -1
    );
  }, [
    calibrationPhaseIndex,
    currentPhaseIndex,
    evaluationPhaseIndex,
    reportingPhaseIndex,
  ]);

  const showShareAction = useMemo(() => {
    // only allow sharing if there is a report to share (i.e. there
    // was an evaluation phase)
    // @ts-expect-error
    return currentPhaseIndex >= reportingPhaseIndex;
  }, [currentPhaseIndex, reportingPhaseIndex]);

  // NOTE: the below models after FilterablePeopleTable to facilitate easier migration to it later
  const [selectedRowIdsSet, setSelectedRowIdsSet] = useState(new Set());
  const selectedRowToggleFieldsSet = selectedRowIdsSet;
  const toggleSelectedRowField = useMemo(() => {
    // only allow selection if there are multiselect actions available
    if (!showShareAction) {
      return undefined;
    }

    return (rowId) => {
      if (selectedRowIdsSet.has(rowId)) {
        setSelectedRowIdsSet(
          new Set(Array.from(selectedRowIdsSet).filter((rid) => rid !== rowId))
        );
      } else {
        setSelectedRowIdsSet(
          new Set([...Array.from(selectedRowIdsSet), rowId])
        );
      }
    };
  }, [selectedRowIdsSet, showShareAction]);

  const isRowSelectable = useCallback(
    (row) => {
      // if report already released, don't allow selection
      // TODO: allow unreleasing reports through this UI
      if (row?.released_at) {
        return false;
      }

      // if not eligible in any phase, return false
      if (!row?.is_active_and_participating_in_any_phase) {
        return false;
      }

      // if not eligible to receive report as indicated in UI, return false
      if (row?.share_status < 0) {
        return false;
      }

      // we must have finished all phases OR be in the reporting phase
      // and this person's report should not be "on hold"
      // NOTE: this logic should match PersonPerformance.js's similar logic
      return (
        row?.survey_response_id &&
        getPerfCampaignIsInReportingPhaseOrClosed(campaign) &&
        !row?.withhold_report_release &&
        (row?.is_only_receiving_review ||
          (getPhaseByType(campaign, PHASE_TYPE_SELF) &&
            getPersonIsParticipatingInPhase(
              row,
              row?.configs,
              getPhaseByType(campaign, PHASE_TYPE_SELF)
            )))
      );
    },
    [campaign]
  );

  const getToggleField = useCallback((row) => row?.survey_response_id, []);

  const onReleasedReportsComplete = useCallback(
    (response) => {
      if (response?.length > 0) {
        const newData = data?.survey_responses?.map((sr) => {
          const matchingResponse = response.find(
            (r) => r.id?.toString() === sr.id?.toString()
          );
          return matchingResponse ? matchingResponse : sr;
        });
        // update UI with released data
        setSelectedRowIdsSet(new Set());
        setData({
          ...data,
          survey_responses: newData,
        });
      }
    },
    [data]
  );

  const multiselectActions = useMemo(() => {
    return (
      <>
        {!!campaign?.id && selectedRowIdsSet.size > 0 && (
          <Col>
            <TeamDashboardReleaseReports
              campaignId={campaign?.id}
              // @ts-expect-error
              surveyReponseIds={selectedRowIdsSet}
              onComplete={onReleasedReportsComplete}
            />
          </Col>
        )}
      </>
    );
  }, [campaign?.id, selectedRowIdsSet, onReleasedReportsComplete]);

  // @ts-expect-error
  const isAdmin = useMemo(() => campaign?.is_admin, [campaign?.is_admin]);

  // non-admin people managers should only be able to see promo, gold stars,
  // heads up, draft rating, and READ-ONLY final rating w/comments if in evaluation phase
  const selfPhaseHasClosed = useMemo(
    () =>
      // @ts-expect-error
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      getPhaseDateHasPassed(
        getPhaseByType(campaign, PHASE_TYPE_SELF)?.end_date
      ),
    [campaign]
  );

  const othersPhaseHasClosed = useMemo(
    () =>
      // @ts-expect-error
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      getPhaseDateHasPassed(
        getPhaseByType(campaign, PHASE_TYPE_OTHERS)?.end_date
      ),
    [campaign]
  );

  // non-admin people managers should only be able to see promo, gold stars,
  // heads up, draft rating, and READ-ONLY final rating w/comments if in evaluation phase
  const evaluationPhaseHasOpened = useMemo(
    () =>
      // @ts-expect-error
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      getPhaseDateHasStarted(
        getPhaseByType(campaign, PHASE_TYPE_EVALUATION)?.start_date
      ),
    [campaign]
  );

  const calibrationPhaseHasClosed = useMemo(() => {
    const calibrationPhase = getPhaseByType(campaign, PHASE_TYPE_CALIBRATION);

    // no calibration phase means we can consider calibration "closed"
    if (!calibrationPhase) {
      return true;
    }

    return (
      // @ts-expect-error
      campaign?.status !== CAMPAIGN_STATUSES.DEMO &&
      getPhaseDateHasPassed(calibrationPhase.end_date)
    );
  }, [campaign]);

  const hasSelfPhase = useMemo(
    () => hasPhase(campaign, PHASE_TYPE_SELF),
    [campaign]
  );

  const shouldShowInfluence = useMemo(() => {
    // @ts-expect-error
    if (campaign?.should_see_influence === false || !hasSelfPhase) {
      return false;
    }

    // also, do not show influence to non-admins while the self-phase is still running
    if (!isAdmin && !selfPhaseHasClosed) {
      return false;
    }

    // do not show influence if we have no influence data
    return data?.relationships?.find(
      (r) =>
        r.type == RELATIONSHIP_TYPES.ENERGIZED_BY ||
        r.type == RELATIONSHIP_TYPES.ADVISED_BY
    );
  }, [
    campaign,
    data?.relationships,
    isAdmin,
    selfPhaseHasClosed,
    hasSelfPhase,
  ]);

  // admins should see all and final rating should not be read only
  const showGoldStars = useMemo(
    () =>
      (isAdmin || selfPhaseHasClosed) &&
      !isEngagementSurveyOnly &&
      hasSelfPhase,
    [isAdmin, selfPhaseHasClosed, isEngagementSurveyOnly, hasSelfPhase]
  );

  const showHeadsUps = useMemo(
    () =>
      (isAdmin || selfPhaseHasClosed) &&
      !isEngagementSurveyOnly &&
      hasSelfPhase,
    [isAdmin, selfPhaseHasClosed, isEngagementSurveyOnly, hasSelfPhase]
  );

  // only show averages container if atleast one average exists
  const showAveragesContainer = useMemo(
    () => showHeadsUps || showGoldStars || shouldShowInfluence,
    [showHeadsUps, showGoldStars, shouldShowInfluence]
  );

  const showFormers = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_CALLOUTS
      ) &&
      (isAdmin || selfPhaseHasClosed),
    [campaign, isAdmin, selfPhaseHasClosed]
  );

  // Checks if rating is provided for upward feedback question
  // Note: The default upward manager question set does NOT include
  // a "rating" question- that's why we check only for custom
  // upward feedback questions here
  const upwardFeedbackRatingQuestion = useMemo(() => {
    const upwardFeedbackQuestions = getCampaignFeature(
      campaign,
      PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
    );
    if (upwardFeedbackQuestions) {
      return upwardFeedbackQuestions.find((q) => q.name === 'rating');
    }
    return null;
  }, [campaign]);

  // Determines whether to show "Upward feedback" column
  const showUpwardFeedbackScore = useMemo(
    () => (isAdmin || othersPhaseHasClosed) && upwardFeedbackRatingQuestion,
    [isAdmin, othersPhaseHasClosed, upwardFeedbackRatingQuestion]
  );

  // For showing the corresponding labels for upward ratings
  const upwardFeedbackScoreLabels = useMemo(() => {
    const question = upwardFeedbackRatingQuestion;
    if (question) {
      if (
        question?.type?.toUpperCase() === INPUT_TYPES.DROPDOWN &&
        question?.objects
      ) {
        return question?.objects?.reduce(
          (acc, o) => ({
            ...acc,
            [o.id]: o.name,
          }),
          {}
        );
      } else if (question?.type?.toUpperCase() === INPUT_TYPES.LIKERT) {
        // Assumes the default likert scale
        const result = {};
        for (const key in LIKERT_SCALE) {
          result[key] = LIKERT_SCALE[key].text;
        }
        return result;
      } else if (
        question?.type?.toUpperCase() === INPUT_TYPES.MULTIPLE_CHOICE &&
        question?.options
      ) {
        return question?.options?.reduce(
          (acc, o) => ({
            ...acc,
            [o.id]: o.name,
          }),
          {}
        );
      }
    }

    return null;
  }, [upwardFeedbackRatingQuestion]);

  const showPromo = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER
      ) &&
      (isAdmin || evaluationPhaseHasOpened),
    [campaign, evaluationPhaseHasOpened, isAdmin]
  );

  const showSalaryIncrease = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER
      ) &&
      (isAdmin || evaluationPhaseHasOpened),
    [campaign, evaluationPhaseHasOpened, isAdmin]
  );

  const ratingRequired = useMemo(
    () =>
      !getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      ),
    [campaign]
  );

  const showFinalRating = useMemo(
    () => evaluationPhaseHasOpened && ratingRequired,
    [ratingRequired, evaluationPhaseHasOpened]
  );

  const campaignIsClosed = useMemo(
    () => campaign && getPerfCampaignIsClosed(campaign),
    [campaign]
  );

  const isSystemAdmin = useMemo(
    () => !!props.currentOrganization?.is_system_admin,
    [props.currentOrganization]
  );

  const isHRBP = props.currentOrganization?.is_hrbp ?? false;

  const allowPeersEditing = isHRBP || isSystemAdmin || isSuperAdmin;

  // allow system admins to calibrate ratings even if calibration is over
  // as long as the full cycle isn't over
  // NOTE: we still have to check for released_at being false
  // to edit if it's a system admin, so we check below in the table
  const finalRatingIsReadOnly = useMemo(
    () =>
      campaignIsClosed ||
      !isAdmin ||
      !evaluationPhaseHasOpened ||
      (!isSystemAdmin && calibrationPhaseHasClosed),
    [
      campaignIsClosed,
      calibrationPhaseHasClosed,
      evaluationPhaseHasOpened,
      isAdmin,
      isSystemAdmin,
    ]
  );

  // we show the draft rating for everyone so people can see those
  // who are defaulted to "0 - new to role" until the end of calibrations
  const showDraftRating = useMemo(
    () => evaluationPhaseHasOpened && ratingRequired,
    [ratingRequired, evaluationPhaseHasOpened]
  );

  // show delta ratings from eval phase until reporting phase
  const showDeltas = useMemo(
    () =>
      // @ts-expect-error
      currentPhaseIndex >= evaluationPhaseIndex &&
      // @ts-expect-error
      currentPhaseIndex < reportingPhaseIndex,
    [currentPhaseIndex, evaluationPhaseIndex, reportingPhaseIndex]
  );

  const showPreviousFinalRatingDeltas = useMemo(
    () => showPreviousFinalRating && showDeltas,
    [showDeltas, showPreviousFinalRating]
  );
  const showFinalRatingDeltas = useMemo(
    () => showFinalRating && showDeltas,
    [showDeltas, showFinalRating]
  );

  const onSaveFinalRating = useCallback(
    (r) => {
      if (r) {
        // replace only the specific relationship
        // (or create it if it wasn't there before)
        // NOTE: we need to update "data" here instead of "unsortedPeople"
        // because the relationships from "data" are what are
        // used to determine draft_to_final_delta, draft_to_final_delta, etc.
        setData({
          ...data,
          relationships: replaceRelationships(
            // @ts-expect-error
            { relationships: data.relationships },
            [r.type],
            [r],
            r.from_person,
            r.to_person,
            false
          ).relationships,
        });

        toast.success(
          formatMessage({
            id: 'app.views.widgets.dashboards.team_dashboard.rating_updated',
            defaultMessage: 'Rating updated!',
          })
        );
      }
    },
    [data, formatMessage]
  );

  const onUpdateFinalRating = useCallback((person, rating, comments) => {
    setCurrentFinalRatingPerson(person);
    setFinalRatingModalProposedScore(rating);
    setFinalRatingModalProposedScoreComments(comments);
    setShowFinalRatingModalEditor(true);
  }, []);

  const onFinalRatingModalClosed = useCallback(() => {
    // @ts-expect-error
    setCurrentFinalRatingPerson(undefined);
    setFinalRatingModalProposedScore(undefined);
    setFinalRatingModalProposedScoreComments(undefined);
  }, []);

  const toggleIneligible = useCallback(() => {
    setShowIneligiblePeople(!showIneligiblePeople);
    resetTableLimit();
  }, [setShowIneligiblePeople, showIneligiblePeople, resetTableLimit]);

  const toggleTerminated = useCallback(() => {
    setShowTerminatedPeople((value) => !value);
    resetTableLimit();
  }, [resetTableLimit, setShowTerminatedPeople]);

  const toggleAllResponses = useCallback(
    () => setShowAllResponses(!showAllResponses),
    [showAllResponses]
  );

  const toggleFinalRatingModalEditor = useCallback(
    () => setShowFinalRatingModalEditor(!showFinalRatingModalEditor),
    [showFinalRatingModalEditor]
  );

  const allowPromotionComments = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_ALLOW_COMMENTS
      ),
    [campaign]
  );

  const allowSalaryIncreaseComments = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_ALLOW_COMMENTS
      ),
    [campaign]
  );

  const promotionPacketsRequired = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED
      ),
    [campaign]
  );

  const showCalibrationFlags = useMemo(
    () =>
      currentPhaseIndex === calibrationPhaseIndex &&
      !getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_DISABLE_AUTO_CALIBRATION
      ) &&
      !data?.hide_auto_calibration,
    [calibrationPhaseIndex, campaign, currentPhaseIndex, data]
  );

  /* get info related to dataset */
  const [showModalDatasetRecordEditor, setShowModalDatasetRecordEditor] =
    useState(false);
  const [personToEdit, setPersonToEdit] = useState();
  const toggleModalDatasetRecordEditor = useCallback(
    () => setShowModalDatasetRecordEditor(!showModalDatasetRecordEditor),
    [showModalDatasetRecordEditor]
  );
  const onSaveModalDatasetRecordEditor = useCallback(
    (result) => {
      const newConfig = result.survey_response_config;
      const checkIfManagerChanged = (oldConfig, newConfig) => {
        if (oldConfig.manager_external_id) {
          return oldConfig.manager_external_id != newConfig.manager_external_id;
        } else {
          return oldConfig.manager_email != newConfig.manager_email;
        }
      };
      const getNewManager = (configs) => {
        if (!newConfig.manager_external_id && !newConfig.manager_email) {
          return null;
        }
        // @ts-expect-error
        for (let i = 0; i < data.survey_responses.length; i++) {
          // @ts-expect-error
          const sr = data.survey_responses[i];
          if (
            (configs.manager_external_id &&
              configs.manager_external_id == sr.person.external_id) ||
            configs.manager_email == sr.person.email
          ) {
            return sr.person;
          }
        }
      };

      // update in-memory data so UI will be updated
      // update survey response
      // @ts-expect-error
      const idx = data.survey_responses.findIndex(
        (sr) => sr.person.id === personToEdit
      );
      if (idx == -1) {
        toast.error(
          formatMessage(
            {
              id: 'app.views.widgets.dashboards.team_dashboard.could_not_find_survey_response',
              defaultMessage:
                'Could not find survey response for {given_name} {family_name}',
            },
            {
              given_name: newConfig.given_name,
              family_name: newConfig.family_name,
            }
          )
        );
        return;
      }
      // @ts-expect-error
      const person = data.survey_responses[idx].person;

      const isNewManager = checkIfManagerChanged(
        // @ts-expect-error
        data.survey_responses[idx].configs,
        newConfig
      );
      // @ts-expect-error
      Object.assign(data.survey_responses[idx].configs, newConfig);

      // update manager
      if (isNewManager) {
        // @ts-expect-error
        const idx = data.relationships.findIndex(
          (r) => r.type === 'R' && r.from_person.id == personToEdit
        );
        const newManager = getNewManager(newConfig);
        if (newManager) {
          if (idx >= 0) {
            // @ts-expect-error
            data.relationships[idx].to_person = newManager;
          } else {
            // @ts-expect-error
            data.relationships.push({
              dataset: -1, // just needs to be truthy to avoid 'warning about not official manager'
              type: 'R',
              from_person: person,
              to_person: newManager,
            });
          }
        } else {
          // @ts-expect-error
          data.relationships.splice(idx, 1);
        }
      }
      const givenName =
        newConfig.preferred_given_name ||
        newConfig.legal_given_name ||
        newConfig.given_name;
      toast.success(
        formatMessage(
          {
            id: 'app.views.widgets.dashboards.team_dashboard.person_updated',
            defaultMessage: `{givenName} {familyName} has been updated. Please wait a minute for this change to sync everywhere.`,
          },
          { givenName, familyName: newConfig.family_name }
        )
      );
      setData(clone(data)); // need to make a clone in order for React to register it as "different"
    },
    [data, personToEdit, formatMessage]
  );

  const updatePeerRelationships = useCallback(
    (response: TeamDashboardEditModalCallbackResponse) => {
      // we replace the peer feedback relationships
      // for the updated type / person with the new ones
      // coming from the update api response
      if (!data?.relationships) return;

      const relationships =
        response.type === 'incoming'
          ? data.relationships.filter(
              (r) =>
                !(
                  r.dataset &&
                  r.to_person.id === response.person_id &&
                  r.type ===
                    RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR
                )
            )
          : data.relationships.filter(
              (r) =>
                !(
                  r.dataset &&
                  r.from_person.id === response.person_id &&
                  r.type ===
                    RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR
                )
            );
      const x = [...relationships, ...response.relationships];
      setData({
        ...data,
        relationships: x,
      });
    },
    [data, setData]
  );

  const getTablePersonFromPerson = useCallback(
    (person) => {
      const uniqueId = person.id
        ? 'u-' + person.id
        : person.id
        ? 'p-' + person.id
        : 'e-' + person.external_id;

      const dataOut = {
        // for accessing raw data while rendering table
        raw_data: person,

        // include multiple feedback response values (which have
        // a format function for custom display within them)
        ...person,

        id: person.id,
        ...(showCalibrationFlags
          ? {
              current_calibration_flag: (
                <CalibrationFlags
                  canAutoCalibrate={person.can_auto_calibrate}
                  // @ts-expect-error
                  canEditFinalRating={
                    !finalRatingIsReadOnly && person.can_edit_final_rating
                  }
                  flags={person.current_calibration_flags}
                  missing={person.current_missing_calibration_flags}
                  isCalibrated={!!person?.final_rating_provided_by_person}
                  onCalibrate={() =>
                    onUpdateFinalRating(
                      person,
                      person?.final_rating,
                      person?.final_rating_comments
                    )
                  }
                  showText={true}
                />
              ),
              original_calibration_flag: (
                <CalibrationFlags
                  canAutoCalibrate={person.can_auto_calibrate}
                  // @ts-expect-error
                  canEditFinalRating={
                    !finalRatingIsReadOnly && person.can_edit_final_rating
                  }
                  flags={person.original_calibration_flags}
                  missing={person.original_missing_calibration_flags}
                  onCalibrate={() =>
                    onUpdateFinalRating(
                      person,
                      person?.final_rating,
                      person?.final_rating_comments
                    )
                  }
                  showText={false}
                />
              ),
            }
          : {}),
        person: (
          <Row>
            <Col
              className={
                'py-0 pe-1 col-auto' + (toggleSelectedRowField ? ' ps-1' : '')
              }
            >
              <Avatar
                className="mt-2 me-2"
                size="xs"
                linked={person.id ? true : false}
                // open externally to avoid interrupting flow
                isExternalUrl={true}
                person={{
                  ...person,
                  url: person.url + PERSON_PERFORMANCE_URL_SUFFIX,
                }}
              />
            </Col>
            <Col className="p-0">
              <div>
                {person.url ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={person.url + PERSON_PERFORMANCE_URL_SUFFIX}
                  >
                    {person.full_name}
                  </a>
                ) : (
                  person.full_name
                )}
              </div>
              {getPersonDisplayTitle(formatMessage, person)}
            </Col>
          </Row>
        ),
        manager: (
          <>
            {person.believed_manager && (
              <>
                <h4
                  id={'believed-manager-' + uniqueId}
                  className={ICONS.ALERT + ' position-absolute'}
                  style={{ marginLeft: '-1.2rem', marginTop: '0.1rem' }}
                ></h4>
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'believed-manager-' + uniqueId}
                >
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.believed_manager"
                    defaultMessage="This person believes their manager is <br></br>{manager}{noManagerOptionalText}."
                    values={{
                      br: () => <br />,
                      manager: (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={person.believed_manager.url}
                        >
                          {person.believed_manager.full_name}
                        </Link>
                      ),
                      noManagerOptionalText: !person.manager && (
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.no_manager_optional"
                          defaultMessage=", but Confirm has no official manager on file. To grant this manager access, contact your Confirm representative"
                        />
                      ),
                    }}
                  />
                </UncontrolledPopover>
              </>
            )}
            {!(person.previous_managers?.length > 0) &&
              !person.manager &&
              person.believed_manager && (
                <>
                  {person.believed_manager && (
                    <Avatar
                      linked={person.believed_manager.id ? true : false}
                      // open externally to avoid interrupting flow
                      isExternalUrl={true}
                      className="me-2"
                      size="xs"
                      person={person.believed_manager}
                    />
                  )}
                </>
              )}
            <span>
              {!(person?.previous_managers?.length > 0) && (
                <>
                  {person.manager && (
                    <Avatar
                      linked={person.manager.id ? true : false}
                      // open externally to avoid interrupting flow
                      isExternalUrl={true}
                      className="me-2"
                      size="xs"
                      person={person.manager}
                    />
                  )}
                </>
              )}
              {person?.previous_managers?.length > 0 && (
                <AvatarGroup
                  // open externally to avoid interrupting flow
                  isExternalUrl={true}
                  className="me-2"
                  size="xs"
                  people={[
                    person.manager
                      ? person.manager
                      : person.believed_manager
                      ? person.believed_manager
                      : {
                          full_name: 'Unknown manager',
                        },
                    ...person.previous_managers,
                  ]}
                  hideAllButFirst={true} // show only first manager visually, rest are in a + menu
                  popoverHeaderPrefix={
                    <div className="mb-2">
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.previous_managers_mentioned"
                        defaultMessage="Previous managers mentioned"
                      />
                    </div>
                  }
                />
              )}
            </span>
          </>
        ),
        level_id: (
          <>
            <span id={'row-level-' + uniqueId}>{person.level_id}</span>
            {person.level && (
              <UncontrolledPopover
                placement="left"
                trigger="hover"
                target={'row-level-' + uniqueId}
              >
                {person.level}
              </UncontrolledPopover>
            )}
          </>
        ),
        function: <span>{person.function}</span>,
        cost_center: <span>{person.cost_center}</span>,
        country: <span>{person.country}</span>,
        department: <span>{person.department}</span>,
        business_unit: <span>{person.business_unit}</span>,
        //gender: <span>{person.gender}</span>,
        //demographics: <span>{person.demographics}</span>,
        perf_progress: isEngagementSurveyOnly ? (
          <div className="ps-2">
            {person.perf_progress_others_phase === 100 && (
              <i style={{ color: 'green' }} className={ICONS.COMPLETE} />
            )}
            {person.perf_progress_others_phase == null && (
              <span id={'row-perf-progress-not-eligible-' + uniqueId}>
                <i className={ICONS.NOT_ELIGIBLE} />
              </span>
            )}
          </div>
        ) : (
          <>
            {(!person.is_active_and_participating_in_any_phase ||
              (currentPhaseIndex === selfPhaseIndex &&
                !person.is_active_and_participating_in_self_phase) ||
              (currentPhaseIndex === evaluationPhaseIndex &&
                typeof person.perf_progress_evaluation_phase ===
                  'undefined')) && (
              <>
                <span id={'row-perf-progress-not-eligible-' + uniqueId}>
                  <i className={ICONS.NOT_ELIGIBLE} />
                </span>
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'row-perf-progress-not-eligible-' + uniqueId}
                >
                  {isInactive(person.status) ? (
                    <>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.person_terminated"
                        defaultMessage="This person has been terminated"
                      />
                    </>
                  ) : (
                    !person.is_active_and_participating_in_any_phase &&
                    (!person.is_only_receiving_review ? (
                      <>
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.not_eligible_overall"
                          defaultMessage="Not eligible to participate in this cycle"
                        />
                      </>
                    ) : (
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.only_receiving_review"
                        defaultMessage="The person is only receiving a review"
                      />
                    ))
                  )}
                  {person.is_active_and_participating_in_any_phase &&
                    currentPhaseIndex === selfPhaseIndex &&
                    !person.is_active_and_participating_in_self_phase && (
                      <>
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.not_eligible_phase_one"
                          defaultMessage="Not eligible to participate in phase I this cycle"
                        />
                      </>
                    )}
                  {person.is_active_and_participating_in_any_phase &&
                    currentPhaseIndex === evaluationPhaseIndex &&
                    typeof person.perf_progress_evaluation_phase ===
                      'undefined' && (
                      <>
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.no_eligible_direct_reports"
                          defaultMessage="No eligible direct reports to evaluate this cycle"
                        />
                      </>
                    )}
                </UncontrolledPopover>
              </>
            )}
            {person.is_active_and_participating_in_any_phase &&
              typeof currentPhaseIndex !== 'undefined' &&
              (currentPhaseIndex !== selfPhaseIndex ||
                person.is_active_and_participating_in_self_phase) &&
              (currentPhaseIndex !== evaluationPhaseIndex ||
                typeof person.perf_progress_evaluation_phase !== 'undefined') &&
              (currentPhaseIndex !== othersPhaseIndex ||
                (!othersPhaseShowAltProgressBars &&
                  person.perf_progress[1].overall_max_steps > 0) ||
                (othersPhaseShowAltProgressBars &&
                  person.perf_progress[1].overall_max_steps_alt > 0)) && (
                <a target="_blank" rel="noopener noreferrer" href={person.url}>
                  <Progress
                    id={'row-perf-progress-' + uniqueId}
                    className="progress-sm"
                    color="success"
                    value={
                      // @ts-expect-error
                      currentPhaseIndex >= reportingPhaseIndex
                        ? person.perf_progress_reporting_phase
                        : currentPhaseIndex === selfPhaseIndex
                        ? person.perf_progress_self_phase
                        : currentPhaseIndex === othersPhaseIndex
                        ? othersPhaseShowAltProgressBars
                          ? person.perf_progress_others_phase_alt
                          : person.perf_progress_others_phase
                        : person.perf_progress_evaluation_phase
                    }
                  />
                  <UncontrolledPopover
                    placement="left"
                    trigger="hover"
                    target={'row-perf-progress-' + uniqueId}
                  >
                    <ul className="list-unstyled mb-0">
                      {selfPhaseIndex !== -1 && (
                        <li
                          className={
                            evaluationPhaseIndex !== -1 ||
                            othersPhaseIndex !== -1
                              ? 'mb-3'
                              : ''
                          }
                        >
                          <h6 className="header-pretitle mb-1">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.phase_num"
                              defaultMessage="Phase {num}"
                              values={{
                                num: selfPhaseNumeral,
                              }}
                            />
                          </h6>
                          <ul className="list-unstyled">
                            <li className="ps-4">
                              {person.perf_progress[0].open_text && (
                                <>{person.perf_progress[0].open_text}</>
                              )}
                              {!person.perf_progress[0].open_text &&
                                person.perf_progress[0].current_step <
                                  person.perf_progress[0].max_steps && (
                                  <span className="fw-bold">
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.on_step_x_of_y"
                                      defaultMessage="On step {x} of {y}"
                                      values={{
                                        x: person.perf_progress[0].current_step,
                                        y: person.perf_progress[0].max_steps,
                                      }}
                                    />
                                  </span>
                                )}
                              {!person.perf_progress[0].open_text &&
                                person.perf_progress[0].current_step >=
                                  person.perf_progress[0].max_steps && (
                                  <>
                                    <i
                                      className={
                                        ICONS.COMPLETE +
                                        ' ms-n4 mt-1 float-start'
                                      }
                                    />
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.complete_action"
                                      defaultMessage="Complete"
                                    />
                                  </>
                                )}
                            </li>
                          </ul>
                        </li>
                      )}
                      {othersPhaseIndex !== -1 && (
                        <li
                          className={evaluationPhaseIndex !== -1 ? 'mb-3' : ''}
                        >
                          <h6 className="header-pretitle mb-1">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.phase_num"
                              defaultMessage="Phase {num}"
                              values={{
                                num: othersPhaseNumeral,
                              }}
                            />
                          </h6>
                          <ul className="list-unstyled">
                            <li className="ps-4">
                              {person.perf_progress[1].open_text && (
                                <>{person.perf_progress[1].open_text}</>
                              )}
                              {!person.perf_progress[1].open_text &&
                                person.perf_progress[1]
                                  .has_completed_manager_step && (
                                  <>
                                    <i
                                      className={
                                        ICONS.COMPLETE +
                                        ' ms-n4 mt-1 float-start'
                                      }
                                    />
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.manager"
                                      defaultMessage="Manager"
                                    />
                                  </>
                                )}
                              {!person.perf_progress[1].open_text &&
                                !person.perf_progress[1]
                                  .has_completed_manager_step && (
                                  <span className="fw-bold">
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.manager_review_not_written"
                                      defaultMessage="Manager review not written"
                                    />
                                  </span>
                                )}
                            </li>
                            {!person.perf_progress[1].open_text &&
                              person.perf_progress[1].max_steps > 0 && (
                                <li className="ps-4">
                                  <Link
                                    to={
                                      person.perf_progress[1]
                                        .outgoing_feedback_url
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-dark"
                                  >
                                    {person.perf_progress[1].current_step <
                                      person.perf_progress[1].max_steps && (
                                      <span className="fw-bold">
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.x_of_y_peer_reviews_written"
                                          defaultMessage="{x} of {y} peer review(s) written"
                                          values={{
                                            x: person.perf_progress[1]
                                              .current_step,
                                            y: person.perf_progress[1]
                                              .max_steps,
                                          }}
                                        />
                                      </span>
                                    )}
                                    {person.perf_progress[1].current_step >=
                                      person.perf_progress[1].max_steps && (
                                      <>
                                        <i
                                          className={
                                            ICONS.COMPLETE +
                                            ' ms-n4 mt-1 float-start'
                                          }
                                        />
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.x_of_y_peer_reviews_written"
                                          defaultMessage="{x} of {y} peer review(s) written"
                                          values={{
                                            x: person.perf_progress[1]
                                              .current_step,
                                            y: person.perf_progress[1]
                                              .max_steps,
                                          }}
                                        />
                                      </>
                                    )}
                                  </Link>
                                </li>
                              )}
                            {!person.perf_progress[1].open_text &&
                              person.perf_progress[1].max_steps_alt > 0 && (
                                <li className="ps-4">
                                  <Link
                                    to={
                                      person.perf_progress[1]
                                        .incoming_feedback_url
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-dark"
                                  >
                                    {person.perf_progress[1].current_step_alt <
                                      person.perf_progress[1].max_steps_alt && (
                                      <span className="fw-bold">
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.x_of_y_peer_reviews_received"
                                          defaultMessage="{x} of {y} peer review(s) received"
                                          values={{
                                            x: person.perf_progress[1]
                                              .current_step_alt,
                                            y: person.perf_progress[1]
                                              .max_steps_alt,
                                          }}
                                        />
                                      </span>
                                    )}
                                    {person.perf_progress[1].current_step_alt >=
                                      person.perf_progress[1].max_steps_alt && (
                                      <>
                                        <i
                                          className={
                                            ICONS.COMPLETE +
                                            ' ms-n4 mt-1 float-start'
                                          }
                                        ></i>
                                        <span>
                                          <FormattedMessage
                                            id="app.views.widgets.dashboards.team_dashboard.x_of_y_peer_reviews_received"
                                            defaultMessage="{x} of {y} peer review(s) received"
                                            values={{
                                              x: person.perf_progress[1]
                                                .current_step_alt,
                                              y: person.perf_progress[1]
                                                .max_steps_alt,
                                            }}
                                          />
                                        </span>
                                      </>
                                    )}
                                  </Link>
                                </li>
                              )}
                            {!person.perf_progress[1].open_text &&
                              person.perf_progress[1]
                                .incoming_upward_max_steps > 0 && (
                                <li className="ps-4">
                                  <Link
                                    to={person.direct_reports_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-dark"
                                  >
                                    {person.perf_progress[1]
                                      .incoming_upward_current_step <
                                      person.perf_progress[1]
                                        .incoming_upward_max_steps && (
                                      <>
                                        <span className="fw-bold">
                                          <FormattedMessage
                                            id="app.views.widgets.dashboards.team_dashboard.x_of_y_manager_reviews_received"
                                            defaultMessage="{x} of {y} manager review(s) received"
                                            values={{
                                              x: person.perf_progress[1]
                                                .incoming_upward_current_step,
                                              y: person.perf_progress[1]
                                                .incoming_upward_max_steps,
                                            }}
                                          />
                                        </span>
                                      </>
                                    )}
                                    {person.perf_progress[1]
                                      .incoming_upward_current_step >=
                                      person.perf_progress[1]
                                        .incoming_upward_max_steps && (
                                      <>
                                        <i
                                          className={
                                            ICONS.COMPLETE +
                                            ' ms-n4 mt-1 float-start'
                                          }
                                        ></i>
                                        <span>
                                          <FormattedMessage
                                            id="app.views.widgets.dashboards.team_dashboard.x_of_y_manager_reviews_received"
                                            defaultMessage="{x} of {y} manager review(s) received"
                                            values={{
                                              x: person.perf_progress[1]
                                                .incoming_upward_current_step,
                                              y: person.perf_progress[1]
                                                .incoming_upward_max_steps,
                                            }}
                                          />
                                        </span>
                                      </>
                                    )}
                                  </Link>
                                </li>
                              )}
                          </ul>
                        </li>
                      )}
                      {evaluationPhaseIndex !== -1 && (
                        <li className="mb-3">
                          <h6 className="header-pretitle mb-1">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.phase_num"
                              defaultMessage="Phase {num}"
                              values={{
                                num: evaluationPhaseNumeral,
                              }}
                            />
                          </h6>
                          <ul className="list-unstyled">
                            <li className="ps-4">
                              {person.perf_progress[2].open_text ? (
                                <>{person.perf_progress[2].open_text}</>
                              ) : person.perf_progress[2].max_steps > 0 ? (
                                <Link
                                  to={person.direct_reports_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-dark"
                                >
                                  {person.perf_progress[2].current_step <
                                    person.perf_progress[2].max_steps && (
                                    <span className="fw-bold">
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.x_of_y_direct_reports_evaluated"
                                        defaultMessage="{x} of {y} direct reports evaluated"
                                        values={{
                                          x: person.perf_progress[2]
                                            .current_step,
                                          y: person.perf_progress[2].max_steps,
                                        }}
                                      />
                                    </span>
                                  )}
                                  {person.perf_progress[2].current_step >=
                                    person.perf_progress[2].max_steps && (
                                    <>
                                      <i
                                        className={
                                          ICONS.COMPLETE +
                                          ' ms-n4 mt-1 float-start'
                                        }
                                      />
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.x_of_y_direct_reports_evaluated"
                                        defaultMessage="{x} of {y} direct reports evaluated"
                                        values={{
                                          x: person.perf_progress[2]
                                            .current_step,
                                          y: person.perf_progress[2].max_steps,
                                        }}
                                      />
                                    </>
                                  )}
                                </Link>
                              ) : (
                                <>
                                  <FormattedMessage
                                    id="app.views.widgets.dashboards.team_dashboard.no_eligible_directs_to_evaluate"
                                    defaultMessage="
                                  No eligible direct reports to evaluate this
                                  cycle
                                "
                                  />
                                </>
                              )}
                            </li>
                          </ul>
                        </li>
                      )}
                      {evaluationPhaseIndex !== -1 && (
                        <li>
                          <h6 className="header-pretitle mb-1">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.conversations"
                              defaultMessage="Conversations"
                            />
                          </h6>
                          <ul className="list-unstyled">
                            <li className="ps-4">
                              {person.perf_progress[4].open_text && (
                                <>{person.perf_progress[4].open_text}</>
                              )}
                              {!person.perf_progress[4].open_text &&
                                person.perf_progress[4].released_at && (
                                  <>
                                    <i
                                      className={
                                        ICONS.COMPLETE +
                                        ' ms-n4 mt-1 float-start'
                                      }
                                    />
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.report_released"
                                      defaultMessage="Report released"
                                    />
                                  </>
                                )}
                              {!person.perf_progress[4].open_text &&
                                !person.perf_progress[4].released_at && (
                                  <span className="fw-bold">
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.report_release_needed"
                                      defaultMessage="Report release needed"
                                    />
                                  </span>
                                )}
                            </li>
                            {!disableConversationAcknowledgements &&
                              !person.perf_progress[4].open_text && (
                                <li className="ps-4">
                                  {!person.perf_progress[4].open_text &&
                                    person.perf_progress[4]
                                      .conversation_acknowledged_at && (
                                      <>
                                        <i
                                          className={
                                            ICONS.COMPLETE +
                                            ' ms-n4 mt-1 float-start'
                                          }
                                        ></i>
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.conversation_acknowledged"
                                          defaultMessage="Conversation acknowledged"
                                        />
                                      </>
                                    )}
                                  {!person.perf_progress[4].open_text &&
                                    !person.perf_progress[4]
                                      .conversation_acknowledged_at && (
                                      <span className="fw-bold">
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.conversation_needed"
                                          defaultMessage="Conversation needed"
                                        />
                                      </span>
                                    )}
                                </li>
                              )}
                          </ul>
                        </li>
                      )}
                    </ul>
                  </UncontrolledPopover>
                </a>
              )}
          </>
        ),
        team_perf_completed: (
          <>
            {typeof currentPhaseIndex !== 'undefined' &&
              person.team_perf_completed &&
              (currentPhaseIndex !== evaluationPhaseIndex ||
                typeof person.team_perf_completed_evaluation_phase_percent !==
                  'undefined') && (
                <a target="_blank" rel="noopener noreferrer" href={person.url}>
                  <Progress
                    id={'row-team-progress-' + uniqueId}
                    className="progress-sm"
                    color="success"
                    value={
                      // @ts-expect-error
                      currentPhaseIndex >= reportingPhaseIndex
                        ? person.team_perf_completed_reporting_phase_percent
                        : currentPhaseIndex === selfPhaseIndex
                        ? person.team_perf_completed_self_phase_percent
                        : currentPhaseIndex === othersPhaseIndex
                        ? othersPhaseShowAltProgressBars
                          ? person.team_perf_completed_others_phase_percent_alt
                          : person.team_perf_completed_others_phase_percent
                        : person.team_perf_completed_evaluation_phase_percent
                    }
                  />
                  {isEngagementSurveyOnly && (
                    <UncontrolledPopover
                      placement="left"
                      trigger="hover"
                      target={'row-team-progress-' + uniqueId}
                    >
                      <div>
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.team.done.survey_only"
                          defaultMessage="{current} of {total} people below {managerName} in the chain of command have completed this survey"
                          values={{
                            managerName: person.full_name,
                            current: person.team_perf_completed_others_phase,
                            total: person.team_perf_total_eligible,
                          }}
                        />
                      </div>
                    </UncontrolledPopover>
                  )}
                  {!isEngagementSurveyOnly && (
                    <UncontrolledPopover
                      placement="left"
                      trigger="hover"
                      target={'row-team-progress-' + uniqueId}
                    >
                      <ul className="list-unstyled mb-0">
                        {selfPhaseIndex !== -1 && (
                          <li className="mb-3">
                            <h6 className="header-pretitle mb-1">
                              <FormattedMessage
                                id="app.views.widgets.dashboards.team_dashboard.phase_num"
                                defaultMessage="Phase {num}"
                                values={{
                                  num: selfPhaseNumeral,
                                }}
                              />
                            </h6>
                            <ul className="list-unstyled">
                              <li className="ps-4">
                                <Link
                                  to={person.full_team_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-dark"
                                >
                                  {person.team_perf_completed_self_phase >=
                                  person.team_perf_total_eligible ? (
                                    <>
                                      <i
                                        className={
                                          ICONS.COMPLETE +
                                          ' ms-n4 mt-1 float-start'
                                        }
                                      />
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.x_of_y_completed"
                                        defaultMessage="{x} of {y} completed"
                                        values={{
                                          x: person.team_perf_completed_self_phase,
                                          y: person.team_perf_total_eligible,
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <span className="fw-bold">
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.x_of_y_completed"
                                          defaultMessage="{x} of {y} completed"
                                          values={{
                                            x: person.team_perf_completed_self_phase,
                                            y: person.team_perf_total_eligible,
                                          }}
                                        />
                                      </span>
                                    </>
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </li>
                        )}
                        {othersPhaseIndex !== -1 && (
                          <li className="mb-3">
                            <h6 className="header-pretitle mb-1">
                              <FormattedMessage
                                id="app.views.widgets.dashboards.team_dashboard.phase_num"
                                defaultMessage="Phase {num}"
                                values={{
                                  num: othersPhaseNumeral,
                                }}
                              />
                            </h6>
                            <ul className="list-unstyled">
                              <li className="ps-4">
                                <Link
                                  to={person.full_team_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-dark"
                                >
                                  {person.team_perf_completed_others_phase >=
                                  person.team_perf_total_eligible ? (
                                    <>
                                      <i
                                        className={
                                          ICONS.COMPLETE +
                                          ' ms-n4 mt-1 float-start'
                                        }
                                      ></i>
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.x_of_y_wrote_all_reviews"
                                        defaultMessage="{x} of {y} wrote all reviews"
                                        values={{
                                          x: person.team_perf_completed_others_phase,
                                          y: person.team_perf_total_eligible,
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <span className="fw-bold">
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.x_of_y_wrote_all_reviews"
                                        defaultMessage="{x} of {y} wrote all reviews"
                                        values={{
                                          x: person.team_perf_completed_others_phase,
                                          y: person.team_perf_total_eligible,
                                        }}
                                      />
                                    </span>
                                  )}
                                </Link>
                              </li>
                              <li className="ps-4">
                                <Link
                                  to={person.full_team_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-dark"
                                >
                                  {person.team_perf_completed_others_phase_alt >=
                                  person.team_perf_total_eligible ? (
                                    <>
                                      <i
                                        className={
                                          ICONS.COMPLETE +
                                          ' ms-n4 mt-1 float-start'
                                        }
                                      ></i>
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.x_of_y_received_all_reviews"
                                        defaultMessage="{x} of {y} received all reviews"
                                        values={{
                                          x: person.team_perf_completed_others_phase_alt,
                                          y: person.team_perf_total_eligible,
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <span className="fw-bold">
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.x_of_y_received_all_reviews"
                                        defaultMessage="{x} of {y} received all reviews"
                                        values={{
                                          x: person.team_perf_completed_others_phase_alt,
                                          y: person.team_perf_total_eligible,
                                        }}
                                      />
                                    </span>
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </li>
                        )}
                        {evaluationPhaseIndex !== -1 && (
                          <li className="mb-3">
                            <h6 className="header-pretitle mb-1">
                              <FormattedMessage
                                id="app.views.widgets.dashboards.team_dashboard.phase_num"
                                defaultMessage="Phase {num}"
                                values={{
                                  num: evaluationPhaseNumeral,
                                }}
                              />
                            </h6>
                            <ul className="list-unstyled">
                              {person.team_perf_total_eligible_evaluation_phase >
                              0 ? (
                                <li className="ps-4">
                                  <Link
                                    to={person.full_team_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-dark"
                                  >
                                    {person.team_perf_completed_evaluation_phase >=
                                    person.team_perf_total_eligible_evaluation_phase ? (
                                      <>
                                        <i
                                          className={
                                            ICONS.COMPLETE +
                                            ' ms-n4 mt-1 float-start'
                                          }
                                        />
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.x_of_y_completed"
                                          defaultMessage="{x} of {y} completed"
                                          values={{
                                            x: person.team_perf_completed_evaluation_phase,
                                            y: person.team_perf_total_eligible_evaluation_phase,
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <span className="fw-bold">
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.x_of_y_completed"
                                          defaultMessage="{x} of {y} completed"
                                          values={{
                                            x: person.team_perf_completed_evaluation_phase,
                                            y: person.team_perf_total_eligible_evaluation_phase,
                                          }}
                                        />
                                      </span>
                                    )}
                                  </Link>
                                </li>
                              ) : (
                                <li className="ps-4">
                                  <FormattedMessage
                                    id="app.views.widgets.dashboards.team_dashboard.no_eligible_team_members_with_direct_reports"
                                    defaultMessage="No eligible team members with direct reports"
                                  />
                                </li>
                              )}
                            </ul>
                          </li>
                        )}
                        <li>
                          <h6 className="header-pretitle mb-1">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.conversations"
                              defaultMessage="Conversations"
                            />
                          </h6>
                          <ul className="list-unstyled">
                            <li className="ps-4">
                              <Link
                                to={person.full_team_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-dark"
                              >
                                {person.team_perf_completed_reporting_phase >=
                                person.team_perf_total_eligible ? (
                                  <>
                                    <i
                                      className={
                                        ICONS.COMPLETE +
                                        ' ms-n4 mt-1 float-start'
                                      }
                                    />
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.x_of_y_completed"
                                      defaultMessage="{x} of {y} completed"
                                      values={{
                                        x: person.team_perf_completed_reporting_phase,
                                        y: person.team_perf_total_eligible,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <span className="fw-bold">
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.x_of_y_completed"
                                      defaultMessage="{x} of {y} completed"
                                      values={{
                                        x: person.team_perf_completed_reporting_phase,
                                        y: person.team_perf_total_eligible,
                                      }}
                                    />
                                  </span>
                                )}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </UncontrolledPopover>
                  )}
                </a>
              )}
          </>
        ),
        overall_tenure:
          typeof person.overall_tenure === 'undefined' ? undefined : (
            <span>
              <span id={'row-organization-tenure-' + uniqueId}>
                {!showTimeInRole &&
                  person.position_change_reason ===
                    POSITION_CHANGE_REASONS.PROMOTION && (
                    <i className={ICONS.PROMOTION} />
                  )}
                {(showTimeInRole ||
                  person.position_change_reason !==
                    POSITION_CHANGE_REASONS.PROMOTION) && (
                  <i className={ICONS.TENURE} />
                )}
                &nbsp;
                {person.overall_tenure}
              </span>
              {!showTimeInRole && person.position_change_reason && (
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'row-organization-tenure-' + uniqueId}
                >
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.role_changed_due_to"
                    defaultMessage="Role changed due to <span>{reason}</span>"
                    values={{
                      reason: getPositionChangeReasonDisplayName(
                        formatMessage,
                        person.position_change_reason
                      ),
                      span: (chunks) => (
                        <span className="fw-bold">{chunks}</span>
                      ),
                    }}
                  />
                </UncontrolledPopover>
              )}
            </span>
          ),
        role_tenure:
          typeof person.role_tenure === 'undefined' ? undefined : (
            <span>
              <span id={'row-role-tenure-' + uniqueId}>
                {person.position_change_reason ===
                  POSITION_CHANGE_REASONS.PROMOTION && (
                  <i className={ICONS.PROMOTION} />
                )}
                {person.position_change_reason !==
                  POSITION_CHANGE_REASONS.PROMOTION && (
                  <i className={ICONS.TENURE} />
                )}
                &nbsp;
                {person.role_tenure}
              </span>
              {person.position_change_reason && (
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'row-role-tenure-' + uniqueId}
                >
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.role_changed_due_to"
                    defaultMessage="Role changed due to <span>{reason}</span>"
                    values={{
                      reason: getPositionChangeReasonDisplayName(
                        formatMessage,
                        person.position_change_reason
                      ),
                      span: (chunks) => (
                        <span className="fw-bold">{chunks}</span>
                      ),
                    }}
                  />
                </UncontrolledPopover>
              )}
            </span>
          ),
        /*management_tier: (
          <span>
            <i className={ICONS.MANAGEMENT_TIER} />
            &nbsp;
            {person.management_tier}
          </span>
        ),*/
        influence: shouldShowInfluence ? (
          <InfluenceCell person={person} />
        ) : (
          <></>
        ),
        goldstars:
          showGoldStars && person ? (
            <GoldStarCell person={person} uniqueId={uniqueId} />
          ) : undefined,
        headsups:
          showHeadsUps && person ? (
            <HeadsUpCell person={person} uniqueId={uniqueId} />
          ) : undefined,
        formers: showFormers ? (
          <>
            <span id={'row-formers-' + uniqueId}>
              <i className={ICONS.FORMER_MANAGER} />
              &nbsp;
              {person.formers?.length}
            </span>
            {person.formers?.length > 0 && (
              <ScrollableUncontrolledPopover
                placement="left"
                trigger="hover"
                target={'row-formers-' + uniqueId}
              >
                <ListGroup className="list-group-flush mt-0 pt-0 mb-n3">
                  {person.formers.map((fo, index) => {
                    const fromPerson = fo.from_person;
                    return (
                      <ListGroupItem key={index} className="border-0 py-0 mb-3">
                        <Row className="flex-nowrap">
                          <Col className="col-auto">
                            <Avatar
                              person={fromPerson}
                              size="xs"
                              isExternalUrl={true}
                            />
                          </Col>
                          <Col className="col ms-n3">
                            <div className="comment-body d-block px-3 py-2 small">
                              <Row>
                                <Col>
                                  <span className="fw-bold small">
                                    <span className="text-dark">
                                      {fromPerson.full_name}
                                    </span>
                                    {fromPerson.title && (
                                      <span className="fw-normal">
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.comma"
                                          defaultMessage=","
                                        />{' '}
                                        {fromPerson.title}
                                      </span>
                                    )}
                                  </span>
                                  {fo.positive_comments && (
                                    <div className="mb-0 py-1">
                                      {fo.positive_comments}
                                    </div>
                                  )}
                                  {!fo.positive_comments && (
                                    <div className="mb-0 py-1 fst-italic">
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.no_comments_provided"
                                        defaultMessage="No comments provided"
                                      />
                                    </div>
                                  )}
                                  {fo.positive_skills?.length > 0 && (
                                    <TagsList
                                      skills={fo.positive_skills}
                                      isExternalUrl={true}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </ScrollableUncontrolledPopover>
            )}
          </>
        ) : undefined,
        upwardFeedbackAverage: showUpwardFeedbackScore ? (
          person.upwardFeedbackAverage === null ? (
            <>
              <span id={'row-upward-feedback-not-received-' + uniqueId}>
                <i className={ICONS.NOT_ELIGIBLE} />
              </span>
              <ScrollableUncontrolledPopover
                placement="left"
                trigger="hover"
                target={'row-upward-feedback-not-received-' + uniqueId}
              >
                <>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.no_feedback_received"
                    defaultMessage="No feedback received"
                  />
                </>
              </ScrollableUncontrolledPopover>
            </>
          ) : (
            <>
              <span id={'row-upward-feedback-' + uniqueId}>
                <i className={ICONS.DIRECT_REPORTS} />
                &nbsp;
                {person.upwardFeedbackAverage}
              </span>
              <ScrollableUncontrolledPopover
                placement="left"
                trigger="hover"
                target={'row-upward-feedback-' + uniqueId}
              >
                {
                  <ListGroup className="list-group-flush mt-0 pt-0 mb-n3">
                    {person.upwardFeedbackRelationshipsWithScore.map(
                      (uf, index) => {
                        const fromPerson = uf.from_person;
                        return (
                          <ListGroupItem
                            key={index}
                            className="border-0 py-0 mb-3"
                          >
                            <Row className="flex-nowrap">
                              <Col className="col-auto">
                                <Avatar
                                  person={fromPerson}
                                  size="xs"
                                  isExternalUrl={true}
                                />
                              </Col>
                              <Col className="col ms-n3">
                                <div className="comment-body d-block px-3 py-2 small">
                                  <Row>
                                    <Col>
                                      <span className="fw-bold small">
                                        <span className="text-dark">
                                          {fromPerson.full_name}
                                        </span>
                                        {fromPerson.title && (
                                          <span className="fw-normal">
                                            <FormattedMessage
                                              id="app.views.widgets.dashboards.team_dashboard.comma"
                                              defaultMessage=","
                                            />{' '}
                                            {fromPerson.title}
                                          </span>
                                        )}
                                      </span>
                                      <div className="mb-0 py-1">{`${
                                        uf.rating
                                      } (${
                                        upwardFeedbackScoreLabels[uf.rating]
                                      })`}</div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        );
                      }
                    )}
                  </ListGroup>
                }
              </ScrollableUncontrolledPopover>
            </>
          )
        ) : undefined,
        previous_final_rating:
          showPreviousFinalRating &&
          typeof person?.previous_final_rating === 'number' ? (
            <>
              <span>
                <i className={ICONS.PREVIOUS_FINAL_RATING} />
              </span>
              &nbsp;
              <span className="text-muted">{person.previous_final_rating}</span>
            </>
          ) : undefined,
        recommend_for_promotion: (
          <>
            {promotionPacketsRequired && (
              <>
                <span
                  id={'row-role-recommend-for-promo-' + uniqueId}
                  role={person?.packet?.id ? 'button' : undefined}
                  onClick={
                    person?.packet?.id
                      ? () => setCurrentPromotionPacketId(person?.packet?.id)
                      : undefined
                  }
                >
                  {person.recommend_for_promotion && (
                    <span>
                      <i className={ICONS.PROMOTION} />
                    </span>
                  )}
                  {person.packet ? (
                    <span>
                      <i
                        className={
                          // @ts-expect-error
                          PACKET_STATUSES.find(
                            (s) => s.id === person.packet?.status
                          ).icon
                        }
                      />
                    </span>
                  ) : (
                    <span>
                      {person.recommend_for_promotion && (
                        <i className="fe fe-help-circle text-muted" />
                      )}
                    </span>
                  )}
                </span>
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'row-role-recommend-for-promo-' + uniqueId}
                >
                  {person.packet && (
                    <>
                      <i
                        className={
                          'pe-2 ' +
                          // @ts-expect-error
                          PACKET_STATUSES.find(
                            (s) => s.id === person.packet?.status
                          ).icon
                        }
                      />
                      {
                        // @ts-expect-error
                        PACKET_STATUSES.find(
                          (s) => s.id === person.packet?.status
                        ).description
                      }
                      <br />
                      <br />
                      <Link
                        to={
                          PROMOTION_PACKETS(formatMessage).path +
                          '/' +
                          person.packet.id
                        }
                        target={'_blank'}
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.open_packet_in_new_window"
                          defaultMessage="Open packet in new window"
                        />{' '}
                        <i className="fe fe-external-link" />
                      </Link>
                    </>
                  )}
                  {!person.packet && (
                    <>
                      <i className="fe fe-help-circle text-muted pe-2" />
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.no_promo_packet_created_yet"
                        defaultMessage="No promotion packet has been created yet."
                      />
                      <br />
                      <br />
                      <Link
                        to={PROMOTION_PACKETS(formatMessage).path}
                        target={'_blank'}
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.create_promo_packet_action"
                          defaultMessage="Create promotion packet"
                        />{' '}
                        <i className="fe fe-external-link" />
                      </Link>
                    </>
                  )}
                </UncontrolledPopover>
              </>
            )}
            {!promotionPacketsRequired &&
              showPromo &&
              person.recommend_for_promotion && (
                <>
                  <span id={'row-role-recommend-for-promo-' + uniqueId}>
                    <i className={ICONS.PROMOTION} />
                  </span>
                  {person.promotion_comments?.length > 0 && (
                    <ScrollableUncontrolledPopover
                      placement="left"
                      trigger="hover"
                      target={'row-role-recommend-for-promo-' + uniqueId}
                    >
                      {person.promotion_comments}
                    </ScrollableUncontrolledPopover>
                  )}
                </>
              )}
          </>
        ),
        recommend_for_salary_increase: (
          <>
            {showSalaryIncrease && person.recommend_for_salary_increase && (
              <>
                <span id={'row-role-recommend-for-salary-increase-' + uniqueId}>
                  <i className={ICONS.SALARY_INCREASE} />
                </span>
                {person.salary_increase_comments?.length > 0 && (
                  <ScrollableUncontrolledPopover
                    placement="left"
                    trigger="hover"
                    target={
                      'row-role-recommend-for-salary-increase-' + uniqueId
                    }
                  >
                    {person.salary_increase_comments}
                  </ScrollableUncontrolledPopover>
                )}
              </>
            )}
          </>
        ),
        draft_rating: showDraftRating ? (
          <>
            {person.draft_rating === RATING_NOT_PARTICIPATING && (
              <>
                <span id={'row-role-draft-rating-not-eligible-' + uniqueId}>
                  <i className={ICONS.NOT_ELIGIBLE} />
                </span>
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'row-role-draft-rating-not-eligible-' + uniqueId}
                >
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.not_eligible_to_participate_in_this_cycle"
                    defaultMessage="Not eligible to participate in this cycle"
                  />
                </UncontrolledPopover>
              </>
            )}
            {person.draft_rating === RATING_NOT_ELIGIBLE_TO_MANAGER_REVIEW && (
              <>
                <span
                  id={
                    'row-role-draft-rating-not-eligible-to-manager-review-' +
                    uniqueId
                  }
                >
                  <i className={ICONS.NOT_ELIGIBLE} />
                </span>
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={
                    'row-role-draft-rating-not-eligible-to-manager-review-' +
                    uniqueId
                  }
                >
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.not_eligible_to_manager_review"
                    defaultMessage="This person was manually marked as ineligible to receive a manager evaluation."
                  />
                </UncontrolledPopover>
              </>
            )}
            {![
              RATING_NOT_PARTICIPATING,
              RATING_NOT_ELIGIBLE_TO_MANAGER_REVIEW,
            ].includes(person.draft_rating) && (
              <>
                {!person.is_rated &&
                  person.draft_rating !== RATING_NOT_ALLOWED && (
                    <>
                      <h4
                        id={'unrated-is-rated-' + uniqueId}
                        className={ICONS.ALERT + ' position-absolute'}
                        style={{ marginLeft: '-1.0rem', marginTop: '-0.1rem' }}
                      ></h4>
                      <UncontrolledPopover
                        placement="left"
                        trigger="hover"
                        target={'unrated-is-rated-' + uniqueId}
                      >
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.default_zero_manager_gave_anyway"
                          defaultMessage="This person was marked to receive no rating but the manager gave a rating anyway."
                        />
                      </UncontrolledPopover>
                    </>
                  )}
                <span id={'row-draft-rating-' + uniqueId}>
                  <span className="fw-bold">
                    <span>
                      <i className={ICONS.DRAFT_RATING} />
                    </span>
                    &nbsp;
                    {getRatingDisplayComponent(
                      person.draft_rating,
                      false,
                      uniqueId,
                      formatMessage
                    )}
                  </span>
                </span>
                {person.draft_rating_provided_by && (
                  <ScrollableUncontrolledPopover
                    placement="left"
                    trigger="hover"
                    target={'row-draft-rating-' + uniqueId}
                  >
                    <Row className="flex-nowrap">
                      <Col className="col-auto">
                        <Avatar
                          person={person.draft_rating_provided_by}
                          size="xs"
                          isExternalUrl={true}
                        />
                      </Col>
                      <Col className="col ms-n3">
                        <div className="comment-body d-block px-3 py-2 small">
                          <Row>
                            <Col>
                              <span className="fw-bold small">
                                <span className="text-dark">
                                  {person.draft_rating_provided_by.full_name}
                                </span>
                                {person.draft_rating_provided_by.title && (
                                  <span className="fw-normal">
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.comma"
                                      defaultMessage=","
                                    />{' '}
                                    {person.draft_rating_provided_by.title}
                                  </span>
                                )}
                              </span>
                              {person.draft_rating_comments && (
                                <div className="mb-0 py-1">
                                  {person.draft_rating_comments}
                                </div>
                              )}
                              {!person.draft_rating_comments && (
                                <div className="mb-0 py-1 fst-italic">
                                  <FormattedMessage
                                    id="app.views.widgets.dashboards.team_dashboard.no_comments_provided"
                                    defaultMessage="No comments provided"
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </ScrollableUncontrolledPopover>
                )}
              </>
            )}
          </>
        ) : undefined,
        final_rating: showFinalRating ? (
          <>
            {!isRealNumericRating(person?.final_rating) &&
              getRatingDisplayComponent(
                person?.final_rating,
                true,
                uniqueId,
                formatMessage
              )}
            {isRealNumericRating(person?.final_rating) && (
              <>
                {(finalRatingIsReadOnly || person?.released_at) && (
                  <>
                    <span id={'row-rating-' + uniqueId}>
                      <span>
                        <i className={ICONS.FINAL_RATING} />
                      </span>
                      &nbsp;
                      {getRatingDisplayComponent(
                        person?.final_rating,
                        true,
                        uniqueId,
                        formatMessage
                      )}
                    </span>
                    {person?.final_rating_provided_by_person && (
                      <ScrollableUncontrolledPopover
                        placement="left"
                        trigger="hover"
                        target={'row-rating-' + uniqueId}
                      >
                        <Row className="flex-nowrap">
                          <Col className="col-auto">
                            <Avatar
                              person={person?.final_rating_provided_by_person}
                              size="xs"
                              isExternalUrl={true}
                            />
                          </Col>
                          <Col className="col ms-n3">
                            <div className="comment-body d-block px-3 py-2 small">
                              <Row>
                                <Col>
                                  <span className="fw-bold small">
                                    <span className="text-dark">
                                      {
                                        person?.final_rating_provided_by_person
                                          .full_name
                                      }
                                    </span>
                                    {person?.final_rating_provided_by_person
                                      .title && (
                                      <span className="fw-normal">
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.comma"
                                          defaultMessage=","
                                        />{' '}
                                        {
                                          person
                                            ?.final_rating_provided_by_person
                                            .title
                                        }
                                      </span>
                                    )}
                                  </span>
                                  {person?.final_rating_comments && (
                                    <div className="mb-0 py-1">
                                      {person?.final_rating_comments}
                                    </div>
                                  )}
                                  {!person?.final_rating_comments && (
                                    <div className="mb-0 py-1 fst-italic">
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.no_comments_provided"
                                        defaultMessage="No comments provided"
                                      />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </ScrollableUncontrolledPopover>
                    )}
                  </>
                )}
                {ratings && !finalRatingIsReadOnly && !person?.released_at && (
                  <>
                    <div id={'row-rating-' + uniqueId}>
                      <ObjectsDropdown
                        onlyAllowProgrammaticChanges={true}
                        buttonClassName="btn-sm"
                        selectText="?"
                        objects={ratings.map((r) => ({
                          ...r,
                          name: r.value?.toString(),
                        }))}
                        value={person?.final_rating?.toString()}
                        onChange={(e) =>
                          onUpdateFinalRating(
                            person,
                            parseFloat(e.target.value),
                            person?.final_rating_comments
                          )
                        }
                      />
                    </div>
                    {person?.final_rating_provided_by_person && (
                      <ScrollableUncontrolledPopover
                        placement="left"
                        trigger="hover"
                        target={'row-rating-' + uniqueId}
                      >
                        <Row className="flex-nowrap">
                          <Col className="col-auto">
                            <Avatar
                              person={person?.final_rating_provided_by_person}
                              size="xs"
                              isExternalUrl={true}
                            />
                          </Col>
                          <Col className="col ms-n3">
                            <div className="comment-body d-block px-3 py-2 small">
                              <Row>
                                <Col>
                                  <span className="fw-bold small">
                                    <span className="text-dark">
                                      {
                                        person?.final_rating_provided_by_person
                                          .full_name
                                      }
                                    </span>
                                    {person?.final_rating_provided_by_person
                                      .title && (
                                      <span className="fw-normal">
                                        <FormattedMessage
                                          id="app.views.widgets.dashboards.team_dashboard.comma"
                                          defaultMessage=","
                                        />{' '}
                                        {
                                          person
                                            ?.final_rating_provided_by_person
                                            .title
                                        }
                                      </span>
                                    )}
                                  </span>
                                  {person?.final_rating_comments && (
                                    <div className="mb-0 py-1">
                                      {person?.final_rating_comments}
                                    </div>
                                  )}
                                  {!person?.final_rating_comments && (
                                    <div className="mb-0 py-1 fst-italic">
                                      <FormattedMessage
                                        id="app.views.widgets.dashboards.team_dashboard.no_comments_provided"
                                        defaultMessage="No comments provided"
                                      />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </ScrollableUncontrolledPopover>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : undefined,
        final_rating_provided_by_person:
          person?.final_rating_provided_by_person ? (
            <Avatar
              person={person?.final_rating_provided_by_person}
              size="xs"
              isExternalUrl={true}
            />
          ) : undefined,
        draft_to_final_delta: showFinalRatingDeltas ? (
          <>
            {person?.draft_to_final_delta > 0 ? '+' : ''}
            {person?.draft_to_final_delta}
          </>
        ) : undefined,
        previous_to_final_delta: showPreviousFinalRatingDeltas ? (
          <span className="text-muted">
            {person?.previous_to_final_delta > 0 ? '+' : ''}
            {person?.previous_to_final_delta}
          </span>
        ) : undefined,
        share_status: showShareAction ? (
          <>
            {person?.share_status < 0 && (
              <>
                <span id={'row-role-final-rating-not-eligible-' + uniqueId}>
                  <i className={ICONS.NOT_ELIGIBLE} />
                </span>
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'row-role-final-rating-not-eligible-' + uniqueId}
                >
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.not_eligible_to_receive_report"
                    defaultMessage="Not eligible to receive report"
                  />
                </UncontrolledPopover>
              </>
            )}
            {person?.share_status >= 0 && (
              <>
                {person?.released_at ? (
                  <>
                    {disableConversationAcknowledgements && (
                      <>
                        <i
                          id={'row-convo-rating-' + uniqueId}
                          className={ICONS.ACKNOWLEDGED}
                        />
                        <UncontrolledPopover
                          placement="left"
                          trigger="hover"
                          target={'row-convo-rating-' + uniqueId}
                        >
                          <FormattedMessage
                            id="app.views.widgets.dashboards.team_dashboard.report_released"
                            defaultMessage="Report released"
                          />
                        </UncontrolledPopover>
                      </>
                    )}
                    {!disableConversationAcknowledgements &&
                      !person.conversation_acknowledged_at && (
                        <>
                          <i
                            id={'row-convo-not-acknowledged-' + uniqueId}
                            className={ICONS.NOT_ACKNOWLEDGED}
                          />
                          <UncontrolledPopover
                            placement="left"
                            trigger="hover"
                            target={'row-convo-not-acknowledged-' + uniqueId}
                          >
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.converasation_not_yet_acknowledged"
                              defaultMessage="Conversation not yet acknowledged by participant"
                            />
                          </UncontrolledPopover>
                        </>
                      )}
                    {!disableConversationAcknowledgements &&
                      person.conversation_acknowledged_at && (
                        <>
                          {!person.conversation_rating && (
                            <>
                              <i
                                id={'row-convo-rating-' + uniqueId}
                                className={ICONS.ACKNOWLEDGED}
                              />
                              <UncontrolledPopover
                                placement="left"
                                trigger="hover"
                                target={'row-convo-rating-' + uniqueId}
                              >
                                <FormattedMessage
                                  id="app.views.widgets.dashboards.team_dashboard.conversation_acknowledged_by_participant"
                                  defaultMessage="Conversation acknowledged by participant"
                                />
                              </UncontrolledPopover>
                            </>
                          )}
                          {person.conversation_rating && (
                            <>
                              <span id={'row-convo-rating-' + uniqueId}>
                                {person.conversation_rating}
                              </span>
                              <UncontrolledPopover
                                placement="left"
                                trigger="hover"
                                target={'row-convo-rating-' + uniqueId}
                              >
                                <FormattedMessage
                                  id="app.views.widgets.dashboards.team_dashboard.likert_rating_range"
                                  defaultMessage="Likert rating (1 = unhelpful, 5 = helpful)"
                                />
                              </UncontrolledPopover>
                            </>
                          )}
                          {person.conversation_comments && (
                            <>
                              <i
                                id={'row-conversation-comments-' + uniqueId}
                                className={ICONS.CONVERSATION + ' ms-2'}
                              />
                              <ScrollableUncontrolledPopover
                                placement="left"
                                trigger="hover"
                                target={'row-conversation-comments-' + uniqueId}
                              >
                                <Row className="flex-nowrap">
                                  <Col className="col-auto">
                                    <Avatar
                                      person={person}
                                      size="xs"
                                      isExternalUrl={true}
                                    />
                                  </Col>
                                  <Col className="col ms-n3">
                                    <div className="comment-body d-block px-3 py-2 small">
                                      <Row>
                                        <Col>
                                          <span className="fw-bold small">
                                            <span className="text-dark">
                                              {person.full_name}
                                            </span>
                                            {person.title && (
                                              <span className="fw-normal">
                                                <FormattedMessage
                                                  id="app.views.widgets.dashboards.team_dashboard.comma"
                                                  defaultMessage=","
                                                />{' '}
                                                {person.title}
                                              </span>
                                            )}
                                          </span>
                                          <div className="mb-0 py-1">
                                            {person.conversation_comments}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </ScrollableUncontrolledPopover>
                            </>
                          )}
                        </>
                      )}
                  </>
                ) : person?.withhold_report_release ? (
                  <>
                    <span
                      id={'row-rating-withheld-' + uniqueId}
                      className={ICONS.ON_HOLD}
                    />
                    <UncontrolledPopover
                      placement="left"
                      trigger="hover"
                      target={'row-rating-withheld-' + uniqueId}
                    >
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.release_on_hold"
                        defaultMessage="
                      The release of this person's report is currently on
                      hold.
                    "
                      />
                    </UncontrolledPopover>
                  </>
                ) : person?.is_active_and_participating_in_self_phase ||
                  person?.is_only_receiving_review ? (
                  <Link
                    // @ts-expect-error
                    tag="button"
                    className="btn btn-sm btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={person.url + '/performance'}
                  >
                    <i className={ICONS.RELEASE_REPORT} />
                  </Link>
                ) : (
                  <i className={ICONS.NOT_ELIGIBLE} />
                )}
              </>
            )}
          </>
        ) : undefined,
        incoming_peers: (
          <>
            {person.is_receiving_peer_reviews && (
              <TeamDashboardEditablePeerList
                peers={person.incoming_peers}
                // @ts-expect-error
                organizationId={props.currentOrganization?.id}
                campaign={campaign}
                relationships={data?.relationships}
                targetPersonId={person.id}
                type={'incoming'}
                editTitle={formatMessage({
                  id: 'app.views.widgets.dashboards.team_dashboard.incoming_peers.edit_title',
                  defaultMessage: `Edit incoming peers`,
                })}
                readonly={!allowPeersEditing}
                callback={updatePeerRelationships}
                people={people}
              />
            )}
            {!person.is_receiving_peer_reviews && (
              <>
                <span id={'row-peers-in-not-eligible-' + uniqueId}>
                  <i className="fe fe-user-x text-muted" />
                </span>
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'row-peers-in-not-eligible-' + uniqueId}
                >
                  <FormattedMessage
                    id="app.views.performance.performance_administration_peer_selection.not_eligible_to_participate"
                    defaultMessage="
                  Not eligible to participate in this cycle
                "
                  />
                </UncontrolledPopover>
              </>
            )}
          </>
        ),
        outgoing_peers: (
          <>
            {person.is_writing_peer_reviews && (
              <TeamDashboardEditablePeerList
                peers={person.outgoing_peers}
                // @ts-expect-error
                organizationId={props.currentOrganization?.id}
                campaign={campaign}
                relationships={data?.relationships}
                targetPersonId={person.id}
                type={'outgoing'}
                editTitle={formatMessage({
                  id: 'app.views.widgets.dashboards.team_dashboard.outgoing_peers.edit_title',
                  defaultMessage: `Edit outgoing peers`,
                })}
                readonly={!allowPeersEditing}
                callback={updatePeerRelationships}
                people={people}
              />
            )}
            {!person.is_writing_peer_reviews && (
              <>
                <span id={'row-peers-out-not-eligible-' + uniqueId}>
                  <i className="fe fe-user-x text-muted" />
                </span>
                <UncontrolledPopover
                  placement="left"
                  trigger="hover"
                  target={'row-peers-out-not-eligible-' + uniqueId}
                >
                  <FormattedMessage
                    id="app.views.performance.performance_administration_peer_selection.not_eligible_to_participate"
                    defaultMessage="
                  Not eligible to participate in this cycle
                "
                  />
                </UncontrolledPopover>
              </>
            )}
          </>
        ),
      };

      if (person.sensitive_data_omitted) {
        for (const key in dataOut) {
          if (
            !WHITELISTED_FIELDS_FOR_SENSITIVE_DATA_OMISSION.includes(key) &&
            !!dataOut[key]
          ) {
            const omittedId = `${OMITTED_FIELD_KEY_PREFIX}${key}-${uniqueId}`;
            dataOut[key] = (
              <OmittedElement
                key={omittedId}
                id={omittedId}
                className="ms-1 text-muted"
              />
            );
          }
        }
      }

      return dataOut;
    },
    [
      showCalibrationFlags,
      finalRatingIsReadOnly,
      toggleSelectedRowField,
      currentPhaseIndex,
      selfPhaseIndex,
      evaluationPhaseIndex,
      othersPhaseIndex,
      othersPhaseShowAltProgressBars,
      reportingPhaseIndex,
      disableConversationAcknowledgements,
      isEngagementSurveyOnly,
      showTimeInRole,
      shouldShowInfluence,
      showGoldStars,
      showHeadsUps,
      showFormers,
      showUpwardFeedbackScore,
      showPreviousFinalRating,
      promotionPacketsRequired,
      showPromo,
      showSalaryIncrease,
      showDraftRating,
      showFinalRating,
      ratings,
      showPreviousFinalRatingDeltas,
      showFinalRatingDeltas,
      showShareAction,
      onUpdateFinalRating,
      upwardFeedbackScoreLabels,
      formatMessage,
      allowPeersEditing,
      campaign,
      props.currentOrganization?.id,
      updatePeerRelationships,
      data?.relationships,
      people,
      selfPhaseNumeral,
      othersPhaseNumeral,
      evaluationPhaseNumeral,
    ]
  );

  const toggleSortByFields = useCallback(
    (fields) => {
      resetTableLimit();
      // map UI field to appropriate internal field
      if (fields[0] === 'person') {
        fields = ['given_name', 'family_name'];
      } else if (fields[0] === 'manager') {
        fields = ['manager_full_name'];
      } else if (fields[0] === 'final_rating_provided_by_person') {
        fields = ['final_rating_provided_by_person_full_name'];
      } else if (fields[0] === 'recommend_for_promotion') {
        fields = ['recommend_for_promotion_sort_value'];
      } else if (fields[0] === 'recommend_for_salary_increase') {
        fields = ['recommend_for_salary_increase_sort_value'];
      } else if (fields[0] === 'incoming_peers') {
        fields = ['incoming_peers_sort_value'];
      } else if (fields[0] === 'outgoing_peers') {
        fields = ['outgoing_peers_sort_value'];
      } else if (fields[0] === 'perf_progress') {
        fields = [
          currentPhaseIndex === selfPhaseIndex
            ? 'perf_progress_self_phase'
            : currentPhaseIndex === othersPhaseIndex
            ? 'perf_progress_others_phase'
            : // keep tracking evaluation phase during calibration phase
            currentPhaseIndex === evaluationPhaseIndex ||
              currentPhaseIndex === calibrationPhaseIndex
            ? 'perf_progress_evaluation_phase'
            : // @ts-expect-error
            currentPhaseIndex >= reportingPhaseIndex
            ? 'perf_progress_reporting_phase'
            : undefined,
        ];
      } else if (fields[0] === 'team_perf_completed') {
        fields = [
          currentPhaseIndex === selfPhaseIndex
            ? 'team_perf_completed_self_phase_percent'
            : currentPhaseIndex === othersPhaseIndex
            ? 'team_perf_completed_others_phase_percent'
            : // keep tracking evaluation phase during calibration phase
            currentPhaseIndex === evaluationPhaseIndex ||
              currentPhaseIndex === calibrationPhaseIndex
            ? 'team_perf_completed_evaluation_phase_percent'
            : // @ts-expect-error
            currentPhaseIndex >= reportingPhaseIndex
            ? 'team_perf_completed_reporting_phase_percent'
            : undefined,
        ];
      } else if (fields[0] === 'current_calibration_flag') {
        fields = ['calibration_status'];
      } else if (fields[0] === 'original_calibration_flag') {
        fields = [
          'calibration_flag_sort_primary',
          'calibration_flag_sort_secondary',
        ];
      }

      if (isEqual(sortByFields, fields)) {
        setSortAtoZ(!sortAtoZ);
      } else {
        // default sort for perf progress, tenure, gold stars, and heads up should
        // be Z to A first
        setSortAtoZ(
          fields[0] === 'perf_progress_self_phase' ||
            fields[0] === 'perf_progress_others_phase' ||
            fields[0] === 'perf_progress_evaluation_phase' ||
            fields[0] === 'perf_progress_calibration_phase' ||
            fields[0] === 'perf_progress_reporting_phase' ||
            fields[0] === 'team_perf_completed_self_phase_percent' ||
            fields[0] === 'team_perf_completed_others_phase_percent' ||
            fields[0] === 'team_perf_completed_evaluation_phase_percent' ||
            fields[0] === 'team_perf_completed_calibration_phase_percent' ||
            fields[0] === 'team_perf_completed_reporting_phase_percent' ||
            fields[0] === 'overall_tenure' ||
            fields[0] === 'role_tenure' ||
            fields[0] === 'recommend_for_promotion' ||
            fields[0] === 'recommend_for_salary_increase' ||
            fields[0] === 'influence' ||
            fields[0] === 'goldstars' ||
            fields[0] === 'headsups' ||
            fields[0] === 'formers' ||
            fields[0] === 'draft_rating' ||
            fields[0] === 'previous_final_rating' ||
            fields[0] === 'final_rating' ||
            fields[0] === 'previous_to_final_delta' ||
            fields[0] === 'draft_to_final_delta' ||
            fields[0] === 'share_status' ||
            fields[0] === 'upwardFeedbackAverage' ||
            fields[0] === 'calibration_flag_sort_primary'
            ? false
            : true
        );

        setSortByFields(fields);
      }
    },
    [
      calibrationPhaseIndex,
      currentPhaseIndex,
      evaluationPhaseIndex,
      othersPhaseIndex,
      reportingPhaseIndex,
      selfPhaseIndex,
      sortAtoZ,
      sortByFields,
      resetTableLimit,
    ]
  );

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    // fetch people from the backend based on your permissions
    if (typeof data === 'undefined' && props.currentOrganization?.id) {
      // For demo orgs, we use the anonymized data file
      if (props.currentOrganization.status === CAMPAIGN_STATUSES.DEMO) {
        fetchTestData(props.currentOrganization.name).then((d) => {
          setData(d);
          setPersonDatasourceCampaign(d.campaign);
        });
      } else {
        ConfirmAPI.getUrlWithCache(
          '/performance/admin/get-current-campaign',
          'performance-admin-get-current-campaign',
          null, // this is too much data, don't cache it
          null,
          {
            organization: props.currentOrganization?.id,
            // @ts-expect-error
            proxy: props.currentProxyPerson
              ? // @ts-expect-error
                props.currentProxyPerson.email
              : undefined,
            // @ts-expect-error
            campaign: personDatasourceCampaign?.id,
            include_terminated_people: true,
            include_completion_stats: true,
            include_promo_packets: true,
            include_ona_sources: true,
            include_previous_ratings: true,
          },
          (data) => {
            if (isMounted) {
              if (data) {
                setData(data);

                // set dropdown value to "right now" if this is the newest
                // campaign and is either over or not yet started, so the latest
                // managers and metadata show
                //
                // otherwise set dropdown value to the latest campaign
                if (data.campaign) {
                  setPersonDatasourceCampaign(data.campaign);
                }
              } else {
                setData(null);
              }
            }
          },
          (message) => {
            setErrorMessage(message);
          }
        );
      }
    }
  }, [
    isMounted,
    people,
    props.currentOrganization,
    sortByFields,
    // @ts-expect-error
    props.currentProxyPerson,
    personDatasourceCampaign,
    data,
    props.campaignIndex,
  ]);

  useEffect(() => {
    setData(undefined);
  }, [props.reload]);

  const filteredPeople = useMemo(() => {
    // only process the below when we have everything we need
    if (
      typeof currentUserPersonWithManager === 'undefined' ||
      !(people?.length > 0)
    ) {
      return [];
    }

    // filter out ineligible people if that toggle is off
    const peopleWithEligibilityAndTerminationAccountedFor = people.filter(
      (p) =>
        (showIneligiblePeople || p.is_active_and_participating_in_any_phase) &&
        (showTerminatedPeople || isCurrentEmployee(p.current_status))
    );

    // first filter by tier if tier filter exists
    const tieredPeopleWithoutTags =
      filterTiers.length > 0
        ? peopleWithEligibilityAndTerminationAccountedFor.filter(
            // @ts-expect-error
            (p) => filterTiers.indexOf(p.management_tier) !== -1
          )
        : peopleWithEligibilityAndTerminationAccountedFor;

    // then filter by any filter tags set
    if (!(includeFilters?.length > 0) && !(excludeFilters?.length > 0)) {
      return tieredPeopleWithoutTags;
    }

    const peopleToCheck = currentUserPersonWithManager
      ? [
          currentUserPersonWithManager,
          ...peopleWithEligibilityAndTerminationAccountedFor,
        ]
      : peopleWithEligibilityAndTerminationAccountedFor;

    const tieredPeople =
      includeFilters?.length > 0
        ? tieredPeopleWithoutTags.filter((p) =>
            // include current user and their manager manually to ensure that
            // any management chain viewing where an admin user wants to see the full
            // team of a person above them can see the people below the admin user
            // (this is an issue related to people not being able to see themselves
            // in the data returned for privacy reasons)
            personMatchesFilters(p, includeFilters, peopleToCheck, false)
          )
        : tieredPeopleWithoutTags;

    const tieredPeopleWithExcludes =
      excludeFilters?.length > 0
        ? tieredPeople.filter(
            (p) =>
              // include current user and their manager manually to ensure that
              // any management chain viewing where an admin user wants to see the full
              // team of a person above them can see the people below the admin user
              // (this is an issue related to people not being able to see themselves
              // in the data returned for privacy reasons)
              !personMatchesFilters(p, excludeFilters, peopleToCheck, true)
          )
        : tieredPeople;

    return tieredPeopleWithExcludes;
  }, [
    currentUserPersonWithManager,
    filterTiers,
    includeFilters,
    excludeFilters,
    people,
    showIneligiblePeople,
    showTerminatedPeople,
  ]);

  const averages = useMemo(() => {
    const counts = {
      influence: [0, 0],
      goldstars: [0, 0],
      headsups: [0, 0],
    };
    filteredPeople.map((x) => {
      counts.goldstars[0] += x.goldstars_count;
      counts.goldstars[1]++;
      counts.influence[0] += x.influence_count;
      counts.influence[1]++;
      counts.headsups[0] += x.headsups_count;
      counts.headsups[1]++;
    });
    return {
      goldstars: numberWithCommas(counts.goldstars[0] / counts.goldstars[1], 1),
      headsups: numberWithCommas(counts.headsups[0] / counts.headsups[1], 1),
      influence: numberWithCommas(counts.influence[0] / counts.influence[1], 1),
    };
  }, [filteredPeople]);

  const sortedFilteredPeople = useMemo(() => {
    if (sortByFields) {
      return [...filteredPeople].sort((a, b) =>
        sortByFieldFunc(a, b, sortByFields, !sortAtoZ)
      );
    } else {
      return filteredPeople;
    }
  }, [filteredPeople, sortAtoZ, sortByFields]);

  const calculateTablePeople = useCallback(
    (sortedFilteredPeople, getTablePersonFromPerson) => {
      if (!sortedFilteredPeople) {
        return null;
      }

      if (sortedFilteredPeople.length === 0) {
        return [];
      }

      return sortedFilteredPeople.map(getTablePersonFromPerson);
    },
    []
  );

  const tablePeople = useMemo(() => {
    const calculatedTablePeople = calculateTablePeople(
      sortedFilteredPeople,
      getTablePersonFromPerson
    );
    setFilteringInProgress(false);
    return calculatedTablePeople;
  }, [calculateTablePeople, getTablePersonFromPerson, sortedFilteredPeople]);

  const atLeastOneRowIsSelectable = useMemo(() => {
    return sortedFilteredPeople?.some((r) => isRowSelectable(r));
  }, [sortedFilteredPeople, isRowSelectable]);

  const allSelectableRowsAreSelected = useMemo(() => {
    const selectableFilteredRows = sortedFilteredPeople?.filter((r) =>
      isRowSelectable(r)
    );

    // if every row is selected, return true, else false
    return (
      selectableFilteredRows?.length > 0 &&
      selectableFilteredRows?.every((row) =>
        selectedRowIdsSet?.has(getToggleField(row))
      )
    );
  }, [
    getToggleField,
    isRowSelectable,
    selectedRowIdsSet,
    sortedFilteredPeople,
  ]);

  const toggleAllSelectableRows = useCallback(() => {
    if (allSelectableRowsAreSelected) {
      // deselect all
      setSelectedRowIdsSet(new Set());
    } else {
      // select all
      setSelectedRowIdsSet(
        new Set(
          sortedFilteredPeople
            ?.filter((r) => isRowSelectable(r))
            ?.map((r) => getToggleField(r))
        )
      );
    }
  }, [
    allSelectableRowsAreSelected,
    getToggleField,
    isRowSelectable,
    sortedFilteredPeople,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    throttle(() => {
      const peopleTable = document.getElementById('people-table');
      if (!peopleTable || tableLimit >= tablePeople.length) {
        return;
      }
      // ensure that the bottom is always about a full screen height away
      // for cleaner scrolling and never seeing the bottom
      const atBottom =
        window.innerHeight * 2 + window.scrollY >= document.body.offsetHeight;
      const rect = peopleTable.getBoundingClientRect();
      if (atBottom || rect.bottom <= 100) {
        setTableLimit(
          tableLimit + TABLE_INFINITE_LOAD_INCREMENT * tableLimitMultiplyFactor
        );
        // max loads exponentially load more to make the
        // of someone wanting to scroll to the bottom feel
        // less frustrating while still benefitting from quick
        // loads on filters and reloads
        setTableLimitMultiplyFactor(tableLimitMultiplyFactor * 2);
      }
    }, TABLE_INFINITE_LOAD_INTERVAL),
    [tableLimit, tablePeople.length]
  );

  const maxTier = useMemo(
    () =>
      people
        ? people.reduce((max, p) => {
            if (p.management_tier > max) {
              return p.management_tier;
            } else {
              return max;
            }
          }, 0)
        : undefined,
    [people]
  );

  const minTier = useMemo(
    () =>
      people
        ? people.reduce((min, p) => {
            if (p.management_tier < min) {
              return p.management_tier;
            } else {
              return min;
            }
          }, maxTier)
        : undefined,
    [people, maxTier]
  );

  const tiersList = useMemo(() => {
    if (maxTier) {
      const tiers = [];
      for (let i = minTier; i <= maxTier; i++) {
        // @ts-expect-error
        tiers.push(i);
      }
      // 1 should be ground floor
      tiers.reverse();
      return tiers;
    } else {
      return undefined;
    }
  }, [maxTier, minTier]);

  const numRatingsIncomplete = useMemo(
    () =>
      filteredPeople.filter((p) => p.final_rating === RATING_NOT_YET_COMPLETE)
        ?.length,
    [filteredPeople]
  );

  const previouslyRatedPeopleCount = useMemo(() => {
    return filteredPeople.filter(
      (p) =>
        // look among those who are eligible for a rating
        typeof p.previous_final_rating === 'number' &&
        p.previous_final_rating != RATING_NOT_PARTICIPATING &&
        p.previous_final_rating != RATING_IGNORE_DUE_TO_NO_MANAGER
    )?.length;
  }, [filteredPeople]);

  const currentlyRatedPeopleCount = useMemo(() => {
    return filteredPeople.filter(
      (p) =>
        // look among those who are eligible for a rating
        typeof p.draft_rating === 'number' &&
        p.draft_rating != RATING_NOT_PARTICIPATING &&
        p.draft_rating != RATING_IGNORE_DUE_TO_NO_MANAGER
    )?.length;
  }, [filteredPeople]);

  const normalizeDatapoint = useCallback(
    (datapoint, denominator) => ({
      x: datapoint.x,
      // have the actual datapoint be a percentage, but keep the total as a z value to reference
      y: Math.round((100.0 * (datapoint.y * 1.0)) / (denominator * 1.0)),
      metadata: {
        numerator: datapoint.y,
        denominator: denominator,
      },
    }),
    []
  );

  const performancePreviousRatingHistogramData = useMemo(() => {
    if (!(filteredPeople?.length > 0) || !showPreviousFinalRating) {
      return undefined;
    }

    return ratings
      .map((r) => ({
        x: r.value,
        y: filteredPeople.filter((p) => p.previous_final_rating === r.value)
          ?.length,
      }))
      .map((x) => normalizeDatapoint(x, previouslyRatedPeopleCount));
  }, [
    filteredPeople,
    normalizeDatapoint,
    previouslyRatedPeopleCount,
    ratings,
    showPreviousFinalRating,
  ]);

  const performanceDraftRatingHistogramData = useMemo(() => {
    if (!(filteredPeople?.length > 0) || !ratings) {
      return undefined;
    }

    return [
      ...ratings.map((r) => ({
        x: r.value,
        y: filteredPeople.filter((p) => p.draft_rating === r.value)?.length,
      })),
      // only show incomplete count if there is at least one that is incomplete
      ...(numRatingsIncomplete > 0
        ? [
            {
              x: -1,
              y: numRatingsIncomplete,
            },
          ]
        : []),
    ].map((x) => normalizeDatapoint(x, currentlyRatedPeopleCount));
  }, [
    currentlyRatedPeopleCount,
    filteredPeople,
    normalizeDatapoint,
    numRatingsIncomplete,
    ratings,
  ]);

  const performanceFinalRatingHistogramData = useMemo(() => {
    if (!(filteredPeople?.length > 0) || !ratings) {
      return undefined;
    }

    return [
      ...ratings.map((r) => ({
        x: r.value,
        y: filteredPeople.filter((p) => p?.final_rating === r.value)?.length,
      })),
      // only show incomplete count if there is at least one that is incomplete
      ...(numRatingsIncomplete > 0
        ? [
            {
              x: -1,
              y: numRatingsIncomplete,
            },
          ]
        : []),
    ].map((x) => normalizeDatapoint(x, currentlyRatedPeopleCount));
  }, [
    currentlyRatedPeopleCount,
    filteredPeople,
    normalizeDatapoint,
    numRatingsIncomplete,
    ratings,
  ]);

  // filter tag elements
  const tagsAreEqual = useCallback((a, b) => {
    if (a?._index !== b?._index) {
      return false;
    }

    if (isPersonType(a._index)) {
      return peopleObjectsAreEqual(a, b);
    }

    return a === b;
  }, []);

  const suggestions = useMemo(() => {
    if (!(people?.length > 0)) {
      return [];
    }

    const suggestionLists = people.reduce(
      (acc, p) => {
        for (const key in acc) {
          if (key === 'people') {
            // pass in entire person object
            acc[key].push(p);
          } else if (key === 'manager_or_above') {
            // we extract this one from the manager field
            if (
              p['manager'] &&
              acc['manager_or_above'].findIndex((v) =>
                itemsForFieldNameAreEqual(key, v, p['manager'])
              ) === -1
            ) {
              acc[key].push(p['manager']);
            }
          } else if (key === 'calibration_text') {
            p['calibration_text'].forEach((v) => {
              if (!acc['calibration_text'].includes(v)) {
                acc[key].push(v);
              }
            });
          } else {
            if (
              p[key] &&
              acc[key].findIndex((v) =>
                itemsForFieldNameAreEqual(key, v, p[key])
              ) === -1
            ) {
              acc[key].push(p[key]);
            }
          }
        }

        return acc;
      },
      {
        // NOTE: order is what takes precedence
        // in autocomplete dropdown when options are limited
        final_rating_provided_by_person: [],
        manager_or_above: [],
        manager: [],
        people: [],
        hrbp: [],
        leader: [],
        business_unit: [],
        business_unit_id: [],
        department: [],
        department_id: [],
        cost_center: [],
        cost_center_id: [],
        country: [],
        function: [],
        function_id: [],
        level: [],
        level_id: [],
        title: [],
        final_rating_text: [],
        location: [],
        calibration_status: [],
        calibration_text: [],
        report_release_status: [],
        report_acknowledgement_status: [],
      }
    );

    let newSuggestions = [];
    for (const i in suggestionLists) {
      newSuggestions = newSuggestions.concat(
        suggestionLists[i].map((s) => getItemFromField(i, s, formatMessage))
      );
    }

    return newSuggestions;
  }, [people, formatMessage]);

  const getFilterByAbbreviationAndType = useCallback(
    (filterTypeAbbreviation, filterId) => {
      const type = getFilterTypeForAbbreviation(filterTypeAbbreviation);
      return suggestions.find((s) => matchesFilter(type, s, filterId));
    },
    [suggestions]
  );

  const getFilterFromSerialization = useCallback(
    (str) => {
      if (!(str?.length > 2)) {
        return null;
      }

      // first character is filter type abbreviation
      const filterTypeAbbreviation = str[0];
      // second char is -, then remaining characters are id for filter
      const filterId = str.substr(2);
      return getFilterByAbbreviationAndType(filterTypeAbbreviation, filterId);
    },
    [getFilterByAbbreviationAndType]
  );

  const getFiltersFromSerialization = useCallback(
    (str) => {
      return str
        .split(',')
        .map(getFilterFromSerialization)
        .filter((f) => f);
    },
    [getFilterFromSerialization]
  );

  const serializeFilter = useCallback((f) => {
    if (!f) {
      return null;
    }

    // for manager, manager_or_above, etc., get external_id or id (whatever is available)
    const filterTypeAbbreviation = getFilterAbbreviationForType(f._index);
    const filterId = getFilterSerializedId(f);

    return filterTypeAbbreviation + '-' + filterId;
  }, []);

  // set filters ONCE when url loads
  const [searchParams, setSearchParams] = useState(() => {
    return new URLSearchParams(window.location.search);
  });

  useDeepCompareEffect(() => {
    const newIncludeFilters = searchParams
      .getAll('filters[]')
      .map(getFiltersFromSerialization)
      .flat();

    const newExcludeFilters = searchParams
      .getAll('exclude[]')
      .map(getFiltersFromSerialization)
      .flat();

    // only change these variables if the url changes
    if (newIncludeFilters?.length > 0 || newExcludeFilters?.length > 0) {
      resetTableLimit();
      // @ts-expect-error
      setIncludeFilters((previous) =>
        isEqual(newIncludeFilters, previous) ? previous : newIncludeFilters
      );
      // @ts-expect-error
      setExcludeFilters((previous) =>
        isEqual(newExcludeFilters, previous) ? previous : newExcludeFilters
      );
    }
  }, [getFilterFromSerialization, searchParams.toString()]);

  const setFiltersAndUpdateUrl = useCallback(
    (includeTags, excludeTags) => {
      // when changing filters, remove any management tier set
      // (as UX is strange when you add filter and don't see folks,
      // HRBPs reported)
      setFilterTiers((filterTiers) => (filterTiers?.length ? [] : filterTiers));
      setIncludeFilters((previous) =>
        isEqual(includeTags, previous) ? previous : includeTags
      );
      setExcludeFilters((previous) =>
        isEqual(excludeTags, previous) ? previous : excludeTags
      );

      const currentQueryParameters = getSearchParamsAsObjectOfArrays(
        new URLSearchParams(location.search)
      );

      delete currentQueryParameters?.['filters'];
      delete currentQueryParameters?.['exclude'];

      const newQueryParameters = {
        'filters[]': includeTags.map((x) => serializeFilter(x)),
        'exclude[]': excludeTags.map((x) => serializeFilter(x)),
      };
      const newQueryString = new URLSearchParams(
        Object.assign(currentQueryParameters, newQueryParameters)
      ).toString();

      // NOTE: we use window.history.replaceState instead of history.replace
      // because we want to update the url in the UI *WITHOUT* reloading the page
      // every time someone enters in a new filter in the UI.
      window.history.replaceState(
        null,
        // @ts-expect-error
        null,
        `${location.pathname}?${newQueryString}`
      );
      setSearchParams(new URLSearchParams(newQueryString));
      resetTableLimit();
    },
    [
      setFilterTiers,
      setIncludeFilters,
      setExcludeFilters,
      location.search,
      location.pathname,
      resetTableLimit,
      serializeFilter,
    ]
  );

  const numCompletedCurrentPhase = useMemo(
    () =>
      typeof currentPhaseIndex !== 'undefined' &&
      sortedFilteredPeople?.length > 0
        ? sortedFilteredPeople.filter((p) =>
            // @ts-expect-error
            currentPhaseIndex >= reportingPhaseIndex
              ? p.perf_progress_reporting_phase === 100
              : currentPhaseIndex === calibrationPhaseIndex
              ? // count those that have been calibrated (is_calibration_actioned is the sanitized version)
                p.draft_rating !== p?.final_rating || p.is_calibration_actioned
              : (currentPhaseIndex === selfPhaseIndex
                  ? p.perf_progress_self_phase
                  : currentPhaseIndex === othersPhaseIndex
                  ? othersPhaseShowAltProgressBars
                    ? p.perf_progress_others_phase_alt
                    : p.perf_progress_others_phase
                  : p.perf_progress_evaluation_phase) === 100
          )?.length
        : undefined,
    [
      calibrationPhaseIndex,
      currentPhaseIndex,
      othersPhaseIndex,
      othersPhaseShowAltProgressBars,
      reportingPhaseIndex,
      selfPhaseIndex,
      sortedFilteredPeople,
    ]
  );

  const isPersonEligibleForCurrentPhase = useCallback(
    (p) => {
      if (currentPhaseIndex === evaluationPhaseIndex) {
        return p.perf_progress_evaluation_phase !== undefined;
      } else if (currentPhaseIndex === othersPhaseIndex) {
        return othersPhaseShowAltProgressBars
          ? p.is_active_and_participating_in_others_alt_phase
          : p.is_active_and_participating_in_others_phase;
      } else if (currentPhaseIndex === selfPhaseIndex) {
        return (
          p.is_active_and_participating_in_any_phase &&
          p.is_active_and_participating_in_self_phase
        );
      } else if (currentPhaseIndex === reportingPhaseIndex) {
        return (
          p.is_only_receiving_review ||
          (p.is_active_and_participating_in_any_phase &&
            p.is_active_and_participating_in_self_phase)
        );
      } else {
        return p.is_active_and_participating_in_any_phase;
      }
    },
    [
      currentPhaseIndex,
      evaluationPhaseIndex,
      othersPhaseIndex,
      othersPhaseShowAltProgressBars,
      reportingPhaseIndex,
      selfPhaseIndex,
    ]
  );

  const numReportsReleased = useMemo(
    () =>
      // @ts-expect-error
      currentPhaseIndex >= reportingPhaseIndex &&
      sortedFilteredPeople?.length > 0
        ? sortedFilteredPeople.filter(
            (p) => p.released_at && isPersonEligibleForCurrentPhase(p)
          )?.length
        : undefined,
    [
      currentPhaseIndex,
      reportingPhaseIndex,
      sortedFilteredPeople,
      isPersonEligibleForCurrentPhase,
    ]
  );

  const numEligibleCurrentPhase = useMemo(() => {
    return sortedFilteredPeople.filter(isPersonEligibleForCurrentPhase).length;
  }, [isPersonEligibleForCurrentPhase, sortedFilteredPeople]);

  const shouldShowReportsReleased = useMemo(() => {
    // Show the "reports released" tracking information when:
    // 1. There is an explicit Reporting (aka Conversations) phase
    // 2. The current phase is the reporting phase
    // 3. There is at least one person in sortedFilteredPeople i.e. visible onscreen
    return (
      hasPhase(campaign, PHASE_TYPE_REPORTING) &&
      // @ts-expect-error
      currentPhaseIndex >= reportingPhaseIndex &&
      sortedFilteredPeople?.length > 0
    );
  }, [
    campaign,
    currentPhaseIndex,
    reportingPhaseIndex,
    sortedFilteredPeople?.length,
  ]);

  const totalEvaluationsOwed = useMemo(
    () =>
      sortedFilteredPeople?.reduce((acc, p) => {
        return acc + (p?.evaluations_owed ?? 0);
      }, 0),
    [sortedFilteredPeople]
  );

  const totalEvaluationsDone = useMemo(
    () =>
      sortedFilteredPeople?.reduce((acc, p) => {
        return acc + (p?.evaluations_done ?? 0);
      }, 0),
    [sortedFilteredPeople]
  );

  const shouldShowReceivedEvaluations = useMemo(() => {
    // Show the "received evaluations" tracking information when:
    // 1. There is an explicit Reporting (aka Conversations) phase
    // 2. The current phase is the reporting phase
    // 3. There is at least one person in sortedFilteredPeople i.e. visible onscreen
    return (
      hasPhase(campaign, PHASE_TYPE_REPORTING) &&
      currentPhaseIndex === evaluationPhaseIndex &&
      sortedFilteredPeople?.length > 0
    );
  }, [
    campaign,
    currentPhaseIndex,
    evaluationPhaseIndex,
    sortedFilteredPeople?.length,
  ]);

  const additionalProgress = useMemo(
    () => (
      <>
        {shouldShowReceivedEvaluations ? (
          <AdditionalProgressCard
            denominator={totalEvaluationsOwed}
            icon={ICONS.PERSON}
            numerator={totalEvaluationsDone}
            title={formatMessage({
              id: 'app.views.widgets.dashboards.team_dashboard.evaluations_received',
              defaultMessage: 'Evaluations received',
            })}
          />
        ) : undefined}
        {shouldShowReportsReleased ? (
          <AdditionalProgressCard
            denominator={numEligibleCurrentPhase}
            icon={ICONS.RELEASE_REPORT}
            numerator={numReportsReleased}
            title={formatMessage({
              id: 'app.views.widgets.dashboards.team_dashboard.reports_released',
              defaultMessage: 'Reports released',
            })}
          />
        ) : undefined}
      </>
    ),
    [
      shouldShowReceivedEvaluations,
      totalEvaluationsOwed,
      totalEvaluationsDone,
      formatMessage,
      shouldShowReportsReleased,
      numReportsReleased,
      numEligibleCurrentPhase,
    ]
  );

  const overallProgress = useMemo(
    () =>
      typeof currentPhaseIndex !== 'undefined' &&
      sortedFilteredPeople?.length > 0 ? (
        <Card className="mb-0">
          <CardBody>
            <Row className="align-items-center">
              {numCompletedCurrentPhase != null && (
                <Col className="col-auto pe-0">
                  <CompletionDoughnut
                    width={40}
                    height={40}
                    numerator={numCompletedCurrentPhase}
                    denominator={numEligibleCurrentPhase}
                  />
                </Col>
              )}
              <Col>
                <h6 className="text-uppercase text-muted mb-2">
                  {isEngagementSurveyOnly ? (
                    <FormattedMessage
                      id="app.views.widgets.dashboards.team_dashboard.engagement_survey_only.survey_completed"
                      defaultMessage="Survey Completed"
                    />
                  ) : (
                    <>
                      {
                        // @ts-expect-error
                        (campaign.phases?.length > 1 ||
                          ((campaign?.phases?.length ?? -1) === 1 &&
                            evaluationPhaseIndex !== -1)) && (
                          <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle
                              tag="span"
                              className="text-primary"
                              role="button"
                            >
                              {capitalize(
                                getPhaseName(
                                  campaign,
                                  currentPhaseIndex,
                                  formatMessage
                                )
                              )}
                            </DropdownToggle>
                            <DropdownMenu end>
                              {[
                                // @ts-expect-error
                                ...Array.from(campaign.phases.keys()),
                                // for conversations phase, which should only occur
                                // if there is an evaluation phase and no Reporting
                                // phase is in the configs (since Reporting phase
                                // also maps to "conversations")
                                ...(evaluationPhaseIndex !== -1 &&
                                explicitReportingPhaseIndex === -1
                                  ? // @ts-expect-error
                                    [campaign.phases.length]
                                  : []),
                              ].map((i, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() => setCurrentPhaseIndex(i)}
                                >
                                  {capitalize(
                                    getPhaseName(campaign, i, formatMessage)
                                  )}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      }
                      {(campaign?.phases?.length ?? -1) <= 1 &&
                        evaluationPhaseIndex === -1 &&
                        formatMessage({
                          id: 'app.views.widgets.dashboards.team_dashboard.engagement_survey_only.survey',
                          defaultMessage: 'Survey',
                        })}{' '}
                      {currentPhaseIndex === othersPhaseIndex ? (
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            tag="span"
                            className="text-primary"
                            role="button"
                          >
                            {othersPhaseShowAltProgressBars
                              ? 'received'
                              : currentPhaseIndex === calibrationPhaseIndex
                              ? 'changes'
                              : 'completed'}
                          </DropdownToggle>
                          <DropdownMenu end>
                            <DropdownItem
                              onClick={() =>
                                setOthersPhaseShowAltProgressBars(false)
                              }
                            >
                              <FormattedMessage
                                id="app.views.widgets.dashboards.team_dashboard.completed_all_peers_and_manager"
                                defaultMessage="Completed (all peers and manager)"
                              />
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                setOthersPhaseShowAltProgressBars(true)
                              }
                            >
                              <FormattedMessage
                                id="app.views.widgets.dashboards.team_dashboard.received_all_feedback_from_peers_and_directs"
                                defaultMessage="Received (all feedback from peers and directs)"
                              />
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      ) : currentPhaseIndex === calibrationPhaseIndex ? (
                        formatMessage({
                          id: 'app.views.widgets.dashboards.team_dashboard.changes',
                          defaultMessage: 'changes',
                        })
                      ) : (
                        formatMessage({
                          id: 'app.views.widgets.dashboards.team_dashboard.completed',
                          defaultMessage: 'completed',
                        })
                      )}
                    </>
                  )}
                </h6>
                <Row className="align-items-center no-gutters">
                  <Col className="col-auto">
                    <span className="h2 me-2 mb-0">
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.x_of_y_reports_released"
                        defaultMessage="{x} <span>of {y}</span>"
                        values={{
                          x: numCompletedCurrentPhase,
                          y: numEligibleCurrentPhase,
                          span: (chunks) => (
                            <span className="text-muted fs-base fw-normal">
                              {chunks}
                            </span>
                          ),
                        }}
                      />
                    </span>
                    <span id="xxxxx-xxxx">
                      <i className="fe fe-help-circle text-primary small" />
                      <UncontrolledPopover
                        placement="top"
                        trigger="hover"
                        target="xxxxx-xxxx"
                      >
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.x_of_y.popover"
                          defaultMessage="This is the total count of people you have visibility into,
                          not the entire population of people who were eligible to participate."
                        />
                      </UncontrolledPopover>
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col className="col-auto ps-0 d-none d-xl-block">
                <span
                  className={
                    'h2 fe text-muted mb-0 ' +
                    // @ts-expect-error
                    (currentPhaseIndex >= reportingPhaseIndex
                      ? 'fe-message-square'
                      : 'fe-user')
                  }
                ></span>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <></>
      ),
    [
      currentPhaseIndex,
      sortedFilteredPeople?.length,
      isEngagementSurveyOnly,
      campaign,
      formatMessage,
      evaluationPhaseIndex,
      explicitReportingPhaseIndex,
      othersPhaseIndex,
      othersPhaseShowAltProgressBars,
      calibrationPhaseIndex,
      numCompletedCurrentPhase,
      numEligibleCurrentPhase,
      reportingPhaseIndex,
    ]
  );

  const getHistogramChart = useCallback(
    (width, height, showAxisLabels) => {
      if (!ratings) {
        return undefined;
      }

      return (
        <Bar
          width={width}
          height={height}
          data={{
            labels: [
              ...ratings.map((r) => r.value.toString()),
              ...(numRatingsIncomplete > 0
                ? [
                    showAxisLabels
                      ? formatMessage({
                          id: 'app.views.widgets.dashboards.team_dashboard.tbd',
                          defaultMessage: 'TBD',
                        })
                      : '?',
                  ]
                : []),
            ],
            datasets: [
              ...(performancePreviousRatingHistogramData
                ? [
                    {
                      label: formatMessage({
                        id: 'app.views.widgets.dashboards.team_dashboard.previous_rating',
                        defaultMessage: 'Previous rating',
                      }),
                      backgroundColor: '#95AAC9',
                      data: performancePreviousRatingHistogramData,
                    },
                  ]
                : []),
              {
                label: formatMessage({
                  id: 'app.views.widgets.dashboards.team_dashboard.draft_rating',
                  defaultMessage: 'Draft rating',
                }),
                backgroundColor: '#A6C5F7',
                data: performanceDraftRatingHistogramData,
              },
              {
                label: formatMessage({
                  id: 'app.views.widgets.dashboards.team_dashboard.final_rating',
                  defaultMessage: 'Final rating',
                }),
                backgroundColor: '#2C7BE5',
                data: performanceFinalRatingHistogramData,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            legend: showAxisLabels
              ? {
                  display: true,
                  labels: {
                    color: 'rgb(255, 99, 132)',
                  },
                }
              : undefined,
            scales: {
              xAxes: [
                {
                  ticks: {
                    reverse: true, // should go low to high instead of high to low
                    padding: 5,
                  },
                },
              ],
              yAxes: [
                {
                  display: showAxisLabels,
                  ticks: {
                    stepSize: 10,
                    // Include a % sign in the ticks
                    callback: (value) => {
                      return value + '%';
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                title: (tooltipItems) => {
                  return (
                    tooltipItems[0].xLabel +
                    ' - ' +
                    getRatingName(
                      formatMessage,
                      campaign,
                      tooltipItems[0].xLabel
                    )
                  );
                },
                label: (tooltipItem, data) => {
                  return (
                    '<span class="ms-2">' +
                    formatMessage(
                      {
                        id: 'app.views.widgets.dashboards.team_dashboard.label.numerator_of_denominator',
                        defaultMessage:
                          '{label}: {numerator} of {denominator} ({yLabel}%)',
                      },
                      {
                        label: data.datasets[tooltipItem.datasetIndex].label,
                        numerator:
                          data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.index
                          ].metadata.numerator,
                        denominator:
                          data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.index
                          ].metadata.denominator,
                        yLabel: tooltipItem.yLabel,
                      }
                    ) +
                    '</span>'
                  );
                },
              },
            },
          }}
        />
      );
    },
    [
      campaign,
      numRatingsIncomplete,
      performanceDraftRatingHistogramData,
      performanceFinalRatingHistogramData,
      performancePreviousRatingHistogramData,
      ratings,
      formatMessage,
    ]
  );

  const largeHistogram = useMemo(
    () => getHistogramChart(480, 280, true),
    [getHistogramChart]
  );

  const smallHistogram = useMemo(
    () =>
      evaluationPhaseHasOpened &&
      ratingRequired &&
      (performancePreviousRatingHistogramData ||
        performanceDraftRatingHistogramData ||
        performanceFinalRatingHistogramData) ? (
        <>
          {largeHistogram && (
            <Modal
              isOpen={histogramIsExpanded}
              toggle={toggleHistogramIsExpanded}
            >
              <ModalHeader toggle={toggleHistogramIsExpanded}>
                <FormattedMessage
                  id="app.views.widgets.dashboards.team_dashboard.campaign_perf_rating_distribution"
                  defaultMessage="{campaignName} performance ratings distribution"
                  values={{ campaignName: campaign?.name }}
                />
              </ModalHeader>
              <ModalBody className="text-center">{largeHistogram}</ModalBody>
            </Modal>
          )}
          <Card
            className="mb-0"
            role="button"
            onClick={toggleHistogramIsExpanded}
          >
            <CardBody className="ps-2 pe-3 pb-2 pt-3">
              <div style={{ position: 'relative', top: '0.15rem' }}>
                {getHistogramChart(120, 70, false)}
              </div>
            </CardBody>
          </Card>
        </>
      ) : undefined,
    [
      evaluationPhaseHasOpened,
      ratingRequired,
      performancePreviousRatingHistogramData,
      performanceDraftRatingHistogramData,
      performanceFinalRatingHistogramData,
      largeHistogram,
      histogramIsExpanded,
      toggleHistogramIsExpanded,
      campaign?.name,
      getHistogramChart,
    ]
  );

  const includeFilterInput = useMemo(
    () => (
      <Col className="col-12 col-md-6 ps-0">
        <ReactTagsInput
          // @ts-expect-error
          className={props.className}
          // @ts-expect-error
          inputClassName={props.inputClassName}
          tagsAreEqual={tagsAreEqual}
          value={includeFilters}
          allowNew={false}
          suggestions={suggestions}
          placeholder={formatMessage({
            id: 'app.views.widgets.dashboards.team_dashboard.placeholder.filter_by_name_or_anything_else',
            defaultMessage: 'Filter by name or anything else',
          })}
          callback={(tags) => setFiltersAndUpdateUrl(tags, excludeFilters)}
          useTagCards={true}
          disableLengthCheck={true}
          minQueryLength={1}
        />
      </Col>
    ),
    [
      excludeFilters,
      includeFilters,
      // @ts-expect-error
      props.className,
      // @ts-expect-error
      props.inputClassName,
      setFiltersAndUpdateUrl,
      suggestions,
      tagsAreEqual,
      formatMessage,
    ]
  );

  const excludeFilterInput = useMemo(
    () => (
      <Col className="col-12 col-md-6 ps-0">
        <ReactTagsInput
          // @ts-expect-error
          className={props.className}
          // @ts-expect-error
          inputClassName={props.inputClassName}
          tagsAreEqual={tagsAreEqual}
          value={excludeFilters}
          allowNew={false}
          suggestions={suggestions}
          placeholder={formatMessage({
            id: 'app.views.widgets.dashboards.team_dashboard.placeholder.exclude_anything',
            defaultMessage: 'Exclude anything',
          })}
          callback={(tags) => setFiltersAndUpdateUrl(includeFilters, tags)}
          useTagCards={true}
          minQueryLength={1}
        />
      </Col>
    ),
    [
      excludeFilters,
      includeFilters,
      // @ts-expect-error
      props.className,
      // @ts-expect-error
      props.inputClassName,
      setFiltersAndUpdateUrl,
      suggestions,
      tagsAreEqual,
      formatMessage,
    ]
  );

  const tiersListInput = useMemo(
    () => (
      <>
        {
          // @ts-expect-error
          tiersList?.length > 1 && (
            <Col className="col-auto d-flex align-items-center">
              <Row id="reporting-tier-label">
                <Col className="col-auto d-flex align-items-center pe-0">
                  <div className="text-muted">
                    <i className={ICONS.MANAGEMENT_TIER + ' me-2'} />
                  </div>
                </Col>
                <Col className="col-auto ps-1">
                  <ul className="pagination mb-0">
                    {
                      // @ts-expect-error
                      tiersList.map((i, index) => (
                        <li
                          key={index}
                          className={
                            'page-item' +
                            (filterTiers.indexOf(i) !== -1 ? ' active' : '')
                          }
                          role="button"
                          onClick={() => toggleFilterTier(i)}
                        >
                          <span className="page-link">
                            {
                              // @ts-expect-error
                              tiersList?.length - i + minTier
                            }
                          </span>
                        </li>
                      ))
                    }
                  </ul>
                </Col>
              </Row>
              <UncontrolledPopover
                placement="top"
                trigger="hover"
                target={'reporting-tier-label'}
              >
                <FormattedMessage
                  id="app.views.widgets.dashboards.team_dashboard.reporting_tier_list"
                  defaultMessage="
              Reporting tier (1 = ICs, 2 = managers of 1's, 3 =
              manager's of 2's, etc.)
            "
                />
              </UncontrolledPopover>
            </Col>
          )
        }
      </>
    ),
    [filterTiers, toggleFilterTier, minTier, tiersList]
  );

  const eligibilityToggle = useMemo(
    () => (
      <Col className="col-auto d-flex align-items-center pe-0">
        <div
          id="show-ineligibile-people"
          className="form-check form-switch"
          onClick={toggleIneligible}
        >
          <Input
            name="ineligibile-switch"
            type="checkbox"
            checked={showIneligiblePeople}
            onChange={toggleIneligible}
            className={'form-check-input'}
          />
          <Label
            className="form-check-label mb-0"
            htmlFor="ineligibile-switch"
            role="button"
          >
            <i className={ICONS.NOT_ELIGIBLE} />
          </Label>
        </div>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={'show-ineligibile-people'}
        >
          <FormattedMessage
            id="app.views.widgets.dashboards.team_dashboard.click_to_show_hide_not_eligible"
            defaultMessage="
          Click to {showIneligiblePeople, select, true {hide} other {show}} people that are not
          eligible to participate in this cycle.
        "
            values={{ showIneligiblePeople: showIneligiblePeople }}
          />
        </UncontrolledPopover>
      </Col>
    ),
    [showIneligiblePeople, toggleIneligible]
  );

  const showAllResponsesToggle = useMemo(
    () => (
      <Col className="col-auto d-flex align-items-center pb-3">
        <div
          id="show-all-responses"
          className="form-check form-switch"
          onClick={toggleAllResponses}
        >
          <Input
            name="all-responses-switch"
            type="checkbox"
            checked={showAllResponses}
            onChange={toggleAllResponses}
            className={'form-check-input'}
          />
          <Label
            className="form-check-label my-0"
            htmlFor="all-responses-switch"
            role="button"
          >
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.show_question_responses"
              defaultMessage="Show question responses"
            />
          </Label>
        </div>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={'show-all-responses'}
        >
          <FormattedMessage
            id="app.views.widgets.dashboards.team_dashboard.click_to_show_hide_responses"
            defaultMessage="
          Click to {showAllResponses, select, true {hide} other {show}} columns for questions
          asked in the cycle, including numeric averages.
        "
            values={{ showAllResponses: showAllResponses }}
          />
        </UncontrolledPopover>
      </Col>
    ),
    [showAllResponses, toggleAllResponses]
  );

  const questionColumnHeadersSelf = useMemo(() => {
    const questions = getCustomQuestionsByPhase(props.campaign);
    let headers = [];
    if (
      PHASE_TYPE_SELF in questions &&
      PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS in
        // @ts-expect-error
        questions[PHASE_TYPE_SELF]
    ) {
      headers = headers.concat(
        generateColumnsForCustomQuestions(
          questions[PHASE_TYPE_SELF][
            PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS
          ],
          SELF_PHASE_QUESTION_PREFIX,
          ICON_SELF_RESPONSE_QUESTION,
          <span className="fw-bold">
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.self_reflection"
              defaultMessage="Self-reflection:"
            />{' '}
          </span>,
          'Self-reflection',
          false
        )
      );
    }
    return headers;
  }, [props.campaign]);

  const questionColumnHeadersUpwardFeedback = useMemo(() => {
    const questions = getCustomQuestionsByPhase(props.campaign);
    let headers = [];
    if (
      PHASE_TYPE_OTHERS in questions &&
      getCampaignHasFeatureEnabled(
        props.campaign,
        PERFORMANCE_FEATURE_ASSESS_MANAGER
      ) &&
      PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS in
        // @ts-expect-error
        questions[PHASE_TYPE_OTHERS]
    ) {
      headers = headers.concat(
        generateColumnsForCustomQuestions(
          questions[PHASE_TYPE_OTHERS][
            PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
          ],
          UPWARD_FEEDBACK_QUESTION_PREFIX,
          ICON_UPWARD_RESPONSE_QUESTION,
          <span className="fw-bold">
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.upward_feedback"
              defaultMessage="Upward feedback:"
            />{' '}
          </span>,
          'Upward feedback',
          true
        )
      );
    }
    return headers;
  }, [props.campaign]);

  const questionColumnHeadersPeer360 = useMemo(() => {
    const questions = getCustomQuestionsByPhase(props.campaign);
    let headers = [];
    if (
      PHASE_TYPE_OTHERS in questions &&
      (getCampaignHasFeatureEnabled(
        props.campaign,
        PERFORMANCE_FEATURE_PEER_FEEDBACK
      ) ||
        getCampaignHasFeatureEnabled(
          props.campaign,
          PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK
        )) &&
      PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS in
        // @ts-expect-error
        questions[PHASE_TYPE_OTHERS]
    ) {
      headers = headers.concat(
        generateColumnsForCustomQuestions(
          questions[PHASE_TYPE_OTHERS][
            PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS
          ],
          PEER_FEEDBACK_QUESTION_PREFIX,
          ICON_PEER_RESPONSE_QUESTION,
          <span className="fw-bold">
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.peer_feedback"
              defaultMessage="Peer feedback:"
            />{' '}
          </span>,
          'Peer feedback',
          true
        )
      );
    }
    return headers;
  }, [props.campaign]);

  const questionColumnHeadersManagerFeedback = useMemo(() => {
    const questions = getCustomQuestionsByPhase(props.campaign);
    let headers = [];
    if (
      PHASE_TYPE_EVALUATION in questions &&
      PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS in
        // @ts-expect-error
        questions[PHASE_TYPE_EVALUATION]
    ) {
      headers = headers.concat(
        generateColumnsForCustomQuestions(
          questions[PHASE_TYPE_EVALUATION][
            PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS
          ],
          EVALUATION_PHASE_QUESTION_PREFIX,
          ICON_DOWNWARD_RESPONSE_QUESTION,
          <span className="fw-bold">
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.manager_feedback"
              defaultMessage="Manager feedback:"
            />{' '}
          </span>,
          'Manager feedback',
          false
        )
      );
    }

    return headers;
  }, [props.campaign]);

  const questionColumnHeaders = useMemo(() => {
    return [
      ...questionColumnHeadersSelf,
      ...questionColumnHeadersUpwardFeedback,
      ...questionColumnHeadersPeer360,
      ...questionColumnHeadersManagerFeedback,
    ];
  }, [
    questionColumnHeadersSelf,
    questionColumnHeadersUpwardFeedback,
    questionColumnHeadersPeer360,
    questionColumnHeadersManagerFeedback,
  ]);

  const csvHeaders = useMemo(() => {
    const wizard_type = campaign
      ? getWizardType(campaign)
      : WIZARD_TYPE_PERFORMANCE;
    const headers = [
      { label: 'ID', key: 'external_id' },
      { label: 'First name', key: 'given_name' },
      { label: 'Last name', key: 'family_name' },
      { label: 'Email', key: 'email' },
      { label: 'Title', key: 'title' },
      { label: 'Confirm url', key: 'full_url' },
      ...(showTimeAtCompany
        ? [{ label: 'Overall tenure', key: 'overall_tenure' }]
        : []),
      ...(showTimeInRole
        ? [{ label: 'Time in role', key: 'role_tenure' }]
        : []),
      { label: 'Manager name', key: 'manager_full_name' },
      { label: 'Manager email', key: 'manager_email' },
      { label: 'Manager title', key: 'manager_title' },
      { label: 'Believed manager name', key: 'believed_manager_full_name' },
      { label: 'Believed manager email', key: 'believed_manager_email' },
      { label: 'Believed manager title', key: 'believed_manager_title' },
      { label: 'Previous managers', key: 'previous_managers_text' },
      { label: 'Department', key: 'department' },
      { label: 'Department ID', key: 'department_id' },
      { label: 'Level ID', key: 'level_id' },
      { label: 'Level', key: 'level' },
      { label: 'Function', key: 'function' },
      { label: 'Function ID', key: 'function_id' },
      { label: 'Cost center', key: 'cost_center' },
      { label: 'Cost center ID', key: 'cost_center_id' },
      { label: 'Country', key: 'country' },
      { label: 'Business unit', key: 'business_unit' },
      { label: 'Business unit ID', key: 'business_unit_id' },
      { label: 'Employee number', key: 'employee_number' },
      { label: 'Location', key: 'location' },
      { label: 'Management tier', key: 'management_tier' },
      { label: 'Original hire date', key: 'original_hire_date' },
      { label: 'Latest hire date', key: 'latest_hire_date' },
      { label: 'Position effective date', key: 'position_effective_date' },
      { label: 'Position start date', key: 'position_start_date' },
      { label: 'Manager effective date', key: 'manager_effective_date' },
      { label: 'Classification', key: 'classification' },
      { label: 'Status', key: 'status' },
      { label: 'HRBP', key: 'hrbp' },
      { label: 'Leader', key: 'leader' },
      { label: 'Self phase progress', key: 'perf_progress_self_phase' },
      ...(othersPhaseIndex === -1
        ? []
        : [
            {
              label: 'Others phase progress',
              key: 'perf_progress_others_phase',
            },
            {
              label: 'Others phase gave manager feedback',
              key: 'perf_progress_others_phase_gave_manager_feedback',
            },
            {
              label: 'Others phase outgoing peer feedbacks assigned',
              key: 'perf_progress_others_phase_outgoing_peers_assigned',
            },
            {
              label: 'Others phase outgoing peer feedbacks completed',
              key: 'perf_progress_others_phase_outgoing_peers_completed',
            },
            {
              label: 'Others phase outgoing peer feedbacks declined',
              key: 'perf_progress_others_phase_outgoing_peers_declined',
            },
            {
              label: 'Others phase received peer feedback count',
              key: 'perf_progress_others_phase_alt_peer_count',
            },
            {
              label: 'Others phase received manager feedback count',
              key: 'perf_progress_others_phase_alt_manager_count',
            },
            {
              label: 'Others phase received feedback progress',
              key: 'perf_progress_others_phase_alt',
            },
          ]),
      {
        label: 'Evaluation phase progress',
        key: 'perf_progress_evaluation_phase',
      },
      {
        label: 'Conversation progress',
        key: 'perf_progress_reporting_phase',
      },
      { label: 'Overall progress', key: 'perf_progress_overall' },
      {
        label: 'Team self phase completed',
        key: 'team_perf_completed_self_phase',
      },
      ...(othersPhaseIndex === -1
        ? []
        : [
            {
              label: 'Team others phase completed',
              key: 'team_perf_completed_others_phase',
            },
            {
              label: 'Team others phase received all feedback progress',
              key: 'team_perf_completed_others_phase_alt',
            },
          ]),
      {
        label: 'Team evaluation phase completed',
        key: 'team_perf_completed_evaluation_phase',
      },
      { label: 'Team total eligible', key: 'team_perf_total_eligible' },
      {
        label: 'Team total eligible (evaluation phase only)',
        key: 'team_perf_total_eligible_evaluation_phase',
      },
      {
        label: 'Team self phase percent completed',
        key: 'team_perf_completed_self_phase_percent',
      },
      ...(othersPhaseIndex === -1
        ? []
        : [
            {
              label: 'Team others phase percent completed',
              key: 'team_perf_completed_others_phase_percent',
            },
          ]),
      {
        label: 'Team evaluation phase percent received all feedback',
        key: 'team_perf_completed_others_phase_percent_alt',
      },
      {
        label: 'Team evaluation phase percent completed',
        key: 'team_perf_completed_evaluation_phase_percent',
      },
      {
        label: 'Team conversations percent completed',
        key: 'team_perf_completed_reporting_phase_percent',
      },
      ...(shouldShowInfluence
        ? [
            {
              label: 'Influence (advised)',
              key: 'influence_advised_ids',
            },
          ]
        : []),
      ...(shouldShowInfluence
        ? [
            {
              label: 'Influence (energized)',
              key: 'influence_energized_ids',
            },
          ]
        : []),
      ...(shouldShowInfluence
        ? [
            ...formatCSVONAKeyLabelByType(
              'Influence People',
              'influence_ids',
              intl
            ),
          ]
        : []),
      ...(shouldShowInfluence
        ? [
            { label: 'Influence', key: 'influence_count' },
            ...formatCSVONAKeyLabelByType('Influence', 'influence_count', intl),
          ]
        : []),
      ...(shouldShowInfluence
        ? [{ label: 'Influence skills', key: 'influence_skills' }]
        : []),
      { label: 'Gold stars', key: 'goldstars_count' },
      ...formatCSVONAKeyLabelByType('Gold stars', 'goldstars_count', intl),
      { label: 'Heads ups', key: 'headsups_count' },
      ...formatCSVONAKeyLabelByType('Heads ups', 'headsups_count', intl),
      { label: 'Gold stars comments', key: 'goldstars_comments' },
      ...formatCSVONAKeyLabelByType(
        'Gold stars comments',
        'goldstars_comments',
        intl
      ),
      { label: 'Heads ups comments', key: 'headsups_comments' },
      ...formatCSVONAKeyLabelByType(
        'Heads ups comments',
        'headsups_comments',
        intl
      ),
      { label: 'Gold stars skills', key: 'goldstars_skills' },
      ...formatCSVONAKeyLabelByType(
        'Gold stars skills',
        'goldstars_skills',
        intl
      ),
      { label: 'Heads ups skills', key: 'headsups_skills' },
      ...formatCSVONAKeyLabelByType(
        'Heads ups skills',
        'headsups_skills',
        intl
      ),
      { label: 'Former managers', key: 'formers_count' },
      { label: 'Former manager comments', key: 'formers_comments' },
      { label: 'Former manager skills', key: 'formers_skills' },
      ...questionColumnHeadersSelf,
      ...questionColumnHeadersUpwardFeedback,
      { label: 'Upward Feedback Score', key: 'upward_feedback_score' },
      ...questionColumnHeadersPeer360,
      ...questionColumnHeadersManagerFeedback,
      { label: 'Promo nominated by manager', key: 'recommend_for_promotion' },
      { label: 'Promo manager comments', key: 'promotion_comments' },
      { label: 'Promo packet status', key: 'promotion_packet_status' },
      {
        label: 'Salary increase nominated by manager',
        key: 'recommend_for_salary_increase',
      },
      {
        label: 'Salary increase manager comments',
        key: 'salary_increase_comments',
      },
      { label: 'Draft rating', key: 'draft_rating' },
      {
        label: 'Draft rating provided by',
        key: 'draft_rating_provided_by_full_name',
      },
      { label: 'Draft rating comments', key: 'draft_rating_comments' },
      ...(showPreviousFinalRating
        ? [
            {
              label: 'Previous final rating',
              key: 'previous_final_rating',
            },
          ]
        : []),
      { label: 'Final rating', key: 'final_rating' },
      { label: 'Final rating comments', key: 'final_rating_comments' },
      {
        label: 'Final rating provided by',
        key: 'final_rating_provided_by_person_full_name',
      },
      {
        label: 'Calibration status',
        key: 'calibration_status',
      },
      {
        label: 'Calibration flags',
        key: 'original_calibration_flags',
      },
      ...(showPreviousFinalRatingDeltas
        ? [
            {
              label: 'Previous to final rating delta',
              key: 'previous_to_final_delta',
            },
          ]
        : []),
      ...(showFinalRatingDeltas
        ? [
            {
              label: 'Draft to final rating delta',
              key: 'draft_to_final_delta',
            },
          ]
        : []),
      { label: 'Report released', key: 'released_at' },
      ...(!disableConversationAcknowledgements
        ? [
            {
              label: 'Conversation acknowledged',
              key: 'conversation_acknowledged_at',
            },
          ]
        : []),
      { label: 'Conversation rating', key: 'conversation_rating' },
      { label: 'Conversation comments', key: 'conversation_comments' },
      { label: 'Conversation visibility', key: 'conversation_visibility' },
      { label: 'Incoming peers', key: 'incoming_peers' },
      { label: 'Outgoing peers', key: 'outgoing_peers' },
    ].filter(
      (i) =>
        !unpopulatedFieldNames.has(i.key) &&
        !isFieldExcluded(i.key, wizard_type)
    );

    return headers;
  }, [
    showTimeAtCompany,
    showTimeInRole,
    othersPhaseIndex,
    shouldShowInfluence,
    intl,
    questionColumnHeadersSelf,
    questionColumnHeadersUpwardFeedback,
    questionColumnHeadersPeer360,
    questionColumnHeadersManagerFeedback,
    showPreviousFinalRating,
    showPreviousFinalRatingDeltas,
    showFinalRatingDeltas,
    disableConversationAcknowledgements,
    unpopulatedFieldNames,
    campaign,
  ]);

  const exportButton = useMemo(
    () => (
      <>
        {sortedFilteredPeople?.length > 0 && (
          <Col className="col-auto pe-0 pb-3 ps-0 ps-md-3">
            <TeamDashboardCSVDownload
              data={sortedFilteredPeople}
              headers={csvHeaders}
              // @ts-expect-error
              campaign={campaign}
            />
          </Col>
        )}
      </>
    ),
    [csvHeaders, sortedFilteredPeople, campaign]
  );

  const columnsEvenWithoutData = useMemo(
    () => [
      {
        name: formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.person',
          defaultMessage: 'Person',
        }),
        field: 'person',
        popoverContent: formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.person_column.help_text',
          defaultMessage: 'Name, title, and link to resume',
        }),
      },
      {
        name: formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.manager.name',
          defaultMessage: 'Mgr',
        }),
        field: 'manager',
        popoverContent: formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.manager_column.help_text',
          defaultMessage: 'Manager',
        }),
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('department')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.department"
              defaultMessage="Department"
            />
          </span>
        ),
        field: 'department',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('department_id')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.department_id"
              defaultMessage="Department ID"
            />
          </span>
        ),
        field: 'department_id',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={'ps-2 ' + getIconForFieldName('level_id')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.level_id"
              defaultMessage="Level ID"
            />
          </span>
        ),
        className: 'ps-1 text-end text-nowrap',
        field: 'level_id',
        popoverContent: formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.level_column.help_text',
          defaultMessage:
            'Level for the given function on the career ladder. For level descriptions, hover below.',
        }),
      },
      {
        name: (
          <span>
            <i className={'ps-2 ' + getIconForFieldName('level')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.level_description"
              defaultMessage="Level"
            />
          </span>
        ),
        className: 'ps-1 text-end text-nowrap',
        field: 'level',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('function')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.function"
              defaultMessage="Function"
            />
          </span>
        ),
        field: 'function',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('function_id')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.function_id"
              defaultMessage="Function ID"
            />
          </span>
        ),
        field: 'function_id',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('cost_center')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.cost_center"
              defaultMessage="Cost center
          "
            />
          </span>
        ),
        field: 'cost_center',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('cost_center_id')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.cost_center_id"
              defaultMessage="Cost center ID"
            />
          </span>
        ),
        field: 'cost_center_id',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('country')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.country"
              defaultMessage="Country"
            />
          </span>
        ),
        field: 'country',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('business_unit')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.business_unit"
              defaultMessage="Business unit"
            />
          </span>
        ),
        field: 'business_unit',
        popoverContent: undefined,
      },
      {
        name: (
          <span>
            <i className={getIconForFieldName('business_unit_id')} />
            &nbsp;{' '}
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.business_unit_id"
              defaultMessage="Business unit ID"
            />
          </span>
        ),
        field: 'business_unit_id',
        popoverContent: undefined,
      },
      ...(showProgressColumns
        ? [
            {
              name: (
                <FormattedMessage
                  id="app.views.widgets.dashboards.team_dashboard.done"
                  defaultMessage="Done"
                />
              ),
              field: 'perf_progress',
              popoverContent: isEngagementSurveyOnly ? (
                <FormattedMessage
                  id="app.team_dashboard.progress_column.perf_progress.survey_only.help_text"
                  defaultMessage="Status of the survey completion for the person"
                />
              ) : (
                <FormattedMessage
                  id="app.team_dashboard.progress_column.perf_progress.help_text"
                  defaultMessage="How close this person is to completing {currentPhaseText}"
                  values={{ currentPhaseText }}
                />
              ),
            },
          ]
        : []),
      ...(showProgressColumns
        ? [
            {
              name: (
                <FormattedMessage
                  id="app.views.widgets.dashboards.team_dashboard.team"
                  defaultMessage="Team"
                />
              ),
              field: 'team_perf_completed',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.progress_column.team_perf_completed.help_text"
                  defaultMessage="How close everyone below this person in the chain of command is to completing {currentPhaseText}"
                  values={{ currentPhaseText }}
                />
              ),
            },
          ]
        : []),
      ...(showTimeAtCompany
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.TENURE} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'overall_tenure',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.overall_tenure_column.help_text"
                  description="Popover explanatory text for employee tenure at company (in months)."
                  defaultMessage="Overall tenure: how many months the person has been at {company_name}, calculated from their most recent start date to the day this performance cycle began."
                  values={{
                    company_name:
                      props.currentOrganization?.name ?? 'the company',
                  }}
                />
              ),
            },
          ]
        : []),
      ...(showTimeInRole
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.TENURE} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'role_tenure',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.role_tenure_column.help_text"
                  description="Popover explanatory text for employee tenure in current role at company (in months)."
                  defaultMessage="Time in role: how many months the person has been in their current role, calculated from their first day in their current role to the day this performance cycle began. For more details on an individual role change, hover below."
                />
              ),
            },
          ]
        : []),
      /*{
        name: (
          <span>
            &nbsp;&nbsp;
            <i className={ICONS.MANAGEMENT_TIER + " ps-3"} />
            &nbsp;
          </span>
        ),
        className: 'text-end',
        field: 'management_tier',
        popoverContent:
          'Management tier, where 1 = highest, 2 = reports to a 1, 3 = reports to a 2, etc.',
      },*/
      ...(shouldShowInfluence
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.INFLUENCE} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'influence',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.influence.help_text"
                  defaultMessage="Number of people that were energized or advised by this person during this cycle."
                />
              ),
            },
          ]
        : []),
      ...(showGoldStars
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.GOLD_STAR} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'goldstars',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.goldstars.help_text"
                  defaultMessage="Number of gold stars received by this person during this cycle. To see comments, hover below."
                />
              ),
            },
          ]
        : []),
      ...(showHeadsUps
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.HEADS_UP} />
                  &nbsp;
                </span>
              ),
              className: 'left text-nowrap',
              field: 'headsups',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.headsups.help_text"
                  defaultMessage="Number of heads ups received by this person during this cycle. To see comments, hover below."
                />
              ),
            },
          ]
        : []),
      ...(showUpwardFeedbackScore
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.DIRECT_REPORTS} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'upwardFeedbackAverage',
              // question could be one-question likert with one option, so show
              // label from there if the normal label isn't there
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.upward_feedback_average.help_text"
                  defaultMessage='Average of ratings from direct reports for "{question}"'
                  values={{
                    question:
                      stripHtml(upwardFeedbackRatingQuestion?.label) ||
                      stripHtml(
                        upwardFeedbackRatingQuestion?.objects?.[0]?.value
                      ),
                  }}
                />
              ),
            },
          ]
        : []),
      ...(showPeerFeedbackColumns
        ? [
            {
              name: (
                <FormattedMessage
                  id="app.team_dashboard.incoming_peers.label"
                  defaultMessage="Peers in"
                />
              ),
              field: 'incoming_peers',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.incoming_peers.help_text"
                  defaultMessage="Peers that are providing feedback about this person"
                />
              ),
            },
            {
              name: (
                <FormattedMessage
                  id="app.team_dashboard.outgoing_peers.label"
                  defaultMessage="Peers out"
                />
              ),
              field: 'outgoing_peers',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.outgoing_peers.help_text"
                  defaultMessage="Peers for whom this person is providing feedback"
                />
              ),
            },
          ]
        : []),
      ...(showFormers
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.FORMER_MANAGER} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'formers',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.formers.help_text"
                  defaultMessage="Number of people indicating they were this person's former manager or informally managed their work. To see comments, hover below."
                />
              ),
            },
          ]
        : []),
      ...(showPreviousFinalRating
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.PREVIOUS_FINAL_RATING_HEADER} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'previous_final_rating',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.previous_final_rating.help_text"
                  defaultMessage="Previous final rating (from the prior cycle if the person participated)"
                />
              ),
            },
          ]
        : []),
      ...(showPromo
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.PROMOTION} />
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'recommend_for_promotion',
              popoverContent:
                (promotionPacketsRequired
                  ? formatMessage({
                      id: 'app.team_dashboard.recommend_for_promotion.help_text',
                      defaultMessage:
                        'Manager plans to submit a promotion packet for this person. If one has been drafted or submitted, its status with a link to manage it is below.',
                    })
                  : formatMessage({
                      id: 'app.team_dashboard.recommend_for_promotion.help_text_no_packets',
                      defaultMessage:
                        'Manager is nominating this person for promotion.',
                    })) +
                ' ' +
                (allowPromotionComments
                  ? formatMessage({
                      id: 'app.team_dashboard.recommend_for_promotion.help_text_comments',
                      defaultMessage: 'To see comments, hover below.',
                    })
                  : ''),
            },
          ]
        : []),
      ...(showSalaryIncrease
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.SALARY_INCREASE} />
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'recommend_for_salary_increase',
              popoverContent:
                formatMessage({
                  id: 'app.team_dashboard.recommend_for_salary_increase.help_text',
                  defaultMessage:
                    'Manager is nominating this person for a salary increase.',
                }) +
                ' ' +
                (allowSalaryIncreaseComments
                  ? formatMessage({
                      id: 'app.team_dashboard.recommend_for_salary_increase.help_text_comments',
                      defaultMessage: 'To see comments, hover below.',
                    })
                  : ''),
            },
          ]
        : []),
      ...(showDraftRating
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.DRAFT_RATING} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'draft_rating',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.draft_rating.help_text"
                  defaultMessage="Draft overall rating by manager. To see comments, hover below."
                />
              ),
            },
          ]
        : []),
      ...(showFinalRating
        ? [
            {
              name: (
                <span>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.final"
                    defaultMessage="Final"
                  />
                </span>
              ),
              className: 'text-end text-nowrap',
              field: 'final_rating',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.final_rating.help_text"
                  defaultMessage="Final rating (only editable by those with calibration privileges). To see comments, hover below."
                />
              ),
            },
          ]
        : []),
      ...(showCalibrationFlags
        ? [
            {
              name: (
                <span>
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.calibration"
                    defaultMessage="Calibration"
                  />
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'current_calibration_flag',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.calibration_flag.help_text"
                  defaultMessage="Based on this cycle's calibration rules, anyone who meets the criteria to be flagged but has not yet been calibrated will be surfaced below. Hover to see details as to why they were flagged."
                />
              ),
            },
          ]
        : []),
      ...(showCalibrationFlags
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.ORIGINAL_CALIBRATION_FLAG} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'original_calibration_flag',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.original_calibration_flag.help_text"
                  defaultMessage="Indicates the original calibration flag, ignoring what calibration action has been taken on this person. Hover to see details as to why they were flagged."
                />
              ),
            },
          ]
        : []),
      ...(showFinalRatingDeltas
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.CALIBRATOR} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'final_rating_provided_by_person',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.calibrator.help_text"
                  defaultMessage="Indicates who has manually calibrated this person, if anyone."
                />
              ),
            },
          ]
        : []),
      ...(showFinalRatingDeltas
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.DRAFT_RATING_DELTA} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'draft_to_final_delta',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.draft_to_final_delta.help_text"
                  defaultMessage="Difference between draft and final rating"
                />
              ),
            },
          ]
        : []),
      ...(showPreviousFinalRatingDeltas
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.PREVIOUS_RATING_DELTA} />
                  &nbsp;
                </span>
              ),
              className: 'text-start text-nowrap',
              field: 'previous_to_final_delta',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.previous_to_final_delta.help_text"
                  defaultMessage="Difference between the previous cycle and this cycle's final rating. This will only show if both ratings are not a 'new to role' rating."
                />
              ),
            },
          ]
        : []),
      ...(showAllResponses ? questionColumnHeaders : []),
      ...(showShareAction
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.RELEASE_REPORT_STATUS} />
                  &nbsp;
                </span>
              ),
              className: 'text-center',
              field: 'share_status',
              popoverContent: (
                <FormattedMessage
                  id="app.team_dashboard.share_status.help_text"
                  defaultMessage="Click below to preview, make updates, and then share the final report with this person. Once shared, an icon below will indicate if the person has acknowledged having a performance conversation. If they choose to share comments about the conversation, you can see these comments below by hovering on a comment icon that will appear."
                />
              ),
            },
          ]
        : []),
    ],
    [
      showProgressColumns,
      showPeerFeedbackColumns,
      currentPhaseText,
      showTimeAtCompany,
      props.currentOrganization?.name,
      isEngagementSurveyOnly,
      showTimeInRole,
      shouldShowInfluence,
      showGoldStars,
      showHeadsUps,
      showUpwardFeedbackScore,
      upwardFeedbackRatingQuestion?.label,
      upwardFeedbackRatingQuestion?.objects,
      showFormers,
      showPreviousFinalRating,
      showPromo,
      promotionPacketsRequired,
      allowPromotionComments,
      showDraftRating,
      showFinalRating,
      showCalibrationFlags,
      showPreviousFinalRatingDeltas,
      showFinalRatingDeltas,
      showAllResponses,
      questionColumnHeaders,
      showShareAction,
      allowSalaryIncreaseComments,
      showSalaryIncrease,
      formatMessage,
    ]
  );

  // don't include columns where all people in column have no data
  // or where the field should be CSV only
  const columns = useMemo(() => {
    const wizard_type = campaign
      ? getWizardType(campaign)
      : WIZARD_TYPE_PERFORMANCE;

    return columnsEvenWithoutData.filter(
      (c) =>
        // @ts-expect-error
        !c.csvOnly &&
        !unpopulatedFieldNames.has(c.field) &&
        !isFieldExcluded(c.field, wizard_type)
    );
  }, [columnsEvenWithoutData, unpopulatedFieldNames, campaign]);

  const {
    columnsForVisualDisplay,
    columnsForVisualDisplayUnfiltered,
    visibleColumns,
    handleCallbackColumnSelector,
    handleOpenColumnSelector,
    handleToggleColumnSelector,
    showColumnSelector,
  } = useFilterableTableColumnSelectorModal({ columns });

  const finalRatingObject = useMemo(
    () => ({
      campaign: campaign?.id,
      // @ts-expect-error
      person: currentFinalRatingPerson?.id,
      final_rating: finalRatingModalProposedScore,
      final_rating_comments: finalRatingModalProposedScoreComments,
    }),
    [
      currentFinalRatingPerson,
      finalRatingModalProposedScore,
      finalRatingModalProposedScoreComments,
      campaign,
    ]
  );

  const getField = useCallback(
    (person, column, uniqueIndexIdentifier, rowIndex) => {
      const value = person[column.field];

      if (column.format) {
        return column.format(value, uniqueIndexIdentifier, rowIndex);
      }

      return value;
    },
    []
  );

  const averagesSummary = useMemo(
    () =>
      typeof currentPhaseIndex !== 'undefined' &&
      sortedFilteredPeople?.length > 0 ? (
        <Card className="mb-0">
          <CardBody>
            <Row className="align-items-center">
              <Col className="pe-0">
                <h6 className="text-uppercase text-muted mb-2">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.averages"
                    defaultMessage="Averages"
                  />
                </h6>
                <Row className="align-items-center no-gutters">
                  <Col className="col-auto fw-bold pe-0">
                    {shouldShowInfluence && (
                      <>
                        <span id="averages-card-influence" className="me-3">
                          <i className={ICONS.INFLUENCE} /> {averages.influence}
                        </span>
                        <UncontrolledPopover
                          placement="top"
                          trigger="hover"
                          target="averages-card-influence"
                        >
                          <FormattedMessage
                            id="app.views.widgets.dashboards.team_dashboard.num_energized_or_advised"
                            defaultMessage="
                          Number of people that were energized or advised by
                          this person during this cycle.
                        "
                          />
                        </UncontrolledPopover>
                      </>
                    )}
                    {showGoldStars && (
                      <>
                        <span id="averages-card-gold-stars" className="me-3">
                          <i className={ICONS.GOLD_STAR} /> {averages.goldstars}
                        </span>
                        <UncontrolledPopover
                          placement="top"
                          trigger="hover"
                          target="averages-card-gold-stars"
                        >
                          <FormattedMessage
                            id="app.views.widgets.dashboards.team_dashboard.num_gold_stars_received"
                            defaultMessage="
                          Number of gold stars received by others during this
                          cycle.
                        "
                          />
                        </UncontrolledPopover>
                      </>
                    )}
                    {showHeadsUps && (
                      <>
                        <span id="averages-card-heads-ups">
                          <i className={ICONS.HEADS_UP} /> {averages.headsups}
                        </span>
                        <UncontrolledPopover
                          placement="top"
                          trigger="hover"
                          target="averages-card-heads-ups"
                        >
                          <FormattedMessage
                            id="app.views.widgets.dashboards.team_dashboard.num_heads_ups_received"
                            defaultMessage="
                          Number of heads ups received by others during this
                          cycle.
                        "
                          />
                        </UncontrolledPopover>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className="col-auto">
                <span
                  className={ICONS.RELEASE_REPORT + 'h2 text-muted mb-0'}
                ></span>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <></>
      ),
    [
      averages.goldstars,
      averages.headsups,
      averages.influence,
      currentPhaseIndex,
      shouldShowInfluence,
      sortedFilteredPeople?.length,
      showGoldStars,
      showHeadsUps,
    ]
  );

  const isAnyFilterApplied = useMemo(
    () =>
      !!includeFilters?.length ||
      !!excludeFilters?.length ||
      !showTerminatedPeople ||
      !showIneligiblePeople ||
      !isEmpty(filterTiers),

    [
      includeFilters?.length,
      excludeFilters?.length,
      showTerminatedPeople,
      showIneligiblePeople,
      filterTiers,
    ]
  );

  const peopleTableBody = useMemo(
    () => (
      <>
        {tablePeople.length === 0 && !isAnyFilterApplied && (
          <div className="text-center mb-4">
            <span className="text-muted">
              <FormattedMessage
                id="app.views.widgets.dashboards.team_dashboard.no_results_found"
                defaultMessage="No results found"
              />
            </span>
          </div>
        )}
        {(tablePeople.length > 0 || isAnyFilterApplied) && (
          <>
            {currentFinalRatingPerson && (
              <ModalEditor
                method="POST"
                url="performance/admin/calibrate-rating"
                isOpen={showFinalRatingModalEditor}
                onClosed={onFinalRatingModalClosed}
                toggle={toggleFinalRatingModalEditor}
                // @ts-expect-error
                submitButtonClassName={'btn btn-primary'}
                title={formatMessage(
                  {
                    id: 'app.views.widgets.dashboards.team_dashboard.update_final_rating_for_person',
                    defaultMessage: 'Update final rating for {person}',
                  },
                  {
                    // @ts-expect-error
                    person: currentFinalRatingPerson?.full_name ?? '',
                  }
                )}
                object={finalRatingObject}
                inputs={[
                  {
                    name: 'final_rating',
                    label: formatMessage({
                      id: 'app.views.widgets.dashboards.team_dashboard.final_rating.label',
                      defaultMessage: 'Final Rating',
                    }),
                    type: INPUT_TYPES.DROPDOWN,
                    selectText: '?',
                    objects: ratings.map((r) => ({
                      id: r.value,
                      name: r.value?.toString(),
                    })),
                  },
                  {
                    type: INPUT_TYPES.TEXTAREA,
                    name: 'final_rating_comments',
                    label: formatMessage({
                      id: 'app.views.widgets.dashboards.team_dashboard.final_rating_comments.label',
                      defaultMessage:
                        'Why do you believe this new rating is appropriate?',
                    }),
                    maxLength: 1000,
                    minRows: 2,
                    autoFocus: true,
                  },
                ]}
                callback={onSaveFinalRating}
              />
            )}
            {isAdmin && (
              <ModalDatasetRecordEditor
                // @ts-expect-error
                method="POST"
                url="performance/admin/edit-dataset-record"
                isOpen={showModalDatasetRecordEditor}
                onSaved={onSaveModalDatasetRecordEditor}
                toggle={toggleModalDatasetRecordEditor}
                submitButtonClassName={'btn btn-primary'}
                person_id={personToEdit}
                // @ts-expect-error
                campaign_name={personDatasourceCampaign?.name}
                // @ts-expect-error
                campaign_id={personDatasourceCampaign?.id}
                userSub={userSub}
              />
            )}
            <Row>
              <Col>
                <Card>
                  <CardHeader className="pb-0" style={{ height: 'auto' }}>
                    <Row className="justify-content-between pt-lg-0">
                      <Col className="col-12 col-md-auto ps-0">
                        <Row>
                          <Col className="col-auto">
                            <Button
                              color="light"
                              className="btn-sm"
                              onClick={toggleFilters}
                            >
                              <i
                                className="fe fe-filter"
                                style={{ position: 'relative', top: '1px' }}
                              />{' '}
                              {showFilters
                                ? formatMessage({
                                    id: 'app.views.widgets.dashboards.team_dashboard.hide_filters',
                                    defaultMessage: 'Hide filters',
                                  })
                                : formatMessage({
                                    id: 'app.views.widgets.dashboards.team_dashboard.filter_this_list',
                                    defaultMessage: 'Filter this list',
                                  })}
                            </Button>
                          </Col>
                          <Col className="col-auto ps-0 pb-3">
                            <Button
                              color="light"
                              className="btn-sm"
                              onClick={handleOpenColumnSelector}
                            >
                              <i
                                className={`${ICONS.CHECK_OPTIONS} me-2`}
                                style={{ position: 'relative', top: '1px' }}
                              />
                              <FormattedMessage
                                id="app.views.widgets.people.filterable_people_table.button.fields_selector.text"
                                defaultMessage="Select fields to display"
                              />
                            </Button>
                            {visibleColumns && (
                              <FilterablePeopleTableColumnSelectorModal
                                modalIsOpen={showColumnSelector}
                                columnDescriptors={
                                  columnsForVisualDisplayUnfiltered
                                }
                                onToggle={handleToggleColumnSelector}
                                onCallback={handleCallbackColumnSelector}
                                selectedColumns={visibleColumns}
                              />
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-auto">
                        <Row>
                          {questionColumnHeaders?.length
                            ? showAllResponsesToggle
                            : ''}
                          {multiselectActions}
                          {exportButton}
                        </Row>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Col>
                    <Row>
                      <Col>
                        {showFilters && (
                          <CardBody className="pt-3 pb-0">
                            <Row>
                              <Col>
                                <Row>
                                  {includeFilterInput}
                                  {excludeFilterInput}
                                </Row>
                              </Col>
                              {tiersListInput}
                              {eligibilityToggle}
                              <TerminatedToggle
                                onClick={toggleTerminated}
                                value={showTerminatedPeople}
                              />
                            </Row>
                          </CardBody>
                        )}
                      </Col>
                    </Row>
                    <Row className="p-3 pb-0 justify-content-between">
                      <Col className="ms-3">
                        <Row>
                          {additionalProgress && (
                            <Col className="col-12 col-md-auto ps-0 pb-3">
                              {additionalProgress}
                            </Col>
                          )}
                          {overallProgress && (
                            <Col className="ps-0 pb-3">{overallProgress}</Col>
                          )}
                          {averagesSummary && showAveragesContainer && (
                            <Col className="col-12 col-md-auto ps-0 pb-3">
                              {averagesSummary}
                            </Col>
                          )}
                          {smallHistogram && (
                            <Col className="col-12 col-md-auto ps-0 pb-3">
                              {smallHistogram}
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {tablePeople.length > 0 && (
                    <div
                      className="table-responsive"
                      data-list='{"valueNames": ["tables-row", "tables-first", "tables-last", "tables-handle"]}'
                    >
                      <table
                        id="people-table"
                        className="card-table table table-sm mb-0 calibration-table"
                      >
                        <thead>
                          <tr>
                            {columnsForVisualDisplay.map((c, index) => (
                              <th
                                key={index}
                                scope="col"
                                ref={(el) => {
                                  if (el) {
                                    if (index === 0) {
                                      el.style.setProperty(
                                        'padding-left',
                                        '0.85rem',
                                        'important'
                                      );
                                    } else if (
                                      index ===
                                      columnsForVisualDisplay.length - 1
                                    ) {
                                      el.style.setProperty(
                                        'padding-right',
                                        '0.85rem',
                                        'important'
                                      );
                                    }
                                  }
                                }}
                                role="button"
                                onClick={() => toggleSortByFields([c.field])}
                                className={
                                  'pe-1 ' + (c.className ? c.className : '')
                                }
                              >
                                <span
                                  id={'column-' + index}
                                  className="text-muted list-sort"
                                  data-sort="tables-row"
                                >
                                  {toggleSelectedRowField && index === 0 && (
                                    <div
                                      className="d-inline-block pe-3"
                                      onClick={(e) => {
                                        // don't propagate click since it will trigger the table header click
                                        // which will cause the table to scroll to the top
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled={!atLeastOneRowIsSelectable}
                                        role={
                                          atLeastOneRowIsSelectable
                                            ? 'button'
                                            : undefined
                                        }
                                        checked={allSelectableRowsAreSelected}
                                        onChange={toggleAllSelectableRows}
                                      />
                                    </div>
                                  )}
                                  <div className="d-inline-block">
                                    {c.name === 'Person'
                                      ? selectedRowIdsSet?.size > 0
                                        ? selectedRowIdsSet?.size === 1
                                          ? '1 person selected'
                                          : selectedRowIdsSet?.size +
                                            ' people selected'
                                        : tablePeople?.length + ' people'
                                      : c.name}
                                  </div>
                                </span>
                                {c.popoverContent && (
                                  <UncontrolledPopover
                                    placement="top"
                                    trigger="hover"
                                    target={'column-' + index}
                                  >
                                    {c.popoverContent}
                                  </UncontrolledPopover>
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="list">
                          {tablePeople.slice(0, tableLimit).map((tp, index) => {
                            // note: this models after FilterablePeopleTable for easier
                            // migration later
                            const personFields = tp;
                            const shouldDisableRow =
                              toggleSelectedRowField &&
                              isRowSelectable &&
                              !isRowSelectable(tp?.raw_data);

                            return (
                              <tr key={tp.id} id={`people-table-row-${index}`}>
                                {columnsForVisualDisplay.map((c, colIndex) => (
                                  <Fragment key={colIndex}>
                                    {colIndex === 0 && (
                                      <th scope="row">
                                        <Row>
                                          {toggleSelectedRowField && (
                                            <Col className="col-auto pe-0 checklist">
                                              <div
                                                className="form-check align-middle d-inline-block"
                                                style={{
                                                  height: '0.5rem',
                                                  paddingTop: '0.5rem',
                                                }}
                                              >
                                                <Input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  disabled={shouldDisableRow}
                                                  role={
                                                    shouldDisableRow
                                                      ? undefined
                                                      : 'button'
                                                  }
                                                  checked={selectedRowToggleFieldsSet.has(
                                                    getToggleField(personFields)
                                                  )}
                                                  onChange={() =>
                                                    toggleSelectedRowField(
                                                      getToggleField(
                                                        personFields
                                                      )
                                                    )
                                                  }
                                                />
                                              </div>
                                            </Col>
                                          )}
                                          <Col>
                                            {getField(
                                              tp,
                                              c,
                                              index + '-' + colIndex,
                                              colIndex
                                            )}
                                          </Col>
                                        </Row>
                                      </th>
                                    )}
                                    {colIndex !== 0 && (
                                      <td
                                        className={
                                          'pe-1 ' +
                                          (c.className ? c.className : '')
                                        }
                                      >
                                        {getField(
                                          tp,
                                          c,
                                          index + '-' + colIndex,
                                          colIndex
                                        )}
                                      </td>
                                    )}
                                  </Fragment>
                                ))}
                                {isAdmin &&
                                  tp.status !== 'T' &&
                                  !tp.sensitive_data_omitted && (
                                    <UncontrolledPopover
                                      key={`mdre-${index}`}
                                      delay={{
                                        show: 25,
                                        hide: 250,
                                      }}
                                      trigger="hover focus"
                                      placement="left"
                                      target={`people-table-row-${index}`}
                                    >
                                      <div
                                        id={`mdre-popover-${index}`}
                                        key={`mdre-div-${index}`}
                                        className="resume-item-action"
                                      >
                                        <Button
                                          color="primary"
                                          className={`btn-sm btn-primary ${
                                            shouldIgnoreCampaignMetadataUnlessUndefined
                                              ? 'disabled'
                                              : ''
                                          }`}
                                          onClick={() => {
                                            if (
                                              !shouldIgnoreCampaignMetadataUnlessUndefined
                                            ) {
                                              setPersonToEdit(tp.id);
                                              toggleModalDatasetRecordEditor();
                                            }
                                          }}
                                        >
                                          <FormattedMessage
                                            id="app.views.widgets.dashboards.team_dashboard.edit"
                                            defaultMessage="Edit"
                                          />
                                        </Button>
                                      </div>
                                      {shouldIgnoreCampaignMetadataUnlessUndefined && (
                                        <UncontrolledPopover
                                          trigger="hover"
                                          placement="top"
                                          target={`mdre-popover-${index}`}
                                        >
                                          <FormattedMessage
                                            id="app.views.widgets.dashboards.team_dashboard.edit_in_hris"
                                            defaultMessage="
                                        To edit this data, change it directly in
                                        your HRIS.
                                      "
                                          />
                                        </UncontrolledPopover>
                                      )}
                                    </UncontrolledPopover>
                                  )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Card>
                {tablePeople.length === 0 && (
                  <div className="text-center mb-4">
                    <span className="text-muted">
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.no_results_found"
                        defaultMessage="No results found"
                      />
                    </span>
                  </div>
                )}
              </Col>
            </Row>
            {tableLimit < tablePeople?.length && <Loading />}
          </>
        )}
      </>
    ),
    [
      tablePeople,
      isAnyFilterApplied,
      currentFinalRatingPerson,
      showFinalRatingModalEditor,
      onFinalRatingModalClosed,
      toggleFinalRatingModalEditor,
      formatMessage,
      finalRatingObject,
      ratings,
      onSaveFinalRating,
      isAdmin,
      showModalDatasetRecordEditor,
      onSaveModalDatasetRecordEditor,
      toggleModalDatasetRecordEditor,
      personToEdit,
      // @ts-expect-error
      personDatasourceCampaign?.name,
      // @ts-expect-error
      personDatasourceCampaign?.id,
      userSub,
      toggleFilters,
      showFilters,
      toggleTerminated,
      showTerminatedPeople,
      handleOpenColumnSelector,
      showColumnSelector,
      columnsForVisualDisplayUnfiltered,
      handleToggleColumnSelector,
      handleCallbackColumnSelector,
      visibleColumns,
      questionColumnHeaders?.length,
      showAllResponsesToggle,
      multiselectActions,
      exportButton,
      includeFilterInput,
      excludeFilterInput,
      tiersListInput,
      eligibilityToggle,
      additionalProgress,
      overallProgress,
      averagesSummary,
      showAveragesContainer,
      smallHistogram,
      columnsForVisualDisplay,
      tableLimit,
      toggleSelectedRowField,
      atLeastOneRowIsSelectable,
      allSelectableRowsAreSelected,
      toggleAllSelectableRows,
      selectedRowIdsSet?.size,
      toggleSortByFields,
      isRowSelectable,
      shouldIgnoreCampaignMetadataUnlessUndefined,
      selectedRowToggleFieldsSet,
      getToggleField,
      getField,
    ]
  );

  const peopleTable = useMemo(
    () => (
      <>
        {people?.length > 0 && (
          <>
            {currentPromotionPacketId && (
              <PromotionPacket
                // @ts-expect-error
                promotionPacketId={currentPromotionPacketId?.toString()}
                inModal={true}
                toggle={() => setCurrentPromotionPacketId(null)}
                onClosed={() => setCurrentPromotionPacketId(null)}
                callback={onUpdatePacket}
              />
            )}
            {peopleTableBody}
          </>
        )}
      </>
    ),
    [people?.length, currentPromotionPacketId, onUpdatePacket, peopleTableBody]
  );

  const loadOrRenderOutput = loadOrRender(people, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  window.onscroll = handleScroll;

  return (
    <>
      {!people && (
        <Loading
          message={formatMessage({
            id: 'app.views.widgets.dashboards.team_dashboard.message.loading_team_data',
            defaultMessage:
              'Loading team data. This may take several seconds...',
          })}
        />
      )}
      {people && people.length === 0 && (
        <Card>
          <CardBody>
            <div>
              <FormattedMessage
                id="app.views.widgets.dashboards.team_dashboard.no_access_go_here_instead"
                defaultMessage="
              You do not have access to view team performance data. Trying to
              complete your responses for the current cycle? <span>Go here instead.</span>"
                values={{
                  span: (msg) => <Link to={PERFORMANCE().path}>{msg}</Link>,
                }}
              />
            </div>
          </CardBody>
        </Card>
      )}
      {peopleTable}
      {isPrivateMode && (
        <style>{`.list th, td.pe-1:nth-child(-n+6) { filter: blur(8px); }
                .avatar, .fw-bold.small > .text-dark { filter: blur(10px); }`}</style>
      )}
      <Loading
        active={filteringInProgress}
        layout="overlay"
        message={formatMessage({
          id: 'app.components.spinner.filtering_calculation_loader.message.applying_filters',
          defaultMessage: 'Applying filters...',
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { authUser, me, currentOrganization, currentProxyPerson, demoPeople } =
    state;

  return {
    authUser,
    me,
    currentOrganization,
    currentProxyPerson,
    demoPeople,
  };
};

export default connect(mapStateToProps)(
  withRouter(
    // @ts-expect-error
    React.memo(TeamDashboard)
  )
);
