import { AttachedContentTypes, Features } from 'types';
import {
  INVALID_REQUEST,
  NETWORK_ERROR,
  NETWORK_ERROR_MESSAGE,
  SUBMIT_ERROR,
  UNSAVED_CHANGES_PROMPT,
} from '../../consts/consts';
import React, { useRef } from 'react';
import { SKILL_TYPE_BEHAVIOR, SKILL_TYPE_EXPERIENCE } from '../models/Skill';
import { useDrag, useDrop } from 'react-dnd';

import { OMITTED_FIELD_KEY_PREFIX } from 'utils/models/Person';
import { capitalize } from './formatter';
// for autocomplete, in the spirit of the UX research on autocompletes that suggest
// focusing on what's different instead of what's similar, we lighten the matching
// text so the user can focus on what's different
import config from '../../utils/util/config';
import { differenceInCalendarDays } from 'date-fns';
import { getDatePart } from './time';
import { toast } from 'react-toastify';

export const log = (message, ...args) => {
  // @ts-expect-error
  if (!config.isProduction()) {
    console.info(message, ...args);
  }
};

/**
 * Convert `URLSearchParams` `[]` properties to array objects.
 * Usage: const params = getSearchParamsAsObjectOfArrays(new URLSearchParams('?foo[]=bar&foo[]=baz')
 */
export const getSearchParamsAsObjectOfArrays = (props) => {
  const params = {};

  for (const key of props.keys()) {
    if (key.endsWith('[]')) {
      params[key.replace('[]', '')] = props.getAll(key);
    } else {
      params[key] = props.get(key);
    }
  }

  return params;
};

export const getFriendlyUserFacingErrorObjectAndMessage = (
  errorObject,
  hardErrorMessage
) => {
  if (hardErrorMessage) {
    // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
    // leave this message blank as those errors will get surfaced below

    if (hardErrorMessage === NETWORK_ERROR) {
      // for network errors, show message prompting retry
      return [errorObject, NETWORK_ERROR_MESSAGE];
    } else if (!errorObject?.data) {
      // if the error contains data (ie. validation errors), callback will handle
      // otherwise, default to submit error
      return [errorObject, SUBMIT_ERROR];
    } else if (typeof errorObject.data !== 'object') {
      // we received an error.data that is NOT a validation error object
      // (e.g. could be html) so treat it like a generic submit error
      return [
        {
          ...errorObject,
          data: undefined,
        },
        // if status is 400, it's a validation error of some sort
        errorObject.status === 400 ? INVALID_REQUEST : SUBMIT_ERROR,
      ];
    } else {
      // return 404's and 403's without the hard error message
      if (errorObject.status === 404 || errorObject.status === 403) {
        return [errorObject, null];
      }

      // otherwise, error object data is populated and is an object, so return as is
      // with any hard error message provided
      return [errorObject, hardErrorMessage];
    }
  }

  // this was likely not a hard error, but instead a soft validation error,
  // so return no error message
  return [errorObject, null];
};

export const getFriendlyErrorMessageIfErrorOccurred = (
  error,
  hardErrorMessage
) => {
  const [errorObject, friendlyErrorMessage] =
    getFriendlyUserFacingErrorObjectAndMessage(error, hardErrorMessage);

  if (friendlyErrorMessage) {
    return friendlyErrorMessage;
  }

  // this was a validation error, so show a friendly toast that structures it
  // in a way that's easy to understand
  const validationErrors = errorObject?.data;
  if (validationErrors) {
    // if validationErrors is array, return the list in a single string of separate sentences
    if (Array.isArray(validationErrors)) {
      return validationErrors.join('. ');
    }

    // validation errors is an object, show accordingly
    const validationErrorMessages = Object.keys(validationErrors).map((key) =>
      key === '__all__'
        ? validationErrors[key]
        : `${capitalize(key)}: ${validationErrors[key]}`
    );
    return validationErrorMessages.join(' ');
  }
};

export const renderErrorOrCallback = (
  successCallback: (any) => void,
  errorCallback?: (any) => void
) => {
  return (response, error, hardErrorMessage = null) => {
    const errorMessage = getFriendlyErrorMessageIfErrorOccurred(
      error,
      hardErrorMessage
    );
    if (errorMessage) {
      if (errorCallback) {
        errorCallback(errorMessage);
      }
    } else {
      successCallback(response);
    }
  };
};

export const toastErrorOrCallback = (
  successCallback: (any) => void,
  errorCallback?: (any) => void,
  autoClose = 5000 // Set it to 0 to switch off autoClose.
) => {
  return (response, error, hardErrorMessage = null) => {
    const errorMessage = getFriendlyErrorMessageIfErrorOccurred(
      error,
      hardErrorMessage
    );
    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: autoClose === 0 ? false : autoClose,
      });
      if (errorCallback) {
        errorCallback(errorMessage);
      }
    } else {
      successCallback(response);
    }
  };
};

// add quotes to string, trim it, and change internal quotes to single quotes
export const quoteString = (str) => {
  return (
    '"' + str.trim().replace('"', "'").replace('“', "'").replace('”', "'") + '"'
  );
};

// make a string suitable for use as a label (i.e. _ -> space, capitalize)
export const labellize = (str) => {
  if (!str) {
    return '';
  }
  return capitalize(str.replaceAll('_', ' '));
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

// parse a date in yyyy-mm-dd format into the local timezone
// as new Date(<YYYY-MM-DD string>) outputs in UTC instead of
// local timezone per https://stackoverflow.com/questions/2587345/why-does-date-parse-give-incorrect-results
export const parseDate = (input) => {
  if (!input) {
    return null;
  }

  // if it has /'s, parse as MM/DD/YYYY, else parse as YYYY-MM-DD
  if (input.indexOf('/') !== -1) {
    const parts = input.split('/');

    // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[2], parts[0] - 1, parts[1]); // Note: months are 0-based
  } else {
    // assume YYYY-MM-DD
    const parts = input.split('-');

    // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2]); // Note: months are 0-based
  }
};

export const getMMDDYYYYStringFromDate = (date) => {
  if (!date) {
    return '';
  }
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${
    year < 10 ? '0' + year : year
  }`;
};

export const getPrettyDate = ({
  dateString,
  hideYear = false,
  includeTime = false,
  locale = 'en-US',
}) => {
  // if is ISO time, use exactly; else, save as T00:00:00 to ensure that
  // for example, a YYYY-MM-DD string with no time outputs the correct date
  const isoDateString =
    typeof dateString === 'string' && dateString.indexOf('T') === -1
      ? parseDate(dateString)
      : dateString;

  const output = new Date(isoDateString).toLocaleDateString(locale, {
    year: hideYear ? undefined : 'numeric',
    month: 'long',
    day: 'numeric',
  });

  if (includeTime) {
    return new Date(isoDateString).toLocaleTimeString(locale, {
      year: hideYear ? undefined : 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
    });
  }

  return output;
};

export const getQuarterNameFromDateInQXYYYYFormat = (dateOrString) => {
  const date =
    typeof dateOrString === 'string' ? parseDate(dateOrString) : dateOrString;
  const quarter = Math.floor(date.getMonth() / 3) + 1;
  return `Q${quarter} ${date.getFullYear()}`;
};

export const getLocaleDependentStringFromDateOrString = (
  dateOrString,
  locale
) => {
  if (!dateOrString) {
    return '';
  }

  if (typeof dateOrString === 'string') {
    return getPrettyDate({ dateString: dateOrString, locale });
  } else {
    return dateOrString.toLocaleDateString(locale);
  }
};

// from https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
const _MS_PER_DAY = 1000 * 60 * 60 * 24;
const dateDiffInDays = (a, b) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const getDaysSince = (isoDateString) => {
  if (!isoDateString) {
    return undefined;
  }

  return dateDiffInDays(new Date(isoDateString), new Date());
};

// if same YYYY-MM-DD, they are equal, else not
export const datesAreEqual = (
  dateOrIsoDateString1,
  dateOrIsoDateString2,
  timesMustAlsoMatch = false
) => {
  const date1 =
    dateOrIsoDateString1 instanceof Date
      ? timesMustAlsoMatch
        ? dateOrIsoDateString1.toISOString()
        : getDatePart(dateOrIsoDateString1)
      : dateOrIsoDateString1;

  const date2 =
    dateOrIsoDateString2 instanceof Date
      ? timesMustAlsoMatch
        ? dateOrIsoDateString2.toISOString()
        : getDatePart(dateOrIsoDateString2)
      : dateOrIsoDateString2;

  return date1 === date2;
};

export const getPrettyDateRange = (
  isoDateString1,
  isoDateString2,
  hideYearIfSame = true,
  locale = 'en-US'
) => {
  if (hideYearIfSame) {
    if (
      new Date(isoDateString1).getFullYear() ===
      new Date(isoDateString2).getFullYear()
    ) {
      return (
        getPrettyDate({
          dateString: isoDateString1,
          hideYear: true,
          locale,
        }) +
        ' - ' +
        getPrettyDate({ dateString: isoDateString2, hideYear: true, locale })
      );
    }
  }
  return (
    getPrettyDate({ dateString: isoDateString1, locale }) +
    ' - ' +
    getPrettyDate({ dateString: isoDateString2, locale })
  );
};

// get value as timestamp (format is MMYYYY)
export const getDateFromMMYYYYString = (dateString) => {
  if (
    !dateString ||
    !dateString.length ||
    (dateString.length !== 5 && dateString.length !== 6)
  ) {
    // unexpected date string, so return current date (which is the default for an "empty" date
    return new Date();
  }

  // 052013 converts to 52013, whereas 112019 stays 112019, hence the below
  const year =
    dateString.length === 5
      ? parseInt(dateString.substr(1, 4), 10)
      : parseInt(dateString.substr(2, 4), 10);

  const month =
    dateString.length === 5
      ? parseInt(dateString.substr(0, 1), 10) - 1
      : parseInt(dateString.substr(0, 2), 10) - 1;

  return new Date(year, month);
};

export const getDateFromMMYYYYArray = (params) => {
  if (!Array.isArray(params)) {
    console.error('Received unexpected date string type: ' + typeof params);
  }

  if (!params || !params.length || params.length !== 1) {
    console.error(
      'getDateFromMMYYYYArray date array length unexpected: ' + params.length
    );
  }

  // empty value we will treat as a new date
  const date = getDateFromMMYYYYString(params[0] ? params[0].toString() : '');

  return date;
};

export const getDateArrayIsInTheFuture = (params) => {
  return getDateFromMMYYYYArray(params) > new Date();
};

export const getDaysInMonth = (year, month) =>
  new Date(year, month, 0).getDate();

export const addMonths = (input, months) => {
  const date = new Date(input);
  date.setDate(1);
  date.setMonth(date.getMonth() + months);
  date.setDate(
    Math.min(
      input.getDate(),
      getDaysInMonth(date.getFullYear(), date.getMonth() + 1)
    )
  );
  return date;
};

export const monthDiff = (d1, d2) => {
  // matches the logic of datediff_in_months in the backend
  const days = differenceInCalendarDays(d2, d1);
  return days <= 0 ? 0 : Math.round(days / 30);
};

export const isValidUrl = (str) => {
  if (str && (str.startsWith('https://') || str.startsWith('http://'))) {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const isValidGoogleDocUrl = (url) => {
  return url?.startsWith('https://docs.google.com/') && isValidUrl(url);
};

// once we migrate to react-router-dom v6 we can use useNavigate() hook
export const toggleUrlHash = (shouldToggle, title, placeholder) => {
  if (shouldToggle) {
    window.history.pushState(title, '', placeholder);
  } else {
    history.replaceState(
      {},
      document.title,
      window.location.href.split('#')[0]
    );
  }
};

// removes duplicates based on id
export const filterUniqueById = (arr) => {
  if (!arr) {
    return null;
  }

  const uniqueIds = arr
    .map((o) => o.id)
    .filter((itm, i, a) => i === a.indexOf(itm));
  return uniqueIds.map((id) => arr.find((o) => o.id === id));
};

export const filterUniqueByESIndexandIdAndPutMostFrequentFirst = (arr) => {
  if (!arr) {
    return null;
  }

  const uniqueIdsAndIndex = arr
    .map((o) => o._index + '-' + o.id)
    .filter((itm, i, a) => i === a.indexOf(itm));

  const getCount = (o) => {
    return arr.reduce((sum, obj) => {
      if (obj._index === o._index && obj.id === o.id) {
        return sum + 1;
      } else {
        return sum;
      }
    }, 0);
  };

  return uniqueIdsAndIndex
    .map((idAndIndex) => arr.find((o) => o._index + '-' + o.id === idAndIndex))
    .sort((a, b) => {
      // sort in descending order
      return getCount(b) - getCount(a);
    });
};

// for now, match based on id or email address
export const hasPerson = (arr, person) => {
  return (
    arr &&
    arr.findIndex(
      (p) =>
        (p.id && person.id && p.id === person.id) ||
        (p.email && person.email && p.email === person.email)
    ) !== -1
  );
};

export const truncateString = (str, len) => {
  if (!str || str.length <= len) {
    return str;
  }

  return str.substr(0, len - 3) + '...';
};

export const getImagesFromHtmlDescription = (desc) => {
  if (!desc || desc.length === 0) {
    return null;
  }

  let m;
  const urls = [];
  const rex = /<img.+?src="([^"]*)"[^>]*>/g;
  while ((m = rex.exec(desc))) {
    // @ts-expect-error
    urls.push(m[1]);
  }

  if (urls.length === 0) {
    return null;
  }

  // extract <img src="" items into an image with original attribute
  // (TODO: thumbnail attribute in the future)
  return urls.map((u) => ({ original: u }));
};

export const getPlainText = (textString) => {
  return textString.trim().replace(/(<([^>]+)>)/gi, '');
};

export const trimAtWordBoundary = (textString, length) => {
  return textString.substr(
    0,
    Math.min(length, textString.substr(0, length).lastIndexOf(' '))
  );
};

export const activityOrSkillPageUrl = (activity) => {
  const skillIds = [SKILL_TYPE_BEHAVIOR.id, SKILL_TYPE_EXPERIENCE.id];
  const base = skillIds.includes(activity.type) ? 'skills' : 'activities';

  return '/' + base + '/' + activity?.id;
};

// adds listener for unload event
// prompts user with warning dialog if there are unsaved changes
export const unloadWarning = (hasUnsavedChanges) => {
  const onUnload = (e) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      e.returnValue = UNSAVED_CHANGES_PROMPT;
      return UNSAVED_CHANGES_PROMPT;
    }
  };

  window.addEventListener('beforeunload', onUnload);
  return () => window.removeEventListener('beforeunload', onUnload);
};

export const confirmLeavePage = (hasUnsavedChanges) => {
  // returns true if there are no unsaved changes
  // otherwise, return user's choice from dialog
  return !hasUnsavedChanges || window.confirm(UNSAVED_CHANGES_PROMPT);
};

//
// prepTagsForSubmit is used in transformObjectBeforeSubmit to
// prepare 'tag' lists by adding the organization id and removing
// the 'object' property (which is only needed for the UI).
//
// the 3rd parameter controls whether or not the tag will be auto-created
// if it does not exist (default is to autocreate)
//
// sample usage:
//
//    skills: prepTagsForSubmit(object.skills, orgId),
//
export const prepTagsForSubmit = (tags, organizationId, autocreate = true) => {
  const prepTag = (t) =>
    !autocreate && t.id
      ? t.id
      : {
          ...{
            ...t,
            object: undefined,
          },
          organization: organizationId,
        };

  // to allow for a single string as a tag, output this as a singleton list
  // This is for the use case where someone uses a dropdown to select a single
  // tag from a list as a string (example: perf campaign where positive_skills
  // is a dropdown list instead of freeform selection); we still want to return
  // it to the backend
  // appropriately
  if (typeof tags === 'string') {
    if (tags.length > 0) {
      return [prepTag({ name: tags })];
    } else {
      // HACK: to allow for empty string as id in dropdown to represent none,
      // e.g. for a "none of the above" list of options when selecting a skill
      return [];
    }
  }

  return tags ? tags.map(prepTag) : null;
};

// this could change behavior. some locales use spaces
// as separators rather than commas, in which case this
// will return differently. (e.g. French, German, Canadian)
export const numberWithCommas = (x, decimal = 0) => {
  if (!x) return 0;
  return x < 1
    ? x.toFixed(decimal)
    : parseFloat(x.toFixed(decimal)).toLocaleString('en-US');
};

export const dateDiffInMonths = (a, b) => {
  const bDate = new Date(b);
  const aDate = new Date(a);
  const diffInSeconds = (bDate.getTime() - aDate.getTime()) / 1000.0;
  const diffInDays = diffInSeconds / 84400.0;
  // @ts-expect-error
  return Math.abs(parseInt(Math.round(diffInDays / 30.0)));
};

export const myOptionFilter = (options, inputRaw) => {
  // Return all options if no input
  if (!inputRaw) return options;

  const input = inputRaw.toLowerCase();
  return options.filter((option) => {
    const label =
      typeof option === 'string'
        ? option.toLowerCase()
        : (option?.name || option?.label)?.toLowerCase();
    return label.includes(input);
  });
};

export const basicDropdownStringSearch = (value, callback, options) =>
  callback(myOptionFilter(options, value));

// Returns date object with the local date (by default, if timezone
// is unspecified, Date returns the date in GMT)
// Assumes input format is "2022-12-31" (ex. perf coverage dates)
export const yyyymmddToLocalDate = (dateString) => {
  if (typeof dateString !== 'string') {
    return null;
  }
  const dateComponents = dateString.split('-');
  // @ts-expect-error
  return new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2]);
};

export const getDayOfWeek = (date) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[date.getDay()];
};

export const stripHtml = (htmlStr, keepLineBreaks = false) => {
  // ensure that null/undefined is unmodified
  if (!htmlStr) return htmlStr;

  let output = htmlStr;

  if (keepLineBreaks) {
    // replace connected paragraph tags and line breaks with new lines
    output = output.replace(/<\/p><p>/gi, '\n');
    output = output.replace(/<br \/>/gi, '\n');
  }

  const tmp = document.createElement('DIV');
  tmp.innerHTML = output;
  return tmp.textContent || tmp.innerText || '';
};

export const toPercent = (num, total) =>
  total > 0 ? Math.round((100 * num) / total) : 0;

export const asInt = (str, defaultValue = undefined) => {
  const value = parseInt(str);
  return isNaN(value) ? defaultValue : value;
};

export const comparePeople = (a, b) => {
  if (!a?.full_name && b) {
    return -1;
  }

  if (a && !b?.full_name) {
    return 1;
  }

  if (!a?.full_name && !b?.full_name) {
    return 0;
  }

  return a.full_name.localeCompare(b.full_name);
};

const roundToTwoDecimalPlaces = (num) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const getAverageForObjectValues = (val) => {
  // if value is not an object, it is its own average so return it
  if (
    typeof val !== 'object' ||
    (val?.key ?? ''.startsWith(OMITTED_FIELD_KEY_PREFIX))
  ) {
    return val;
  }

  const valKeys = Object.keys(val);

  // if parsed value is null, this is someone who didn't yet
  // complete the question, so don't count for averaging
  const nonNullValues = Object.values(val).filter((v) => v !== null);

  const average =
    valKeys?.length > 0
      ? nonNullValues.reduce((acc, v) => {
          // if acc is null, it means at least one invalid value,
          // so return null
          if (acc === null) {
            return null;
          }

          // @ts-expect-error
          const parsedValue = parseFloat(v);

          if (isNaN(parsedValue)) {
            return null;
          }

          if (acc === undefined) {
            return parsedValue / nonNullValues.length;
          }
          // @ts-expect-error
          return acc + parsedValue / nonNullValues.length;
        }, undefined)
      : undefined;

  // round before returning (note: null and undefined mean
  // two different things in the above context)
  return average === undefined || average === null
    ? average
    : roundToTwoDecimalPlaces(average);
};

export const sortByFieldFunc = (a, b, fieldOrFuncList, reverse = false) => {
  if (!(fieldOrFuncList?.length > 0)) {
    // a must be equal to b
    return 0;
  }

  const [firstFieldOrFunc, ...remainingFieldOrFuncs] = fieldOrFuncList;
  const reverseFlag = reverse ? -1 : 1;

  if (typeof firstFieldOrFunc === 'function') {
    const sortSoFar = firstFieldOrFunc(a, b, reverse);
    if (sortSoFar) {
      return sortSoFar;
    }
    return sortByFieldFunc(a, b, remainingFieldOrFuncs, reverse);
  }

  const averageA =
    a[firstFieldOrFunc]?.constructor === Object
      ? getAverageForObjectValues(a[firstFieldOrFunc])
      : undefined;
  const averageB =
    b[firstFieldOrFunc]?.constructor === Object
      ? getAverageForObjectValues(b[firstFieldOrFunc])
      : undefined;

  // note: this may be a number or string, so we should consider each case
  const aExists =
    typeof a[firstFieldOrFunc] !== 'undefined' &&
    a[firstFieldOrFunc] !== null &&
    (typeof a[firstFieldOrFunc] === 'number' ||
      typeof averageA === 'number' ||
      Array.isArray(a[firstFieldOrFunc]) ||
      (typeof a[firstFieldOrFunc] === 'string' && a[firstFieldOrFunc]));
  const bExists =
    typeof b[firstFieldOrFunc] !== 'undefined' &&
    b[firstFieldOrFunc] !== null &&
    (typeof b[firstFieldOrFunc] === 'number' ||
      typeof averageB === 'number' ||
      Array.isArray(b[firstFieldOrFunc]) ||
      (typeof b[firstFieldOrFunc] === 'string' && b[firstFieldOrFunc]));

  if (!aExists && bExists) {
    return 1;
  }

  if (aExists && !bExists) {
    return -1;
  }

  if (!aExists && !bExists) {
    return sortByFieldFunc(a, b, remainingFieldOrFuncs, reverse);
  }

  // if this is an array, the first value is the value to use for
  // sorting and the second is a display value for the UI; however,
  // if it's an object, it's a set of values for which we should
  // use the average
  const aValue =
    typeof averageA !== 'undefined'
      ? averageA
      : Array.isArray(a[firstFieldOrFunc])
      ? a[firstFieldOrFunc][0]
      : a[firstFieldOrFunc];
  const bValue =
    typeof averageB !== 'undefined'
      ? averageB
      : Array.isArray(b[firstFieldOrFunc])
      ? b[firstFieldOrFunc][0]
      : b[firstFieldOrFunc];

  // sort numeric fields by converting to values
  if (aValue < bValue) {
    return -1 * reverseFlag;
  }
  if (aValue > bValue) {
    return 1 * reverseFlag;
  }

  // equal, so sort by next field
  return sortByFieldFunc(a, b, remainingFieldOrFuncs, reverse);
};

export const isEnabled = (features, flag) => !!features[flag]?.enabled;

export const isEnabledWithDefault = (
  features: Features,
  flag: string,
  defaultValue: boolean
) => !!(features[flag]?.enabled ?? defaultValue);

export const stringWithMax = (str, max) => {
  if (max >= str.length) {
    return str;
  }
  return str.slice(0, max - 3) + '...';
};

/**
 * From https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 *
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export const getInsecureHashCodeFromString = (str) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

// when a user generates content that we use for id fields in the DOM,
// we need to ensure they are valid so things don't break.
export const stripInvalidHtmlSelectorCharacters = (str) => {
  const regex = /[^a-zA-Z0-9-_]/g;
  return str.replace(regex, '');
};

// for use in demo/preview mode (for simulating elasticsearch
// filtering on fake/demo people)
export const personMatchesQuerySimpleCase = (person, query) => {
  return (
    person.full_name?.toLowerCase().startsWith(query) ||
    person.given_name?.toLowerCase().startsWith(query) ||
    person.family_name?.toLowerCase().startsWith(query) ||
    person.email?.toLowerCase().startsWith(query) ||
    person.title?.toLowerCase().startsWith(query)
  );
};

// for drag and drop / React DnD
export const DraggableItemTypes = {
  QUESTION: 'question',
  TABLE_ROW: 'table_row',
  AGENDA_ITEM: 'agenda_item',
};

export const DRAG_AND_DROP_ICON = (
  <>
    <i className="fe fe-more-vertical" />
    <i
      className="fe fe-more-vertical position-relative"
      style={{ right: '0.6rem' }}
    />
  </>
);

const dragAndDropHover = (item, monitor, ref, hoverIndex, moveFunction) => {
  if (!ref.current) {
    return;
  }

  const dragIndex = item.index;

  // Don't replace items with themselves
  if (dragIndex === hoverIndex) {
    return;
  }

  // Determine rectangle on screen
  const hoverBoundingRect = ref.current?.getBoundingClientRect();

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;

  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%
  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return;
  }

  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return;
  }

  // Time to actually perform the action
  moveFunction(dragIndex, hoverIndex);

  // Note: we're mutating the monitor item here!
  // Generally it's better to avoid mutations,
  // but it's good here for the sake of performance
  // to avoid expensive index searches.
  item.index = hoverIndex;
};

export const useDragAndDrop = (
  DraggableItemType,
  uniqueId,
  hoverIndex,
  moveFunction,
  canDrag
) => {
  // drag and drop stuff based on example at
  // https://react-dnd.github.io/react-dnd/examples/sortable/simple
  const dragAndDropRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: DraggableItemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item, monitor) =>
      dragAndDropHover(item, monitor, dragAndDropRef, hoverIndex, moveFunction),
  });

  const [{ isDragging }, drag] = useDrag({
    type: DraggableItemType,
    item: () => {
      return { id: uniqueId, index: hoverIndex };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag() {
      return canDrag;
    },
  });

  return [dragAndDropRef, handlerId, drop, isDragging, drag];
};

// for use in tables where we want to allow exporting of a raw value
// but want to show something more visual in the UI
export const isRawValueFollowedByOutputValueArrayOfTwo = (val) => {
  return Array.isArray(val) && val.length === 2 && typeof val[0] !== 'object';
};

export const formatIntegerList = (list) => {
  if (!list?.length) {
    return '';
  }

  // sort low to high
  const sortedList = list.sort().map((x) => x.toString());

  return new Intl.ListFormat('en', {
    type: 'disjunction',
    style: 'long',
  }).format(sortedList);
};

// return true unless null or undefined
export const exists = (x) => {
  return typeof x !== 'undefined' && x !== null;
};

export const getSHADigest = async (message) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
};

// our convention for durable objects: /<type>/<key>
// where "type" matches the key in the `routing` object in `collab/src/index.ts`
// and "key" is an opaque identifier that all clients joining the same shared
// session will be able to use
export const getWebSocketPath = async (type, path) => {
  const str = `CONFIRM_${type}_${path}`;
  const hash = await getSHADigest(str);
  return `${type}/${hash}`;
};

export const sumObjectValues = (obj): number => {
  if (typeof obj !== 'object') {
    return 0;
  }
  // @ts-expect-error
  return Object.values(obj).reduce((total, v) => total + v, 0);
};

export const createCSVFileName = (name = '') => {
  return (
    'Confirm-' +
    (name ? name + '-' : '') +
    new Date().toISOString().split('.')[0] +
    '.csv'
  );
};

export const isCORSEnabledFilesUrl = (url) => {
  if (typeof url !== 'string') {
    return false;
  }

  // on local environment, allow the domain of REACT_APP_CONFIRM_API_URL
  // which is what we use for uploading files
  // @ts-expect-error
  if (config.isLocal()) {
    return url.includes(
      // @ts-expect-error
      new URL(process.env.REACT_APP_CONFIRM_API_URL).hostname
    );
  }

  // any environment ending in .confirm.com is expected to be fine for CORS images
  return /^https?:\/\/[A-Za-z_0-9.-]+\.confirm\.com/.test(url);
};

export const getSign = (value) => {
  const toCheck = value ?? 0;
  if (toCheck > 0) {
    return 'positive';
  } else if (toCheck < 0) {
    return 'negative';
  }
  return 'zero';
};

export const autoDownloadLink = ({ data, filename, mime }) => {
  const url = window.URL.createObjectURL(new Blob([data], { type: mime }));
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(url);
};

// take any number or falsy value and return it as a string with 1 decimal place
export const formatSimpleDecimal = (n) => {
  // if it's an integer, return it as a string
  // otherwise, return as a string with with 1 decimal places
  // 3, 3.0, 2.9999 -> '3'
  // 3.14159 -> '3.1'
  // 3.15 -> '3.2'
  // undefined, null, 0, 0.0 -> '0'
  if (!n) {
    return '0';
  }
  return n.toLocaleString(undefined, { maximumFractionDigits: 1 });
};

// this can be used for sorting rows being prepared for a csv
export const compareForCsv = (a, b, descending = false) => {
  const FLIP_IF_DESCENDING = descending ? -1 : 1;
  // Handle undefined values: treat them as less than any other value
  if (a === undefined && b === undefined) return 0;
  if (a === undefined) return -1 * FLIP_IF_DESCENDING;
  if (b === undefined) return 1 * FLIP_IF_DESCENDING;

  // Compare strings
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b) * FLIP_IF_DESCENDING;
  }

  // Compare numbers
  if (typeof a === 'number' && typeof b === 'number') {
    return (a - b) * FLIP_IF_DESCENDING;
  }

  // Handle mixed types: strings are considered greater than numbers
  if (typeof a === 'string') return 1 * FLIP_IF_DESCENDING;
  if (typeof b === 'string') return -1 * FLIP_IF_DESCENDING;

  // Fallback for unexpected types or values (shouldn't happen with correct typings)
  return 0;
};

export const sortCsvRows = (rows) => {
  // rows will be typed "(string | number | undefined)[]" when this is typescripted
  return rows.sort((a, b) => {
    for (let i = 0; i < a.length; i++) {
      const compareResult = compareForCsv(a?.[i], b?.[i]);
      if (compareResult !== 0) {
        return compareResult;
      }
    }
    return 0;
  });
};

export const simpleHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};

// find the type of object attached to another
// if that exists
export const findAttachedObjectType = (
  attachedContentTypes: AttachedContentTypes,
  content_type: string | null | undefined
): string | undefined => {
  return Object.keys(attachedContentTypes ?? {}).find((key) => {
    return attachedContentTypes[key] === content_type;
  });
};

export const getTwoNamesFromName = (name: string): [string, string] => {
  const names = name.split(/\s+/);
  const firstName = names.length > 1 ? names.slice(0, -1).join(' ') : name;
  const lastName = names.length > 1 ? names.pop() ?? '' : '';
  return [firstName, lastName];
};

export const getTwoNamesForLshus = (name: string): [string, string] => {
  const words = name.split(',');
  const wordLength = words.length;
  let firstName: string = '';
  let lastName: string = '';

  if (wordLength > 1) {
    firstName = words[1].trim();
    lastName = words[0].trim();
  } else if (wordLength === 1) {
    firstName = words[0].trim();
    lastName = '';
  }

  return [firstName, lastName];
};

export const getTwoNamesForUsana = (name: string): [string, string] => {
  const words = name.split('(');
  return getTwoNamesFromName(words[0].trim());
};
