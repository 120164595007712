import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useMemo } from 'react';
import {
  SKILL_TYPE_BEHAVIOR,
  SKILL_TYPE_EXPERIENCE,
} from '../../utils/models/Skill';

import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { operatingManualSections } from './PersonOperatingManual';

const getProfileCompletenessState = (
  features,
  person,
  activities,
  editIntro,
  editSkills,
  editBehaviors,
  createActivity,
  formatMessage
) => {
  if (!person?.profile) {
    return null;
  }

  // default 40% completeness
  let percent = 40;

  const possibleObjectsList = operatingManualSections(formatMessage).reduce(
    (arr, section) => {
      // @ts-expect-error
      return arr.concat(section.content.map((c) => c.name));
    },
    []
  );

  const completedOperatingManualObjects = possibleObjectsList.filter(
    (name) =>
      person.profile?.operating_manual &&
      person.profile?.operating_manual[name]?.length > 0
  );

  const completedOmCount = completedOperatingManualObjects.length;
  const possibleOmCount = possibleObjectsList.length;

  // complete intro = at least one contact record, pronouns, and join date
  const hasCompleteIntro =
    person.profile?.pronouns && person.profile?.most_reachable_via?.length > 0;

  // contribution considered if any activity with a contribution of theirs is on it
  const hasContribution = activities?.length > 0;

  // contributions require a skill, so either means there is a skill
  const hasSkills =
    person?.declared_skills?.filter((s) => s.type === SKILL_TYPE_EXPERIENCE.id)
      ?.length > 0 || hasContribution;

  // contributions don't require a behavior, so require it be declared
  const hasBehaviors =
    person?.declared_skills?.filter((s) => s.type === SKILL_TYPE_BEHAVIOR.id)
      ?.length > 0;

  // other checks are self-explanatory
  const hasOperatingManualStarted = completedOmCount > 0;
  const hasHalfOfOperatingManual = completedOmCount >= possibleOmCount / 2;
  const hasCompleteFullOperatingManual = completedOmCount === possibleOmCount;

  if (hasCompleteIntro) {
    percent += 10;
  }

  if (hasSkills) {
    percent += 5;
  }

  if (hasBehaviors) {
    percent += 5;
  }

  if (hasOperatingManualStarted) {
    percent += 10;
  }

  if (hasContribution) {
    percent += 10;
  }

  if (hasHalfOfOperatingManual) {
    percent += 10;
  }

  if (hasCompleteFullOperatingManual) {
    percent += 10;
  }

  // NOTE: order below is intentional
  if (!hasCompleteIntro) {
    return {
      percent: percent,
      nextStep: formatMessage({
        id: 'person.profile.completeness.intro.has_complete_intro.next_step',
        defaultMessage: 'Help coworkers get to know you better.',
      }),
      nextActionText: formatMessage({
        id: 'person.profile.completeness.intro.has_complete_intro.next_action_text',
        defaultMessage: 'Complete Intro',
      }),
      nextActionFunction: editIntro,
    };
  }

  if (!hasSkills) {
    return {
      percent: percent,
      nextStep: formatMessage({
        id: 'person.profile.completeness.intro.has_skills.next_step',
        defaultMessage: "Add skills so people know what you're great at.",
      }),
      nextActionText: formatMessage({
        id: 'person.profile.completeness.intro.has_skills.next_action_text',
        defaultMessage: 'Add skills',
      }),
      nextActionFunction: editSkills,
    };
  }

  if (!hasBehaviors) {
    return {
      percent: percent,
      nextStep: formatMessage({
        id: 'person.profile.completeness.intro.has_behaviors.next_step',
        defaultMessage: 'Add behaviors so people can learn more about you.',
      }),
      nextActionText: formatMessage({
        id: 'person.profile.completeness.intro.has_behaviors.next_action_text',
        defaultMessage: 'Add behaviors',
      }),
      nextActionFunction: editBehaviors,
    };
  }

  if (!hasOperatingManualStarted) {
    return {
      percent: percent,
      nextStep: formatMessage({
        id: 'person.profile.completeness.intro.has_operating_manual_started.next_step',
        defaultMessage: 'Help colleagues work with you effectively.',
      }),
      nextActionText: formatMessage({
        id: 'person.profile.completeness.intro.has_operating_manual_started.next_action_text',
        defaultMessage: 'Edit Operating Manual',
      }),
      nextActionUrl: person.url + '/manual',
    };
  }

  if (!hasContribution) {
    return {
      percent: percent,
      nextStep: formatMessage({
        id: 'person.profile.completeness.intro.has_contribution.next_step',
        defaultMessage: "Show coworkers what you've been working on.",
      }),
      nextActionText: formatMessage({
        id: 'person.profile.completeness.intro.has_contribution.next_action_text',
        defaultMessage: 'Share an Activity',
      }),
      nextActionFunction: createActivity,
    };
  }

  if (!hasHalfOfOperatingManual) {
    return {
      percent: percent,
      nextStep: formatMessage({
        id: 'person.profile.completeness.intro.has_half_of_operating_manual.next_step',
        defaultMessage: 'Complete half of your Operating Manual.',
      }),
      nextActionText: formatMessage({
        id: 'person.profile.completeness.intro.has_half_of_operating_manual.next_action_text',
        defaultMessage: 'Edit Operating Manual',
      }),
      nextActionUrl: person.url + '/manual',
    };
  }

  if (!hasCompleteFullOperatingManual) {
    return {
      percent: percent,
      nextStep: formatMessage({
        id: 'person.profile.completeness.intro.has_complete_full_operating_manual.next_step',
        defaultMessage: 'Finish all of your Operating Manual.',
      }),
      nextActionText: formatMessage({
        id: 'person.profile.completeness.intro.has_complete_full_operating_manual.next_action_text',
        defaultMessage: 'Complete Operating Manual',
      }),
      nextActionUrl: person.url + '/manual',
    };
  }

  // 100%, return nothing to indicate it's already complete
  return null;
};

const PersonProfileCompleteness: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const completenessState = useMemo(
    () =>
      getProfileCompletenessState(
        props.features,
        props.person,
        props.activities,
        props.editIntro,
        props.editSkills,
        props.editBehaviors,
        props.createActivity,
        formatMessage
      ),
    [
      props.features,
      props.person,
      props.activities,
      props.editIntro,
      props.editSkills,
      props.editBehaviors,
      props.createActivity,
      formatMessage,
    ]
  );

  // if completed or no info provided, don't render anything
  if (!completenessState || completenessState.percent === 100) {
    return <></>;
  }

  const progressData = {
    datasets: [
      {
        data: [completenessState.percent, 100 - completenessState.percent],
        backgroundColor: ['#2C7BE5', '#D2DDEC'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    tooltips: {
      enabled: false,
    },
    cutoutPercentage: 82,
    animation: {
      duration: 2500,
    },
  };

  return (
    // @ts-expect-error
    <Card style={{ backgroundColor: '#edf2f9', BorderColor: '#d4ddeb' }}>
      <CardBody>
        <Row className="row">
          <Col className="ps-1 pe-1 col-auto">
            <div style={{ position: 'relative', top: '-0.5rem' }}>
              <Doughnut
                data={progressData}
                options={options}
                width={85}
                height={85}
              />
            </div>
            <div
              className="position-absolute fw-bold"
              style={{ top: '3rem', left: '2.75rem' }}
            >
              <strong>
                {completenessState.percent}
                {'%'}
              </strong>
            </div>
          </Col>
          <Col className="col ms-n2">
            <div className="mb-2">
              <strong>
                <FormattedMessage
                  id="app.views.person.person_profile_completeness.complete_your_resume"
                  defaultMessage="Complete your resume"
                />
              </strong>
            </div>
            <p className="small">
              <FormattedMessage
                id="app.views.person.person_profile_completeness.next"
                defaultMessage="<strong>Next:</strong> {nextStep}"
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                  nextStep: completenessState.nextStep,
                }}
              />
            </p>
            {completenessState.nextActionFunction && (
              <Button
                onClick={completenessState.nextActionFunction}
                color="outline-primary"
                className="btn btn-sm"
              >
                {completenessState.nextActionText}
              </Button>
            )}
            {completenessState.nextActionUrl && (
              <Link
                to={completenessState.nextActionUrl}
                className="btn btn-sm btn-primary"
              >
                {completenessState.nextActionText}
              </Link>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const PersonProfileCompleteness_propTypes = {
  person: PropTypes.object.isRequired,
  activities: PropTypes.arrayOf(PropTypes.object.isRequired),
  editIntro: PropTypes.func.isRequired,
  editSkills: PropTypes.func.isRequired,
  editBehaviors: PropTypes.func.isRequired,
  createActivity: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
};

type Props = PropTypes.InferProps<typeof PersonProfileCompleteness_propTypes>;

// These props come from the application's
// state when it is started
const mapStateToProps = (state: ReduxState) => {
  const { features } = state;

  return {
    features,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(PersonProfileCompleteness));
